import MProcessFileDocumentSource from '~/model/MProcessFileDocumentSource'
import MProcessFileMissingDocumentSource from '~/model/MProcessFileMissingDocumentSource'
import MProcessFileOverviewSource from '~/model/MProcessFileOverviewSource'
import MProcessFileUploadSource from '~/model/MProcessFileUploadSource'
import MTimestamp from '~/model/MTimestamp'


export default class MProcessFile {

	static fieldSelection(): string {
		return `{
			archivalTimestamp
			contentType
			creationTimestamp
			dispatchTimestamp
			downloadUrl
			id
			name
			size
			source {
				_type
				... on ProcessFileDocumentSource ${MProcessFileDocumentSource.fieldSelection()}
				... on ProcessFileMissingDocumentSource ${MProcessFileMissingDocumentSource.fieldSelection()}
				... on ProcessFileOverviewSource ${MProcessFileOverviewSource.fieldSelection()}
			}
		}`
	}


	readonly archivalTimestamp: MTimestamp | null
	readonly contentType: string
	readonly creationTimestamp: MTimestamp
	readonly dispatchTimestamp: MTimestamp | null
	readonly downloadUrl: string
	readonly id: string
	readonly name: string
	readonly size: number
	readonly source: MProcessFileDocumentSource | MProcessFileMissingDocumentSource | MProcessFileOverviewSource | MProcessFileUploadSource


	constructor(properties: {
		readonly archivalTimestamp: MTimestamp | null
		readonly contentType: string
		readonly creationTimestamp: MTimestamp
		readonly dispatchTimestamp: MTimestamp | null
		readonly downloadUrl: string
		readonly id: string
		readonly name: string
		readonly size: number
		readonly source: MProcessFileDocumentSource | MProcessFileMissingDocumentSource | MProcessFileOverviewSource | MProcessFileUploadSource
	}) {
		this.archivalTimestamp = properties.archivalTimestamp
		this.contentType = properties.contentType
		this.creationTimestamp = properties.creationTimestamp
		this.dispatchTimestamp = properties.dispatchTimestamp
		this.downloadUrl = properties.downloadUrl
		this.id = properties.id
		this.name = properties.name
		this.size = properties.size
		this.source = properties.source
	}


	static fromJson(json: any) {
		let source

		const sourceType: 'document' | 'missing document' | 'overview' | 'upload' = json.source._type
		switch (sourceType) {
			case 'document':
				source = MProcessFileDocumentSource.fromJson(json.source)
				break

			case 'missing document':
				source = MProcessFileMissingDocumentSource.fromJson(json.source)
				break

			case 'overview':
				source = MProcessFileOverviewSource.fromJson(json.source)
				break

			case 'upload':
				source = MProcessFileUploadSource.fromJson(json.source)
				break
		}


		return new this({
			archivalTimestamp: json.archivalTimestamp ? MTimestamp.parse(json.archivalTimestamp) : null,
			contentType: json.contentType,
			creationTimestamp: MTimestamp.parse(json.creationTimestamp),
			dispatchTimestamp: json.dispatchTimestamp ? MTimestamp.parse(json.dispatchTimestamp) : null,
			downloadUrl: json.downloadUrl,
			id: json.id,
			name: json.name,
			size: json.size,
			source
		})
	}
}
