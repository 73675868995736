
	import equipmentAndMusicInput from '@/main/model/EquipmentAndMusicInput'
	import { Options, Prop, Vue } from 'vue-property-decorator'
	import XCheckbox from '~/components/XCheckbox.vue'
	import XFormRow from '~/components/XFormRow.vue'
	import XInput from '~/components/XInput.vue'
	import XSelect from '~/components/XSelect.vue'
	import icons from '~/styles/icons.module.scss'
	import { compareBy } from '~/utility'


	@Options({
		components: { XCheckbox, XFormRow, XInput, XSelect },
		name: 'x-equipment-and-music-input'
	})
	export default class extends Vue {

		readonly icons = icons

		@Prop() state!: ReturnType<typeof equipmentAndMusicInput>

		tabIndex = 0


		get configurations() {
			return [
				{ label: 'Anlage', inputs: this.state.soundInputs, labels: this.state.soundLabels },
				{ label: 'Licht', inputs: this.state.lightInputs, labels: this.state.lightLabels },
				{ label: 'Musik', inputs: this.state.musicInputs, labels: this.state.musicLabels }
			]
		}


		get currentConfiguration() {
			return this.configurations[this.tabIndex]
		}


		get currentOptions() {
			return Object.entries(this.currentConfiguration.labels)
				.map(([id, label]) => ({ id, label }))
				.sort(compareBy(option => option.label))
		}


		onTabClicked(event: MouseEvent, index: number) {
			(event.currentTarget as HTMLElement).blur()

			this.tabIndex = index
		}
	}
