
	import contactPersonInput from '@/main/model/ContactPersonInput'
	import { Options, Prop, Vue } from 'vue-property-decorator'
	import XCheckbox from '~/components/XCheckbox.vue'
	import XFormRow from '~/components/XFormRow.vue'
	import XIconButton from '~/components/XIconButton.vue'
	import XInput from '~/components/XInput.vue'
	import XLink from '~/components/XLink.vue'
	import XSelect from '~/components/XSelect.vue'
	import MLocalDate from '~/model/MLocalDate'
	import icons from '~/styles/icons.module.scss'


	@Options({
		components: { XFormRow, XCheckbox, XIconButton, XInput, XLink, XSelect },
		name: 'x-contact-person-input'
	})
	export default class extends Vue {

		readonly icons = icons

		@Prop() input!: ReturnType<typeof contactPersonInput>
		@Prop() label?: string
		@Prop() required?: boolean


		get age() {
			const birthday = this.input.birthday.valueOrUndefined
			if (!birthday || birthday.year < 1900) {
				return null
			}

			const age = MLocalDate.now().yearsSince(birthday)
			if (age < 0) {
				return null
			}

			return age
		}


		onChangedNameToggleClicked() {
			const lastName = this.input.changedLastName.value
			const changedLastName = this.input.lastName.value
			const changedLastNameIsAfterWedding = !this.input.changedLastNameIsAfterWedding.value

			this.input.lastName = this.input.lastName.withValue(lastName)
			this.input.changedLastName = this.input.changedLastName.withValue(changedLastName)
			this.input.changedLastNameIsAfterWedding = this.input.changedLastNameIsAfterWedding.withValue(changedLastNameIsAfterWedding)
		}
	}
