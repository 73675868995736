import MInlineText from '~/model/MInlineText'
import MProcessFile from '~/model/MProcessFile'
import MText from '~/model/MText'
import MTimestamp from '~/model/MTimestamp'
import MVariableConfiguration from '~/model/MVariableConfiguration'


export default class MDocument {

	static fieldSelection({ includeFiles, includeVariableConfiguration }: { includeFiles?: boolean, includeVariableConfiguration?: boolean } = {}): string {
		return `{
			archivalTimestamp
			content
			creationTimestamp
			${includeFiles ? `files ${MProcessFile.fieldSelection()}` : ''}
			id
			modificationTimestamp
			name
			recipientAddress
			recipient { id }
			state
			type
			${includeVariableConfiguration ? `variableConfiguration ${MVariableConfiguration.fieldSelection()}` : ''}
		}`
	}


	readonly archivalTimestamp: MTimestamp | null
	readonly content: MText
	readonly files?: MProcessFile[]
	readonly id: string
	readonly creationTimestamp: MTimestamp
	readonly modificationTimestamp: MTimestamp
	readonly name: string
	readonly recipientAddress: MInlineText | null
	readonly recipientId: string
	readonly state: 'draft' | 'final'
	readonly type: 'contract' | 'contractCoverLetter' | 'processOverview' | 'proposal' | 'questionnaire'
	readonly variableConfiguration?: MVariableConfiguration


	constructor(properties: {
		readonly archivalTimestamp: MTimestamp | null
		readonly content: MText
		readonly files?: MProcessFile[]
		readonly id: string
		readonly creationTimestamp: MTimestamp
		readonly modificationTimestamp: MTimestamp
		readonly name: string
		readonly recipientAddress: MInlineText | null
		readonly recipientId: string
		readonly state: 'draft' | 'final'
		readonly type: 'contract' | 'contractCoverLetter' | 'processOverview' | 'proposal' | 'questionnaire'
		readonly variableConfiguration?: MVariableConfiguration
	}) {
		this.archivalTimestamp = properties.archivalTimestamp
		this.content = properties.content
		this.files = properties.files
		this.id = properties.id
		this.creationTimestamp = properties.creationTimestamp
		this.modificationTimestamp = properties.modificationTimestamp
		this.name = properties.name
		this.recipientAddress = properties.recipientAddress
		this.recipientId = properties.recipientId
		this.state = properties.state
		this.type = properties.type
		this.variableConfiguration = properties.variableConfiguration
	}


	static fromJson(json: any): MDocument {
		return new this({
			archivalTimestamp: json.archivalTimestamp ? MTimestamp.parse(json.archivalTimestamp) : null,
			content: MText.parse(json.content),
			creationTimestamp: MTimestamp.parse(json.creationTimestamp),
			files: json.files?.map(it => MProcessFile.fromJson(it)),
			id: json.id,
			modificationTimestamp: MTimestamp.parse(json.modificationTimestamp),
			name: json.name,
			recipientAddress: json.recipientAddress ? MInlineText.parse(json.recipientAddress) : null,
			recipientId: json.recipient?.id ?? null,
			state: json.state,
			type: json.type,
			variableConfiguration: json.variableConfiguration ? MVariableConfiguration.fromJson(json.variableConfiguration) : undefined
		})
	}
}
