import ListenersMixin from '@/ListenersMixin'
import RxMixin from '@/RxMixin'
import XApp from '@/XApp.vue'
import { createApp } from 'vue'
import router from '~/utility/router'


export const app = createApp(XApp)
	.use(router)
	.mixin(ListenersMixin)
	.mixin(RxMixin)

app.mount('#app')
