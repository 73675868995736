import MEquipment from '~/model/MEquipment'


export default class MEventPricingEquipmentOption {

	readonly equipment: MEquipment
	readonly isSelected: boolean
	readonly price: number


	constructor(properties: {
		readonly equipment: MEquipment
		readonly isSelected: boolean
		readonly price: number
	}) {
		this.equipment = properties.equipment
		this.isSelected = properties.isSelected
		this.price = properties.price
	}


	static fromJson(json: any): MEventPricingEquipmentOption {
		return new this({
			equipment: MEquipment.fromJson(json.equipment),
			isSelected: json.isSelected,
			price: json.price / 100
		})
	}
}
