
	import { Options, Prop, Vue } from 'vue-property-decorator'


	@Options({
		name: 'x-process-id'
	})
	export default class extends Vue {

		@Prop() value!: string


		get components(): [string, string, string, string, string, string, string] {
			const match = this.value.match(/^(\d{3})(\d)(\d)(\d)(\d)(\d)-(\d+)$/)
			if (!match) {
				return ['???', '?', '?', '?', '?', '?', '?']
			}

			return [match[1], match[2], match[3], match[4], match[5], match[6], match[7]]
		}
	}
