
	import XCurrentProcessBar from '@/main/components/XCurrentProcessBar.vue'
	import XMainPageContentOld from '@/main/components/XMainPageContentOld.vue'
	import XNoProcess from '@/main/components/XNoProcess.vue'
	import { Inject, Options, Vue, Watch } from 'vue-property-decorator'
	import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
	import Api, { ApiError, ApiResponse, ApiResult, DjPricingUpdateData, FetchEventCompensationResult } from '~/api/Api'
	import XCheckbox from '~/components/XCheckbox.vue'
	import XFormColumn from '~/components/XFormColumn.vue'
	import XFormRow from '~/components/XFormRow.vue'
	import XIconButton from '~/components/XIconButton.vue'
	import XInput from '~/components/XInput.vue'
	import XLink from '~/components/XLink.vue'
	import XLoadingAnimation from '~/components/XLoadingAnimation.vue'
	import XSection from '~/components/XSection.vue'
	import XSelect from '~/components/XSelect.vue'
	import XStatusMessage from '~/components/XStatusMessage.vue'
	import MDj from '~/model/MDj'
	import MDjCategory from '~/model/MDjCategory'
	import MEvent from '~/model/MEvent'
	import MEventCompensation from '~/model/MEventCompensation'
	import MEventPricing from '~/model/MEventPricing'
	import MProcess from '~/model/MProcess'
	import MProcessFile from '~/model/MProcessFile'
	import icons from '~/styles/icons.module.scss'
	import { compareBy, compareWith, error, formatDuration, formatNumber, formatPrice, isNull, isUndefined, reverseOrder, sorted } from '~/utility'
	import { booleanInput, compositeInput, enumInput, EnumInputGroup, integerInput, priceInput, stringInput, timeDurationInput } from '~/utility/Input'


	@Options({
		components: {
			XCheckbox,
			XCurrentProcessBar,
			XFormColumn,
			XFormRow,
			XIconButton,
			XInput,
			XLink,
			XLoadingAnimation,
			XMainPageContentOld,
			XNoProcess,
			XSection,
			XSelect,
			XStatusMessage
		},
		name: 'page-main-dj-pricing'
	})
	export default class extends Vue {

		readonly formatDuration = formatDuration // TODO there must be a better way
		readonly formatNumber = formatNumber

		readonly discardConfirmationMessage = 'Du hast Daten geändert aber noch nicht gespeichert.\nWillst du wirklich fortfahren?'
		readonly icons: { readonly [key: string]: string } = icons

		apiResponse: ApiResponse<FetchEventCompensationResult> | null = null
		input = this.makeInput()
		isBusy = false
		pricingInput = this.makePricingInput()
		status: ApiError | string | null = null
		updatedProcess?: MProcess | null

		@Inject() readonly api!: Api


		beforeRouteLeave(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
			next(this.confirmDiscardingChanges())
		}


		get bookedDjCategory() {
			return this.event?.djCategory ?? null
		}


		confirmDiscardingChanges() {
			if (!this.hasChanges) {
				return true
			}

			return confirm(this.discardConfirmationMessage)
		}


		async created() {
			this.onLoaded(await this.api.fetchEventCompensation())
		}


		data() {
			return {
				updatedProcess: undefined
			}
		}


		destroyed() {
			window.removeEventListener('beforeunload', this.onWindowBeforeUnload)
		}


		get dj() {
			return this.event?.dj
		}


		get djCategories() {
			return this.result.data.djCategories
		}


		get djCategory() {
			return this.dj?.category ?? null
		}


		get djs() {
			return this.result.data.djs
		}


		get event() {
			return this.process?.event ?? null
		}


		get eventDuration() {
			const duration = this.event?.duration
			if (!duration) {
				return null
			}

			return Math.ceil(duration / 30 / 60) * 30 * 60
		}


		get files(): readonly MProcessFile[] {
			const files = this.process?.files?.filter(it => it.source._type === 'overview' && it.source.audience === 'dj')
			if (!files) {
				return []
			}

			return sorted(
				files,
				reverseOrder(compareBy(it => it.creationTimestamp))
			)
		}


		get formattedSubtotal() {
			return this.subtotal === null ? null : formatPrice(this.subtotal)
		}


		get formattedTotalExcludingVat() {
			return this.totalExcludingVat === null ? null : formatPrice(this.totalExcludingVat)
		}


		get formattedTotalIncludingVat() {
			return this.totalIncludingVat === null ? null : formatPrice(this.totalIncludingVat)
		}


		get hasChanges() {
			return !this.input.isInitial() || !this.relevantPricingInput.isInitial()
		}


		get hasDjCategoryMismatch() {
			const dj = this.dj
			const djCategoryId = this.event?.djCategory?.id

			if (!dj || !djCategoryId) {
				return false
			}

			return dj.category.id !== djCategoryId
		}


		get isValid() {
			return this.relevantPricingInput.isValid()
		}


		makeInput(properties?: {
			readonly djCategories: readonly MDjCategory[]
			readonly djs: readonly MDj[]
			readonly event: MEvent | null
		}) {
			const event = properties?.event

			return compositeInput({
				dj: enumInput(
					event?.dj?.id ?? null,
					[
						{ key: null, label: '- noch zu klären -', value: null },
						...sorted(properties?.djCategories ?? [], reverseOrder(compareBy(it => it.id)))
							.map(djCategory => new EnumInputGroup({
								key: djCategory.id,
								label: djCategory.label,
								options: (properties?.djs ?? [])
									.filter(it => it.category.id === djCategory.id)
									.sort(compareWith(compareBy(it => it.lastName), compareBy(it => it.firstName)))
									.map(dj => ({
										key: dj.id,
										label: dj.alias ? `${dj.nameReversed} (${dj.alias})` : dj.nameReversed,
										value: dj
									}))
							}))
							.filter(it => it.options.length)
					]
				),
				djIsFixedByClientRequest: booleanInput(event?.djIsFixedByClientRequest ?? false),
				djIsFixedByProximity: booleanInput(event?.djIsFixedByProximity ?? false),
				djIsFixedByRecommendation: booleanInput(event?.djIsFixedByRecommendation ?? false),
				djIsFixedForOtherReason: stringInput(event?.djIsFixedForOtherReason ?? ''),
				djIsFixedForOtherReasonCheck: booleanInput(Boolean(event?.djIsFixedForOtherReason))
			})
		}


		makePricingInput(properties?: {
			readonly djPricing: MEventCompensation | null
		}) {
			const pricing = properties?.djPricing

			return compositeInput({
				baseFee: priceInput(pricing?.baseFee ?? 0),
				baseHours: timeDurationInput(pricing?.baseHours ?? 0),
				customerPrice: priceInput(pricing?.customerPrice ?? 0),
				customerPriceRemarks: stringInput(pricing?.customerPriceRemarks ?? ''),
				drivingTime: timeDurationInput(pricing?.drivingTime ?? 0),
				drivingTimeCompensation: priceInput(pricing?.drivingTimeCompensation ?? 0),
				lightDetails: stringInput(pricing?.lightDetails ?? ''),
				lightPrice: priceInput(pricing?.lightPrice ?? 0),
				miscLabel1: stringInput(pricing?.miscLabel1 ?? ''),
				miscLabel2: stringInput(pricing?.miscLabel2 ?? ''),
				miscLabel3: stringInput(pricing?.miscLabel3 ?? ''),
				miscPrice1: priceInput(pricing?.miscLabel1 ? (pricing?.miscPrice1 ?? 0) : undefined),
				miscPrice2: priceInput(pricing?.miscLabel2 ? (pricing?.miscPrice2 ?? 0) : undefined),
				miscPrice3: priceInput(pricing?.miscLabel3 ? (pricing?.miscPrice3 ?? 0) : undefined),
				overtimeUnits: integerInput(pricing?.overtimeUnits ?? 0),
				overtimeFee: priceInput(pricing?.overtimeUnits ? (pricing?.overtimeFee ?? 0) : undefined),
				remarks: stringInput(pricing?.remarks ?? ''),
				soundDetails: stringInput(pricing?.soundDetails ?? ''),
				soundPrice: priceInput(pricing?.soundPrice ?? 0)
			})
		}


		mounted() {
			window.addEventListener('beforeunload', this.onWindowBeforeUnload)
		}


		async onCreateCompensationClicked() {
			const event = this.event ?? error()

			this.isBusy = true
			this.status = null

			const response = await this.api.createEventCompensation(event.id)

			this.isBusy = false

			if (response instanceof ApiError) {
				this.status = response

				return
			}

			this.status = 'DJ-Gage wurde eingerichtet.'

			this.onLoaded(response)
		}


		onLoaded(response: ApiResponse<FetchEventCompensationResult>) {
			this.apiResponse = response
			this.updatedProcess = undefined

			if (response instanceof ApiError) {
				return
			}

			this.input = this.makeInput({
				djCategories: response.data.djCategories,
				djs: response.data.djs,
				event: response.currentProcess?.event ?? null
			})
			this.pricingInput = this.makePricingInput({ djPricing: this.pricing })
		}


		@Watch('input.dj')
		onDjChanged() {
			if (!this.input.dj.value) {
				this.input.djIsFixedByClientRequest = this.input.djIsFixedByClientRequest.withValue(false)
				this.input.djIsFixedByProximity = this.input.djIsFixedByProximity.withValue(false)
				this.input.djIsFixedByRecommendation = this.input.djIsFixedByRecommendation.withValue(false)
				this.input.djIsFixedForOtherReason = this.input.djIsFixedForOtherReason.withValue('')
				this.input.djIsFixedForOtherReasonCheck = this.input.djIsFixedForOtherReasonCheck.withValue(false)
			}
		}


		async onDjSubmitted() {
			const event = this.event ?? error()
			const input = this.input
			const djId = input.dj.value?.id ?? null

			this.isBusy = true
			this.status = null

			const response = await this.api.updateEventDj(
				event.id,
				djId,
				input.djIsFixedByClientRequest.value,
				input.djIsFixedByProximity.value,
				input.djIsFixedByRecommendation.value,
				input.djIsFixedForOtherReasonCheck.value ? input.djIsFixedForOtherReason.value : null
			)

			this.isBusy = false

			if (response instanceof ApiError) {
				this.status = response

				return
			}

			this.input = this.makeInput({
				djCategories: this.djCategories,
				djs: this.djs,
				event: response.data?.event ?? null
			})
			this.status = 'DJ wurde gespeichert.'
			this.updatedProcess = response.currentProcess
		}


		async onDjPricingSubmitted() {
			if (!this.isValid) {
				return
			}

			const event = this.event ?? error()

			this.isBusy = true
			this.status = null

			const input = this.pricingInput

			const update: DjPricingUpdateData = {
				baseFee: input.baseFee.value,
				baseHours: input.baseHours.value,
				customerPrice: input.customerPrice.value,
				customerPriceRemarks: input.customerPriceRemarks.value || null,
				drivingTime: input.drivingTime.value,
				drivingTimeCompensation: input.drivingTimeCompensation.value,
				lightDetails: input.lightDetails.value || null,
				lightPrice: input.lightPrice.value,
				miscLabel1: input.miscLabel1.value || null,
				miscLabel2: input.miscLabel2.value || null,
				miscLabel3: input.miscLabel3.value || null,
				miscPrice1: input.miscPrice1.valueOrUndefined ?? null,
				miscPrice2: input.miscPrice2.valueOrUndefined ?? null,
				miscPrice3: input.miscPrice3.valueOrUndefined ?? null,
				overtimeUnits: input.overtimeUnits.value,
				overtimeFee: input.overtimeFee.valueOrUndefined ?? 0,
				remarks: input.remarks.value || null,
				soundDetails: input.soundDetails.value || null,
				soundPrice: input.soundPrice.value
			}

			const response = await this.api.updateEventCompensation(event.id, update)

			this.isBusy = false

			if (response instanceof ApiError) {
				this.status = response

				return
			}

			this.status = 'Änderungen wurden gespeichert.'

			this.onLoaded(response)
		}


		onWindowBeforeUnload(event: Event) {
			if (this.hasChanges) {
				(event as any).returnValue = this.discardConfirmationMessage
			}
		}


		get pricing(): MEventCompensation | null {
			return this.event?.compensation ?? null
		}


		get customerPricing(): MEventPricing | null {
			return this.event?.pricing ?? null
		}


		get process(): MProcess | null {
			return this.updatedProcess === undefined ? this.result.currentProcess : this.updatedProcess
		}


		set process(value: MProcess | null) {
			this.updatedProcess = value
		}


		get relevantPricingInput() {
			const input = this.pricingInput

			function noPrice() {
				return priceInput(null, { optional: true })
			}

			return compositeInput({
				baseFee: input.baseFee,
				baseHours: input.baseHours,
				customerPrice: input.customerPrice,
				customerPriceRemarks: input.customerPriceRemarks,
				drivingTime: input.drivingTime,
				drivingTimeCompensation: input.drivingTimeCompensation,
				lightDetails: input.lightDetails,
				lightPrice: input.lightPrice,
				miscLabel1: input.miscLabel1,
				miscLabel2: input.miscLabel2,
				miscLabel3: input.miscLabel3,
				miscPrice1: input.miscLabel1.value ? input.miscPrice1 : noPrice(),
				miscPrice2: input.miscLabel2.value ? input.miscPrice2 : noPrice(),
				miscPrice3: input.miscLabel3.value ? input.miscPrice3 : noPrice(),
				overtimeUnits: input.overtimeUnits,
				overtimeFee: !input.overtimeUnits.isValid() || input.overtimeUnits.value ? input.overtimeFee : noPrice(),
				remarks: input.remarks,
				soundDetails: input.soundDetails,
				soundPrice: input.soundPrice
			})
		}


		get result(): ApiResult<FetchEventCompensationResult> {
			return this.apiResponse?.asResult() ?? error()
		}


		get subtotal(): number | null {
			const input = this.pricingInput

			return [
				input.baseFee.valueOrUndefined,
				(input.overtimeUnits.valueOrUndefined ?? 1) ? input.overtimeFee.valueOrUndefined : 0,
				input.drivingTimeCompensation.valueOrUndefined,
				input.miscLabel1.value ? input.miscPrice1.valueOrUndefined : 0,
				input.miscLabel2.value ? input.miscPrice2.valueOrUndefined : 0,
				input.miscLabel3.value ? input.miscPrice3.valueOrUndefined : 0
			].reduce<number | null>(
				(subtotal, it) => isUndefined(it) || isNull(subtotal) ? null : (subtotal + it),
				0
			)
		}


		get tags() {
			return this.result.processTags
		}


		get equipmentSubtotal(): number | null {
			const input = this.pricingInput

			return [
				input.lightPrice.valueOrUndefined,
				input.soundPrice.valueOrUndefined
			].reduce<number | null>(
				(subtotal, it) => isUndefined(it) || isNull(subtotal) ? null : (subtotal + it),
				0
			)
		}


		get totalExcludingVat(): number | null {
			const subtotal = this.subtotal
			if (subtotal === null) {
				return null
			}

			const equipmentSubtotal = this.equipmentSubtotal
			if (equipmentSubtotal === null) {
				return null
			}

			return subtotal + equipmentSubtotal
		}


		get totalIncludingVat(): number | null {
			const excludingVat = this.totalExcludingVat
			if (excludingVat === null) {
				return null
			}

			const vatRate = this.customerPricing?.vatRate ?? null
			if (vatRate === null) {
				return null
			}

			return (excludingVat * (vatRate + 100)) / 100
		}
	}
