
	import { Options, Vue } from 'vue-property-decorator'
	import XLink from '~/components/XLink.vue'


	@Options({
		components: { XLink },
		name: 'x-no-process'
	})
	export default class extends Vue {}
