import MDj from '~/model/MDj'
import MLocalDate from '~/model/MLocalDate'
import MLocation from '~/model/MLocation'
import MMagazine from '~/model/MMagazine'
import MWeddingFair from '~/model/MWeddingFair'


export default class MReferral {

	static fieldSelection(): string {
		return `{
			eventDate
			eventDj ${MDj.fieldSelection()}
			eventName
			experienced
			friends
			gastronomy
			google
			internet
			location ${MLocation.fieldSelection()}
			magazine ${MMagazine.fieldSelection()}
			other
			ourWebsite
			weddingFair ${MWeddingFair.fieldSelection()}
		}`
	}


	readonly eventDate: MLocalDate | null
	readonly eventDj: MDj | null
	readonly eventName: string | null
	readonly experienced: boolean
	readonly friends: boolean
	readonly gastronomy: boolean
	readonly google: boolean
	readonly internet: boolean
	readonly location: MLocation | null
	readonly magazine: MMagazine | null
	readonly other: string | null
	readonly ourWebsite: boolean
	readonly weddingFair: MWeddingFair | null


	constructor(properties: {
		readonly eventDate: MLocalDate | null
		readonly eventDj: MDj | null
		readonly eventName: string | null
		readonly experienced: boolean
		readonly friends: boolean
		readonly gastronomy: boolean
		readonly google: boolean
		readonly internet: boolean
		readonly location: MLocation | null
		readonly magazine: MMagazine | null
		readonly other: string | null
		readonly ourWebsite: boolean
		readonly weddingFair: MWeddingFair | null
	}) {
		this.eventDate = properties.eventDate
		this.eventDj = properties.eventDj
		this.eventName = properties.eventName
		this.experienced = properties.experienced
		this.friends = properties.friends
		this.gastronomy = properties.gastronomy
		this.google = properties.google
		this.internet = properties.internet
		this.location = properties.location
		this.magazine = properties.magazine
		this.other = properties.other
		this.ourWebsite = properties.ourWebsite
		this.weddingFair = properties.weddingFair
	}


	get components(): string[] {
		const components: string[] = []

		if (this.eventName || this.eventDate || this.eventDj) {
			let eventText = this.eventName || ''
			if (this.eventDate) {
				if (eventText !== '') {
					eventText += ' '
				}

				eventText += 'am '
				eventText += this.eventDate.format()
			}
			if (this.eventDj) {
				if (eventText !== '') {
					eventText += ' '
				}

				eventText += `mit DJ ${this.eventDj.firstName} ${this.eventDj.lastName}`
			}

			components.push(eventText)
		}
		if (this.weddingFair) {
			components.push(`Hochzeitsmesse "${this.weddingFair.name}"`)
		}
		if (this.magazine) {
			components.push(`Magazin "${this.magazine.name}"`)
		}
		if (this.location) {
			components.push(`Location "${this.location.name}"`)
		}
		if (this.experienced) {
			components.push('erlebt')
		}
		if (this.friends) {
			components.push('Freunde')
		}
		if (this.gastronomy) {
			components.push('Gastro')
		}
		if (this.google) {
			components.push('Google')
		}
		if (this.internet) {
			components.push('Internet')
		}
		if (this.ourWebsite) {
			components.push('MMPT Webseite')
		}
		if (this.other) {
			components.push(`Sonstiges: ${this.other}`)
		}

		return components
	}


	static fromJson(json: any): MReferral {
		return new this({
			eventDate: json.eventDate ? MLocalDate.parse(json.eventDate) : null,
			eventDj: json.eventDj ? MDj.fromJson(json.eventDj) : null,
			eventName: json.eventName,
			experienced: json.experienced,
			friends: json.friends,
			gastronomy: json.gastronomy,
			google: json.google,
			internet: json.internet,
			location: json.location ? MLocation.fromJson(json.location) : null,
			magazine: json.magazine ? MMagazine.fromJson(json.magazine) : null,
			other: json.other,
			ourWebsite: json.ourWebsite,
			weddingFair: json.weddingFair ? MWeddingFair.fromJson(json.weddingFair) : null,
		})
	}
}
