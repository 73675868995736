import MContactPerson from '~/model/MContactPerson'
import MPhoneNumber from '~/model/MPhoneNumber'
import MProcessParticipation from '~/model/MProcessParticipation'
import MReferral from '~/model/MReferral'
import { error } from '~/utility'


export default class MContact {

	static fieldSelection(
		{
			includePersons,
			includeProcessParticipations,
			processParticipationArguments,
		}: {
			includePersons?: boolean
			includeProcessParticipations?: boolean
			processParticipationArguments?: string
		},
	): string {
		return `{
			businessPhoneNumber
			companyName
			faxNumber
			id
			internalRemarks
			${includePersons ? `person1 ${MContactPerson.fieldSelection()}` : ''}
			${includePersons ? `person2 ${MContactPerson.fieldSelection()}` : ''}
			${includeProcessParticipations ? `processParticipations${processParticipationArguments || ''}    ${MProcessParticipation.fieldSelection({ includeProcess: true })}` : ''}
			referral ${MReferral.fieldSelection()}
			remarks
		}`
	}


	private readonly _person1: MContactPerson | null
	private readonly _person2: MContactPerson | null

	readonly businessPhoneNumber: MPhoneNumber | null
	readonly companyName: string
	readonly faxNumber: MPhoneNumber | null
	readonly id: string
	readonly internalRemarks: string | null
	readonly processParticipations: MProcessParticipation[] | null
	readonly referral: MReferral | null
	readonly remarks: string | null


	constructor(properties: {
		readonly businessPhoneNumber: MPhoneNumber | null
		readonly companyName: string
		readonly faxNumber: MPhoneNumber | null
		readonly id: string
		readonly internalRemarks: string | null
		readonly person1: MContactPerson | null
		readonly person2: MContactPerson | null
		readonly processParticipations: MProcessParticipation[] | null
		readonly referral: MReferral | null
		readonly remarks: string | null
	}) {
		this.businessPhoneNumber = properties.businessPhoneNumber
		this.companyName = properties.companyName
		this.faxNumber = properties.faxNumber
		this.id = properties.id
		this.internalRemarks = properties.internalRemarks
		this._person1 = properties.person1
		this._person2 = properties.person2
		this.processParticipations = properties.processParticipations
		this.referral = properties.referral
		this.remarks = properties.remarks
	}


	get names() {
		if (this.person1.lastName && this.person1.lastName === this.person2.lastName && this.person1.firstName && this.person2.firstName) {
			return `${this.person1.firstName} & ${this.person2.firstName} ${this.person1.lastName}`
		}

		const name1 = this.person1.name
		const name2 = this.person2.name

		if (name1) {
			if (name2) {
				return `${name1} & ${name2}`
			}

			return name1
		}
		else {
			return name2 ?? this.companyName
		}
	}


	get namesReverse() {
		if (this.person1.lastName && this.person1.lastName === this.person2.lastName && this.person1.firstName && this.person2.firstName) {
			return `${this.person1.lastName}, ${this.person1.firstName} & ${this.person2.firstName}`
		}

		const name1 = this.person1.nameReverse
		const name2 = this.person2.nameReverse

		if (name1) {
			if (name2) {
				return `${name1} & ${name2}`
			}

			return name1
		}
		else {
			return name2 ?? this.companyName
		}
	}


	get person1(): MContactPerson {
		return this._person1 ?? error('person1 hasn\'t been requested from the API.')
	}


	get person2(): MContactPerson {
		return this._person2 ?? error('person2 hasn\'t been requested from the API.')
	}


	person(id: string): MContactPerson | null {
		if (this._person1 && id === this._person1.id)
			return this.person1
		if (this._person2 && id === this._person2.id)
			return this.person2

		return null
	}


	static fromJson(json: any): MContact {
		return new this({
			businessPhoneNumber: json.businessPhoneNumber ? MPhoneNumber.parse(json.businessPhoneNumber) : null,
			companyName: json.companyName,
			faxNumber: json.faxNumber ? MPhoneNumber.parse(json.faxNumber) : null,
			id: json.id,
			internalRemarks: json.internalRemarks,
			person1: json.person1 ? MContactPerson.fromJson(json.person1) : null,
			person2: json.person2 ? MContactPerson.fromJson(json.person2) : null,
			processParticipations: json.processParticipations ? json.processParticipations.map(it => MProcessParticipation.fromJson(it)) : null,
			referral: json.referral ? MReferral.fromJson(json.referral) : null,
			remarks: json.remarks,
		})
	}
}
