import moment from 'moment'
import MCountry from '~/model/MCountry'
import MIntRange from '~/model/MIntRange'
import MLocation from '~/model/MLocation'


export default class MCity {

	static fieldSelection({ includeLocations }: { includeLocations?: boolean } = {}): string {
		return `{
			country ${MCountry.fieldSelection()}
			drivingTime
			id
			${includeLocations ? `locations ${MLocation.fieldSelection()}` : ''}
			name
			postalCodes
			remarks
		}`
	}


	readonly country: MCountry
	readonly drivingTime: number
	readonly id: string
	readonly locations: readonly MLocation[] | null
	readonly name: string
	readonly postalCodeRange: MIntRange | null // TODO rn to postalCodes
	readonly remarks: string | null


	constructor(properties: {
		readonly country: MCountry
		readonly drivingTime: number
		readonly id: string
		readonly locations: readonly MLocation[] | null
		readonly name: string
		readonly postalCodeRange: MIntRange | null
		readonly remarks: string | null
	}) {
		this.country = properties.country
		this.drivingTime = properties.drivingTime
		this.id = properties.id
		this.locations = properties.locations
		this.name = properties.name
		this.postalCodeRange = properties.postalCodeRange
		this.remarks = properties.remarks
	}


	static fromJson(json: any): MCity {
		return new this({
			country: MCountry.fromJson(json.country),
			drivingTime: moment.duration(json.drivingTime).asSeconds(),
			id: json.id,
			locations: json.locations ? json.locations.map(it => MLocation.fromJson(it)) : null,
			name: json.name,
			postalCodeRange: json.postalCodes ? MIntRange.fromJson(json.postalCodes) : null,
			remarks: json.remarks
		})
	}
}
