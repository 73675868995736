export default class MDjCategory {

	static fieldSelection(): string {
		return `{
			compensationPerHourOfCommute
			compensationPerOvertimeUnit
			compensationInHighSeason
			compensationInLowSeason
			id
			label
			priceExcludingVatInHighSeason
			priceExcludingVatInLowSeason
			pricePerOvertimeUnit
		}`
	}


	readonly compensationPerHourOfCommute: number
	readonly compensationPerOvertimeUnit: number
	readonly compensationInHighSeason: number
	readonly compensationInLowSeason: number
	readonly id: string
	readonly label: string
	readonly priceExcludingVatInHighSeason: number
	readonly priceExcludingVatInLowSeason: number
	readonly pricePerOvertimeUnit: number


	constructor(properties: {
		readonly compensationPerHourOfCommute: number
		readonly compensationPerOvertimeUnit: number
		readonly compensationInHighSeason: number
		readonly compensationInLowSeason: number
		readonly id: string
		readonly label: string
		readonly priceExcludingVatInHighSeason: number
		readonly priceExcludingVatInLowSeason: number
		readonly pricePerOvertimeUnit: number
	}) {
		this.compensationPerHourOfCommute = properties.compensationPerHourOfCommute
		this.compensationPerOvertimeUnit = properties.compensationPerOvertimeUnit
		this.compensationInHighSeason = properties.compensationInHighSeason
		this.compensationInLowSeason = properties.compensationInLowSeason
		this.id = properties.id
		this.label = properties.label
		this.priceExcludingVatInHighSeason = properties.priceExcludingVatInHighSeason
		this.priceExcludingVatInLowSeason = properties.priceExcludingVatInLowSeason
		this.pricePerOvertimeUnit = properties.pricePerOvertimeUnit
	}


	static fromJson(json: any): MDjCategory {
		return new this({
			compensationPerHourOfCommute: json.compensationPerHourOfCommute / 100,
			compensationPerOvertimeUnit: json.compensationPerOvertimeUnit / 100,
			compensationInHighSeason: json.compensationInHighSeason / 100,
			compensationInLowSeason: json.compensationInLowSeason / 100,
			id: json.id,
			label: json.label,
			priceExcludingVatInHighSeason: json.priceExcludingVatInHighSeason / 100,
			priceExcludingVatInLowSeason: json.priceExcludingVatInLowSeason / 100,
			pricePerOvertimeUnit: json.pricePerOvertimeUnit / 100
		})
	}
}
