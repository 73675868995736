import MDocument from '~/model/MDocument'
import MJournalItem from '~/model/MJournalItem'


export default class MDocumentCreatedJournalItem extends MJournalItem {

	static fieldSelection(): string {
		return `{
			document ${MDocument.fieldSelection()}
		}`
	}


	readonly document: MDocument


	constructor(properties: {
		readonly document: MDocument
	}) {
		super('document created', [])

		this.document = properties.document
	}


	static fromJson(json: any) {
		return new this({
			document: MDocument.fromJson(json.document),
		})
	}
}
