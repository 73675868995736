import MPhoneNumber from '~/model/MPhoneNumber'
import { associateBy, Defined, error } from '~/utility'


// TODO Cleanup all names, types, and file structure.

export interface InputDefinition<Value extends Defined, SerializedValue extends Defined> {

	readonly defaultValue: Value | undefined

	readonly hints: InputHints

	isEqualValue(a: Value, b: Value): boolean

	isEqualValueOrUndefined(a: Value | undefined, b: Value | undefined): boolean

	parseValue(value: SerializedValue): { error: string } | { value: Value }

	parseValueOrUndefined(value: SerializedValue): Value | undefined

	serializeValue(value: Value): SerializedValue
}


export interface InputDefinitionWithOptions<Value extends Defined> extends InputDefinition<Value, string> {

	readonly hints: InputHints & { readonly options: InputOption[] }
}


export function emailAddressInputDefinition(properties: {
	readonly required: true
}): InputDefinition<string, string> & { defaultValue: undefined }


export function emailAddressInputDefinition(properties?: {
	readonly required?: boolean
}): InputDefinition<string | null, string> & { defaultValue: null }


export function emailAddressInputDefinition(properties?: {
	readonly required?: boolean
}) {
	return inputDefinition<string | null, string>({
		defaultValue: properties?.required ? undefined : null,
		hints: {
			htmlType: 'email',
			maximumLength: 100,
			required: properties?.required ?? false
		},
		isEqualValue: (a, b) => (a ?? '') === (b ?? ''),
		parseValue: text => {
			text = text.trim()

			if (text === '') {
				if (properties?.required)
					return { error: 'muss angegeben werden' }
				else
					return { value: null }
			}

			if (!/^[^@\s]+@[^@\s]+\.[^@.\s]+$/.test(text))
				return { error: 'ungültige Email-Adresse' }

			return { value: text }
		},
		serializeValue: value => value ?? ''
	})
}


type EnumInputValue = ({ id: string } | { key: string }) & ({ label: string } | { name: string })

export function enumInputDefinition<Value extends EnumInputValue>(properties: {
	readonly required: true
	readonly values: ReadonlyArray<Value>
}): InputDefinition<Value, string> & { defaultValue: undefined }


export function enumInputDefinition<Value extends EnumInputValue>(properties: {
	readonly required?: boolean
	readonly values: ReadonlyArray<Value>
}): InputDefinition<Value | null, string> & { defaultValue: null }


export function enumInputDefinition<Value extends { id?: string, key?: string, label?: string, name?: string }>(properties: {
	readonly required?: boolean
	readonly values: ReadonlyArray<Value>
}) {
	function keyOf(value: Value | null | undefined) {
		if (!value)
			return ''

		const key = value.key ?? value.id ?? error()
		if (key === '')
			error('enumInputDefinition doesn\'t support values with an empty key.')

		return key
	}

	const options = properties.values.map(value => ({
		key: keyOf(value),
		label: value.label ?? value.name ?? error(),
		value
	}))

	const optionsByKey = associateBy(options, it => it.key)

	return inputDefinition<Value | null, string>({
		defaultValue: properties?.required ? undefined : null,
		hints: {
			options,
			required: properties?.required ?? false
		},
		isEqualValue: (a, b) => keyOf(a) === keyOf(b), // TODO Shouldn't treat `null` and `undefined` the same.
		parseValue: text => {
			if (text === '') {
				if (properties?.required)
					return { error: 'muss angegeben werden' }
				else
					return { value: null }
			}

			const element = optionsByKey[text]
			if (!element)
				return { error: 'Auswahl ist nicht möglich' }

			return { value: element.value }
		},
		serializeValue: value => optionsByKey[keyOf(value)]?.key ?? ''
	})
}


export function phoneNumberInputDefinition(properties: {
	readonly required: true
}): InputDefinition<MPhoneNumber, string> & { defaultValue: undefined }


export function phoneNumberInputDefinition(properties?: {
	readonly required?: boolean
}): InputDefinition<MPhoneNumber | null, string> & { defaultValue: null }


export function phoneNumberInputDefinition(properties?: {
	readonly required?: boolean
}) {
	return inputDefinition<MPhoneNumber | null, string>({
		defaultValue: properties?.required ? undefined : null,
		hints: {
			htmlType: 'tel',
			maximumLength: 20,
			required: properties?.required ?? false
		},
		isEqualValue: (a, b) => a === b || (a && b && a.isEqualTo(b)),
		parseValue: text => {
			text = text.trim()

			if (text === '') {
				if (properties?.required)
					return { error: 'muss angegeben werden' }
				else
					return { value: null }
			}

			const value = MPhoneNumber.parseOrNull(text)
			if (!value)
				return { error: 'ungültige Telefonnummer' }

			return { value }
		},
		serializeValue: value => value?.formatInGermany() ?? ''
	})
}


export function stringInputDefinition(properties: {
	readonly maximumLength?: number
	readonly minimumLength?: number
	readonly multiline?: boolean
	readonly required: true
	readonly trim?: boolean
}): InputDefinition<string, string> & { defaultValue: undefined }


export function stringInputDefinition(properties?: {
	readonly maximumLength?: number
	readonly minimumLength?: number
	readonly multiline?: boolean
	readonly required?: boolean
	readonly trim?: boolean
}): InputDefinition<string | null, string> & { defaultValue: null }


export function stringInputDefinition(properties?: {
	readonly maximumLength?: number
	readonly minimumLength?: number
	readonly multiline?: boolean
	readonly required?: boolean
	readonly trim?: boolean
}) {
	return inputDefinition<string | null, string>({
		defaultValue: properties?.required ? undefined : null,
		hints: {
			htmlType: 'text',
			maximumLength: properties?.maximumLength,
			minimumLength: properties?.minimumLength,
			multiline: properties?.multiline ?? false,
			required: properties?.required ?? false
		},
		isEqualValue: (a, b) => (a ?? '') === (b ?? ''),
		parseValue: text => {
			if (properties?.trim ?? true)
				text = text.trim()

			if (text === '') {
				if (properties?.required)
					return { error: 'muss angegeben werden' }
				else
					return { value: null }
			}

			const maximumLength = properties?.maximumLength
			if (maximumLength !== undefined && text.length > maximumLength)
				return { error: `darf maximal ${maximumLength} Zeichen lang sein` }

			const minimumLength = properties?.minimumLength
			if (minimumLength !== undefined && text.length < minimumLength)
				return { error: `muss mindestens ${minimumLength} Zeichen lang sein` }

			return { value: text }
		},
		serializeValue: value => value ?? ''
	})
}


interface InputHints {

	readonly htmlType?: 'email' | 'tel' | 'text'
	readonly maximumLength?: number
	readonly minimumLength?: number
	readonly multiline?: boolean
	readonly options?: InputOption[]
	readonly required: boolean
}


interface InputOption {

	readonly key: string
	readonly label: string
}


function inputDefinition<Value extends Defined, SerializedValue extends Defined>(properties: {
	readonly defaultValue: Value | undefined
	readonly hints: InputHints
	isEqualValue(a: Value, b: Value)
	parseValue(value: SerializedValue): { error: string } | { value: Value }
	serializeValue(value: Value): SerializedValue
}): InputDefinition<Value, SerializedValue> {
	return {
		defaultValue: properties.defaultValue,
		hints: properties.hints,
		isEqualValue: properties.isEqualValue,
		isEqualValueOrUndefined(a: Value | undefined, b: Value | undefined): boolean {
			return (a === b || (a !== undefined && b !== undefined && this.isEqualValue(a, b)))
		},
		parseValue: properties.parseValue,
		parseValueOrUndefined(value: SerializedValue): Value | undefined {
			const result = this.parseValue(value)
			if ('error' in result)
				return undefined

			return result.value
		},
		serializeValue: properties.serializeValue
	}
}
