/* eslint-disable multiline-ternary */
import GraphOperation from '@/graphql/operations/GraphOperation'
import moment from 'moment'
import Api, {
	ApiError,
	ApiResponse,
	ApiResult,
	CityInputData,
	ContactUpdateData,
	CreateCommentInput,
	CreateProcessResult,
	DjPricingUpdateData,
	EventUpdateData,
	FetchCityResult,
	FetchContactResult,
	FetchDashboardResult,
	FetchEmailDataResult,
	FetchEventCompensationResult,
	FetchEventPricingResult,
	FetchEventResult,
	FetchLocationResult,
	FetchRequestResult,
	FetchRequestsResult,
	LocationInputData,
	PricingInputData,
	SearchResult,
	SearchType,
	UpdateCityResult,
	UpdateContactResult,
	UpdateEventResult,
	UpdateLocationResult
} from '~/api/Api'
import MCity from '~/model/MCity'
import MCommentJournalItem from '~/model/MCommentJournalItem'
import MConfiguration from '~/model/MConfiguration'
import MContact from '~/model/MContact'
import MCountry from '~/model/MCountry'
import MDj from '~/model/MDj'
import MDjCategory from '~/model/MDjCategory'
import MDocument from '~/model/MDocument'
import MEmailRecipient from '~/model/MEmailRecipient'
import MEmailRecipientOption from '~/model/MEmailRecipientOption'
import MEquipment from '~/model/MEquipment'
import MError, { MAuthenticationRequiredError } from '~/model/MError'
import MEvent from '~/model/MEvent'
import MEventCompensation from '~/model/MEventCompensation'
import MEventConsultation from '~/model/MEventConsultation'
import MEventOccasion from '~/model/MEventOccasion'
import MEventPricing from '~/model/MEventPricing'
import MInlineText from '~/model/MInlineText'
import MJournalEntry from '~/model/MJournalEntry'
import MLocalDate from '~/model/MLocalDate'
import MLocation from '~/model/MLocation'
import MMagazine from '~/model/MMagazine'
import MMusic from '~/model/MMusic'
import MPhoneNumber from '~/model/MPhoneNumber'
import MProcess from '~/model/MProcess'
import MProcessEmailTemplate from '~/model/MProcessEmailTemplate'
import MProcessFile from '~/model/MProcessFile'
import MProcessParticipation from '~/model/MProcessParticipation'
import MProcessParticipationTag from '~/model/MProcessParticipationTag'
import MProcessTag from '~/model/MProcessTag'
import MRequest from '~/model/MRequest'
import MSalutation from '~/model/MSalutation'
import MText from '~/model/MText'
import MUpload from '~/model/MUpload'
import MUser from '~/model/MUser'
import MWeddingFair from '~/model/MWeddingFair'
import { processSessionManager } from '~/ProcessSessionManager'
import { asString, EmptyObject, error, identity, isNullOrUndefined } from '~/utility'


// TODO Migrate everything to GraphClient & separate operations.
export default class GraphApi implements Api {

	private readonly baseUrl: string


	constructor(properties: {
		readonly baseUrl: string
	}) {
		this.baseUrl = properties.baseUrl
	}


	async archiveJournalEntry(id: string) {
		return error('TODO')
	}


	archiveDocument(id: string) {
		return this.executeGraphOperation({
			type: 'mutation',
			name: 'archiveDocument',
			args: 'id: $id',
			variables: {
				id: ['ID!', id]
			},
			fieldSelection: '{ id }',
			parseResult: () => ({})
		})
	}


	archiveFile(id: string) {
		return this.executeGraphOperation({
			type: 'mutation',
			name: 'archiveProcessFile',
			args: 'id: $id',
			variables: {
				id: ['ID!', id]
			},
			fieldSelection: '{ id }',
			parseResult: () => ({})
		})
	}


	archiveRequest(id: string) {
		return this.executeGraphOperation({
			type: 'mutation',
			name: 'archiveRequest',
			args: 'id: $id',
			variables: {
				id: ['ID!', id]
			},
			fieldSelection: '{ id }',
			parseResult: () => ({})
		})
	}


	async changeEventDjCategory(id: string, djCategoryId: string | null): Promise<ApiResponse<EmptyObject>> {
		const response = await this.executeGraphOperation({
			type: 'mutation',
			name: 'changeEventDjCategory',
			args: 'input: $input',
			variables: {
				input: ['ChangeEventDjCategoryInput!', {
					eventId: id,
					djCategoryId
				}]
			},
			fieldSelection: '{ id }',
			parseResult: () => ({})
		})
		if (response instanceof ApiError) {
			return response
		}

		return (await this.fetchEvent(id)) as ApiResponse<EmptyObject>
	}


	async changeEventLocation(id: string, locationId: string | null): Promise<ApiResponse<MEvent>> {
		const response = await this.executeGraphOperation({
			type: 'mutation',
			name: 'changeEventLocation',
			args: 'input: $input',
			variables: {
				input: ['ChangeEventLocationInput!', {
					eventId: id,
					locationId
				}]
			},
			fieldSelection: MEvent.fieldSelection({ includeProcess: true }), // TODO location page: events, load asynchronously
			parseResult: json => MEvent.fromJson(json)
		})
		if (response instanceof ApiError) {
			return response
		}

		const eventResponse = await this.fetchEvent(id)
		if (eventResponse instanceof ApiError) {
			return eventResponse
		}

		return new ApiResult({ ...eventResponse, data: response.data })
	}


	changeProcessState(id: string, state: string): Promise<ApiResponse<{ process: MProcess }>> {
		return this.executeGraphOperation({
			type: 'mutation',
			name: 'updateProcess',
			args: 'input: $input',
			variables: {
				input: ['UpdateProcessInput!', {
					id,
					status: state
				}]
			},
			fieldSelection: MProcess.fieldSelection({ includeEvent: true, includeParticipations: true }),
			parseResult: json => ({ process: MProcess.fromJson(json) })
		})
	}


	async copyProcess(id: string, eventDate: MLocalDate): Promise<ApiResponse<{ process: MProcess }>> {
		const response = await this.executeGraphOperation({
			type: 'mutation',
			name: 'copyProcess',
			args: 'input: { id: $id, eventDate: $eventDate }',
			variables: {
				id: ['ID!', id],
				eventDate: ['LocalDate!', eventDate.toString()]
			},
			fieldSelection: MProcess.fieldSelection({ includeEvent: true, includeParticipations: true }),
			parseResult: json => ({ process: MProcess.fromJson(json) })
		})
		if (response instanceof ApiError) {
			return response
		}

		const process = response.data.process
		await processSessionManager.open(process.id)

		return response
	}


	createComment(input: CreateCommentInput): Promise<ApiResponse<MJournalEntry<MCommentJournalItem>>> {
		return this.executeGraphOperation({
			type: 'mutation',
			name: 'createComment',
			args: 'input: $input',
			variables: {
				input: ['CreateCommentInput!', {
					message: input.message,
					subjectIds: input.subjectIds
				}]
			},
			fieldSelection: MJournalEntry.fieldSelection(),
			parseResult: json => MJournalEntry.fromJson(json) as MJournalEntry<MCommentJournalItem>
		})
	}


	createContact(properties: {
		businessPhoneNumber: MPhoneNumber | null
		companyName: string | null
		faxNumber: MPhoneNumber | null
		person1: {
			addressCity: string | null
			addressCountryCode: string | null
			addressPostalCode: string | null
			addressStreet: string | null
			emailAddress: string | null
			firstName: string | null
			homePhoneNumber: MPhoneNumber | null
			lastName: string | null
			mobilePhoneNumber: MPhoneNumber | null
			salutationId: string | null
		}
	}): Promise<ApiResponse<MContact>> {
		return this.executeGraphOperation({
			type: 'mutation',
			name: 'createContact',
			args: 'input: $input',
			variables: {
				input: ['CreateContactInput!', {
					businessPhoneNumber: properties.businessPhoneNumber?.toString() || null,
					companyName: properties.companyName,
					faxNumber: properties.faxNumber?.toString() || null,
					person1: {
						address: {
							city: properties.person1.addressCity,
							countryCode: properties.person1.addressCountryCode,
							postalCode: properties.person1.addressPostalCode,
							line1: properties.person1.addressStreet
						},
						emailAddress: properties.person1.emailAddress,
						firstName: properties.person1.firstName,
						homePhoneNumber: properties.person1.homePhoneNumber?.toString() || null,
						lastName: properties.person1.lastName,
						mobilePhoneNumber: properties.person1.mobilePhoneNumber?.toString() || null,
						salutationId: properties.person1.salutationId
					}
				}]
			},
			fieldSelection: MContact.fieldSelection({ includePersons: true }),
			parseResult: json => MContact.fromJson(json)
		})
	}


	createDocument(parameters: {
		content: MText
		processId: string
		recipientAddress: MInlineText | null
		recipientId: string
		type: 'contract' | 'proposal'
	}): Promise<ApiResponse<MDocument>> {
		return this.executeGraphOperation({
			type: 'mutation',
			name: 'createDocument',
			args: 'input: $input',
			variables: {
				input: ['CreateDocumentInput!', {
					content: parameters.content.serialize(),
					processId: parameters.processId,
					recipientAddress: parameters.recipientAddress?.serialize() ?? null,
					recipientId: parameters.recipientId,
					type: parameters.type
				}]
			},
			fieldSelection: MDocument.fieldSelection({ includeVariableConfiguration: true }),
			parseResult: json => MDocument.fromJson(json)
		})
	}


	createDocumentFile(id: string): Promise<ApiResponse<EmptyObject>> {
		return this.executeGraphOperation({
			type: 'mutation',
			name: 'createDocumentFile',
			args: 'input: $input',
			variables: {
				input: ['CreateDocumentFileInput!', {
					documentId: id
				}]
			},
			fieldSelection: '{ id }',
			parseResult: () => ({})
		})
	}


	createEvent(processId: string, occasionId: string): Promise<ApiResponse<{ event: MEvent }>> {
		return this.executeGraphOperation({
			type: 'mutation',
			name: 'createEvent',
			args: 'input: $input',
			variables: {
				input: ['CreateEventInput!', {
					processId,
					occasionId
				}]
			},
			fieldSelection: MEvent.fieldSelection(),
			parseResult: json => ({ event: MEvent.fromJson(json) })
		})
	}


	createEventAttachment(eventId: string, uploadId: string, name: string): Promise<ApiResponse<{ attachment: MProcessFile }>> {
		return this.executeGraphOperation({
			type: 'mutation',
			name: 'createProcessFile',
			args: 'input: $input',
			variables: {
				input: ['CreateProcessFileInput!', {
					eventId,
					name,
					uploadId
				}]
			},
			fieldSelection: MProcessFile.fieldSelection(),
			parseResult: json => ({ attachment: MProcessFile.fromJson(json) })
		})
	}


	async createEventCompensation(
		eventId: string
	): Promise<ApiResponse<FetchEventCompensationResult>> {
		const response = await this.executeGraphOperation({
			type: 'mutation',
			name: 'createEventCompensation',
			args: 'input: $input',
			variables: {
				input: ['CreateEventCompensationInput!', {
					eventId
				}]
			},
			fieldSelection: MEventCompensation.fieldSelection(),
			parseResult: json => ({ djPricing: MEventCompensation.fromJson(json) })
		})
		if (response instanceof ApiError) {
			return response
		}

		const fetchResponse = await this.fetchEventCompensation()
		if (fetchResponse instanceof ApiError) {
			return fetchResponse
		}

		return new ApiResult({ ...fetchResponse, data: { ...fetchResponse.data, ...response.data } })
	}


	async createPricing(
		eventId: string,
		basePrice: number,
		vatIncluded: boolean
	): Promise<ApiResponse<FetchEventPricingResult & { pricing: MEventPricing }>> {
		const response = await this.executeGraphOperation({
			type: 'mutation',
			name: 'createEventPricing',
			args: 'input: $input',
			variables: {
				input: ['CreateEventPricingInput!', {
					basePrice: Math.round(basePrice * 100),
					eventId,
					vatInclusion: vatIncluded ? 'inclusive' : 'exclusive'
				}]
			},
			fieldSelection: MEventPricing.fieldSelection(),
			parseResult: json => ({ pricing: MEventPricing.fromJson(json) })
		})
		if (response instanceof ApiError) {
			return response
		}

		const fetchResponse = await this.fetchPricing(eventId)
		if (fetchResponse instanceof ApiError) {
			return fetchResponse
		}

		return new ApiResult({ ...fetchResponse, data: { ...fetchResponse.data, ...response.data } })
	}


	async createProcess(): Promise<ApiResponse<{ process: MProcess }>> {
		const response = await this.executeGraphOperation({
			type: 'mutation',
			name: 'createProcess',
			fieldSelection: MProcess.fieldSelection({ includeEvent: true, includeParticipations: true }),
			parseResult: json => ({ process: MProcess.fromJson(json) })
		})
		if (response instanceof ApiError) {
			return response
		}

		const process = response.data.process
		await processSessionManager.open(process.id)

		return response
	}


	async createProcessFromRequest(properties: {
		event: { date: MLocalDate | null, dateRemarks: string | null, occasionId: string, occasionDetails: string | null }
		locationId: string | null
		participation: { contactId: string, tagIds: string[] }
		remarks: string | null
		requestId: string
	}): Promise<ApiResponse<CreateProcessResult>> {
		const response = await this.executeGraphOperation({
			type: 'mutation',
			name: 'createProcessForRequest',
			args: `input: {
				event: {
					date: $eventDate
					dateRemarks: $eventDateRemarks
					occasionId: $eventOccasionId
					occasionDetails: $eventOccasionDetails
				}
				locationId: $locationId
				participation: {
					contactId: $participationContactId
					tagIds:    $participationTagIds
				}
				remarks: $remarks
				requestId: $requestId
			}`,
			variables: {
				eventDate: ['LocalDate', properties.event.date?.toString() || null],
				eventDateRemarks: ['Remarks', properties.event.dateRemarks],
				eventOccasionId: ['ID!', properties.event.occasionId],
				eventOccasionDetails: ['String', properties.event.occasionDetails],
				locationId: ['ID', properties.locationId],
				participationContactId: ['ID!', properties.participation.contactId],
				participationTagIds: ['[ID!]!', properties.participation.tagIds],
				remarks: ['Remarks!', properties.remarks],
				requestId: ['ID!', properties.requestId]
			},
			fieldSelection: MProcess.fieldSelection({ includeEvent: true, includeParticipations: true }),
			parseResult: json => ({ process: MProcess.fromJson(json) })
		})
		if (response instanceof ApiError) {
			return response
		}

		const process = response.data.process
		await processSessionManager.open(process.id)

		return response
	}


	deleteUpload(id: string) {
		return this.executeGraphOperation({
			type: 'mutation',
			name: 'deleteUpload',
			args: 'id: $id',
			variables: {
				id: ['ID!', id]
			},
			parseResult: () => ({})
		})
	}


	documentFilePreviewUrl(type: string, eventId: string, recipientId: string, recipientAddress: MInlineText, content: MText) {
		return `${this.baseUrl}/documents/preview?type=${encodeURIComponent(type)}&eventId=${encodeURIComponent(eventId)}&recipientId=${encodeURIComponent(recipientId)}&recipientAddress=${encodeURIComponent(recipientAddress.serialize())}&content=${encodeURIComponent(content.serialize())}`
	}


	fetchCity(id?: string): Promise<ApiResponse<FetchCityResult>> {
		const operations: { name: string, args?: string, fieldSelection: string }[] = [
			{
				name: 'countries',
				fieldSelection: MCountry.fieldSelection()
			}
		]

		const variables: { [key: string]: [string, any] } = {}

		if (id) {
			operations.push({
				name: 'city',
				args: 'id: $cityId',
				fieldSelection: MCity.fieldSelection({ includeLocations: true })
			})

			variables.cityId = ['ID', id]
		}

		return this.executeGraphOperations({
			type: 'query',
			operations,
			variables,
			parseResult: json => {
				const city = json.city ? MCity.fromJson(json.city) : null

				return {
					city,
					countries: json.countries.map(it => MCountry.fromJson(it)),
					defaultCountryId: 'DE',
					locations: city?.locations ?? []
				}
			}
		})
	}


	fetchCityByPostalCode(postalCode: string, countryCode: string): Promise<ApiResponse<{ city: MCity | null }>> {
		return this.executeGraphOperation({
			type: 'query',
			name: 'cityByPostalCode',
			args: 'countryCode: $countryCode, postalCode: $postalCode',
			variables: {
				countryCode: ['CountryCode', countryCode],
				postalCode: ['PostalCode', postalCode]
			},
			fieldSelection: MCity.fieldSelection(),
			parseResult: result => ({
				city: result ? MCity.fromJson(result) : null
			})
		})
	}


	fetchContact(id?: string): Promise<ApiResponse<FetchContactResult>> {
		const operations: { name: string, args?: string, fieldSelection: string }[] = [
			{
				name: 'countries',
				fieldSelection: MCountry.fieldSelection()
			},
			{
				name: 'djs',
				fieldSelection: '{ id firstName lastName }'
			},
			{
				name: 'locations',
				fieldSelection: '{ key:id label:name }'
			},
			{
				name: 'magazines',
				fieldSelection: MMagazine.fieldSelection()
			},
			{
				name: 'processParticipationTags(includeArchived: true)',
				fieldSelection: MProcessParticipationTag.fieldSelection()
			},
			{
				name: 'salutations',
				fieldSelection: MSalutation.fieldSelection()
			},
			{
				name: 'weddingFairs',
				fieldSelection: MWeddingFair.fieldSelection()
			}
		]

		const variables: { [key: string]: [string, any] } = {}

		if (id && id !== 'process') {
			operations.push({
				name: 'contact',
				args: 'id: $contactId',
				fieldSelection: MContact.fieldSelection({ includePersons: true, includeProcessParticipations: true })
			})

			variables.contactId = ['ID', id]
		}

		return this.executeGraphOperations({
			type: 'query',
			operations,
			variables,
			parseResult: (json, currentProcess) => {
				let contact: MContact | null
				if (id === 'process') {
					contact = currentProcess?.primaryParticipation?.contact ?? null
				}
				else {
					contact = json.contact ? MContact.fromJson(json.contact) : null
				}

				return {
					contact,
					countries: json.countries.map(it => MCountry.fromJson(it)),
					djOptions: json.djs.map(({ id: key, firstName, lastName }) => ({
						key,
						label: `${lastName}, ${firstName}`
					})),
					defaultCountryId: 'DE',
					locationOptions: json.locations,
					magazines: json.magazines.map(it => MMagazine.fromJson(it)),
					processParticipationTags: json.processParticipationTags.map(it => MProcessParticipationTag.fromJson(it)),
					salutations: json.salutations.map(it => MSalutation.fromJson(it)),
					weddingFairs: json.weddingFairs.map(it => MWeddingFair.fromJson(it))
				}
			}
		})
	}


	fetchDashboard(tagIds: string[]): Promise<ApiResponse<FetchDashboardResult>> {
		const operations: { alias?: string, name: string, args?: string, fieldSelection: string }[] = [
			{
				name: 'dashboard',
				fieldSelection: `{
					openImportRequestCount
					openWebsiteRequestCount
				}`
			},
			{
				alias: 'openProcesses',
				name: 'processes',
				args: 'hasContractDeadline: true',
				fieldSelection: MProcess.fieldSelection({ includeEvent: true, includeParticipations: true })
			}
		]

		const variables: { [key: string]: [string, any] } = {}

		if (tagIds.length) {
			operations.push({
				alias: 'taggedProcesses',
				name: 'processes',
				args: 'tagIds: $tagIds',
				fieldSelection: MProcess.fieldSelection({
					includeEvent: true,
					includeJournalEntries: '(limit: 1, sortCriteria: creationTimestamp, sortDirection: descending, types: ["comment"])',
					includeParticipations: true
				})
			})

			variables.tagIds = ['[ID!]!', tagIds]
		}

		return this.executeGraphOperations({
			type: 'query',
			operations,
			variables,
			parseResult: json => {
				return {
					openImportRequestCount: json.dashboard.openImportRequestCount,
					openProcesses: json.openProcesses.map((process: any) => MProcess.fromJson(process)),
					openWebsiteRequestCount: json.dashboard.openWebsiteRequestCount,
					taggedProcesses: json.taggedProcesses ? json.taggedProcesses.map((process: any) => MProcess.fromJson(process)) : []
				}
			}
		})
	}


	fetchDj(id: string): Promise<ApiResponse<MDj | null>> {
		return this.executeGraphOperation({
			type: 'query',
			name: 'dj',
			args: 'id: $id',
			variables: {
				id: ['ID!', id]
			},
			fieldSelection: MDj.fieldSelection(),
			parseResult: json => json ? MDj.fromJson(json) : null
		})
	}


	fetchDjCategories(includeArchived = false): Promise<ApiResponse<ReadonlyArray<MDjCategory>>> {
		return this.executeGraphOperation({
			type: 'query',
			name: 'djCategories',
			args: 'includeArchived: $includeArchived',
			variables: {
				includeArchived: ['Boolean!', includeArchived]
			},
			fieldSelection: MDjCategory.fieldSelection(),
			parseResult: json => json.map(it => MDjCategory.fromJson(it))
		})
	}


	fetchDjs(includeArchived = false): Promise<ApiResponse<ReadonlyArray<MDj>>> {
		return this.executeGraphOperation({
			type: 'query',
			name: 'djs',
			args: 'includeArchived: $includeArchived',
			variables: {
				includeArchived: ['Boolean!', includeArchived]
			},
			fieldSelection: MDj.fieldSelection(),
			parseResult: json => json.map(it => MDj.fromJson(it))
		})
	}


	fetchEmailData(type: 'customer' | 'dj' | 'file', defaultFileId?: string): Promise<ApiResponse<FetchEmailDataResult>> {
		const processId = processSessionManager.processId ?? error('Kein Vorgang mehr geöffnet.')

		const operations: { alias?: string, name: string, args?: string, fieldSelection: string }[] = [
			{
				name: 'configuration',
				fieldSelection: MConfiguration.fieldSelection()
			},
			{
				name: 'me',
				fieldSelection: '{ ... on User { id } }'
			},
			{
				name: 'process',
				args: 'id: $processId',
				fieldSelection: MProcess.fieldSelection({ includeEvent: true, includeParticipations: true })
			},
			{
				name: 'users',
				fieldSelection: MUser.fieldSelection()
			}
		]

		if (type === 'customer') {
			operations.push({
				name: 'processEmailTemplates',
				fieldSelection: MProcessEmailTemplate.fieldSelection()
			})
		}

		const variables: { [key: string]: [string, any] } = {
			processId: ['ID!', processId]
		}

		const parseResult = (json: any) => {
			const configuration = MConfiguration.fromJson(json.configuration)
			const process = json.process = MProcess.fromJson(json.process)
			const event = process.event
			const files = process.files

			const file = files.find(it => it.id === defaultFileId)
			const document = file?.source?._type === 'document' ? file.source.document : null
			const dj = event?.dj
			const location = event?.location
			const meId: string = json.me.id // TODO could be null if logged out
			const templates = json.processEmailTemplates?.map(it => MProcessEmailTemplate.fromJson(it)) ?? []
			const users = json.users.map(it => MUser.fromJson(it))
			const date = event?.date ? ` am ${event.date.format()}` : ''

			let defaultMessage = ''
			let defaultSubject = ''

			switch (type) {
				case 'customer':
					break

				case 'dj':
					if (dj && event && location) {
						defaultSubject = `Informationen zur Veranstaltung${date}. Vorgang ${process.externalId}`
						defaultMessage = `BITTE BESTÄTIGE UNS MÖGLICHST UMGEHEND DEN ERHALT DEINER UNTERLAGEN KURZ PER EMAIL. DANKE!

Hallo ${dj.firstName},

anbei die Unterlagen zu deinem Job${date} in ${location.address.city}.

Viel Spaß und viele Grüße!
Laura


______________________________________________________________________

mr. mac's party team | Steffen Eifert
Endersbacher Str. 42 | 70374 Stuttgart
Tel 0711.78 74 78 0 | Fax 0711.78 74 78 22
info@mrmac.de | https://www.mrmac.de
______________________________________________________________________

Sie sind nicht der richtige Empfänger dieser E-Mail von mr. mac's party team?
Dann sagen Sie mir doch bitte kurz Bescheid. Vielen Dank.

Diese E-Mail enthält eventuell vertrauliche und/oder rechtlich geschützte Informationen.
Das unerlaubte Kopieren sowie die unbefugte Weitergabe dieser Mail sind nicht gestattet
______________________________________________________________________`
					}
					break

				case 'file':
					if (event && file) {
						switch (document?.type) {
							case 'contract':
								defaultSubject = `Buchungsbestätigung für Ihre Veranstaltung${date} (Vorgangsnummer: ${process.externalId})`
								break

							case 'proposal':
								defaultSubject = `Ihre Anfrage bei mr. mac's party team (Vorgangsnummer: ${process.externalId})`
								defaultMessage = `Hallo!

Vielen Dank für die Anfrage. Die gewünschten Informationen befinden sich in der beigefügten Datei.
Bei weiteren Fragen bitte anrufen oder mailen.

Viele Grüße

Mr. Mac's Party Team

Tel:  0711/ 78 74 78 0
Fax: 0711/ 78 74 78 22`
								break

							default:
								break
						}
					}
					break

				default:
					error()
			}

			const emailAddresses = new Set<string>()
			const recipientOptions: MEmailRecipientOption[] = []
			for (const participation of process.participations!) {
				const contact = participation.contact!
				if (contact.person1.emailAddress && !emailAddresses.has(contact.person1.emailAddress)) {
					emailAddresses.add(contact.person1.emailAddress)
					recipientOptions.push(new MEmailRecipientOption({
						isSelected: document?.recipientId === contact.person1.id,
						recipient: new MEmailRecipient({
							address: contact.person1.emailAddress,
							name: contact.person1.name
						}),
						tags: participation.tags.map(it => it.label)
					}))
				}
				if (contact.person2.emailAddress && !emailAddresses.has(contact.person2.emailAddress)) {
					emailAddresses.add(contact.person2.emailAddress)
					recipientOptions.push(new MEmailRecipientOption({
						isSelected: document?.recipientId === contact.person2.id,
						recipient: new MEmailRecipient({
							address: contact.person2.emailAddress,
							name: contact.person2.name
						}),
						tags: participation.tags.map(it => it.label)
					}))
				}
			}
			if (dj && dj.emailAddress) {
				recipientOptions.push(new MEmailRecipientOption({
					isSelected: type === 'dj',
					recipient: new MEmailRecipient({
						address: dj.emailAddress,
						name: dj.name
					}),
					tags: ['DJ']
				}))
			}

			const senderOptions = users.map(user => new MEmailRecipientOption({
				isSelected: user.id === meId,
				recipient: new MEmailRecipient({
					address: user.emailAddress,
					name: `${user.firstName} ${user.lastName}`
				}),
				tags: []
			}))

			const copyRecipientOptions = (type === 'dj' ? configuration.djEmailCopyRecipients : configuration.emailCopyRecipients)
				.map(recipient => new MEmailRecipientOption({
					isSelected: true,
					recipient,
					tags: []
				}))

			return {
				copyRecipientOptions,
				defaultFile: file ? { name: file.name, source: file } : null,
				defaultMessage,
				defaultSubject,
				recipientOptions,
				senderOptions,
				templates
			}
		}

		return this.executeGraphOperations({
			type: 'query',
			operations,
			variables,
			parseResult
		})
	}


	fetchEvent(id: string | 'process'): Promise<ApiResponse<FetchEventResult>> {
		if (!id) {
			error('ID not set.')
		}

		const processId = processSessionManager.processId
		const operations: { name: string, args?: string, fieldSelection: string }[] = [
			{
				name: 'djCategories',
				fieldSelection: MDjCategory.fieldSelection()
			},
			{
				name: 'equipments',
				fieldSelection: MEquipment.fieldSelection()
			},
			{
				name: 'eventConsultations',
				fieldSelection: MEventConsultation.fieldSelection()
			},
			{
				name: 'eventOccasions',
				fieldSelection: MEventOccasion.fieldSelection()
			},
			{
				name: 'musicPreferences',
				fieldSelection: MMusic.fieldSelection()
			}
		]

		const variables: { [key: string]: [string, any] } = {}

		if (id !== 'process') {
			operations.push({
				name: 'event',
				args: 'id: $eventId',
				fieldSelection: MEvent.fieldSelection({ includeProcess: true })
			})

			variables.eventId = ['ID!', id]
		}
		else if (processId) {
			operations.push({
				name: 'process',
				args: 'id: $processId',
				fieldSelection: MProcess.fieldSelection({ includeEvent: true, includeParticipations: true })
			})

			variables.processId = ['ID!', processId]
		}

		return this.executeGraphOperations({
			type: 'query',
			operations,
			variables,
			parseResult: (json: any) => {
				const event = json.event ? MEvent.fromJson(json.event) : null
				const process = json.process ? MProcess.fromJson(json.process) : null

				return {
					djCategories: json.djCategories.map(it => MDjCategory.fromJson(it)),
					equipments: json.equipments.map(it => MEquipment.fromJson(it)),
					eventConsultations: json.eventConsultations.map(it => MEventConsultation.fromJson(it)),
					eventOccasions: json.eventOccasions.map(it => MEventOccasion.fromJson(it)),
					event: id === 'process' ? (process?.event ?? null) : event,
					musics: json.musicPreferences.map(it => MMusic.fromJson(it)),
				}
			}
		})
	}


	fetchEventCompensation() {
		return this.executeGraphOperations({
			type: 'query',
			operations: [
				{
					name: 'djCategories',
					fieldSelection: MDjCategory.fieldSelection()
				},
				{
					name: 'djs',
					fieldSelection: MDj.fieldSelection()
				}
			],
			parseResult: json => ({
				djCategories: json.djCategories.map(it => MDjCategory.fromJson(it)),
				djs: json.djs.map(it => MDj.fromJson(it))
			})
		})
	}


	fetchLocation(id: string | 'process' | undefined): Promise<ApiResponse<FetchLocationResult>> {
		const operations: { name: string, args?: string, fieldSelection: string }[] = [
			{
				name: 'countries',
				fieldSelection: MCountry.fieldSelection()
			}
		]

		const variables: { [key: string]: [string, any] } = {}

		if (id && id !== 'process') {
			operations.push({
				name: 'location',
				args: 'id: $locationId',
				fieldSelection: MLocation.fieldSelection({ includeCity: true, includeEvents: true })
			})

			variables.locationId = ['ID', id]
		}

		return this.executeGraphOperations({
			type: 'query',
			operations,
			variables,
			parseResult: (json, currentProcess) => {
				let location
				if (id === 'process') {
					location = currentProcess?.event?.location ?? null
				}
				else {
					location = json.location ? MLocation.fromJson(json.location) : null
				}

				return {
					city: location?.city ?? null,
					countries: json.countries.map(it => MCountry.fromJson(it)),
					defaultCountryId: 'DE',
					location
				}
			}
		})
	}


	fetchOpenRequests(): Promise<ApiResponse<FetchRequestsResult>> {
		return this.executeGraphOperation({
			type: 'query',
			name: 'requests',
			args: 'includeArchived: false',
			fieldSelection: MRequest.fieldSelection(),
			parseResult: json => ({ requests: json.map((request: any) => MRequest.fromJson(request)) })
		})
	}


	async fetchPricing(eventId?: string): Promise<ApiResponse<FetchEventPricingResult>> {
		const response = await this.fetchEvent(eventId ?? 'process')
		if (response instanceof ApiError) {
			return response
		}

		let documents: MDocument[]
		const processId = response.currentProcess?.id
		if (processId) {
			const docResponse = await this.executeGraphOperation({
				type: 'query',
				name: 'process',
				args: 'id: $id',
				variables: {
					id: ['ID!', processId]
				},
				fieldSelection: `{ documents(includeArchived: false) ${MDocument.fieldSelection({ includeFiles: true })} }`,
				parseResult: json => json.documents.map(it => MDocument.fromJson(it))
			})
			if (docResponse instanceof ApiError) {
				return docResponse
			}

			documents = docResponse.data
		}
		else {
			documents = []
		}

		return new ApiResult({ ...response, data: { ...response.data, documents, pricing: response.data.event?.pricing ?? null } })
	}


	fetchProcess(id: string) {
		return this.executeGraphOperation({
			type: 'query',
			name: 'process',
			args: 'id: $id',
			variables: {
				id: ['ID', id]
			},
			fieldSelection: MProcess.fieldSelection({ includeEvent: true, includeParticipations: true }),
			parseResult: json => json ? MProcess.fromJson(json) : null
		})
	}


	fetchProcessJournalEntries(processId: string) {
		return this.executeGraphOperation({
			type: 'query',
			name: 'process',
			args: 'id: $id',
			variables: {
				id: ['ID', processId]
			},
			fieldSelection: `{ journalEntries ${MJournalEntry.fieldSelection()} }`,
			parseResult: json => json.journalEntries.map(it => MJournalEntry.fromJson(it))
		})
	}


	fetchRequest(id: string): Promise<ApiResponse<FetchRequestResult>> {
		return this.executeGraphOperations({
			type: 'query',
			operations: [
				{
					name: 'eventOccasions',
					fieldSelection: MEventOccasion.fieldSelection()
				},
				{
					name: 'processParticipationTags(includeArchived: true)',
					fieldSelection: MProcessParticipationTag.fieldSelection()
				},
				{
					name: 'salutations',
					fieldSelection: MSalutation.fieldSelection()
				},
				{
					name: 'request',
					args: 'id: $requestId',
					fieldSelection: MRequest.fieldSelection({ includePotentialMatches: true })
				}
			],
			variables: {
				requestId: ['ID', id]
			},
			parseResult: json => {
				return {
					eventTypeOptions: json.eventOccasions.map(it => MEventOccasion.fromJson(it)),
					participantRoleOptions: json.processParticipationTags.map(it => MProcessParticipationTag.fromJson(it)),
					request: MRequest.fromJson(json.request),
					salutationOptions: json.salutations.map(it => MSalutation.fromJson(it))
				}
			}
		})
	}


	async importRequests(file: File): Promise<ApiResponse<{ requests: MRequest[] }>> {
		const csv = await readFileAsText(file)

		return this.executeGraphOperation({
			type: 'mutation',
			name: 'importRequestsFromCsv',
			args: 'csv: $csv',
			fieldSelection: MRequest.fieldSelection(),
			variables: {
				csv: ['String!', csv]
			},
			parseResult: json => ({ requests: json.map(it => MRequest.fromJson(it)) })
		})
	}


	fetchSeason(date: MLocalDate): Promise<ApiResponse<'high' | 'low'>> {
		return this.executeGraphOperation({
			type: 'query',
			name: 'eventSeasonByDate',
			args: 'date: $date',
			variables: {
				date: ['LocalDate', date.toString()]
			},
			parseResult: identity
		})
	}


	findProcessesByEventDate(date: MLocalDate): Promise<ApiResponse<{ readonly processes: readonly MProcess[] }>> {
		return this.executeGraphOperation({
			type: 'query',
			name: 'eventsByDate',
			args: 'date: $date',
			variables: {
				date: ['LocalDate', date.toString()]
			},
			fieldSelection: MEvent.fieldSelection({ includeProcess: true }),
			parseResult: result => ({
				processes: result.map(json => {
					const event = MEvent.fromJson(json)
					const process = event.process!;
					(process as any).event = event

					return process
				})
			})
		})
	}


	async execute<Output>(operation: GraphOperation<Output>): Promise<Output | MError> {
		const field = `${operation.name}${operation.arguments ? `(${operation.arguments})` : ''} ${operation.fieldSelection}`
		const variables = operation.variables
		const variableNames = Object.keys(variables)
		const variableValues: Record<string, any> = {}

		let variableDefinitions = ''
		if (variableNames.length) {
			variableDefinitions = '(' + variableNames
				.map(variableName => `$${variableName}: ${variables[variableName][0]}`)
				.join(', ') + ')'

			variableNames.forEach(it => variableValues[it] = variables[it][1])
		}

		const body = JSON.stringify({
			query: `${operation.type}${variableDefinitions} {
				${field}
			}`,
			variables: variableValues
		})
		const headers: { [key: string]: string } = { 'Content-Type': 'application/json; charset=utf-8' }

		let response: Response
		try {
			response = await fetch(
				`${this.baseUrl}/graphql`,
				{
					body,
					credentials: 'include',
					headers,
					method: 'post'
				}
			)
		}
		catch (e) {
			return new MError({
				cause: e,
				graphOperationName: operation.name,
				isTemporary: true,
				userMessage: 'Die Kundendatenbank konnte nicht erreicht werden.'
			})
		}

		if (!response.ok) {
			if (response.status === 401) {
				return new MAuthenticationRequiredError({
					developerMessage: 'The server responded with status code 401.',
					graphOperationName: operation.name,
					isTemporary: false,
					userMessage: 'Du bist nicht länger angemeldet.'
				})
			}

			return new MError({
				developerMessage: `The server responded with status code ${response.status} (${response.statusText}).`,
				graphOperationName: operation.name,
				isTemporary: response.status >= 500 && response.status <= 599,
				userMessage: 'Die Kundendatenbank ist eventuell außer Betrieb.'
			})
		}

		let json: any
		try {
			json = await response.json()
		}
		catch (e) {
			console.error(e)

			return new MError({
				cause: e,
				graphOperationName: operation.name,
				isTemporary: true,
				userMessage: 'Die Kundendatenbank ist eventuell außer Betrieb.'
			})
		}

		try {
			if (json.errors) {
				const error = json.errors[0]

				// TODO support developer & user message
				return new MError({
					developerMessage: asString(error.message) || 'Unbekannter Fehler',
					graphOperationName: operation.name,
					isTemporary: false,
					userMessage: asString(error.message) || 'Unbekannter Fehler'
				})
			}

			return operation.parseData(json.data[operation.name])
		}
		catch (e) {
			console.error(e)

			return new MError({
				cause: e,
				graphOperationName: operation.name,
				isTemporary: false,
				userMessage: 'Die Kundendatenbank arbeitet nicht korrekt.\n' +
					'Eventuell ist eine neue Version verfügbar und ein erneutes Laden der Seite löst das Problem.\n' +
					'Bleibt das Problem bestehen dann melde es bitte dem zuständigen Techniker.'
			})
		}
	}


	private async executeGraphOperation<Result>({ type, name, args, variables, fieldSelection, parseResult }: {
		type: 'mutation' | 'query'
		name: string
		args?: string
		variables?: { [_: string]: [string, any] }
		fieldSelection?: string
		parseResult: (json: any, currentProcess: MProcess | null) => Result
	}): Promise<ApiResponse<Result>> {
		return this.executeGraphOperations({
			type,
			operations: [{
				name,
				args,
				fieldSelection
			}],
			variables,
			parseResult: (json, currentProcess) => parseResult(json[name], currentProcess)
		})
	}


	private async executeGraphOperations<Result>({ type, operations, variables, parseResult }: {
		type: 'mutation' | 'query'
		operations: { alias?: string, name: string, args?: string, fieldSelection?: string }[]
		variables?: { [_: string]: [string, any] }
		parseResult: (json: any, currentProcess: MProcess | null) => Result
	}): Promise<ApiResponse<Result>> {
		const variableValues: { [key: string]: any } = {}
		const allVariables = variables || {}

		const currentProcessId = processSessionManager.processId

		const fields = operations.map(operation => {
			const args = operation.args ? `(${operation.args})` : ''

			return `${operation.alias ? `${operation.alias}: ` : ''}${operation.name}${args} ${operation.fieldSelection || ''}`
		})
		if (type === 'query') {
			fields.push(`processTags ${MProcessTag.fieldSelection()}`)
			if (currentProcessId) {
				fields.push(`currentProcess: process(id: $currentProcessId) ${MProcess.fieldSelection({
					includeEvent: true,
					includeParticipations: true
				})}`)
				allVariables.currentProcessId = ['ID', currentProcessId]
			}
		}

		let variableDefinitions = ''
		if (Object.keys(allVariables).length) {
			variableDefinitions = '(' + Object.keys(allVariables)
				.map(variableName => `$${variableName}: ${allVariables[variableName][0]}`)
				.join(', ') + ')'

			Object.keys(allVariables).forEach(variableName => variableValues[variableName] = allVariables[variableName][1])
		}

		const body = JSON.stringify({
			query: `${type}${variableDefinitions} {
				${fields.join('\n')}
			}`,
			variables: variableValues || {}
		})
		const headers: { [key: string]: string } = { 'Content-Type': 'application/json; charset=utf-8' }

		const graphqlOperationName = operations.map(it => it.name).join(' + ')

		let response: Response
		try {
			response = await fetch(
				`${this.baseUrl}/graphql`,
				{
					body,
					credentials: 'include',
					headers,
					method: 'post'
				}
			)
		}
		catch (e) {
			console.error(e)

			return new ApiError({
				cause: e,
				graphqlOperationName,
				userMessage: 'Die Kundendatenbank konnte nicht erreicht werden.'
			})
		}

		if (!response.ok) {
			if (response.status === 401) {
				return new ApiError({
					graphqlOperationName,
					developerMessage: `The server responded with status code ${response.status} (${response.statusText}).`,
					newError: new MAuthenticationRequiredError({ userMessage: 'Du bist nicht länger angemeldet.' }),
					userMessage: 'Die Kundendatenbank ist eventuell außer Betrieb.'
				})
			}

			return new ApiError({
				graphqlOperationName,
				developerMessage: `The server responded with status code ${response.status} (${response.statusText}).`,
				userMessage: 'Die Kundendatenbank ist eventuell außer Betrieb.'
			})
		}

		let json
		try {
			json = await response.json()
		}
		catch (e) {
			console.error(e)

			return new ApiError({
				cause: e,
				graphqlOperationName,
				userMessage: 'Die Kundendatenbank ist eventuell außer Betrieb.'
			})
		}

		try {
			if (json.errors) {
				const error = json.errors[0]

				// TODO support developer & user message
				return new ApiError({
					graphqlOperationName,
					developerMessage: asString(error.message) || 'Unbekannter Fehler',
					userMessage: asString(error.message) || 'Unbekannter Fehler'
				})
			}

			const currentProcess = json.data.currentProcess ? MProcess.fromJson(json.data.currentProcess) : null

			return new ApiResult<Result>({
				currentProcess,
				data: parseResult(json.data, currentProcess),
				processTags: json.data.processTags ? json.data.processTags.map((json: any) => MProcessTag.fromJson(json)) : null
			})
		}
		catch (e) {
			console.error(e)

			return new ApiError({
				cause: e,
				graphqlOperationName,
				userMessage: 'Die Kundendatenbank arbeitet nicht korrekt.\n' +
					'Eventuell ist eine neue Version verfügbar und ein erneuter Versuch löst das Problem.\n' +
					'Bleibt das Problem bestehen dann melde es bitte dem zuständigen Techniker.'
			})
		}
	}


	queryProcessesByTags(tagIds: string[]): Promise<ApiResponse<MProcess[]>> {
		return this.executeGraphOperation({
			type: 'query',
			name: 'processes',
			args: 'tagIds: $tagIds',
			variables: {
				tagIds: ['[ID!]!', tagIds]
			},
			fieldSelection: MProcess.fieldSelection({ includeEvent: true, includeParticipations: true }),
			parseResult: json => json.map(it => MProcess.fromJson(it))
		})
	}


	async removeContactFromProcess(contactId: string, processId: string): Promise<ApiResponse<UpdateContactResult>> {
		const removeResponse = await this.executeGraphOperation({
			type: 'mutation',
			name: 'removeProcessParticipant',
			args: 'input: $input',
			variables: {
				input: ['RemoveProcessParticipantInput!', {
					contactId,
					processId
				}]
			},
			fieldSelection: '{ id }',
			parseResult: () => null
		})
		if (removeResponse instanceof ApiError) {
			return removeResponse
		}

		return await this.fetchContact(contactId) as ApiResponse<UpdateContactResult>
	}


	search(parameters: { offset?: number, query: string, type?: SearchType }): Promise<ApiResponse<SearchResult>> {
		return this.executeGraphOperation({
			type: 'query',
			name: 'search',
			args: 'offset: $offset, query: $query, type: $type',
			variables: {
				offset: ['Int', parameters.offset],
				query: ['SearchQuery!', parameters.query],
				type: ['SearchType', parameters.type ?? null]
			},
			fieldSelection: `{
				cityResult {
					elements ${MCity.fieldSelection()}
					nextOffset
					totalCount
				}
				contactResult {
					elements ${MContact.fieldSelection({ includePersons: true })}
					nextOffset
					totalCount
				}
				locationResult {
					elements ${MLocation.fieldSelection()}
					nextOffset
					totalCount
				}
				processResult {
					elements ${MProcess.fieldSelection({ includeEvent: true, includeParticipations: true })}
					nextOffset
					totalCount
				}
			}`,
			parseResult: result => ({
				cityResult: result.cityResult ? {
					elements: result.cityResult.elements.map(it => MCity.fromJson(it)),
					nextOffset: result.cityResult.nextOffset,
					totalCount: result.cityResult.totalCount
				} : null,
				contactResult: result.contactResult ? {
					elements: result.contactResult.elements.map(it => MContact.fromJson(it)),
					nextOffset: result.contactResult.nextOffset,
					totalCount: result.contactResult.totalCount
				} : null,
				locationResult: result.locationResult ? {
					elements: result.locationResult.elements.map(it => MLocation.fromJson(it)),
					nextOffset: result.locationResult.nextOffset,
					totalCount: result.locationResult.totalCount
				} : null,
				processResult: result.processResult ? {
					elements: result.processResult.elements.map(it => MProcess.fromJson(it)),
					nextOffset: result.processResult.nextOffset,
					totalCount: result.processResult.totalCount
				} : null
			})
		})
	}


	sendEmail(parameters: {
		copyRecipients: MEmailRecipient[]
		files: { name: string, source: MProcessFile | MUpload }[]
		message: string
		processId: string
		recipients: MEmailRecipient[]
		sender: MEmailRecipient
		subject: string
	}) {
		return this.executeGraphOperation({
			type: 'mutation',
			name: 'sendProcessEmail',
			args: 'input: $input',
			variables: {
				input: ['SendProcessEmailInput!', {
					attachments: parameters.files.map(file => ({
						processFile: file.source instanceof MProcessFile ? { id: file.source.id, name: file.name } : null,
						upload: file.source instanceof MUpload ? { id: file.source.id, name: file.name } : null
					})),
					blindCopyRecipients: parameters.copyRecipients.map(it => ({ address: it.address, name: it.name })),
					message: parameters.message,
					processId: parameters.processId,
					recipients: parameters.recipients.map(it => ({ address: it.address, name: it.name })),
					sender: { address: parameters.sender.address, name: parameters.sender.name },
					subject: parameters.subject
				}]
			},
			parseResult: () => ({})
		})
	}


	async updateCity(id: string, data: CityInputData): Promise<ApiResponse<UpdateCityResult>> {
		const cityResponse = await this.executeGraphOperation({
			type: 'mutation',
			name: id === 'new' ? 'createCity' : 'updateCity',
			args: 'input: $input',
			variables: {
				input: [id === 'new' ? 'CreateCityInput!' : 'UpdateCityInput!', {
					countryCode: data.countryCode,
					drivingTime: moment.duration(data.drivingTime, 'seconds').toISOString(),
					id: id === 'new' ? undefined : id,
					name: data.name,
					postalCodes: data.postalCodeRangeStart || data.postalCodeRangeEnd
						? { start: data.postalCodeRangeStart || data.postalCodeRangeEnd, endInclusive: data.postalCodeRangeEnd || data.postalCodeRangeStart }
						: null,
					remarks: data.remarks
				}]
			},
			fieldSelection: MCity.fieldSelection(),
			parseResult: json => MCity.fromJson(json)
		})
		if (cityResponse instanceof ApiError) {
			return cityResponse
		}

		return await this.fetchCity(cityResponse.data?.id ?? id) as ApiResponse<UpdateCityResult>
	}


	async updateContact(id: string, data: ContactUpdateData): Promise<ApiResponse<UpdateContactResult>> {
		const processId = processSessionManager.processId

		const contactResponse = await this.executeGraphOperation({
			type: 'mutation',
			name: id === 'new' ? 'createContact' : 'updateContact',
			args: 'input: $input',
			variables: {
				input: [id === 'new' ? 'CreateContactInput' : 'UpdateContactInput!', {
					businessPhoneNumber: data.businessPhoneNumber,
					companyName: data.companyName,
					faxNumber: data.faxNumber,
					id: id === 'new' ? undefined : id,
					internalRemarks: data.internalRemarks,
					person1: {
						address: {
							city: data.person1.addressCity,
							countryCode: data.person1.addressCountryCode,
							line1: data.person1.addressStreet,
							line2: data.person1.addressAddition,
							postalCode: data.person1.addressPostalCode
						},
						birthday: data.person1.birthday,
						canUseInformalCommunication: data.person1.canUseInformalCommunication,
						changedLastName: data.person1.changedLastName,
						changedLastNameIsAfterWedding: data.person1.changedLastNameIsAfterWedding,
						emailAddress: data.person1.emailAddress,
						firstName: data.person1.firstName,
						homePhoneNumber: data.person1.homePhoneNumber,
						lastName: data.person1.lastName,
						mobilePhoneNumber: data.person1.mobilePhoneNumber,
						noNewsletter: data.person1.noNewsletter,
						salutationId: data.person1.salutationId,
						title: data.person1.title
					},
					person2: {
						address: {
							city: data.person2.addressCity,
							countryCode: data.person2.addressCountryCode,
							line1: data.person2.addressStreet,
							line2: data.person2.addressAddition,
							postalCode: data.person2.addressPostalCode
						},
						birthday: data.person2.birthday,
						canUseInformalCommunication: data.person2.canUseInformalCommunication,
						changedLastName: data.person2.changedLastName,
						changedLastNameIsAfterWedding: data.person2.changedLastNameIsAfterWedding,
						emailAddress: data.person2.emailAddress,
						firstName: data.person2.firstName,
						homePhoneNumber: data.person2.homePhoneNumber,
						lastName: data.person2.lastName,
						mobilePhoneNumber: data.person2.mobilePhoneNumber,
						noNewsletter: data.person2.noNewsletter,
						salutationId: data.person2.salutationId,
						title: data.person2.title
					},
					referral: {
						eventDate: data.referral.eventDate,
						eventDjId: data.referral.eventDjId,
						eventName: data.referral.eventName,
						experienced: data.referral.experienced,
						friends: data.referral.friends,
						gastronomy: data.referral.gastronomy,
						google: data.referral.google,
						internet: data.referral.internet,
						locationId: data.referral.locationId,
						magazineId: data.referral.magazineId,
						other: data.referral.other,
						ourWebsite: data.referral.ourWebsite,
						weddingFairId: data.referral.weddingFairId
					},
					remarks: data.remarks
				}]
			},
			fieldSelection: MContact.fieldSelection({ includePersons: true, includeProcessParticipations: true }),
			parseResult: json => MContact.fromJson(json)
		})
		if (contactResponse instanceof ApiError) {
			return contactResponse
		}

		if (id === 'new') {
			id = contactResponse.data.id
		}

		if (data.processParticipation && (data.processParticipationTagIdsToAdd.length || data.processParticipationTagIdsToRemove.length)) {
			if (!processId) {
				error()
			}

			const participationResponse = await this.executeGraphOperation({
				type: 'mutation',
				name: data.processParticipation === 'add' ? 'addProcessParticipant' : 'changeProcessParticipantTags',
				args: 'input: $input',
				variables: {
					input: [data.processParticipation === 'add' ? 'AddProcessParticipantInput!' : 'ChangeProcessParticipantTagsInput!', {
						contactId: id,
						processId,
						tagIds: data.processParticipation === 'add' ? data.processParticipationTagIdsToAdd : undefined,
						tagIdsToAdd: data.processParticipation === 'add' ? undefined : data.processParticipationTagIdsToAdd,
						tagIdsToRemove: data.processParticipation === 'add' ? undefined : data.processParticipationTagIdsToRemove
					}]
				},
				fieldSelection: MProcessParticipation.fieldSelection(),
				parseResult: () => null
			})
			if (participationResponse instanceof ApiError) {
				return participationResponse
			}
		}

		const fetchResponse = await this.fetchContact(id)
		if (fetchResponse instanceof ApiError) {
			return fetchResponse
		}

		return new ApiResult({ ...fetchResponse, data: { ...fetchResponse.data, contact: contactResponse.data } })
	}


	updateDocument(parameters: {
		content: MText
		id: string
		recipientAddress: MInlineText | null
		recipientId: string
	}): Promise<ApiResponse<MDocument>> {
		return this.executeGraphOperation({
			type: 'mutation',
			name: 'updateDocument',
			args: 'input: $input',
			variables: {
				input: ['UpdateDocumentInput!', {
					content: parameters.content.serialize(),
					id: parameters.id,
					recipientAddress: parameters.recipientAddress?.serialize() ?? null,
					recipientId: parameters.recipientId
				}]
			},
			fieldSelection: MDocument.fieldSelection({ includeVariableConfiguration: true }),
			parseResult: json => MDocument.fromJson(json)
		})
	}


	async updateEventCompensation(
		eventId: string,
		data: DjPricingUpdateData
	): Promise<ApiResponse<FetchEventCompensationResult>> {
		const response = await this.executeGraphOperation({
			type: 'mutation',
			name: 'updateEventCompensation',
			args: 'input: $input',
			variables: {
				input: ['UpdateEventCompensationInput!', {
					baseFee: isNullOrUndefined(data.baseFee) ? null : Math.round(data.baseFee * 100),
					baseHours: moment.duration(data.baseHours, 'seconds').toISOString(),
					customerPrice: isNullOrUndefined(data.customerPrice) ? null : Math.round(data.customerPrice * 100),
					customerPriceRemarks: data.customerPriceRemarks,
					drivingTime: moment.duration(data.drivingTime, 'seconds').toISOString(),
					drivingTimeCompensation: isNullOrUndefined(data.drivingTimeCompensation) ? null : Math.round(data.drivingTimeCompensation * 100),
					eventId: eventId,
					lightDetails: data.lightDetails,
					lightPrice: isNullOrUndefined(data.lightPrice) ? null : Math.round(data.lightPrice * 100),
					miscLabel1: data.miscLabel1,
					miscLabel2: data.miscLabel2,
					miscLabel3: data.miscLabel3,
					miscPrice1: isNullOrUndefined(data.miscPrice1) ? null : Math.round(data.miscPrice1 * 100),
					miscPrice2: isNullOrUndefined(data.miscPrice2) ? null : Math.round(data.miscPrice2 * 100),
					miscPrice3: isNullOrUndefined(data.miscPrice3) ? null : Math.round(data.miscPrice3 * 100),
					overtimeFee: isNullOrUndefined(data.overtimeFee) ? null : Math.round(data.overtimeFee * 100),
					overtimeUnits: data.overtimeUnits,
					remarks: data.remarks,
					soundDetails: data.soundDetails,
					soundPrice: isNullOrUndefined(data.soundPrice) ? null : Math.round(data.soundPrice * 100)
				}]
			},
			fieldSelection: MEventCompensation.fieldSelection(),
			parseResult: json => ({ djPricing: MEventCompensation.fromJson(json) })
		})
		if (response instanceof ApiError) {
			return response
		}

		const fetchResponse = await this.fetchEventCompensation()
		if (fetchResponse instanceof ApiError) {
			return fetchResponse
		}

		return new ApiResult({ ...fetchResponse, data: { ...fetchResponse.data, ...response.data } })
	}


	async updateEvent(id: string, data: EventUpdateData): Promise<ApiResponse<UpdateEventResult>> {
		const updateResponse = await this.executeGraphOperation({
			type: 'mutation',
			name: 'updateEvent',
			args: 'input: $input',
			variables: {
				input: ['UpdateEventInput', {
					date: data.date,
					definiteEndTime: data.definiteEndTime,
					endTime: data.endTime,
					equipmentIds: data.lightIds.concat(data.soundIds),
					givenConsultationIds: data.consultationIds,
					guestChildCount: data.guestChildCount,
					guestCount: data.guestCount,
					guestCountForMusic: data.guestCountForMusic,
					guestNationalities: data.guestNationalities,
					guestRemarks: data.guestRemarks,
					id: id,
					internalRemarks: data.internalRemarks,
					lightRemarks: data.lightRemarks,
					locationHasRecommendedUs: data.locationHasRecommendedUs,
					locationRemarks: data.locationRemarks,
					musicPreferenceIds: data.musicIds,
					musicRemarks: data.musicRemarks,
					occasionDetails: data.typeDetails,
					occasionId: data.typeId,
					plannedEndTime: data.plannedEndTime,
					remarks: data.remarks,
					remarksAreHighPriority: data.remarksAreHighPriority,
					season: data.season,
					setupTime: data.setupTime,
					soundRemarks: data.soundRemarks,
					startTime: data.startTime
				}]
			},
			fieldSelection: MEvent.fieldSelection(),
			parseResult: json => MEvent.fromJson(json)
		})
		if (updateResponse instanceof ApiError) {
			return updateResponse
		}

		const updateProcessResponse = await this.executeGraphOperation({
			type: 'mutation',
			name: 'updateProcess',
			args: 'input: $input',
			variables: {
				input: ['UpdateProcessInput', {
					id: data.processId,
					contractDeadline: data.contractDeadline ? {
						date: data.contractDeadline.date.toString(),
						misses: data.contractDeadline.misses
					} : null
				}]
			},
			fieldSelection: '{ id }',
			parseResult: () => null
		})
		if (updateProcessResponse instanceof ApiError) {
			return updateProcessResponse
		}

		const otherResponse = await this.fetchEvent(id)
		if (otherResponse instanceof ApiError) {
			return otherResponse
		}

		return new ApiResult({
			currentProcess: otherResponse.currentProcess,
			data: { ...otherResponse.data, event: (otherResponse.data.event ?? updateResponse.data) },
			processTags: otherResponse.processTags
		})
	}


	async updateEventDj(
		id: string,
		djId: string | null,
		djIsFixedByClientRequest: boolean,
		djIsFixedByProximity: boolean,
		djIsFixedByRecommendation: boolean,
		djIsFixedForOtherReason: string | null
	): Promise<ApiResponse<{ event: MEvent }>> {
		const response = await this.executeGraphOperation({
			type: 'mutation',
			name: 'changeEventDj',
			args: 'input: $input',
			variables: {
				input: ['ChangeEventDjInput!', {
					eventId: id,
					djId: djId,
					djIsFixedByClientRequest: djIsFixedByClientRequest,
					djIsFixedByProximity: djIsFixedByProximity,
					djIsFixedByRecommendation: djIsFixedByRecommendation,
					djIsFixedForOtherReason: djIsFixedForOtherReason
				}]
			},
			fieldSelection: '{ id }',
			parseResult: () => null
		})
		if (response instanceof ApiError) {
			return response
		}

		return await this.fetchEvent(id) as ApiResponse<{ event: MEvent }>
	}


	async updateLocation(id: string, data: LocationInputData): Promise<ApiResponse<UpdateLocationResult>> {
		const locationResponse = await this.executeGraphOperation({
			type: 'mutation',
			name: id === 'new' ? 'createLocation' : 'updateLocation',
			args: 'input: $input',
			variables: {
				input: [id === 'new' ? 'CreateLocationInput!' : 'UpdateLocationInput!', {
					address: {
						city: data.addressCity,
						countryCode: data.addressCountryCode,
						line1: data.addressStreet,
						line2: data.addressAddition,
						postalCode: data.addressPostalCode
					},
					approachRemarks: data.approachRemarks,
					capacity: data.capacity,
					commissionRemarks: data.commissionRemarks,
					contactName: data.contactName,
					drivingTime: moment.duration(data.drivingTime, 'seconds').toISOString(),
					drivingTimeAsPerNavigationSoftware: moment.duration(data.drivingTimeAsPerNavigationSoftware, 'seconds').toISOString(),
					emailAddress: data.emailAddress,
					equipmentRemarks: data.equipmentRemarks,
					faxNumber: data.faxNumber,
					internalRemarks: data.internalRemarks,
					id: id === 'new' ? undefined : id,
					isFolderFiled: data.isFolderFiled,
					isRecommendingUs: data.isRecommendingUs,
					mobilePhoneNumber: data.mobilePhoneNumber,
					name: data.name,
					otherRemarks: data.otherRemarks,
					phoneNumber: data.phoneNumber,
					receivesCommission: data.receivesCommission,
					setupRemarks: data.setupRemarks,
					specificsRemarks: data.specificsRemarks,
					timingRemarks: data.timingRemarks,
					websiteUrl: data.websiteUrl
				}]
			},
			fieldSelection: MLocation.fieldSelection({ includeCity: true }),
			parseResult: json => MLocation.fromJson(json)
		})
		if (locationResponse instanceof ApiError) {
			return locationResponse
		}

		const otherResponse = await this.fetchLocation(locationResponse.data?.id ?? id)
		if (otherResponse instanceof ApiError) {
			return otherResponse
		}

		return new ApiResult({
			currentProcess: otherResponse.currentProcess,
			data: { ...otherResponse.data, location: locationResponse.data, city: locationResponse.data.city },
			processTags: otherResponse.processTags
		})
	}


	async updatePricing(eventId: string, data: PricingInputData): Promise<ApiResponse<FetchEventPricingResult & { pricing: MEventPricing }>> {
		const response = await this.executeGraphOperation({
			type: 'mutation',
			name: 'updateEventPricing',
			args: 'input: $input',
			variables: {
				input: ['UpdateEventPricingInput!', {
					additionalOptions: data.additionalItems.map(option => ({
						...option,
						price: isNullOrUndefined(option.price) ? null : Math.round(option.price * 100)
					})),
					djCategoryOptions: data.djCategories.map(option => ({
						...option,
						price: isNullOrUndefined(option.price) ? null : Math.round(option.price * 100)
					})),
					equipmentOptions: data.equipmentList.map(option => ({
						...option,
						price: isNullOrUndefined(option.price) ? null : Math.round(option.price * 100)
					})),
					eventId: eventId,
					isEarlyStartSelected: data.includesEarlyStart,
					isServiceSelected: data.includesService,
					isSetupInAdvanceSelected: data.includesSetupInAdvance,
					priceForEarlyStart: isNullOrUndefined(data.priceForEarlyStart) ? null : Math.round(data.priceForEarlyStart * 100),
					priceForService: isNullOrUndefined(data.priceForService) ? null : Math.round(data.priceForService * 100),
					priceForSetupInAdvance: isNullOrUndefined(data.priceForSetupInAdvance) ? null : Math.round(data.priceForSetupInAdvance * 100),
					pricePerOvertimeUnit: isNullOrUndefined(data.pricePerOvertimeUnit) ? null : Math.round(data.pricePerOvertimeUnit * 100),
					remarks: data.remarks,
					vatInclusion: data.vatInclusion,
					vatRate: data.vatRate
				}]
			},
			fieldSelection: MEventPricing.fieldSelection(),
			parseResult: json => ({ pricing: MEventPricing.fromJson(json) })
		})
		if (response instanceof ApiError) {
			return response
		}

		const fetchResponse = await this.fetchPricing(eventId)
		if (fetchResponse instanceof ApiError) {
			return fetchResponse
		}

		return new ApiResult({ ...fetchResponse, data: { ...fetchResponse.data, ...response.data } })
	}


	updateProcessTags(id: string, tagIds: string[]): Promise<ApiResponse<{ process: MProcess }>> {
		return this.executeGraphOperation({
			type: 'mutation',
			name: 'updateProcess',
			args: 'input: { id: $id, tagIds: $tagIds }',
			variables: {
				id: ['ID!', id],
				tagIds: ['[ID!]!', tagIds]
			},
			fieldSelection: MProcess.fieldSelection({ includeEvent: true, includeParticipations: true }),
			parseResult: json => ({ process: MProcess.fromJson(json) })
		})
	}


	async uploadFile(file: File): Promise<ApiResponse<MUpload>> {
		const prepareResponse = await this.executeGraphOperation({
			type: 'mutation',
			name: 'prepareUpload',
			args: 'input: { contentType: $contentType, name: $name, size: $size }',
			variables: {
				contentType: ['ContentType!', file.type],
				name: ['FileName!', file.name],
				size: ['Int!', file.size]
			},
			parseResult: json => json as string
		})
		if (prepareResponse instanceof ApiError) {
			return prepareResponse
		}

		const url = prepareResponse.data
		const headers: { [key: string]: string } = { 'Content-Type': file.type }

		try {
			await fetch(
				url,
				{
					body: file,
					credentials: 'include',
					headers,
					method: 'put'
				}
			)
		}
		catch (e) {
			console.error(e)

			return new ApiError({
				cause: e,
				graphqlOperationName: 'uploadFile',
				userMessage: 'Die Kundendatenbank konnte nicht erreicht werden.'
			})
		}

		return this.executeGraphOperation({
			type: 'mutation',
			name: 'completeUpload',
			args: 'url: $url',
			variables: {
				url: ['Url!', url]
			},
			fieldSelection: MUpload.fieldSelection(),
			parseResult: json => (json ? MUpload.fromJson(json) : null) as MUpload // TODO Could actually be null.
		})
	}
}

function readFileAsText(file): Promise<string> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.onerror = reject
		reader.onload = () => resolve(reader.result as string)
		reader.readAsText(file)
	})
}
