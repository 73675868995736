import MEmailRecipient from '~/model/MEmailRecipient'


export default class MEmailRecipientOption {

	readonly isSelected: boolean
	readonly recipient: MEmailRecipient
	readonly tags: string[]


	constructor(properties: {
		readonly isSelected: boolean
		readonly recipient: MEmailRecipient
		readonly tags: string[]
	}) {
		this.isSelected = properties.isSelected
		this.recipient = properties.recipient
		this.tags = properties.tags
	}
}
