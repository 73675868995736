import MChange from '~/model/MChange'
import MEntity from '~/model/MEntity'
import MEntityParser from '~/model/MEntityParser'
import MJournalItem from '~/model/MJournalItem'
import MProcessContractDeadline from '~/model/MProcessContractDeadline'
import MProcessStatus from '~/model/MProcessStatus'


export default class MProcessUpdatedJournalItem extends MJournalItem {

	static fieldSelection(): string {
		return `{
			contractDeadline ${MChange.fieldSelection(MProcessContractDeadline.fieldSelection())}
			id
			status ${MChange.fieldSelection()}
			subjects ${MEntity.fieldSelection()}
		}`
	}


	readonly contractDeadline: MChange<MProcessContractDeadline | null> | null
	readonly id: string
	readonly status: MChange<MProcessStatus> | null


	// readonly tagIds: MChange<string[]> | null


	constructor(properties: {
		readonly contractDeadline: MChange<MProcessContractDeadline | null> | null
		readonly id: string
		readonly status: MChange<MProcessStatus> | null
		readonly subjects: MEntity[]
		// readonly tagIds: MChange<string[]> | null
	}) {
		super('process updated', properties.subjects)

		this.contractDeadline = properties.contractDeadline
		this.id = properties.id
		this.status = properties.status
		// this.tagIds = properties.tagIds
	}


	static fromJson(json: any) {
		return new this({
			contractDeadline: MChange.fromJson(json.contractDeadline, it => MProcessContractDeadline.fromJson(it)),
			id: json.id,
			status: MChange.fromJson(json.status, it => MProcessStatus.byId(it)),
			subjects: json.subjects.map(it => MEntityParser(it))
			// tagIds: MChange.fromJson(json.tagIds, identity),
		})
	}
}
