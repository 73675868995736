import MJournalItem from '~/model/MJournalItem'
import MProcess from '~/model/MProcess'


export default class MProcessCreatedJournalItem extends MJournalItem {

	static fieldSelection(): string {
		return `{
			process ${MProcess.fieldSelection()}
		}`
	}


	readonly process: MProcess


	constructor(properties: {
		readonly process: MProcess
	}) {
		super('process file created', [])

		this.process = properties.process
	}


	static fromJson(json: any) {
		return new this({
			process: MProcess.fromJson(json.process),
		})
	}
}
