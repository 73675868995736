import MEquipment from '~/model/MEquipment'
import MEvent from '~/model/MEvent'
import MMusic from '~/model/MMusic'
import { associateByTo } from '~/utility'
import { booleanInput, compositeInput, stringInput } from '~/utility/Input'


export default function equipmentAndMusicInput(properties?: {
	readonly equipments: readonly MEquipment[]
	readonly event: MEvent | null
	readonly musics: readonly MMusic[]
}) {
	const lightEquipmentList = properties?.equipments?.filter(it => it.type === 'light') ?? []
	const soundEquipmentList = properties?.equipments?.filter(it => it.type === 'sound') ?? []
	const selectedEquipmentIds = new Set(properties?.event?.equipment?.map(it => it.id) ?? [])
	const selectedMusicIds = new Set(properties?.event?.music?.map(it => it.id) ?? [])

	function makeEquipmentLabels(equipments: readonly MEquipment[]) {
		return associateByTo(equipments, option => [option.id, option.label])
	}

	return {
		lightInputs: compositeInput({
			options: compositeInput(associateByTo(lightEquipmentList, it => [it.id, booleanInput(selectedEquipmentIds.has(it.id))])),
			remarks: stringInput(properties?.event?.lightRemarks ?? '')
		}),
		lightLabels: makeEquipmentLabels(lightEquipmentList),
		musicInputs: compositeInput({
			options: compositeInput(properties
				? associateByTo(properties.musics, it => [it.id, booleanInput(selectedMusicIds.has(it.id))])
				: {}
			),
			remarks: stringInput(properties?.event?.musicRemarks ?? '')
		}),
		musicLabels: associateByTo(properties?.musics ?? [], option => [option.id, option.label]),
		soundInputs: compositeInput({
			options: compositeInput(associateByTo(soundEquipmentList, it => [it.id, booleanInput(selectedEquipmentIds.has(it.id))])),
			remarks: stringInput(properties?.event?.soundRemarks ?? '')
		}),
		soundLabels: makeEquipmentLabels(soundEquipmentList)
	}
}
