import MTimestamp from '~/model/MTimestamp'


export default class MProcessParticipationTag {

	static fieldSelection(): string {
		return `{
			archivalTimestamp
			description
			id
			isPrimary
			label
			limitPerProcess
			priority
		}`
	}


	readonly archivalTimestamp: MTimestamp | null
	readonly description: string
	readonly id: string
	readonly isPrimary: boolean
	readonly label: string
	readonly limitPerProcess: number | null
	readonly priority: number


	constructor(properties: {
		readonly archivalTimestamp: MTimestamp | null
		readonly description: string
		readonly id: string
		readonly isPrimary: boolean
		readonly label: string
		readonly limitPerProcess: number | null
		readonly priority: number
	}) {
		this.archivalTimestamp = properties.archivalTimestamp
		this.description = properties.description
		this.id = properties.id
		this.isPrimary = properties.isPrimary
		this.label = properties.label
		this.limitPerProcess = properties.limitPerProcess
		this.priority = properties.priority
	}


	static fromJson(json: any): MProcessParticipationTag {
		return new this({
			archivalTimestamp: json.archivalTimestamp ? MTimestamp.parse(json.archivalTimestamp) : null,
			description: json.description,
			id: json.id,
			isPrimary: json.isPrimary,
			label: json.label,
			limitPerProcess: json.limitPerProcess,
			priority: json.priority
		})
	}
}
