
	import XCurrentProcessBar from '@/main/components/XCurrentProcessBar.vue'
	import XMainPageContentOld from '@/main/components/XMainPageContentOld.vue'
	import XRequestTable from '@/main/components/XRequestTable.vue'
	import { Inject, Options, Vue } from 'vue-property-decorator'
	import Api, { ApiError, ApiResponse, ApiResult, FetchRequestsResult } from '~/api/Api'
	import XSection from '~/components/XSection.vue'
	import XStatusMessage from '~/components/XStatusMessage.vue'
	import MProcess from '~/model/MProcess'
	import MRequest from '~/model/MRequest'
	import { compareBy, error, sorted } from '~/utility'


	@Options({
		components: {
			XStatusMessage,
			XCurrentProcessBar,
			XMainPageContentOld,
			XRequestTable,
			XSection
		},
		name: 'page-main-requests'
	})
	export default class extends Vue {

		apiResponse: ApiResponse<FetchRequestsResult> | null = null
		isImporting = false
		requestsUploadError: ApiError | null = null
		requestsUploaded: MRequest[] = []
		requestsUploadedCount: number | null = null
		updatedCurrentProcess?: MProcess | null

		@Inject() readonly api!: Api


		private get importFileElement(): HTMLInputElement | undefined {
			return this.$refs.importFileElement as HTMLInputElement | undefined
		}


		async created() {
			this.apiResponse = await this.api.fetchOpenRequests()
		}


		get currentProcess() {
			return this.updatedCurrentProcess === undefined ? this.result.currentProcess : this.updatedCurrentProcess
		}


		set currentProcess(value: MProcess | null) {
			this.updatedCurrentProcess = value
		}


		data() {
			return {
				updatedCurrentProcess: undefined
			}
		}


		get importedRequests() {
			return sorted(
				this.requests
					.concat(this.requestsUploaded)
					.filter(it => it.source === 'import'),
				compareBy(it => it.receptionTimestamp)
			)
		}


		async onImportFormSubmitted() {
			if (!this.importFileElement) {
				return error('File element must be present')
			}

			const file = this.importFileElement.files ? (this.importFileElement.files[0] || null) : null
			if (!file) {
				alert('Bitte wähle zunächst eine CSV-Datei aus.')
				return
			}

			this.isImporting = true
			this.requestsUploadError = null
			this.requestsUploadedCount = null

			const response = await this.api.importRequests(file)
			if (response instanceof ApiError) {
				this.isImporting = false
				this.requestsUploadError = response

				return
			}

			const requests = response.data.requests

			this.isImporting = false
			this.requestsUploaded = this.requestsUploaded.concat(requests)
			this.requestsUploadedCount = requests.length
		}


		get requests() {
			return this.result.data.requests
		}


		get result(): ApiResult<FetchRequestsResult> {
			return this.apiResponse?.asResult() ?? error()
		}


		get tags() {
			return this.result.processTags
		}


		get requestsUploadStatus() {
			if (this.requestsUploadError) {
				return this.requestsUploadError
			}
			if (this.requestsUploadedCount === null) {
				return null
			}

			switch (this.requestsUploadedCount) {
				case 0:
					return 'Es wurde keine Anfrage importiert. Entweder enthält die CSV-Datei keine Einträge oder ist fehlerhaft.'

				case 1:
					return 'Es wurde eine Anfrage importiert.'

				default:
					return `Es wurden ${this.requestsUploadedCount} Anfragen importiert.`
			}
		}


		get requestsUploadStatusType() {
			if (this.requestsUploadError) {
				return 'error'
			}
			if (this.requestsUploadedCount === null) {
				return null
			}

			return this.requestsUploadedCount ? 'success' : 'error'
		}


		get websiteRequests() {
			return sorted(
				this.requests
					.filter(it => it.source === 'website'),
				compareBy(it => it.receptionTimestamp)
			)
		}
	}
