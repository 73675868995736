import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_x_error_handler = _resolveComponent("x-error-handler")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ [_ctx.$style.staging]: !_ctx.isProduction })
  }, [
    _createVNode(_component_x_error_handler),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.content)
    }, [
      _createVNode(_component_router_view)
    ], 2)
  ], 2))
}