
	import { Options, Prop, Vue } from 'vue-property-decorator'
	import XIconButton from '~/components/XIconButton.vue'
	import XLink from '~/components/XLink.vue'
	import MProcessTag from '~/model/MProcessTag'
	import icons from '~/styles/icons.module.scss'
	import { compareBy, sorted } from '~/utility'


	@Options({
		components: { XIconButton, XLink },
		name: 'x-tag-selection-input'
	})
	export default class extends Vue {

		readonly icons = icons

		@Prop() allowClearAll?: boolean
		@Prop() tags!: MProcessTag[]
		@Prop() modelValue!: string[]


		onClearClicked() {
			this.$emit('update:modelValue', [])
		}


		onSubmitted() {
			this.$emit('submit')
		}


		onFixedTagToggled(tag: MProcessTag) {
			const tagIds = this.modelValue.concat()
			const index = tagIds.indexOf(tag.id)
			if (index >= 0) {
				tagIds.splice(index, 1)
			}
			else {
				tagIds.push(tag.id)
			}

			this.$emit('update:modelValue', tagIds)
		}


		get sortedTags() {
			return sorted(this.tags, compareBy(it => it.label))
		}
	}
