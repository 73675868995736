
	import { app } from '@/main'
	import { Options, Vue } from 'vue-property-decorator'
	import { ApiError } from '~/api/Api'
	import XLink from '~/components/XLink.vue'


	@Options({
		components: { XLink },
		name: 'x-error-handler'
	})
	export default class extends Vue {

		readonly defaultUserMessage = 'Ein Programmierfehler ist aufgetreten!'

		defaultErrorHandler!: typeof app.config.errorHandler
		defaultWarnHandler!: typeof app.config.warnHandler
		error: ApiError | null = null


		created() {
			this.defaultErrorHandler = app.config.errorHandler
			this.defaultWarnHandler = app.config.warnHandler

			app.config.errorHandler = (unknownError, vm, info) => {
				const error = unknownError as Error // TODO what if not Error?

				console.error(`in ${info}:\n${error.stack}`)

				this.error = new ApiError({
					cause: error,
					developerMessage: `Vue error (${info} @ ${vm?.$options.name ?? '<unknown component>'})`,
					userMessage: this.defaultUserMessage
				})
			}

			app.config.warnHandler = (message, _, trace) => {
				console.error(message + trace)

				this.error = new ApiError({
					developerMessage: `Vue warning: ${message + trace}`,
					userMessage: this.defaultUserMessage
				})
			}

			window.addEventListener('error', this.onError)
			window.addEventListener('unhandledrejection', this.onUnhandledRejection)
		}


		destroyed() {
			app.config.errorHandler = this.defaultErrorHandler
			app.config.warnHandler = this.defaultWarnHandler
			window.removeEventListener('error', this.onError)
			window.removeEventListener('unhandledrejection', this.onUnhandledRejection)
		}


		onError(event: ErrorEvent) {
			this.error = new ApiError({
				cause: event.error,
				developerMessage: 'Uncaught front-end error',
				userMessage: this.defaultUserMessage
			})
		}


		onUnhandledRejection(event: PromiseRejectionEvent) {
			this.error = new ApiError({
				cause: event.reason,
				developerMessage: 'Unhandled front-end error in promise',
				userMessage: this.defaultUserMessage
			})
		}
	}
