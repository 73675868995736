import MAddress from '~/model/MAddress'
import MContactPerson from '~/model/MContactPerson'
import MEventOccasion from '~/model/MEventOccasion'
import MLocalDate from '~/model/MLocalDate'
import MLocation from '~/model/MLocation'
import MPhoneNumber from '~/model/MPhoneNumber'
import MProcess from '~/model/MProcess'
import MReferral from '~/model/MReferral'
import MTimestamp from '~/model/MTimestamp'


export default class MRequest {

	static fieldSelection({includePotentialMatches}: { includePotentialMatches?: boolean } = {}): string {
		return `{
			address ${MAddress.fieldSelection()}
			businessPhoneNumber
			companyName
			emailAddress
			eventCity
			eventDate
			eventGuestCount
			eventLocation
			eventSeason
			eventOccasion ${MEventOccasion.fieldSelection()}
			eventWeekday
			faxNumber
			firstName
			homePhoneNumber
			id
			lastName
			mobilePhoneNumber
			${includePotentialMatches ? `potentialLocations ${MLocation.fieldSelection()}` : ''}
			${includePotentialMatches
			? `potentialPersons ${MContactPerson.fieldSelection({
				includeContact: true,
				includeProcessParticipations: true,
				processParticipationArguments: '(limit:1 sortCriteria:processCreationTimestamp sortDirection:descending)'
			})}`
			: ''}
			process { id }
			${includePotentialMatches ? `potentialProcesses ${MProcess.fieldSelection({includeEvent: true})}` : ''}
			receptionTimestamp
			referral ${MReferral.fieldSelection()}
			remarks
			source
		}`
	}


	readonly address: MAddress | null
	readonly businessPhoneNumber: MPhoneNumber | null
	readonly companyName: string | null
	readonly emailAddress: string | null
	readonly eventCity: string | null
	readonly eventDate: MLocalDate | null
	readonly eventGuestCount: number | null
	readonly eventLocation: string | null
	readonly eventOccasion: MEventOccasion | null
	readonly eventOccasionDetails: string | null
	readonly eventSeason: string | null
	readonly eventWeekday: string | null
	readonly faxNumber: MPhoneNumber | null
	readonly firstName: string | null
	readonly homePhoneNumber: MPhoneNumber | null
	readonly id: string
	readonly isImported: boolean
	readonly lastName: string | null
	readonly mobilePhoneNumber: MPhoneNumber | null
	readonly potentialLocations: MLocation[] | null
	readonly potentialPersons: MContactPerson[] | null
	readonly potentialProcesses: MProcess[] | null
	readonly receptionTimestamp: MTimestamp
	readonly referral: MReferral | null
	readonly remarks: string | null
	readonly source: 'import' | 'website'


	constructor(properties: {
		readonly address: MAddress | null
		readonly businessPhoneNumber: MPhoneNumber | null
		readonly companyName: string | null
		readonly emailAddress: string | null
		readonly eventCity: string | null
		readonly eventDate: MLocalDate | null
		readonly eventGuestCount: number | null
		readonly eventLocation: string | null
		readonly eventOccasion: MEventOccasion | null
		readonly eventOccasionDetails: string | null
		readonly eventSeason: string | null
		readonly eventWeekday: string | null
		readonly faxNumber: MPhoneNumber | null
		readonly firstName: string | null
		readonly homePhoneNumber: MPhoneNumber | null
		readonly id: string
		readonly isImported: boolean
		readonly lastName: string | null
		readonly mobilePhoneNumber: MPhoneNumber | null
		readonly potentialLocations: MLocation[] | null
		readonly potentialPersons: MContactPerson[] | null
		readonly potentialProcesses: MProcess[] | null
		readonly receptionTimestamp: MTimestamp
		readonly referral: MReferral | null
		readonly remarks: string | null
		readonly source: 'import' | 'website'
	}) {
		this.address = properties.address
		this.businessPhoneNumber = properties.businessPhoneNumber
		this.companyName = properties.companyName
		this.emailAddress = properties.emailAddress
		this.eventCity = properties.eventCity
		this.eventDate = properties.eventDate
		this.eventGuestCount = properties.eventGuestCount
		this.eventLocation = properties.eventLocation
		this.eventOccasion = properties.eventOccasion
		this.eventOccasionDetails = properties.eventOccasionDetails
		this.eventSeason = properties.eventSeason
		this.eventWeekday = properties.eventWeekday
		this.faxNumber = properties.faxNumber
		this.firstName = properties.firstName
		this.homePhoneNumber = properties.homePhoneNumber
		this.id = properties.id
		this.isImported = properties.isImported
		this.lastName = properties.lastName
		this.mobilePhoneNumber = properties.mobilePhoneNumber
		this.potentialLocations = properties.potentialLocations
		this.potentialPersons = properties.potentialPersons
		this.potentialProcesses = properties.potentialProcesses
		this.receptionTimestamp = properties.receptionTimestamp
		this.referral = properties.referral
		this.remarks = properties.remarks
		this.source = properties.source
	}


	static fromJson(json: any) {
		return new this({
			address: json.address ? MAddress.fromJson(json.address) : null,
			businessPhoneNumber: json.businessPhoneNumber ? MPhoneNumber.parse(json.businessPhoneNumber) : null,
			companyName: json.companyName,
			emailAddress: json.emailAddress,
			eventCity: json.eventCity,
			eventDate: json.eventDate ? MLocalDate.parse(json.eventDate) : null,
			eventGuestCount: json.eventGuestCount,
			eventLocation: json.eventLocation,
			eventOccasion: json.eventOccasion ? MEventOccasion.fromJson(json.eventOccasion) : null,
			eventOccasionDetails: json.eventOccasionDetails,
			eventSeason: json.eventSeason,
			eventWeekday: json.eventWeekday,
			faxNumber: json.faxNumber ? MPhoneNumber.parse(json.faxNumber) : null,
			firstName: json.firstName,
			homePhoneNumber: json.homePhoneNumber ? MPhoneNumber.parse(json.homePhoneNumber) : null,
			id: json.id,
			isImported: Boolean(json.process),
			lastName: json.lastName,
			mobilePhoneNumber: json.mobilePhoneNumber ? MPhoneNumber.parse(json.mobilePhoneNumber) : null,
			potentialLocations: json.potentialLocations ? json.potentialLocations.map(it => MLocation.fromJson(it)) : null,
			potentialPersons: json.potentialPersons ? json.potentialPersons.map(it => MContactPerson.fromJson(it)) : null,
			potentialProcesses: json.potentialProcesses ? json.potentialProcesses.map(it => MProcess.fromJson(it)) : null,
			receptionTimestamp: MTimestamp.parse(json.receptionTimestamp),
			referral: json.referral,
			remarks: json.remarks,
			source: json.source
		})
	}


	get name() {
		if (this.firstName) {
			if (this.lastName) {
				return `${this.firstName} ${this.lastName}`
			}

			return this.firstName
		}

		return this.lastName
	}
}
