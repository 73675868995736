import MLocalDate from '~/model/MLocalDate'


export default class MProcessContractDeadline {

	static fieldSelection(): string {
		return `{
			date
			misses
		}`
	}


	readonly date: MLocalDate
	readonly misses: number


	constructor(properties: {
		readonly date: MLocalDate
		readonly misses: number
	}) {
		this.date = properties.date
		this.misses = properties.misses
	}


	static fromJson(json: any): MProcessContractDeadline {
		return new this({
			date: MLocalDate.parse(json.date),
			misses: json.misses
		})
	}


	toString(): string {
		let string = `bis: BB ${this.date.formatShort()}`
		if (this.misses > 0) {
			string += ', '
			string += (this.misses + 1)
			string += '. Mal'
		}

		return string
	}
}
