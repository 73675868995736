import MDjCategory from '~/model/MDjCategory'
import MEquipment from '~/model/MEquipment'
import MEventPricingAdditionalOption from '~/model/MEventPricingAdditionalOption'
import MEventPricingDjCategoryOption from '~/model/MEventPricingDjCategoryOption'
import MEventPricingEquipmentOption from '~/model/MEventPricingEquipmentOption'


export default class MEventPricing {

	static fieldSelection(): string {
		return `{
			additionalOptions {
				isSelected
				label
				price
			}
			defaultVatRate
			djCategoryOptions {
				category ${MDjCategory.fieldSelection()}
				isSelected
				label
				prepaymentAmount
				price
			}
			equipmentOptions {
				equipment ${MEquipment.fieldSelection()}
				isSelected
				price
			}
			isEarlyStartSelected
			isServiceSelected
			isSetupInAdvanceSelected
			priceForEarlyStart
			priceForService
			priceForSetupInAdvance
			pricePerOvertimeUnit
			remarks
			vatInclusion
			vatRate
		}`
	}


	readonly additionalOptions: MEventPricingAdditionalOption[]
	readonly defaultVatRate: number
	readonly djCategoryOptions: MEventPricingDjCategoryOption[]
	readonly equipmentOptions: MEventPricingEquipmentOption[]
	readonly isEarlyStartSelected: boolean
	readonly isServiceSelected: boolean
	readonly isSetupInAdvanceSelected: boolean
	readonly priceForEarlyStart: number | null
	readonly priceForService: number | null
	readonly priceForSetupInAdvance: number | null
	readonly pricePerOvertimeUnit: number
	readonly remarks: string | null
	readonly vatInclusion: 'inclusive' | 'exclusive'
	readonly vatRate: number


	constructor(properties: {
		readonly additionalOptions: MEventPricingAdditionalOption[]
		readonly defaultVatRate: number
		readonly djCategoryOptions: MEventPricingDjCategoryOption[]
		readonly equipmentOptions: MEventPricingEquipmentOption[]
		readonly isEarlyStartSelected: boolean
		readonly isServiceSelected: boolean
		readonly isSetupInAdvanceSelected: boolean
		readonly priceForEarlyStart: number | null
		readonly priceForService: number | null
		readonly priceForSetupInAdvance: number | null
		readonly pricePerOvertimeUnit: number
		readonly remarks: string | null
		readonly vatInclusion: 'inclusive' | 'exclusive'
		readonly vatRate: number
	}) {
		this.additionalOptions = properties.additionalOptions
		this.defaultVatRate = properties.defaultVatRate
		this.djCategoryOptions = properties.djCategoryOptions
		this.equipmentOptions = properties.equipmentOptions
		this.isEarlyStartSelected = properties.isEarlyStartSelected
		this.isServiceSelected = properties.isServiceSelected
		this.isSetupInAdvanceSelected = properties.isSetupInAdvanceSelected
		this.priceForEarlyStart = properties.priceForEarlyStart
		this.priceForService = properties.priceForService
		this.priceForSetupInAdvance = properties.priceForSetupInAdvance
		this.pricePerOvertimeUnit = properties.pricePerOvertimeUnit
		this.remarks = properties.remarks
		this.vatInclusion = properties.vatInclusion
		this.vatRate = properties.vatRate
	}


	static fromJson(json: any): MEventPricing {
		return new this({
			additionalOptions: json.additionalOptions.map((json: any) => MEventPricingAdditionalOption.fromJson(json)),
			defaultVatRate: json.defaultVatRate,
			djCategoryOptions: json.djCategoryOptions.map((json: any) => MEventPricingDjCategoryOption.fromJson(json)),
			equipmentOptions: json.equipmentOptions.map((json: any) => MEventPricingEquipmentOption.fromJson(json)),
			isEarlyStartSelected: json.isEarlyStartSelected,
			isServiceSelected: json.isServiceSelected,
			isSetupInAdvanceSelected: json.isSetupInAdvanceSelected,
			priceForEarlyStart: json.priceForEarlyStart ? json.priceForEarlyStart / 100 : json.priceForEarlyStart,
			priceForService: json.priceForService ? json.priceForService / 100 : json.priceForService,
			priceForSetupInAdvance: json.priceForSetupInAdvance ? json.priceForSetupInAdvance / 100 : json.priceForSetupInAdvance,
			pricePerOvertimeUnit: json.pricePerOvertimeUnit / 100,
			remarks: json.remarks,
			vatInclusion: json.vatInclusion,
			vatRate: json.vatRate
		})
	}
}
