import MAddress from '~/model/MAddress'
import MContact from '~/model/MContact'
import MLocalDate from '~/model/MLocalDate'
import MPhoneNumber from '~/model/MPhoneNumber'
import MSalutation from '~/model/MSalutation'


export default class MContactPerson {

	static fieldSelection(
		{
			includeContact,
			includeProcessParticipations,
			processParticipationArguments,
		}: {
			includeContact?: boolean
			includeProcessParticipations?: boolean
			processParticipationArguments?: string
		} = {},
	): string {
		return `{
			address ${MAddress.fieldSelection()}
			birthday
			canUseInformalCommunication
			changedLastName
			changedLastNameIsAfterWedding
			${includeContact ? `contact ${MContact.fieldSelection({
			includePersons: false,
			includeProcessParticipations,
			processParticipationArguments,
		})}` : ''}
			emailAddress
			firstName
			homePhoneNumber
			id
			lastName
			mobilePhoneNumber
			noNewsletter
			salutation ${MSalutation.fieldSelection()}
			title
		}`
	}


	readonly address: MAddress | null
	readonly birthday: MLocalDate | null
	readonly canUseInformalCommunication: boolean
	readonly changedLastName: string | null
	readonly changedLastNameIsAfterWedding: boolean
	readonly contact: MContact | null
	readonly emailAddress: string | null
	readonly firstName: string | null
	readonly homePhoneNumber: MPhoneNumber | null
	readonly id: string
	readonly lastName: string | null
	readonly mobilePhoneNumber: MPhoneNumber | null
	readonly noNewsletter: boolean
	readonly salutation: MSalutation | null
	readonly title: string | null


	constructor(properties: {
		readonly address: MAddress | null
		readonly birthday: MLocalDate | null
		readonly canUseInformalCommunication: boolean
		readonly changedLastName: string | null
		readonly changedLastNameIsAfterWedding: boolean
		readonly contact: MContact | null
		readonly emailAddress: string | null
		readonly firstName: string | null
		readonly homePhoneNumber: MPhoneNumber | null
		readonly id: string
		readonly lastName: string | null
		readonly mobilePhoneNumber: MPhoneNumber | null
		readonly noNewsletter: boolean
		readonly salutation: MSalutation | null
		readonly title: string | null
	}) {
		this.address = properties.address
		this.birthday = properties.birthday
		this.canUseInformalCommunication = properties.canUseInformalCommunication
		this.changedLastName = properties.changedLastName
		this.changedLastNameIsAfterWedding = properties.changedLastNameIsAfterWedding
		this.contact = properties.contact
		this.emailAddress = properties.emailAddress
		this.firstName = properties.firstName
		this.homePhoneNumber = properties.homePhoneNumber
		this.id = properties.id
		this.lastName = properties.lastName
		this.mobilePhoneNumber = properties.mobilePhoneNumber
		this.noNewsletter = properties.noNewsletter
		this.salutation = properties.salutation
		this.title = properties.title
	}


	static fromJson(json: any): MContactPerson {
		return new this({
			address: json.address ? MAddress.fromJson(json.address) : null,
			birthday: json.birthday ? MLocalDate.parse(json.birthday) : null,
			canUseInformalCommunication: json.canUseInformalCommunication,
			changedLastName: json.changedLastName,
			changedLastNameIsAfterWedding: json.changedLastNameIsAfterWedding,
			contact: json.contact ? MContact.fromJson(json.contact) : null,
			emailAddress: json.emailAddress,
			firstName: json.firstName,
			homePhoneNumber: json.homePhoneNumber ? MPhoneNumber.parse(json.homePhoneNumber) : null,
			id: json.id,
			lastName: json.lastName,
			mobilePhoneNumber: json.mobilePhoneNumber ? MPhoneNumber.parse(json.mobilePhoneNumber) : null,
			noNewsletter: json.noNewsletter,
			salutation: json.salutation ? MSalutation.fromJson(json.salutation) : null,
			title: json.title,
		})
	}


	get name(): string | null {
		if (this.firstName) {
			if (this.lastName) {
				return `${this.firstName} ${this.lastName}`
			}

			return this.firstName
		}

		return this.lastName
	}


	get nameReverse() {
		if (this.firstName) {
			if (this.lastName) {
				return `${this.lastName}, ${this.firstName}`
			}

			return this.firstName
		}

		return this.lastName
	}
}
