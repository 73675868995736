import GraphOperation from '@/graphql/operations/GraphOperation'


export default class SignOutMutation extends GraphOperation<void> {

	constructor() {
		super({
			name: 'signOut',
			type: 'mutation',
		})
	}


	parseData() {
	}
}
