
	import XTagSelectionInput from '@/main/components/XTagSelectionInput.vue'
	import { Inject, Options, Prop, Vue } from 'vue-property-decorator'
	import Api, { ApiError } from '~/api/Api'
	import XIconButton from '~/components/XIconButton.vue'
	import XLink from '~/components/XLink.vue'
	import MProcess from '~/model/MProcess'
	import MProcessTag from '~/model/MProcessTag'
	import icons from '~/styles/icons.module.scss'


	@Options({
		components: {
			XIconButton,
			XLink,
			XTagSelectionInput
		},
		name: 'x-process-tags'
	})
	export default class extends Vue {

		readonly icons = icons

		tagIds: string[] = []
		tagSelectionState: '' | 'failed' | 'saved' | 'saving' = ''
		tagSelectionMessage = ''
		tagsAreExpanded = false

		@Inject() api!: Api

		@Prop() tags!: MProcessTag[]
		@Prop() modelValue!: MProcess


		get currentTags() {
			return this.modelValue.tags
		}


		onTagExpandActionClicked() {
			this.tagsAreExpanded = !this.tagsAreExpanded
			this.tagSelectionMessage = ''
			this.tagSelectionState = ''

			if (this.tagsAreExpanded) {
				this.tagIds = this.modelValue.tags.map(it => it.id)
			}
		}


		async onTagSelectionSubmitted() {
			this.tagSelectionState = 'saving'
			this.tagsAreExpanded = false
			this.tagSelectionMessage = 'Tags werden gespeichert…'

			const response = await this.api.updateProcessTags(this.modelValue.id, this.tagIds)

			if (response instanceof ApiError) {
				this.tagSelectionState = 'failed'
				this.tagsAreExpanded = true
				this.tagSelectionMessage = 'Tags konnten nicht gespeichert werden! Bitte versuche es erneut.'

				return
			}

			this.tagSelectionState = 'saved'
			this.tagSelectionMessage = 'Tags erfolgreich gespeichert!'

			this.$emit('update:modelValue', response.data.process)
		}
	}
