import AuthenticateMutation from '@/graphql/operations/AuthenticateMutation'
import MeQuery from '@/graphql/operations/MeQuery'
import SignOutMutation from '@/graphql/operations/SignOutMutation'
import { BehaviorSubject, Observable } from 'rxjs'
import Api from '~/api/Api'
import { AuthenticationStatus, AuthenticationStatusAuthenticated } from '~/authentication/AuthenticationStatus'
import MError, { MAuthenticationRequiredError } from '~/model/MError'
import MUser from '~/model/MUser'
import { delay, error, getCookie } from '~/utility'


export default class Authenticator {

	private readonly api: Api
	private readonly _status: BehaviorSubject<AuthenticationStatus>


	constructor(properties: {
		readonly api: Api
	}) {
		this.api = properties.api
		this._status = new BehaviorSubject<AuthenticationStatus>(
			getCookie('credentialsExpirationTimestamp') // TODO Improve.
				? { type: 'authenticating', error: null, promise: this.executeAccessTokenCheck() }
				: { type: 'not authenticated' }
		)
	}


	private checkStatus(type: 'authenticated' | 'authenticating' | 'not authenticated' | 'signing out'): AuthenticationStatus {
		const status = this._status.getValue()
		const actualType = status.type
		if (actualType !== type) {
			error(`Authentication status is '${actualType}' but '${type}' is expected.`)
		}

		return status
	}


	private async executeAccessTokenCheck(): Promise<MUser | MError> {
		const output = await this.api.execute(new MeQuery())
		if (output instanceof MAuthenticationRequiredError) {
			this._status.next({ type: 'not authenticated' })
		}
		else if (output instanceof MError) {
			await delay(1_000 + (Math.random() * 4_000))
			await this.executeAccessTokenCheck()
		}
		else {
			this._status.next({ type: 'authenticated', user: output })
		}

		return output
	}


	private async executeSignIn(emailAddress: string, password: string): Promise<MUser | MError> {
		const output = await this.api.execute(new AuthenticateMutation({ emailAddress, password }))
		if (output instanceof MError) {
			this._status.next({ type: 'not authenticated' })

			return output
		}

		this._status.next({ type: 'authenticated', user: output.user })

		return output.user
	}


	private async executeSignOut(user: MUser): Promise<void | MError> {
		const output = await this.api.execute(new SignOutMutation())
		if (output instanceof MError) {
			this._status.next({ type: 'authenticated', user })

			return output
		}

		this._status.next({ type: 'not authenticated' })
	}


	async signIn(emailAddress: string, password: string): Promise<MUser | MError> {
		this.checkStatus('not authenticated')

		const promise = this.executeSignIn(emailAddress, password)
		this._status.next({ type: 'authenticating', error: null, promise })

		return promise
	}


	async signOut(): Promise<void | MError> {
		const status = this.checkStatus('authenticated') as AuthenticationStatusAuthenticated

		const promise = this.executeSignOut(status.user)
		this._status.next({ type: 'signing out', error: null, promise, user: status.user })

		return promise
	}


	get status(): AuthenticationStatus {
		return this._status.getValue()
	}


	get status$(): Observable<AuthenticationStatus> {
		return this._status
	}
}
