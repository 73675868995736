import { Comparable, error } from '~/utility'


export default class MTimestamp implements Comparable<MTimestamp> {

	private static readonly dateShortFormatter = new Intl.DateTimeFormat('de-DE', {
		day: '2-digit',
		month: '2-digit',
		timeZone: 'Europe/Berlin',
		year: 'numeric'
	})

	private static readonly shortFormatter = new Intl.DateTimeFormat('de-DE', {
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		month: '2-digit',
		timeZone: 'Europe/Berlin',
		year: 'numeric'
	})

	private readonly value: Date


	private constructor(value: Date) {
		this.value = value
	}


	compare(other: MTimestamp) {
		return this.value.getTime() - other.value.getTime()
	}


	isBefore(other: MTimestamp) {
		return this.compare(other) < 0
	}


	static now() {
		return new this(new Date())
	}


	// TODO move to own formatting class
	formatDateShort() {
		return MTimestamp.dateShortFormatter.format(this.value)
	}


	// TODO move to own formatting class
	formatShort() {
		return MTimestamp.shortFormatter.format(this.value)
	}


	static ofEpochSeconds(epochSeconds: number) {
		return new this(new Date(epochSeconds * 1_000))
	}


	static parse(string: string) {
		const value = new Date(string)
		if (isNaN(value.getTime())) {
			error(`Invalid date string: ${string}`)
		}

		return new this(value)
	}


	toString() {
		return this.value.toISOString()
	}
}
