import MContact from '~/model/MContact'
import MProcess from '~/model/MProcess'
import MProcessParticipationTag from '~/model/MProcessParticipationTag'


export default class MProcessParticipation {

	static fieldSelection({ includeContact, includeProcess }: { includeContact?: boolean, includeProcess?: boolean } = {}): string {
		return `{
			${includeContact ? `contact ${MContact.fieldSelection({ includePersons: true, includeProcessParticipations: false })}` : ''}
			${includeProcess ? `process ${MProcess.fieldSelection({ includeEvent: true, includeParticipations: false })}` : ''}
			tags ${MProcessParticipationTag.fieldSelection()}
		}`
	}


	readonly contact: MContact | null
	readonly process: MProcess | null
	readonly tags: MProcessParticipationTag[]


	constructor(properties: {
		readonly contact: MContact | null
		readonly process: MProcess | null
		readonly tags: MProcessParticipationTag[]
	}) {
		this.contact = properties.contact
		this.process = properties.process
		this.tags = properties.tags
	}


	static fromJson(json: any): MProcessParticipation {
		return new this({
			contact: json.contact ? MContact.fromJson(json.contact) : null,
			process: json.process ? MProcess.fromJson(json.process) : null,
			tags: json.tags.map((tag: any) => MProcessParticipationTag.fromJson(tag))
		})
	}
}
