import MVariable from '~/model/MVariable'


export default class MVariableConfiguration {

	static fieldSelection(): string {
		return `{
			values
			valuesByPersonId
			variables ${MVariable.fieldSelection()}
		}`
	}


	readonly values: { readonly [id: string]: string | null }
	readonly valuesByPersonId: { readonly [id: string]: { readonly [id: string]: string | null } }
	readonly variables: readonly MVariable[]


	constructor(properties: {
		readonly values: { readonly [id: string]: string | null }
		readonly valuesByPersonId: { readonly [id: string]: { readonly [id: string]: string | null } }
		readonly variables: readonly MVariable[]
	}) {
		this.values = properties.values
		this.valuesByPersonId = properties.valuesByPersonId
		this.variables = properties.variables
	}


	static fromJson(json: any) {
		return new this({
			values: json.values,
			valuesByPersonId: json.valuesByPersonId,
			variables: json.variables
		})
	}
}
