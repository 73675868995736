import GraphOperation from '@/graphql/operations/GraphOperation'
import { InjectionKey } from 'vue'
import configuration from '~/configuration'
import MCity from '~/model/MCity'
import MCommentJournalItem from '~/model/MCommentJournalItem'
import MContact from '~/model/MContact'
import MCountry from '~/model/MCountry'
import MDj from '~/model/MDj'
import MDjCategory from '~/model/MDjCategory'
import MDocument from '~/model/MDocument'
import MEmailRecipient from '~/model/MEmailRecipient'
import MEmailRecipientOption from '~/model/MEmailRecipientOption'
import MEquipment from '~/model/MEquipment'
import MError from '~/model/MError'
import MEvent from '~/model/MEvent'
import MEventConsultation from '~/model/MEventConsultation'
import MEventOccasion from '~/model/MEventOccasion'
import MEventPricing from '~/model/MEventPricing'
import MInlineText from '~/model/MInlineText'
import MJournalEntry from '~/model/MJournalEntry'
import MLocalDate from '~/model/MLocalDate'
import MLocation from '~/model/MLocation'
import MMagazine from '~/model/MMagazine'
import MMusic from '~/model/MMusic'
import MPhoneNumber from '~/model/MPhoneNumber'
import MProcess from '~/model/MProcess'
import MProcessEmailTemplate from '~/model/MProcessEmailTemplate'
import MProcessFile from '~/model/MProcessFile'
import MProcessParticipationTag from '~/model/MProcessParticipationTag'
import MProcessTag from '~/model/MProcessTag'
import MRequest from '~/model/MRequest'
import MSalutation from '~/model/MSalutation'
import MText from '~/model/MText'
import MTimestamp from '~/model/MTimestamp'
import MUpload from '~/model/MUpload'
import MWeddingFair from '~/model/MWeddingFair'
import { EmptyObject } from '~/utility'


// TODO Migrate everything to GraphClient & separate operations.
export default interface Api {

	archiveDocument(id: string): Promise<ApiResponse<EmptyObject>>

	archiveFile(id: string): Promise<ApiResponse<EmptyObject>>

	archiveJournalEntry(id: string): Promise<ApiResponse<MJournalEntry<any>>>

	archiveRequest(id: string): Promise<ApiResponse<EmptyObject>>

	changeEventDjCategory(id: string, djCategoryId: string | null): Promise<ApiResponse<EmptyObject>>

	changeEventLocation(id: string, locationId: string | null): Promise<ApiResponse<MEvent>>

	changeProcessState(id: string, state: string): Promise<ApiResponse<{ process: MProcess }>>

	copyProcess(id: string, eventDate: MLocalDate): Promise<ApiResponse<{ process: MProcess }>>

	createComment(input: CreateCommentInput): Promise<ApiResponse<MJournalEntry<MCommentJournalItem>>>

	createContact(properties: {
		businessPhoneNumber: MPhoneNumber | null
		companyName: string | null
		faxNumber: MPhoneNumber | null
		person1: {
			addressCity: string | null
			addressCountryCode: string | null
			addressPostalCode: string | null
			addressStreet: string | null
			emailAddress: string | null
			firstName: string | null
			homePhoneNumber: MPhoneNumber | null
			lastName: string | null
			mobilePhoneNumber: MPhoneNumber | null
			salutationId: string | null
		}
	}): Promise<ApiResponse<MContact>>

	createDocument(parameters: {
		content: MText
		processId: string
		recipientAddress: MInlineText | null
		recipientId: string
		type: 'contract' | 'proposal'
	}): Promise<ApiResponse<MDocument>>

	createDocumentFile(id: string): Promise<ApiResponse<EmptyObject>>

	createEvent(processId: string, occasionId: string): Promise<ApiResponse<{ event: MEvent }>>

	createEventAttachment(eventId: string, fileId: string, name: string): Promise<ApiResponse<{ attachment: MProcessFile }>>

	createEventCompensation(eventId: string): Promise<ApiResponse<FetchEventCompensationResult>>

	createPricing(
		eventId: string,
		basePrice: number,
		vatIncluded: boolean,
	): Promise<ApiResponse<FetchEventPricingResult & { pricing: MEventPricing }>>

	createProcess(): Promise<ApiResponse<{ process: MProcess }>>

	createProcessFromRequest(properties: {
		event: { date: MLocalDate | null, dateRemarks: string | null, occasionId: string, occasionDetails: string | null }
		locationId: string | null
		participation: { contactId: string, tagIds: string[] }
		remarks: string | null
		requestId: string
	}): Promise<ApiResponse<CreateProcessResult>>

	deleteUpload(id: string): Promise<ApiResponse<EmptyObject>>

	documentFilePreviewUrl(type: string, eventId: string, recipientId: string, recipientAddress: MInlineText, content: MText): string

	execute<Output>(operation: GraphOperation<Output>): Promise<Output | MError>

	fetchCity(id?: string): Promise<ApiResponse<FetchCityResult>>

	fetchCityByPostalCode(postalCode: string, countryCode: string): Promise<ApiResponse<{ city: MCity | null }>>

	fetchContact(id?: string | 'process'): Promise<ApiResponse<FetchContactResult>>

	fetchDashboard(tagIds: string[]): Promise<ApiResponse<FetchDashboardResult>>

	fetchEmailData(type: 'customer' | 'dj' | 'file', defaultFileId?: string): Promise<ApiResponse<FetchEmailDataResult>>

	fetchEvent(id: string | 'process'): Promise<ApiResponse<FetchEventResult>>

	fetchEventCompensation(): Promise<ApiResponse<FetchEventCompensationResult>>

	fetchDj(id: string): Promise<ApiResponse<MDj | null>>

	fetchDjCategories(includeArchived?: boolean): Promise<ApiResponse<ReadonlyArray<MDjCategory>>>

	fetchDjs(includeArchived?: boolean): Promise<ApiResponse<ReadonlyArray<MDj>>>

	fetchLocation(id?: string | 'process'): Promise<ApiResponse<FetchLocationResult>>

	fetchOpenRequests(): Promise<ApiResponse<FetchRequestsResult>>

	fetchPricing(eventId?: string): Promise<ApiResponse<FetchEventPricingResult>>

	fetchProcess(id: string): Promise<ApiResponse<MProcess | null>>

	fetchProcessJournalEntries(processId: string): Promise<ApiResponse<MJournalEntry<any>[]>>

	fetchRequest(id: string): Promise<ApiResponse<FetchRequestResult>>

	fetchSeason(date: MLocalDate): Promise<ApiResponse<'high' | 'low'>>

	findProcessesByEventDate(date: MLocalDate): Promise<ApiResponse<{ readonly processes: readonly MProcess[] }>>

	importRequests(file: File): Promise<ApiResponse<{ requests: MRequest[] }>>

	queryProcessesByTags(tagIds: string[]): Promise<ApiResponse<MProcess[]>>

	removeContactFromProcess(contactId: string, processId: string): Promise<ApiResponse<UpdateContactResult>>

	search(parameters: { offset?: number, query: string, type?: SearchType | undefined }): Promise<ApiResponse<SearchResult>>

	sendEmail(parameters: {
		copyRecipients: MEmailRecipient[]
		files: { name: string, source: MProcessFile | MUpload }[]
		message: string
		processId: string
		recipients: MEmailRecipient[]
		sender: MEmailRecipient
		subject: string
	}): Promise<ApiResponse<EmptyObject>>

	updateCity(id: string | 'new', data: CityInputData): Promise<ApiResponse<UpdateCityResult>>

	updateContact(id: string | 'new', data: ContactUpdateData): Promise<ApiResponse<UpdateContactResult>>

	updateDocument(parameters: {
		content: MText
		id: string
		recipientAddress: MInlineText | null
		recipientId: string
	}): Promise<ApiResponse<MDocument>>

	updateEvent(id: string, data: EventUpdateData): Promise<ApiResponse<UpdateEventResult>>

	updateEventCompensation(eventId: string, data: DjPricingUpdateData): Promise<ApiResponse<FetchEventCompensationResult>>

	updateEventDj(
		id: string,
		djId: string | null,
		djIsFixedByClientRequest: boolean,
		djIsFixedByProximity: boolean,
		djIsFixedByRecommendation: boolean,
		djIsFixedForOtherReason: string | null,
	): Promise<ApiResponse<{ event: MEvent }>>

	updateLocation(id: string | 'new', data: LocationInputData): Promise<ApiResponse<UpdateLocationResult>>

	updatePricing(eventId: string, data: PricingInputData): Promise<ApiResponse<FetchEventPricingResult & { pricing: MEventPricing }>>

	updateProcessTags(id: string, tagIds: string[]): Promise<ApiResponse<{ process: MProcess }>>

	uploadFile(file: File): Promise<ApiResponse<MUpload>>
}


export interface CreateCommentInput {

	readonly message: string
	readonly subjectIds: string[]
}


export interface CityInputData {

	readonly countryCode: string
	readonly drivingTime: number
	readonly name: string
	readonly postalCodeRangeEnd: number | null
	readonly postalCodeRangeStart: number | null
	readonly remarks: string | null
}


export interface EventUpdateData {

	readonly consultationIds: string[]
	readonly contractDeadline: {
		readonly date: MLocalDate
		readonly misses: number
	} | null
	readonly date: string | null
	readonly definiteEndTime: string | null
	readonly endTime: string | null
	readonly guestChildCount: number | null
	readonly guestCount: number | null
	readonly guestCountForMusic: number | null
	readonly guestNationalities: string | null
	readonly guestRemarks: string | null
	readonly internalRemarks: string | null
	readonly lightIds: string[]
	readonly lightRemarks: string | null
	readonly locationHasRecommendedUs: boolean
	readonly locationRemarks: string | null
	readonly musicIds: string[]
	readonly musicRemarks: string | null
	readonly plannedEndTime: string | null
	readonly processId: string
	readonly remarks: string | null
	readonly remarksAreHighPriority: boolean
	readonly season: 'low' | 'high' | undefined
	readonly setupTime: string | null
	readonly soundIds: string[]
	readonly soundRemarks: string | null
	readonly startTime: string | null
	readonly typeDetails: string | null
	readonly typeId: string
}


export interface ContactPersonUpdateData {

	readonly addressAddition: string | null
	readonly addressCity: string | null
	readonly addressCountryCode: string
	readonly addressPostalCode: string | null
	readonly addressStreet: string | null
	readonly birthday: string | null
	readonly canUseInformalCommunication: boolean
	readonly changedLastName: string | null
	readonly changedLastNameIsAfterWedding: boolean
	readonly emailAddress: string | null
	readonly firstName: string | null
	readonly homePhoneNumber: string | null
	readonly lastName: string | null
	readonly mobilePhoneNumber: string | null
	readonly noNewsletter: boolean
	readonly salutationId: string | null
	readonly title: string | null
}


export interface ContactReferralUpdateData {

	readonly eventDate: string | null
	readonly eventDjId: string | null
	readonly eventName: string | null
	readonly experienced: boolean
	readonly friends: boolean
	readonly gastronomy: boolean
	readonly google: boolean
	readonly internet: boolean
	readonly locationId: string | null
	readonly magazineId: string | null
	readonly other: string | null
	readonly ourWebsite: boolean
	readonly weddingFairId: string | null
}


export interface ContactUpdateData {

	readonly processParticipation: 'add' | 'update' | null
	readonly businessPhoneNumber: string | null
	readonly companyName: string | null
	readonly faxNumber: string | null
	readonly internalRemarks: string | null
	readonly person1: ContactPersonUpdateData
	readonly person2: ContactPersonUpdateData
	readonly processParticipationTagIdsToAdd: string[]
	readonly processParticipationTagIdsToRemove: string[]
	readonly referral: ContactReferralUpdateData
	readonly remarks: string | null
}


export interface DjPricingUpdateData {

	readonly baseFee: number
	readonly baseHours: number
	readonly customerPrice: number
	readonly customerPriceRemarks: string | null
	readonly drivingTime: number
	readonly drivingTimeCompensation: number
	readonly lightDetails: string | null
	readonly lightPrice: number
	readonly miscLabel1: string | null
	readonly miscLabel2: string | null
	readonly miscLabel3: string | null
	readonly miscPrice1: number | null
	readonly miscPrice2: number | null
	readonly miscPrice3: number | null
	readonly overtimeUnits: number
	readonly overtimeFee: number
	readonly remarks: string | null
	readonly soundDetails: string | null
	readonly soundPrice: number
}


export interface CreateProcessResult {

	readonly process: MProcess
}


export interface FetchCityResult {

	readonly city: MCity | null
	readonly countries: readonly MCountry[]
	readonly defaultCountryId: string
	readonly locations: readonly MLocation[]
}


export interface FetchContactResult {

	readonly contact: MContact | null
	readonly countries: readonly MCountry[]
	readonly defaultCountryId: string
	readonly djOptions: readonly { key: string, label: string }[]
	readonly locationOptions: readonly { key: string, label: string }[]
	readonly magazines: readonly MMagazine[]
	readonly processParticipationTags: readonly MProcessParticipationTag[]
	readonly salutations: readonly MSalutation[]
	readonly weddingFairs: readonly MWeddingFair[]
}


export interface FetchDashboardResult {

	readonly openImportRequestCount: number
	readonly openProcesses: readonly MProcess[]
	readonly openWebsiteRequestCount: number
	readonly taggedProcesses: readonly MProcess[]
}


export interface FetchEmailDataResult {

	readonly copyRecipientOptions: readonly MEmailRecipientOption[]
	readonly defaultFile: Readonly<{ name: string, source: MProcessFile }> | null
	readonly defaultMessage: string
	readonly defaultSubject: string
	readonly recipientOptions: readonly MEmailRecipientOption[]
	readonly senderOptions: readonly MEmailRecipientOption[]
	readonly templates: readonly MProcessEmailTemplate[]
}


export interface FetchEventResult {

	readonly djCategories: readonly MDjCategory[]
	readonly equipments: readonly MEquipment[]
	readonly eventConsultations: readonly MEventConsultation[]
	readonly eventOccasions: readonly MEventOccasion[]
	readonly event: MEvent | null
	readonly musics: readonly MMusic[]
}


export interface FetchEventCompensationResult {

	readonly djCategories: readonly MDjCategory[]
	readonly djs: readonly MDj[]
}


export interface FetchEventPricingResult {

	readonly djCategories: readonly MDjCategory[]
	readonly documents: readonly MDocument[]
	readonly equipments: readonly MEquipment[]
	readonly pricing: MEventPricing | null
}


export interface FetchLocationResult {

	readonly city: MCity | null
	readonly countries: readonly MCountry[]
	readonly defaultCountryId: string
	readonly location: MLocation | null
}


export interface FetchRequestResult {

	readonly eventTypeOptions: readonly MEventOccasion[]
	readonly participantRoleOptions: readonly MProcessParticipationTag[]
	readonly request: MRequest
	readonly salutationOptions: readonly MSalutation[]
}


export interface FetchRequestsResult {

	readonly requests: readonly MRequest[]
}


export interface LocationInputData {

	readonly addressAddition: string | null
	readonly addressCity: string
	readonly addressCountryCode: string
	readonly addressPostalCode: string | null
	readonly addressStreet: string | null
	readonly approachRemarks: string | null
	readonly capacity: number | null
	readonly commissionRemarks: string | null
	readonly contactName: string | null
	readonly drivingTime: number | null
	readonly drivingTimeAsPerNavigationSoftware: number | null
	readonly emailAddress: string | null
	readonly equipmentRemarks: string | null
	readonly faxNumber: string | null
	readonly internalRemarks: string | null
	readonly isFolderFiled: boolean
	readonly isRecommendingUs: boolean
	readonly mobilePhoneNumber: string | null
	readonly name: string
	readonly otherRemarks: string | null
	readonly phoneNumber: string | null
	readonly receivesCommission: boolean | null
	readonly setupRemarks: string | null
	readonly specificsRemarks: string | null
	readonly timingRemarks: string | null
	readonly websiteUrl: string | null
}


export interface PricingInputData {

	readonly additionalItems: readonly { readonly isSelected: boolean, readonly label: string, readonly price: number }[]
	readonly djCategories: readonly { readonly id: string, readonly isSelected: boolean, readonly label: string, readonly prepaymentAmount: number, readonly price: number }[]
	readonly equipmentList: readonly { readonly id: string, readonly isSelected: boolean, readonly price: number }[] // TODO separate from event page logic
	readonly includesEarlyStart: boolean
	readonly includesService: boolean
	readonly includesSetupInAdvance: boolean
	readonly priceForEarlyStart?: number
	readonly priceForService?: number
	readonly priceForSetupInAdvance?: number
	readonly pricePerOvertimeUnit: number
	readonly remarks: string | null
	readonly vatInclusion: 'inclusive' | 'exclusive'
	readonly vatRate: number
}


export interface SaveDocumentData {

	readonly createPdf: boolean
	readonly eventId: string
	readonly recipientId: string
	readonly address: string
	readonly body: string
	readonly footer: string
	readonly type: 'contract' | 'proposal'
}


export interface UpdateCityResult extends FetchCityResult {

	readonly city: MCity
}


export interface UpdateContactResult extends FetchContactResult {

	readonly contact: MContact
}


export interface UpdateEventResult extends FetchEventResult {

	readonly event: MEvent
}


export interface UpdateLocationResult extends FetchLocationResult {

	readonly location: MLocation
}


export type SearchType = 'city' | 'contact' | 'location' | 'process'


export interface SearchResult {

	readonly cityResult: SearchSubresult<MCity> | null
	readonly contactResult: SearchSubresult<MContact> | null
	readonly locationResult: SearchSubresult<MLocation> | null
	readonly processResult: SearchSubresult<MProcess> | null
}


export interface SearchSubresult<T extends unknown> {

	readonly elements: readonly T[]
	readonly nextOffset: number | null
	readonly totalCount: number
}


export class ApiError extends Error {

	readonly cause: Error | null
	readonly developerMessage: string
	readonly newError: MError | null // will replace ApiError over time
	readonly graphqlOperationName: string | null
	readonly userMessage: string


	constructor(properties: {
		readonly cause?: unknown
		readonly developerMessage?: string | null
		readonly graphqlOperationName?: string | null
		readonly newError?: MError | null
		readonly userMessage: string
	}) {
		super(properties.userMessage)

		const errorCause = properties.cause instanceof Error ? properties.cause : null

		this.cause = errorCause
		this.developerMessage = properties.developerMessage ?? errorCause?.toString() ?? properties.userMessage
		this.graphqlOperationName = properties.graphqlOperationName ?? null
		this.newError = properties.newError ?? null
		this.userMessage = properties.userMessage

		Object.freeze(this)
	}


	asError(): this {
		return this
	}


	asResult(): null {
		return null
	}


	map(): ApiError {
		return this
	}


	mapData(): ApiError {
		return this
	}


	requireResult(): never {
		throw this
	}


	get reportUrl() {
		const subject = `Fehler in der MMPT Kundendatenbank am ${MTimestamp.now().formatShort()} Uhr`

		let message = ''
		message += 'Hey,\n'
		message += 'in der Kundendatenbank ist folgender Fehler aufgetreten:\n\n---\n'
		message += this.userMessage
		message += '\n---\n\n'

		if (this.developerMessage !== this.userMessage) {
			message += 'Details:\n---\n'
			message += this.developerMessage
			message += '\n---\n\n'
		}

		if (this.cause) {
			message += 'Ursache:\n---\n'
			message += this.cause.stack
			message += '\n---\n\n'
		}

		message += 'Adresse: '
		message += window.location.href
		message += '\n'

		if (this.graphqlOperationName) {
			message += 'GraphQL-Operation: '
			message += this.graphqlOperationName
			message += '\n'
		}

		message += 'Browser: '
		message += window.navigator.userAgent

		return 'mailto:' +
			encodeURIComponent(configuration.technicalSupportEmailAddress) +
			'?subject=' + encodeURIComponent(subject) +
			'&body=' + encodeURIComponent(message)
	}
}


Object.defineProperty(ApiError.prototype, 'name', {
	value: 'ApiError',
})


export class ApiResult<Data> {

	readonly currentProcess: MProcess | null
	readonly data: Data
	readonly processTags: MProcessTag[] | null


	constructor(properties: {
		readonly currentProcess: MProcess | null
		readonly data: Data
		readonly processTags: MProcessTag[] | null
	}) {
		this.currentProcess = properties.currentProcess
		this.data = properties.data
		this.processTags = properties.processTags

		Object.freeze(this)
	}


	asError(): null {
		return null
	}


	asResult(): this {
		return this
	}


	map<Response extends ApiResponse<any>>(transform: (data: Data) => Response): Response {
		return transform(this.data)
	}


	mapData<TransformedData>(transform: (data: Data) => TransformedData): ApiResult<TransformedData> {
		return new ApiResult<TransformedData>({
			currentProcess: this.currentProcess,
			data: transform(this.data),
			processTags: this.processTags,
		})
	}


	requireResult(): this {
		return this
	}


	static of<Data>(data: Data) {
		return new ApiResult({
			currentProcess: null,
			data: data,
			processTags: null,
		})
	}
}


export type ApiResponse<Data> = ApiResult<Data> | ApiError


export const ApiInjectionKey: InjectionKey<Api> = Symbol('Api')
