import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toHandlers as _toHandlers, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["autocapitalize", "autocomplete", "autocorrect", "disabled", "id", "placeholder", "readonly", "rows", "required", "spellcheck", "tabindex", ".value"]
const _hoisted_3 = ["autocapitalize", "autocomplete", "autocorrect", "disabled", "id", "max", "maxlength", "min", "placeholder", "readonly", "required", "spellcheck", "step", "tabindex", "type", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classes)
  }, [
    (_ctx.hasLabel)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(_ctx.labelClasses),
          for: _ctx.id
        }, [
          _renderSlot(_ctx.$slots, "label", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.label) + _toDisplayString(_ctx.labelNoColon ? '' : ':'), 1)
          ])
        ], 10, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.multiline)
      ? (_openBlock(), _createElementBlock("textarea", _mergeProps({
          key: 1,
          autocapitalize: _ctx.autocapitalize,
          autocomplete: _ctx.actualAutocomplete,
          autocorrect: _ctx.autocorrect,
          class: _ctx.inputClasses,
          disabled: _ctx.disabled,
          id: _ctx.id,
          placeholder: _ctx.placeholder,
          readonly: _ctx.readonly,
          ref: "inputElement",
          rows: _ctx.rows,
          required: _ctx.required,
          spellcheck: _ctx.spellcheck,
          tabindex: _ctx.tabindex,
          ".value": _ctx.modelValue.text
        }, _toHandlers(_ctx.inputListeners)), null, 16, _hoisted_2))
      : (_openBlock(), _createElementBlock("input", _mergeProps({
          key: 2,
          autocapitalize: _ctx.autocapitalize,
          autocomplete: _ctx.actualAutocomplete,
          autocorrect: _ctx.autocorrect,
          class: _ctx.inputClasses,
          disabled: _ctx.disabled,
          id: _ctx.id,
          max: _ctx.max,
          maxlength: _ctx.maxlength,
          min: _ctx.min,
          placeholder: _ctx.placeholder,
          ref: "inputElement",
          readonly: _ctx.readonly,
          required: _ctx.required,
          spellcheck: _ctx.spellcheck,
          step: _ctx.step,
          tabindex: _ctx.tabindex,
          type: _ctx.type,
          value: _ctx.modelValue.text
        }, _toHandlers(_ctx.inputListeners)), null, 16, _hoisted_3))
  ], 2))
}