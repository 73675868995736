
	import XTagSelectionInput from '@/main/components/XTagSelectionInput.vue'
	import { Inject, Options, Prop, Vue } from 'vue-property-decorator'
	import Api from '~/api/Api'
	import XIconButton from '~/components/XIconButton.vue'
	import XLink from '~/components/XLink.vue'
	import XSection from '~/components/XSection.vue'
	import MProcess from '~/model/MProcess'
	import MProcessTag from '~/model/MProcessTag'
	import icons from '~/styles/icons.module.scss'


	@Options({
		components: { XIconButton, XLink, XSection, XTagSelectionInput },
		name: 'x-tag-filter'
	})
	export default class extends Vue {

		readonly icons = icons

		action: '' | 'create' | 'edit' = ''
		tagIds: string[] = []
		status: '' | 'busy' | 'failure' | 'success' = ''
		statusMessage = ''

		@Inject() readonly api!: Api

		@Prop() tags!: MProcessTag[]
		@Prop() modelValue!: string[]


		get fixedTagList() {
			if (!this.modelValue.length) {
				return ''
			}

			return this.tags
				.filter(tag => this.modelValue.includes(tag.id))
				.map(it => it.label)
				.sort()
				.join(', ')
		}


		onCreateClicked() {
			this.action = 'create'
			this.tagIds = []
			this.statusMessage = ''
		}


		onEditClicked() {
			this.action = 'edit'
			this.tagIds = this.modelValue
			this.statusMessage = ''
		}


		onSelectionSubmitted() {
			this.performAsyncOperation(
				'Filter wird gespeichert…',
				'Filter konnte nicht gespeichert werden!',
				async() => {
					const processes = this.tagIds.length ? (await this.api.queryProcessesByTags(this.tagIds)).requireResult().data : []

					this.$emit('update:modelValue', this.tagIds)

					return {
						completionMessage: 'Filter erfolgreich gespeichert!',
						success: true,
						taggedProcesses: processes
					}
				}
			)
		}


		async performAsyncOperation(
			busyMessage: string,
			failureMessage: string,
			operation: () => Promise<{ completionMessage: string, success: boolean, taggedProcesses: MProcess[] }>
		) {
			this.status = 'busy'
			this.statusMessage = busyMessage

			try {
				const { completionMessage, success, taggedProcesses } = await operation()

				this.status = success ? 'success' : 'failure'
				this.statusMessage = completionMessage

				if (success) {
					this.action = ''
					this.$emit('tagged-processes-changed', taggedProcesses)
				}
			}
			catch (error) {
				this.status = 'failure'
				this.statusMessage = `${failureMessage} Bitte versuche es erneut.`
			}
		}
	}
