import MainPage from '@/main/MainPage.vue'
import MainContactPage from '@/main/pages/MainContactPage.vue'
import MainDashboardPage from '@/main/pages/MainDashboardPage.vue'
import MainDjPage from '@/main/pages/MainDjPricingPage.vue'
import MainEmailPage from '@/main/pages/MainEmailPage.vue'
import MainEventPage from '@/main/pages/MainEventPage.vue'
import MainLocationPage from '@/main/pages/MainLocationPage.vue'
import MainPricingPage from '@/main/pages/MainPricingPage.vue'
import MainProcessPage from '@/main/pages/MainProcessPage.vue'
import MainRequestImportPage from '@/main/pages/MainRequestImportPage.vue'
import MainRequestsPage from '@/main/pages/MainRequestsPage.vue'
import MainSearchPage from '@/main/pages/MainSearchPage.vue'
import NotFoundPage from '@/main/pages/NotFoundPage.vue'
import SignInPage from '@/main/pages/SignInPage.vue'
import { RouteRecordRaw } from 'vue-router'


const routes: RouteRecordRaw[] = [
	{
		path: '/',
		component: MainPage,
		children: [

			{
				path: '/',
				name: 'dashboard',
				alias: '/uebersicht.php',
				component: MainDashboardPage,
			},
			{
				path: '/anfrage.php',
				name: 'event',
				component: MainEventPage,
			},
			{
				path: '/dj.php',
				name: 'dj-pricing',
				component: MainDjPage,
			},
			{
				path: '/email',
				alias: [
					'/email_infomail.php',
				],
				name: 'email',
				component: MainEmailPage,
			},
			{
				path: '/import.php',
				name: 'requests',
				component: MainRequestsPage,
			},
			{
				path: '/import_task.php',
				name: 'request-import',
				component: MainRequestImportPage,
			},
			{
				path: '/kunde.php',
				name: 'contact',
				component: MainContactPage,
			},
			{
				path: '/location.php',
				name: 'location',
				component: MainLocationPage,
			},
			{
				path: '/preise.php',
				name: 'pricing',
				component: MainPricingPage,
			},
			{
				path: '/vorgang_uebersicht.php',
				name: 'process',
				component: MainProcessPage,
			},
			{
				path: '/search',
				alias: [
					'/kunde_suchen.php',
					'/location_suchen.php',
					'/ort_suchen.php',
					'/vorgang_suchen.php',
				],
				name: 'search',
				component: MainSearchPage,
			},
		],
	},
	{
		path: '/sign-in',
		name: 'sign-in',
		component: SignInPage,
	},
	{
		path: '/.*',
		name: 'not found',
		component: NotFoundPage,
	},
]


export default routes
