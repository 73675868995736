import { error } from '~/utility'


export default abstract class MInlineTextMark {

	abstract readonly type: string


	abstract toJson(): any


	static fromJson(json: any): MInlineTextMark {
		if (typeof json === 'string') {
			switch (json) {
				case 'emphasized':
					return new MInlineTextMarkEmphasized()

				case 'small':
					return new MInlineTextMarkSmall()

				case 'strong':
					return new MInlineTextMarkStrong()

				default:
					error(`type: ${json}`)
			}
		}
		else {
			const [type, data] = json
			switch (type) {
				case 'emphasized':
					return new MInlineTextMarkEmphasized()

				case 'link':
					return new MInlineTextMarkLink(data)

				case 'small':
					return new MInlineTextMarkSmall()

				case 'strong':
					return new MInlineTextMarkStrong()

				default:
					error(`type: ${type}`)
			}
		}
	}
}


export class MInlineTextMarkEmphasized extends MInlineTextMark {

	readonly type = 'emphasized'


	toJson(): any {
		return ['emphasized']
	}
}


export class MInlineTextMarkLink extends MInlineTextMark {

	readonly type = 'link'


	constructor(readonly target: string) {
		super()
	}


	toJson(): any {
		return ['link', this.target]
	}
}


export class MInlineTextMarkSmall extends MInlineTextMark {

	readonly type = 'small'


	toJson(): any {
		return ['small']
	}
}


export class MInlineTextMarkStrong extends MInlineTextMark {

	readonly type = 'strong'


	toJson(): any {
		return ['strong']
	}
}
