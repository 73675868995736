import routes from '@/routes'
import { createRouter, createWebHistory } from 'vue-router'


const router = createRouter({
	history: createWebHistory(),
	parseQuery: query => {
		// Manual parsing as we have to deal with old non-UTF8 URLs which don't get parsed correctly by the default implementation
		const params: { [name: string]: string[] | string | null } = {}

		query = query.replace(
			/(^|&)daten=([^&]+)/,
			(_, pre, value, post) => `${pre}data=${encodeURIComponent(value)}${post}`
		)

		const search = new URLSearchParams(query)
		for (const key of search.keys()) {
			const values = search.getAll(key)
			if (!values.length) {
				params[key] = null
			}
			else if (values.length === 1) {
				params[key] = values[0]
			}
			else {
				params[key] = values
			}
		}

		return params
	},
	routes
})

router.beforeEach((to, from, next) => {
	if (to.path !== '/' && to.path.endsWith('/')) {
		next({
			path: to.path.replace(/\/$/, ''),
			hash: to.hash,
			query: to.query,
			params: to.params
		})
	}
	else {
		next()
	}
})


export default router
