
	import XCurrentProcessBar from '@/main/components/XCurrentProcessBar.vue'
	import XMainPageContentOld from '@/main/components/XMainPageContentOld.vue'
	import XNoProcess from '@/main/components/XNoProcess.vue'
	import XProcessTags from '@/main/components/XProcessTags.vue'
	import { Inject, Options, Vue } from 'vue-property-decorator'
	import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
	import Api, { ApiError, ApiResponse, ApiResult } from '~/api/Api'
	import XFormColumn from '~/components/XFormColumn.vue'
	import XFormRow from '~/components/XFormRow.vue'
	import XIconButton from '~/components/XIconButton.vue'
	import XInput from '~/components/XInput.vue'
	import XLink from '~/components/XLink.vue'
	import XLoadingAnimation from '~/components/XLoadingAnimation.vue'
	import XModal from '~/components/XModal.vue'
	import XProcessId from '~/components/XProcessId.vue'
	import XSection from '~/components/XSection.vue'
	import XStatusMessage from '~/components/XStatusMessage.vue'
	import MEquipment from '~/model/MEquipment'
	import MEvent from '~/model/MEvent'
	import MEventCompensation from '~/model/MEventCompensation'
	import MEventPricing from '~/model/MEventPricing'
	import MLocalDate from '~/model/MLocalDate'
	import MLocalTime from '~/model/MLocalTime'
	import MLocation from '~/model/MLocation'
	import MProcess from '~/model/MProcess'
	import { processSessionManager } from '~/ProcessSessionManager'
	import icons from '~/styles/icons.module.scss'
	import { asString, compareBy, error, formatDuration, formatNumber, formatPrice, reverseOrder, sorted } from '~/utility'
	import { localDateInput } from '~/utility/Input'


	@Options({
		components: {
			XCurrentProcessBar,
			XFormColumn,
			XFormRow,
			XIconButton,
			XInput,
			XLink,
			XLoadingAnimation,
			XMainPageContentOld,
			XModal,
			XNoProcess,
			XProcessId,
			XProcessTags,
			XSection,
			XStatusMessage
		},
		name: 'page-main-process'
	})
	export default class extends Vue {

		readonly formatDuration = formatDuration
		readonly formatNumber = formatNumber
		readonly formatPrice = formatPrice
		readonly icons: { readonly [key: string]: string } = icons

		apiResponse: ApiResponse<MProcess | null> | null = null
		copyDate = localDateInput(null)
		copyModalIsVisible = false
		isBusy = false
		isCopyingProcess = false
		seasonForDateResponse: ApiResponse<'high' | 'low'> | null = null
		status: ApiError | string | null = null
		updatedProcess?: MProcess | null

		@Inject() readonly api!: Api


		ageForBirthday(birthday: MLocalDate) {
			return MLocalDate.now().yearsSince(birthday)
		}


		beforeRouteUpdate(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
			this.reload(to)
			next()
		}


		get compensation(): MEventCompensation | null {
			return this.event?.compensation ?? null
		}


		get compensationSubtotal(): number | null {
			const compensation = this.compensation
			if (!compensation) {
				return null
			}

			return [
				compensation.baseFee,
				compensation.overtimeFee,
				compensation.drivingTimeCompensation,
				compensation.miscLabel1 ? compensation.miscPrice1 : 0,
				compensation.miscLabel2 ? compensation.miscPrice2 : 0,
				compensation.miscLabel3 ? compensation.miscPrice3 : 0
			].reduce<number>((a, b) => a + b!, 0)
		}


		created() {
			this.reload(this.$route)
		}


		get currentProcess() {
			return this.result.currentProcess
		}


		data() {
			return {
				updatedProcess: undefined
			}
		}


		get dj() {
			return this.event?.dj ?? null
		}


		get event(): MEvent | null {
			return this.process?.event ?? null
		}


		get eventAdditionalTimes() {
			const times: { label: string, value: MLocalTime }[] = []

			const event = this.event
			if (event) {
				if (event.setupTime) {
					times.push({ label: 'Aufbau', value: event.setupTime })
				}
				if (event.plannedEndTime) {
					times.push({ label: 'geplantes Ende', value: event.plannedEndTime })
				}
				if (event.definiteEndTime) {
					times.push({ label: 'definitives Ende', value: event.definiteEndTime })
				}
			}

			return times
		}


		get files() {
			return sorted(
				this.process!.files,
				reverseOrder(compareBy(it => it.creationTimestamp))
			)
		}


		guestCountDetailsForEvent(event: MEvent) {
			let details = ''
			if (event.guestChildCount) {
				details += 'davon '
				details += formatNumber(event.guestChildCount)
				details += event.guestChildCount === 1 ? ' Kind' : ' Kinder'
			}
			if (event.guestCountForMusic) {
				if (event.guestChildCount) {
					details += ' und '
				}

				details += formatNumber(event.guestCountForMusic)
				details += ' zu beschallen'
			}

			return details
		}


		get isCurrentProcess() {
			return Boolean(this.currentProcess && this.process && this.currentProcess.id === this.process.id)
		}


		get light(): MEquipment[] {
			return this.event?.equipment?.filter(it => it.type === 'light') ?? []
		}


		get location(): MLocation | null {
			return this.event?.location ?? null
		}


		onCopyClicked() {
			this.copyModalIsVisible = true
		}


		async onCopySubmitted() {
			const id = this.process?.id ?? error()
			const eventDate = this.copyDate.value ?? error()

			this.isCopyingProcess = true

			try {
				await this.api.copyProcess(id, eventDate)
			}
			catch (e) {
				this.isCopyingProcess = false

				return
			}

			await this.$router.push({ name: 'event' })
		}


		async openProcess() {
			const process = this.process ?? error()

			processSessionManager.open(process.id)

			await this.$router.push({ name: 'event' })
		}


		get process(): MProcess | null {
			return this.updatedProcess === undefined ? this.result.data : this.updatedProcess
		}


		set process(value: MProcess | null) {
			this.updatedProcess = value
		}


		get pricing(): MEventPricing | null {
			return this.event?.pricing ?? null
		}


		get pricingSelectedDjCategoryOptions() {
			return this.pricing?.djCategoryOptions?.filter(it => it.isSelected) ?? []
		}


		get pricingSelectedDjCategoryOptionsText() {
			const options = this.pricingSelectedDjCategoryOptions
			if (!options.length) {
				return ''
			}

			let text = ''
			for (const category of options) {
				if (text) {
					text += ', '
				}

				text += category.label
				text += ' ('
				text += formatPrice(category.price)
				text += ' €)'
			}

			return text
		}


		get pricingSelectedDjCategoryOptionsTotalText() {
			const options = this.pricingSelectedDjCategoryOptions
			if (!options.length) {
				return ''
			}

			const total = this.pricingTotal
			if (total === null) {
				return ''
			}

			let text = ''
			for (const category of options) {
				if (text) {
					text += ', '
				}

				if (options.length > 1) {
					text += category.label
					text += ' ('
				}
				text += formatPrice(category.price + total.allExceptDjCategories)
				text += ' €'
				if (options.length > 1) {
					text += ')'
				}
			}

			return text
		}


		get pricingTotal() {
			const pricing = this.pricing
			if (!pricing) {
				return null
			}

			let light = 0
			let sound = 0
			let allExceptDjCategories = 0

			if (pricing.isEarlyStartSelected) {
				allExceptDjCategories += pricing.priceForEarlyStart ?? 0
			}
			if (pricing.isServiceSelected) {
				allExceptDjCategories += pricing.priceForService ?? 0
			}
			if (pricing.isSetupInAdvanceSelected) {
				allExceptDjCategories += pricing.priceForSetupInAdvance ?? 0
			}
			for (const option of pricing.equipmentOptions) {
				if (option.isSelected) {
					allExceptDjCategories += option.price

					if (option.equipment.type === 'light') {
						light += option.price
					}
					if (option.equipment.type === 'sound') {
						sound += option.price
					}
				}
			}
			for (const option of pricing.additionalOptions) {
				if (option.label) {
					allExceptDjCategories += option.price
				}
			}

			return {
				allExceptDjCategories,
				light,
				sound
			}
		}


		async reload(route: RouteLocationNormalized) {
			const id = asString(route.query.id)
			if (!id) {
				return this.$router.push({ name: 'dashboard' })
			}

			this.apiResponse = null
			this.seasonForDateResponse = null
			this.status = null

			this.apiResponse = await this.api.fetchProcess(id)
			this.updatedProcess = undefined

			const event = this.event
			if (event && event.date) {
				this.seasonForDateResponse = await this.api.fetchSeason(event.date)
			}
		}


		get result(): ApiResult<MProcess | null> {
			return this.apiResponse?.asResult() ?? error()
		}


		get seasonForDate(): 'high' | 'low' | null {
			return this.seasonForDateResponse?.asResult()?.data ?? null
		}


		get sound(): MEquipment[] {
			return this.event?.equipment?.filter(it => it.type === 'sound') ?? []
		}
	}
