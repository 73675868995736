
	import '+initialization'
	import GraphClient, { GraphClientInjectionKey } from '@/graphql/GraphClient'
	import { provide } from 'vue'
	import { Options, Vue } from 'vue-property-decorator'
	import Api, { ApiInjectionKey } from '~/api/Api'
	import GraphApi from '~/api/GraphApi'
	import Authenticator from '~/authentication/Authenticator'
	import XErrorHandler from '~/components/XErrorHandler.vue'
	import configuration from '~/configuration'
	import { isProduction } from '~/utility'


	@Options({
		name: 'x-app',
		components: { XErrorHandler }
	})
	export default class extends Vue {

		private readonly api: Api
		private readonly authenticator: Authenticator

		readonly isProduction = isProduction


		constructor(props, ctx) {
			super(props, ctx)

			this.api = new GraphApi({
				baseUrl: configuration.apiBaseUrl
			})
			this.authenticator = new Authenticator({
				api: this.api
			})
		}


		created() {
			provide('api', this.api) // Vue 2
			provide('authenticator', this.authenticator) // Vue 2

			provide(ApiInjectionKey, this.api)
			provide(GraphClientInjectionKey, new GraphClient({
				baseUrl: configuration.apiBaseUrl
			}))
		}
	}
