import { ref, Ref } from '@vue/reactivity'


export default class Storage {

	private _tagIds: Ref<string[]> = ref([])


	constructor() {
		this._tagIds.value = JSON.parse(localStorage.getItem('tagIds') || '[]')
	}


	get tagIds(): string[] {
		return this._tagIds.value
	}


	set tagIds(value: string[]) {
		this._tagIds.value = value

		localStorage.setItem('tagIds', JSON.stringify(value))
	}
}


export const storage = new Storage()
