
	import { Options, Prop, Vue, Watch } from 'vue-property-decorator'
	import { randomId } from '~/utility'
	import { booleanInput, Input } from '~/utility/Input'


	@Options({
		name: 'x-checkbox'
	})
	export default class extends Vue {

		readonly id = randomId()

		@Prop() autocomplete?: string
		@Prop() disabled?: boolean
		@Prop() label?: string
		@Prop({ default: 'horizontal' }) layout?: 'horizontal' | 'inline'
		@Prop() noChangeHighlight?: boolean
		@Prop() readonly?: boolean
		@Prop() required?: boolean

		@Prop({
			default: () => booleanInput(false),
			type: null as any
		})
		modelValue!: Input<boolean | null>

		private inputElement?: HTMLInputElement


		get actualAutocomplete() {
			if (this.autocomplete === 'really-off') {
				return this.id
			}

			return this.autocomplete
		}


		get classes() {
			return [
				this.$style.root,
				this.$style[`root-${this.hasLabel ? this.layout : 'standalone'}`]
			]
		}


		focus(options?: FocusOptions) {
			if (this.inputElement) {
				this.inputElement.focus(options)
			}
		}


		get hasChanges() {
			return !this.modelValue.isInitial()
		}


		get hasLabel() {
			return Boolean(this.label || this.$slots.label)
		}


		get inputClasses() {
			const classes = [
				this.$style.input,
				this.$style[`input-${this.hasLabel ? this.layout : 'standalone'}`]
			]
			if (!this.noChangeHighlight && this.hasChanges) {
				classes.push(this.$style.changed)
			}
			if (this.disabled) {
				classes.push(this.$style.disabled)
			}

			return classes
		}


		get inputListeners() {
			return {
				...this.$listeners,
				input: this.onInput
			}
		}


		get labelClasses() {
			const classes = [
				this.$style.label,
				this.$style[`label-${this.hasLabel ? this.layout : 'standalone'}`]
			]
			if (!this.noChangeHighlight && this.hasChanges) {
				classes.push(this.$style.changed)
			}
			if (this.disabled) {
				classes.push(this.$style.disabled)
			}

			return classes
		}


		mounted() {
			this.inputElement = this.$refs.inputElement as HTMLInputElement

			this.updateValidity()
		}


		onInput() {
			if (!this.inputElement) {
				return
			}

			const data = this.modelValue.withValue(this.inputElement.checked)

			this.$emit('update:modelValue', data)
		}


		@Watch('modelValue', { immediate: true })
		onInputChanged() {
			this.updateValidity()
		}


		private updateValidity() {
			if (!this.inputElement) {
				return
			}

			this.inputElement.setCustomValidity(this.modelValue.value === null ? 'Hier muss eine Auswahl getroffen werden.' : '')
		}
	}
