
	import { Inject, Options, Vue } from 'vue-property-decorator'
	import { RouteRecordName } from 'vue-router'
	import { AuthenticationStatus } from '~/authentication/AuthenticationStatus'
	import Authenticator from '~/authentication/Authenticator'
	import XFormRow from '~/components/XFormRow.vue'
	import XInput from '~/components/XInput.vue'
	import XLoadingAnimation from '~/components/XLoadingAnimation.vue'
	import MError from '~/model/MError'
	import { asString } from '~/utility'
	import { stringInput } from '~/utility/Input'


	@Options({
		components: { XLoadingAnimation, XInput, XFormRow },
		name: 'page-sign-in'
	})
	export default class extends Vue {

		emailAddressInput = stringInput('')
		passwordInput = stringInput('')
		errorMessage = ''
		isBusy = false


		@Inject()
		readonly authenticator!: Authenticator


		get canSubmit() {
			return !this.isBusy && (this.emailAddressInput.valueOrUndefined || '').length >= 1 && (this.passwordInput.valueOrUndefined || '').length >= 1
		}


		beforeMount() {
			this.$subscribeTo<AuthenticationStatus>(this.authenticator.status$, status => {
				switch (status.type) {
					case 'authenticated':
						this.onAuthenticated()
						break

					case 'authenticating':
						this.isBusy = true
						break

					case 'not authenticated':
						this.isBusy = false
						break
				}
			})
		}


		private onAuthenticated() {
			let route: { name?: RouteRecordName | null } | null = null
			try {
				// might throw if `next` is an invalid URI
				route = this.$router.resolve(asString(this.$route.query.next) || '/', this.$route)
			}
			catch {
				// ignore
			}

			if (!route || route.name === 'not found') {
				route = { name: 'dashboard' }
			}

			this.$router.replace({ ...route, ...{ name: route.name ?? undefined } })
		}


		async signIn() {
			this.errorMessage = ''

			const emailAddress = this.emailAddressInput.value
			const password = this.passwordInput.value

			// TODO not here
			const result = await this.authenticator.signIn(emailAddress, password)
			if (result instanceof MError) {
				this.errorMessage = result.userMessage
			}
		}
	}
