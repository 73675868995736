export default class MEventOccasion {

	static fieldSelection(): string {
		return `{
			id
			isMiscellaneous
			label
		}`
	}


	readonly id: string
	readonly isMiscellaneous: boolean
	readonly label: string


	constructor(properties: {
		readonly id: string
		readonly isMiscellaneous: boolean
		readonly label: string
	}) {
		this.id = properties.id
		this.isMiscellaneous = properties.isMiscellaneous
		this.label = properties.label
	}


	static fromJson(json: any): MEventOccasion {
		return new this({
			id: json.id,
			isMiscellaneous: json.isMiscellaneous,
			label: json.label
		})
	}
}
