
	import { Options, Prop, Vue } from 'vue-property-decorator'


	@Options({
		name: 'x-section'
	})
	export default class extends Vue {

		@Prop() contentClass?: string
		@Prop() noBullet?: boolean
		@Prop() title?: string
	}
