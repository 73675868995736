
	import { Options, Prop, Vue, Watch } from 'vue-property-decorator'
	import XIconButton from '~/components/XIconButton.vue'
	import { findTabbableChildren } from '~/utility'


	// TODO add document-wide focus handler
	// TODO handle ESC key
	@Options({
		components: { XIconButton }
	})
	export default class XModal extends Vue {

		@Prop() contentClass?: string
		@Prop() title?: string
		@Prop() unclosable?: boolean
		@Prop() visible?: boolean

		private dialogNode!: HTMLDivElement

		isTransitioning = false


		hide() {
			if (this.visible && !this.unclosable) {
				this.$emit('update:visible', false)
			}
		}


		mounted() {
			this.dialogNode = this.$refs.dialogNode as HTMLDivElement

			this.isTransitioning = false
		}


		onBackgroundClicked() {
			this.hide()
		}


		onCloseClicked() {
			this.hide()
		}


		onFocusBeforeDialog() {
			const tabbableElements = findTabbableChildren(this.dialogNode)
			const elementToFocus = tabbableElements[tabbableElements.length - 1]
			if (elementToFocus) {
				elementToFocus.focus()
			}
		}


		onFocusAfterDialog() {
			const tabbableElements = findTabbableChildren(this.dialogNode)
			const elementToFocus = tabbableElements[0]
			if (elementToFocus) {
				elementToFocus.focus()
			}
		}


		onTransitionEnded(event: TransitionEvent) {
			if (!this.isTransitioning || event.propertyName !== 'opacity') {
				return
			}

			this.isTransitioning = false

			this.$emit(this.visible ? 'shown' : 'hidden')
		}


		@Watch('visible')
		onVisibleUpdated() {
			this.isTransitioning = true
		}
	}
