import configuration from '~/configuration'
import MTimestamp from '~/model/MTimestamp'


export default class MError {

	static fieldSelection(): string {
		return `{
			userMessage
		}`
	}


	readonly cause: Error | null
	readonly developerMessage: string
	readonly graphOperationName: string | null
	readonly isTemporary: boolean
	readonly userMessage: string


	constructor(properties: {
		readonly cause?: unknown
		readonly developerMessage?: string | null
		readonly graphOperationName?: string | null
		readonly isTemporary?: boolean
		readonly userMessage: string
	}) {
		const errorCause = properties.cause instanceof Error ? properties.cause : null

		this.cause = errorCause
		this.developerMessage = properties.developerMessage ?? errorCause?.toString() ?? properties.userMessage
		this.graphOperationName = properties.graphOperationName ?? null
		this.isTemporary = properties.isTemporary ?? false
		this.userMessage = properties.userMessage
	}


	static fromJson(json: any): MError {
		return new this({
			developerMessage: json.developerMessage,
			isTemporary: false, // TODO Support in back-end.
			userMessage: json.userMessage
		})
	}


	get reportUrl() {
		const subject = `Fehler in der MMPT Kundendatenbank am ${MTimestamp.now().formatShort()} Uhr`

		let message = ''
		message += 'Hey,\n'
		message += 'in der Kundendatenbank ist folgender Fehler aufgetreten:\n\n---\n'
		message += this.userMessage
		message += '\n---\n\n'

		if (this.developerMessage !== this.userMessage) {
			message += 'Details:\n---\n'
			message += this.developerMessage
			message += '\n---\n\n'
		}

		if (this.cause) {
			message += 'Ursache:\n---\n'
			message += this.cause.stack
			message += '\n---\n\n'
		}

		message += 'Adresse: '
		message += window.location.href
		message += '\n'

		if (this.graphOperationName) {
			message += 'GraphQL-Operation: '
			message += this.graphOperationName
			message += '\n'
		}

		message += 'Browser: '
		message += window.navigator.userAgent

		return 'mailto:' +
			encodeURIComponent(configuration.technicalSupportEmailAddress) +
			'?subject=' + encodeURIComponent(subject) +
			'&body=' + encodeURIComponent(message)
	}
}


export class MAuthenticationRequiredError extends MError {
}


export function errorAsNull<T>(value: T | MError): T | null {
	if (value instanceof MError) {
		return null
	}

	return value
}
