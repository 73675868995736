import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_x_link = _resolveComponent("x-link")!

  return (_ctx.error)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.$style.root)
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.$style.message)
        }, _toDisplayString(_ctx.defaultUserMessage), 3),
        _createVNode(_component_x_link, {
          class: _normalizeClass(_ctx.$style.link),
          href: _ctx.error.reportUrl,
          rel: "noopener",
          target: "_blank",
          text: "Problem melden"
        }, null, 8, ["class", "href"]),
        _createVNode(_component_x_link, {
          class: _normalizeClass(_ctx.$style.link),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.error = null)),
          text: "Ausblenden"
        }, null, 8, ["class"])
      ], 2))
    : _createCommentVNode("", true)
}