import CollatorOptions = Intl.CollatorOptions
import { inject, InjectionKey } from 'vue'
import { RouteLocationRaw, Router } from 'vue-router'


export const isProduction = false // FIXME process.env.NODE_ENV === 'production'

export type AnyComparable = Comparable<any> | number | string
export type Comparator<T> = (a: T, b: T) => number
export type Defined = bigint | boolean | number | object | string | symbol | null
export type EmptyObject = { [K in any]: never }
export type NonNull = bigint | boolean | number | object | string | symbol


export interface Comparable<T> {

	compare(other: T): number
}


const focusableElementsSelector = [
	'a[href]',
	'button',
	'input',
	'object',
	'select',
	'textarea',
	'[contenteditable]',
	'[tabindex]'
]
	.map(selector => `${selector}:not(:disabled):not([disabled])`)
	.join(', ')


export function assertNotNullable<Value extends NonNull>(value: Value | null | undefined): Value {
	if (value === null || value === undefined) {
		return error('Value expected to not be null or undefined')
	}

	return value
}


export function assertNotUndefined<Value extends Defined>(value: Value | undefined): Value {
	if (value === undefined) {
		return error('Value expected to not be undefined')
	}

	return value
}


export function associateBy<T>(items: readonly T[], keySelector: (item: T, index: number) => string) {
	const object: { [key: string]: T } = {}
	items.forEach((item, index) => {
		object[keySelector(item, index)] = item
	})

	return object
}


export function associateByTo<T, U>(items: readonly T[], keyValueSelector: (item: T, index: number) => [string, U]): { [key: string]: U } {
	const object: { [key: string]: U } = {}
	items.forEach((item, index) => {
		const [key, value] = keyValueSelector(item, index)
		object[key] = value
	})

	return object
}


export function associateTo<T>(items: readonly string[], valueSelector: (key: string, index: number) => T) {
	const object: { [key: string]: T } = {}
	items.forEach((key, index) => {
		object[key] = valueSelector(key, index)
	})

	return object
}


export function asString(value: any): string | null {
	return typeof value === 'string' ? value : null
}


export function compare<T extends AnyComparable | null | undefined>(a: T, b: T, locales?: string | string[], options?: CollatorOptions) {
	if (a === b) {
		return 0
	}
	if (a === null || a === undefined) {
		if (b === null || b === undefined) {
			return 0
		}

		return -1
	}
	if (b === null || b === undefined) {
		return 1
	}

	if (typeof a === 'string') {
		return a.localeCompare(b as string, locales, options)
	}
	if (typeof a === 'number') {
		return a - +b
	}

	return a.compare(b)
}


export function compareBy<T>(transform: (value: T) => AnyComparable | null | undefined): Comparator<T>
export function compareBy<T>(transform: (value: T) => string | null | undefined, locales?: string | string[], options?: CollatorOptions): Comparator<T>

export function compareBy<T>(transform: (value: T) => AnyComparable | null | undefined, locales?: string | string[], options?: CollatorOptions): Comparator<T> {
	return (a: T, b: T) => compare(transform(a), transform(b), locales, options)
}


export function compareWith<T>(comparator1: Comparator<T>, comparator2: Comparator<T>, ...additionalComparators: Comparator<T>[]): Comparator<T> {
	return (a: T, b: T) => {
		let result = comparator1(a, b)
		if (result) {
			return result
		}

		result = comparator2(a, b)
		if (result) {
			return result
		}

		for (const additionalComparator of additionalComparators) {
			result = additionalComparator(a, b)
			if (result) {
				return result
			}
		}

		return 0
	}
}


export function delay(milliseconds: number): Promise<void> {
	return new Promise(resolve => setTimeout(resolve, milliseconds))
}


export function deleteCookie(name: string) {
	document.cookie = `${name}=deleted;path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT`
}


export function elementIsVisible(element: HTMLElement) {
	return Boolean(element.offsetWidth || element.offsetHeight || element.getClientRects().length)
}


export function elementHasAncestor(element: HTMLElement, ancestor: HTMLElement): boolean {
	if (element === ancestor) {
		return true
	}

	const parent = element.parentElement
	if (!(parent instanceof HTMLElement)) {
		return false
	}

	return elementHasAncestor(parent, ancestor)
}


export function elementTopInAncestor(element: HTMLElement, ancestor: HTMLElement): number | null {
	if (element === ancestor) {
		return 0
	}
	if (ancestor === document.documentElement) {
		return element.getBoundingClientRect().top + window.pageYOffset
	}
	if (!elementHasAncestor(element, ancestor)) {
		return null
	}

	const offsetParent = element.offsetParent
	if (!(offsetParent instanceof HTMLElement)) {
		return null
	}

	if (offsetParent === ancestor) {
		return element.offsetTop
	}
	if (elementHasAncestor(offsetParent, ancestor)) {
		const offsetParentTop = elementTopInAncestor(offsetParent, ancestor)
		if (offsetParentTop === null) {
			return null
		}

		return element.offsetTop + offsetParentTop
	}

	return element.offsetTop - ancestor.offsetTop
}


export function error(message?: string): never {
	throw new Error(message === undefined ? 'This should never happen…' : message)
}


export function findFocusableChildren(root: HTMLElement) {
	return Array.from(root.querySelectorAll<HTMLElement>(focusableElementsSelector))
		.filter(element => !(element as any).disabled && elementIsVisible(element))
}


export function findScrollParent(element: HTMLElement, dimension: 'x' | 'y'): HTMLElement | null {
	const parent = element.parentElement
	if (parent && parent instanceof HTMLElement) {
		if (isScrollableElement(parent, dimension)) {
			return parent
		}

		return findScrollParent(parent, dimension)
	}

	const rootElement = document.scrollingElement || document.documentElement
	if (!(rootElement instanceof HTMLElement)) {
		return null
	}

	return rootElement
}


export function findTabbableChildren(root: HTMLElement) {
	return findFocusableChildren(root)
		.filter(element => (!element.tabIndex || element.tabIndex >= 0))
}


export function formatDuration(seconds: number) {
	const hours = Math.floor(seconds / 3600)
	seconds %= 3600

	const minutes = Math.floor(seconds / 60)

	return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`
}


export function formatNumber(value: number, fractionDigits?: number, options?: { omitZeroFraction?: boolean }) {
	let text = new Intl.NumberFormat('de-DE', {
		maximumFractionDigits: fractionDigits || 0,
		minimumFractionDigits: fractionDigits || 0
	}).format(value)

	if (options && options.omitZeroFraction) {
		text = text.replace(/,0+$/, '')
	}

	return text
}


export function formatPrice(value: number, options?: { omitZeroFraction?: boolean }) {
	return formatNumber(value, 2, options)
}


export function getCookie(name: string) {
	return document.cookie
		.split(';')
		.map(cookie => cookie.trim().split('=', 2))
		.filter(cookie => cookie[0] === name)
		.map(cookie => cookie[1])[0] || null
}


export function groupBy<T>(items: readonly T[], keySelector: (item: T, index: number) => string) {
	const object: { [key: string]: T[] } = {}
	items.forEach((item, index) => {
		const key = keySelector(item, index)

		if (!object[key]) {
			object[key] = []
		}

		object[key].push(item)
	})

	return object
}


export function htmlEncode(string: string): string {
	return string.replace(/[&<>"'`]/g, character => {
		const replacements: { [character: string]: string } = {
			'&': '&amp;',
			'<': '&lt;',
			'>': '&gt;',
			'"': '&quot;',
			'\'': '&#x27;',
			'`': '&#x60;'
		}

		return replacements[character] || character
	})
}


export function identity<Value>(value: Value) {
	return value
}


export function isDefined<Value extends Defined>(value: Value | undefined): value is Value {
	return value !== undefined
}


export function isNullOrUndefined<Value extends {}>(value: Value | null | undefined): value is null | undefined {
	return value === null || value === undefined
}


export function isNotNull<Value extends Defined>(value: Value | null): value is Value {
	return value !== null
}


export function isNotNullOrUndefined<Value extends Defined>(value: Value | null | undefined): value is Value {
	return value !== null && value !== undefined
}


export function isNull(value: any): value is null {
	return value === null
}


export function isSameRoute(a: RouteLocationRaw, b: RouteLocationRaw, router: Router) {
	return router.resolve(a, router.currentRoute.value).href === router.resolve(b, router.currentRoute.value).href
}


export function isScrollableElement(element: Element, dimension: 'x' | 'y'): boolean {
	if (element.clientHeight <= 0 || element.scrollHeight <= element.clientHeight) {
		return false
	}

	const overflow = getComputedStyle(element).getPropertyValue(`overflow-${dimension}`)
	return (overflow === 'auto' || overflow === 'scroll')
}


export function isUndefined(value: Defined | undefined): value is undefined {
	return value === undefined
}


export function nullsFirst<T extends {}>(comparator: Comparator<T>): Comparator<T | null | undefined> {
	return (a, b) => {
		if (a === undefined) {
			a = null
		}
		if (b === undefined) {
			b = null
		}

		if (a === b) {
			return 0
		}
		if (a === null) {
			return -1
		}
		if (b === null) {
			return 1
		}

		return comparator(a as NonNullable<T>, b as NonNullable<T>)
	}
}


export function parsePrice(value: string): number | undefined {
	value = value.trim()
	if (!value) {
		return undefined
	}

	let price

	let match = value.match(/^-?\d+(\.\d{3})*(,\d{0,2})?$/)
	if (match) {
		price = Number.parseFloat(value.replace(/\./g, '').replace(',', '.'))
	}
	else {
		match = value.match(/^-?\d+(,\d{3})*(\.\d{0,2})?$/)
		if (!match) {
			return undefined
		}

		price = Number.parseFloat(value.replace(/,/g, ''))
	}

	if (isNaN(price)) {
		return undefined
	}

	return price
}


export function randomId() {
	return `_${Math.random().toString(36)}${Math.random().toString(36)}${Math.random().toString(36)}`.replace(/0\./g, '')
}


export function reverseOrder<T>(comparator: Comparator<T>): Comparator<T> {
	return (a, b) => -comparator(a, b)
}


export function setCookie(name: string, value: string, maxAge?: number) {
	if (maxAge === undefined) {
		maxAge = 365 * 24 * 60 * 60
	}

	document.cookie = `${name}=${value};path=/;max-age=${maxAge}`
}


export function undefinedAsNull<Value>(value: Value | undefined): Value extends undefined ? null : (Value | null) {
	if (value === undefined) {
		return null as any
	}

	return value as any
}


interface ScrollElementIntoViewOptions {

	behavior?: ScrollBehavior
	padding?: number
}


export function scrollElementIntoView(element: HTMLElement, options?: ScrollElementIntoViewOptions) {
	const behavior = options && options.behavior || undefined
	const padding = options && options.padding || 0

	const scrollParent = findScrollParent(element, 'y')
	if (!scrollParent) {
		return
	}

	const elementTopInScrollParent = elementTopInAncestor(element, scrollParent)
	if (elementTopInScrollParent === null) {
		return
	}

	const scrollHeight = scrollParent.clientHeight
	const scrollTop = scrollParent.scrollTop
	const scrollBottom = scrollTop + scrollHeight
	const elementHeight = element.offsetHeight
	const elementTop = elementTopInScrollParent - padding
	const elementBottom = elementTop + elementHeight + (padding * 2)

	if (scrollTop > elementTop) {
		scrollParent.scrollTo({ behavior, top: elementTop })
	}
	else if (scrollBottom < elementBottom) {
		scrollParent.scrollTo({ behavior, top: elementBottom - scrollHeight })
	}
}


export function sorted<Element>(array: readonly Element[], comparator?: Comparator<Element>): readonly Element[] {
	return array.concat().sort(comparator)
}


type AddOptionalProperties<Union, Properties extends keyof any> =
	Union extends (infer Component)
		? { [P in keyof Union]: Union[P] } & { [P in Exclude<Properties, keyof Union>]?: never }
		: never

type UnionToIntersection<Union> =
	(Union extends any ? (_: Union) => void : never) extends ((_: infer Component) => void) ? Component : never

export type Merge<Union> = AddOptionalProperties<Union, keyof UnionToIntersection<Union>>


export function injectRequired<T>(key: InjectionKey<T>): T {
	const value = inject(key)
	if (value === undefined) {
		error(`No value provided for injection key '${key.toString()}'.`)
	}

	return value
}
