import MDj from '~/model/MDj'
import MDjCategory from '~/model/MDjCategory'
import MEntity from '~/model/MEntity'
import MEquipment from '~/model/MEquipment'
import MEventCompensation from '~/model/MEventCompensation'
import MEventConsultation from '~/model/MEventConsultation'
import MEventOccasion from '~/model/MEventOccasion'
import MEventPricing from '~/model/MEventPricing'
import MLocalDate from '~/model/MLocalDate'
import MLocalTime from '~/model/MLocalTime'
import MLocation from '~/model/MLocation'
import MMusic from '~/model/MMusic'
import MProcess from '~/model/MProcess'
import MProcessFile from '~/model/MProcessFile'
import MTimestamp from '~/model/MTimestamp'


export default class MEvent extends MEntity {

	static fieldSelection({
		                      includeLocationEvents,
		                      includeProcess
	                      }: { includeLocationEvents?: boolean, includeProcess?: boolean } = {}): string {
		return `{
			compensation ${MEventCompensation.fieldSelection()}
			creationTimestamp
			date
			definiteEndTime
			dj ${MDj.fieldSelection()}
			djCategory ${MDjCategory.fieldSelection()}
			djIsFixedByClientRequest
			djIsFixedByProximity
			djIsFixedByRecommendation
			djIsFixedForOtherReason
			endTime
			equipment ${MEquipment.fieldSelection()}
			files ${MProcessFile.fieldSelection()}
			givenConsultations ${MEventConsultation.fieldSelection()}
			guestChildCount
			guestCount
			guestCountForMusic
			guestNationalities
			guestRemarks
			id
			internalRemarks
			lightRemarks
			location ${MLocation.fieldSelection({ includeCity: true, includeEvents: includeLocationEvents })}
			locationHasRecommendedUs
			locationRemarks
			musicPreferences ${MMusic.fieldSelection()}
			musicRemarks
			occasion ${MEventOccasion.fieldSelection()}
			occasionDetails
			plannedEndTime
			pricing ${MEventPricing.fieldSelection()}
			${includeProcess ? `process ${MProcess.fieldSelection({ includeEvent: false })}` : ''}
			remarks
			remarksAreHighPriority
			season
			setupTime
			soundRemarks
			startTime
		}`
	}


	readonly compensation: MEventCompensation | null
	readonly creationTimestamp: MTimestamp
	readonly date: MLocalDate | null
	readonly definiteEndTime: MLocalTime | null
	readonly dj: MDj | null
	readonly djCategory: MDjCategory | null
	readonly djIsFixedByClientRequest: boolean
	readonly djIsFixedByProximity: boolean
	readonly djIsFixedByRecommendation: boolean
	readonly djIsFixedForOtherReason: string
	readonly endTime: MLocalTime | null
	readonly equipment: readonly MEquipment[]
	readonly files: readonly MProcessFile[]
	readonly givenConsultations: readonly MEventConsultation[]
	readonly guestChildCount: number | null
	readonly guestCount: number | null
	readonly guestCountForMusic: number | null
	readonly guestNationalities: string | null
	readonly guestRemarks: string | null
	readonly internalRemarks: string | null
	readonly lightRemarks: string | null
	readonly location: MLocation | null
	readonly locationHasRecommendedUs: boolean | null
	readonly locationRemarks: string | null
	readonly music: readonly MMusic[] // TODO rn to musicPreferences
	readonly musicRemarks: string | null
	readonly occasion: MEventOccasion
	readonly occasionDetails: string | null
	readonly plannedEndTime: MLocalTime | null
	readonly pricing: MEventPricing | null
	readonly process: MProcess | null
	readonly remarks: string | null
	readonly remarksAreHighPriority: boolean
	readonly season: 'high' | 'low' | null
	readonly setupTime: MLocalTime | null
	readonly soundRemarks: string | null
	readonly startTime: MLocalTime | null


	constructor(properties: {
		readonly compensation: MEventCompensation | null
		readonly creationTimestamp: MTimestamp
		readonly date: MLocalDate | null
		readonly definiteEndTime: MLocalTime | null
		readonly dj: MDj | null
		readonly djCategory: MDjCategory | null
		readonly djIsFixedByClientRequest: boolean
		readonly djIsFixedByProximity: boolean
		readonly djIsFixedByRecommendation: boolean
		readonly djIsFixedForOtherReason: string
		readonly files: readonly MProcessFile[]
		readonly endTime: MLocalTime | null
		readonly equipment: readonly MEquipment[]
		readonly givenConsultations: readonly MEventConsultation[]
		readonly guestChildCount: number | null
		readonly guestCount: number | null
		readonly guestCountForMusic: number | null
		readonly guestNationalities: string | null
		readonly guestRemarks: string | null
		readonly id: string
		readonly internalRemarks: string | null
		readonly lightRemarks: string | null
		readonly location: MLocation | null
		readonly locationHasRecommendedUs: boolean | null
		readonly locationRemarks: string | null
		readonly music: readonly MMusic[]
		readonly musicRemarks: string | null
		readonly occasion: MEventOccasion
		readonly occasionDetails: string | null
		readonly plannedEndTime: MLocalTime | null
		readonly pricing: MEventPricing | null
		readonly process: MProcess | null
		readonly remarks: string | null
		readonly remarksAreHighPriority: boolean
		readonly season: 'high' | 'low' | null
		readonly setupTime: MLocalTime | null
		readonly soundRemarks: string | null
		readonly startTime: MLocalTime | null
	}) {
		super(properties.id)

		this.compensation = properties.compensation
		this.creationTimestamp = properties.creationTimestamp
		this.date = properties.date
		this.definiteEndTime = properties.definiteEndTime
		this.dj = properties.dj
		this.djCategory = properties.djCategory
		this.djIsFixedByClientRequest = properties.djIsFixedByClientRequest
		this.djIsFixedByProximity = properties.djIsFixedByProximity
		this.djIsFixedByRecommendation = properties.djIsFixedByRecommendation
		this.djIsFixedForOtherReason = properties.djIsFixedForOtherReason
		this.endTime = properties.endTime
		this.equipment = properties.equipment
		this.files = properties.files
		this.givenConsultations = properties.givenConsultations
		this.guestChildCount = properties.guestChildCount
		this.guestCount = properties.guestCount
		this.guestCountForMusic = properties.guestCountForMusic
		this.guestNationalities = properties.guestNationalities
		this.guestRemarks = properties.guestRemarks
		this.internalRemarks = properties.internalRemarks
		this.lightRemarks = properties.lightRemarks
		this.location = properties.location
		this.locationHasRecommendedUs = properties.locationHasRecommendedUs
		this.locationRemarks = properties.locationRemarks
		this.music = properties.music
		this.musicRemarks = properties.musicRemarks
		this.occasion = properties.occasion
		this.occasionDetails = properties.occasionDetails
		this.plannedEndTime = properties.plannedEndTime
		this.pricing = properties.pricing
		this.process = properties.process
		this.remarks = properties.remarks
		this.remarksAreHighPriority = properties.remarksAreHighPriority
		this.season = properties.season
		this.setupTime = properties.setupTime
		this.soundRemarks = properties.soundRemarks
		this.startTime = properties.startTime
	}


	/** seconds */
	get duration(): number | null {
		if (!this.startTime || !this.endTime) {
			return null
		}

		// TODO will break between summer/winter-time
		let duration = this.endTime.minus(this.startTime)
		if (duration < 0) {
			duration += 24 * 60 * 60
		}

		return duration
	}


	static fromJson(json: any): MEvent {
		return new this({
			compensation: json.compensation ? MEventCompensation.fromJson(json.compensation) : null,
			creationTimestamp: MTimestamp.parse(json.creationTimestamp),
			date: json.date ? MLocalDate.parseOrNull(json.date) : null,
			definiteEndTime: json.definiteEndTime ? MLocalTime.parseOrNull(json.definiteEndTime) : null,
			dj: json.dj ? MDj.fromJson(json.dj) : null,
			djCategory: json.djCategory ? MDjCategory.fromJson(json.djCategory) : null,
			djIsFixedByClientRequest: json.djIsFixedByClientRequest,
			djIsFixedByProximity: json.djIsFixedByProximity,
			djIsFixedByRecommendation: json.djIsFixedByRecommendation,
			djIsFixedForOtherReason: json.djIsFixedForOtherReason,
			endTime: json.endTime ? MLocalTime.parseOrNull(json.endTime) : null,
			equipment: json.equipment.map((equipment: any) => MEquipment.fromJson(equipment)),
			files: json.files.map((json: any) => MProcessFile.fromJson(json)),
			givenConsultations: json.givenConsultations.map((consultation: any) => MEventConsultation.fromJson(consultation)),
			guestChildCount: json.guestChildCount,
			guestCount: json.guestCount,
			guestCountForMusic: json.guestCountForMusic,
			guestNationalities: json.guestNationalities,
			guestRemarks: json.guestRemarks,
			id: json.id,
			internalRemarks: json.internalRemarks,
			lightRemarks: json.lightRemarks,
			location: json.location ? MLocation.fromJson(json.location) : null,
			locationHasRecommendedUs: json.locationHasRecommendedUs,
			locationRemarks: json.locationRemarks,
			music: json.musicPreferences.map((music: any) => MMusic.fromJson(music)),
			musicRemarks: json.musicRemarks,
			occasion: MEventOccasion.fromJson(json.occasion),
			occasionDetails: json.occasionDetails,
			plannedEndTime: json.plannedEndTime ? MLocalTime.parseOrNull(json.plannedEndTime) : null,
			pricing: json.pricing ? MEventPricing.fromJson(json.pricing) : null,
			process: json.process ? MProcess.fromJson(json.process) : null,
			remarks: json.remarks,
			remarksAreHighPriority: json.remarksAreHighPriority,
			season: json.season,
			setupTime: json.setupTime ? MLocalTime.parseOrNull(json.setupTime) : null,
			soundRemarks: json.soundRemarks,
			startTime: json.startTime ? MLocalTime.parseOrNull(json.startTime) : null
		})
	}
}
