import { Comparable, error } from '~/utility'


export default class MLocalTime implements Comparable<MLocalTime> {

	readonly hour: number
	readonly minute: number
	readonly second: number


	private constructor(hour: number, minute: number, second: number) {
		this.hour = hour
		this.minute = minute
		this.second = second
	}


	compare(other: MLocalTime) {
		let result = this.hour - other.hour
		if (result === 0) {
			result = this.minute - other.minute
			if (result === 0) {
				result = this.second - other.second
			}
		}

		return result
	}


	format(includeSeconds?: boolean) {
		if (includeSeconds === undefined) {
			includeSeconds = true
		}

		if (includeSeconds) {
			return `${this.hour < 10 ? '0' : ''}${this.hour}:${this.minute < 10 ? '0' : ''}${this.minute}:${this.second < 10 ? '0' : ''}${this.second}`
		}
		else {
			return `${this.hour < 10 ? '0' : ''}${this.hour}:${this.minute < 10 ? '0' : ''}${this.minute}`
		}
	}


	isEqualTo(other: MLocalTime) {
		return !this.compare(other)
	}


	static isValid(hour: number, minute: number, second: number) {
		return hour >= 0 && hour <= 23 && minute >= 0 && minute <= 59 && second >= 0 && second <= 59
	}


	static make(hour: number, minute: number, second: number) {
		return this.makeOrNull(hour, minute, second) || error(`Incorrect time (hour=${hour}, minute=${minute}, second=${second})`)
	}


	static makeOrNull(hour: number, minute: number, second: number) {
		if (!this.isValid(hour, minute, second)) {
			return null
		}

		return new this(hour, minute, second)
	}


	minus(other: MLocalTime) {
		const hours = this.hour - other.hour
		const minutes = this.minute - other.minute
		const seconds = this.second - other.second

		return (hours * 3600) + (minutes * 60) + seconds
	}


	plus(hours: number, minutes = 0, seconds = 0) {
		let second = this.second + seconds
		let minute = this.minute + minutes
		let hour = this.hour + hours

		minute += Math.floor(second / 60)
		second %= 60

		hour += Math.floor(minute / 60)
		minute %= 60

		return MLocalTime.make(
			Math.round(hour % 24),
			Math.round(minute),
			Math.round(second)
		)
	}


	static now() {
		const date = new Date()

		return new this(date.getHours(), date.getMinutes(), date.getSeconds())
	}


	static parse(string: string) {
		return this.parseOrNull(string) || error(`Invalid MLocalTime string: ${string}`)
	}


	static parseOrNull(string: string) {
		const match = string.match(/^(\d{1,2}):(\d{1,2})(:(\d{1,2}))?$/)
		if (!match) {
			return null
		}

		return this.makeOrNull(
			Number.parseInt(match[1]),
			Number.parseInt(match[2]),
			Number.parseInt(match[4] || '0')
		)
	}


	toString() {
		return this.format(this.second !== 0)
	}
}
