
	import { Options, Prop, Vue } from 'vue-property-decorator'
	import { ApiError } from '~/api/Api'
	import MError from '~/model/MError'


	@Options({
		name: 'x-status-message'
	})
	export default class extends Vue {

		@Prop({ default: '', type: [Error, Object, String] }) content!: string | ApiError | MError
		@Prop() type?: 'error' | 'success' | 'warning'


		get actualType() {
			if (this.type) {
				return this.type
			}

			return (this.content instanceof ApiError || this.content instanceof MError) ? 'error' : 'success'
		}


		get apiErrorReportUrl() {
			return (this.content instanceof ApiError || this.content instanceof MError) ? this.content.reportUrl : null
		}


		get defaultText() {
			return (this.content instanceof ApiError || this.content instanceof MError) ? this.content.userMessage : this.content
		}
	}
