import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_x_loading_animation = _resolveComponent("x-loading-animation")!
  const _component_x_main_header = _resolveComponent("x-main-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_x_call_service = _resolveComponent("x-call-service")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style.root, _ctx.isAuthenticating ? _ctx.$style.isAuthenticating : null])
  }, [
    (_ctx.isAuthenticating)
      ? (_openBlock(), _createBlock(_component_x_loading_animation, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_x_main_header),
          _createElementVNode("div", null, [
            _createVNode(_component_router_view)
          ]),
          _createVNode(_component_x_call_service)
        ], 64))
  ], 2))
}