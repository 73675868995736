
	import XCurrentProcessBar from '@/main/components/XCurrentProcessBar.vue'
	import XMainPageContentOld from '@/main/components/XMainPageContentOld.vue'
	import cityInput from '@/main/model/CityInput'
	import locationInput from '@/main/model/LocationInput'
	import { Inject, Options, Vue, Watch } from 'vue-property-decorator'
	import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
	import Api, { ApiError, ApiResponse, ApiResult, FetchCityResult, FetchLocationResult, UpdateCityResult, UpdateLocationResult } from '~/api/Api'
	import XCheckbox from '~/components/XCheckbox.vue'
	import XFormColumn from '~/components/XFormColumn.vue'
	import XFormRow from '~/components/XFormRow.vue'
	import XIconButton from '~/components/XIconButton.vue'
	import XInput from '~/components/XInput.vue'
	import XLink from '~/components/XLink.vue'
	import XProcessId from '~/components/XProcessId.vue'
	import XSection from '~/components/XSection.vue'
	import XSelect from '~/components/XSelect.vue'
	import XStatusMessage from '~/components/XStatusMessage.vue'
	import MCity from '~/model/MCity'
	import MLocation from '~/model/MLocation'
	import MProcess from '~/model/MProcess'
	import icons from '~/styles/icons.module.scss'
	import { asString, compareBy, error, formatDuration, isNullOrUndefined, Merge, reverseOrder, sorted } from '~/utility'
	import { stringInput } from '~/utility/Input'


	type ApiData = Merge<FetchCityResult | FetchLocationResult | UpdateCityResult | UpdateLocationResult>


	@Options({
		components: {
			XProcessId,
			XStatusMessage,
			XCheckbox,
			XCurrentProcessBar,
			XFormColumn,
			XFormRow,
			XIconButton,
			XInput,
			XLink,
			XMainPageContentOld,
			XSection,
			XSelect
		},
		name: 'page-main-location'
	})
	export default class extends Vue {

		readonly formatDuration = formatDuration
		readonly discardConfirmationMessage = 'Du hast Daten geändert aber noch nicht gespeichert.\nWillst du wirklich fortfahren?'
		readonly icons: { readonly [key: string]: string } = icons

		apiResponse: ApiResponse<ApiData> | null = null
		cityForPostalCodeBasis: string | null = null
		cityForPostalCodeIsLoading = false
		cityForPostalCodeTimeoutId = 0
		cityInput = cityInput()
		ignoresRouteUpdates = false
		isBusy = false
		isEditingCityInLocation = false
		isSaved = false
		locationInput = locationInput()
		mode: 'city' | 'location' = 'location'
		searchQueryInput = stringInput('')
		status: ApiError | string | null = null
		statusLocation: 'city' | 'location' = 'location'
		updatedCity?: MCity | null
		updatedCurrentProcess?: MProcess | null
		updatedLocation?: MLocation | null
		warningDismissed = false

		@Inject() readonly api!: Api


		beforeRouteLeave(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
			next(this.confirmDiscardingChanges())
		}


		beforeRouteUpdate(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
			next((this.ignoresRouteUpdates || this.onRouteChanged(to)))
		}


		get city() {
			return this.updatedCity === undefined ? this.result.data.city : this.updatedCity
		}


		confirmDiscardingChanges() {
			if (!this.hasChanges) {
				return true
			}

			return confirm(this.discardConfirmationMessage)
		}


		get countries() {
			return this.result.data.countries
		}


		created() {
			this.onRouteChanged(this.$route)
		}


		get currentProcess() {
			return this.updatedCurrentProcess === undefined ? this.result.currentProcess : this.updatedCurrentProcess
		}


		set currentProcess(value: MProcess | null) {
			this.updatedCurrentProcess = value
		}


		data() {
			return {
				updatedCity: undefined,
				updatedCurrentProcess: undefined,
				updatedLocation: undefined
			}
		}


		destroyed() {
			window.removeEventListener('beforeunload', this.onWindowBeforeUnload)
		}


		get events() {
			return sorted(
				this.result.data.location?.events ?? [],
				reverseOrder(compareBy(it => it.date))
			)
		}


		get hasChanges() {
			return this.hasCityChanges || this.hasLocationChanges
		}


		get hasCityChanges() {
			return this.showsCityForm && !this.cityInput.isInitial()
		}


		get hasLocationChanges() {
			return this.showsLocationForm && !this.locationInput.isInitial()
		}


		get isCityLocation() {
			return Boolean(this.location && this.location.name === 'unbekannt' && this.location.city)
		}


		get isReadonlyLocation() {
			return this.isCityLocation || this.isEditingCityInLocation || this.mode === 'city'
		}


		get isValid() {
			if (this.mode === 'city' || this.isEditingCityInLocation) {
				return this.cityInput.isValid()
			}
			else {
				return this.locationInput.isValid()
			}
		}


		get locationIsInCurrentProcess() {
			return this.location &&
				this.currentProcess &&
				this.currentProcess.event &&
				this.currentProcess.event.location &&
				this.location.id === this.currentProcess.event.location.id
		}


		get location() {
			return this.updatedLocation === undefined ? this.result.data.location : this.updatedLocation
		}


		get locations() {
			return sorted(
				this.result.data.locations ?? [],
				compareBy(it => it.name)
			)
		}


		mounted() {
			window.addEventListener('beforeunload', this.onWindowBeforeUnload)
		}


		async onCitySubmitted() {
			const input = this.cityInput
			if (!input.name.value) {
				return alert('Bitte gib den Namen des Ortes ein.')
			}

			const postalCodeRangeStart = input.postalCodeRangeStart.value
			const postalCodeRangeEnd = input.postalCodeRangeEnd.value ?? postalCodeRangeStart

			if (postalCodeRangeStart && postalCodeRangeEnd && postalCodeRangeStart > postalCodeRangeEnd) {
				return alert('Der Postleitzahlenbereich ist ungültig (\'bis\' darf nicht kleiner sein als \'von\').')
			}

			if (isNullOrUndefined(input.drivingTime.valueOrUndefined)) {
				return alert('Bitte gib die Fahrzeit zum Ort ein.')
			}

			if (this.hasLocationChanges && !confirm(this.discardConfirmationMessage)) {
				return
			}

			const cityId = this.city ? this.city.id : 'new'

			this.isBusy = true
			this.status = null

			const response = await this.api.updateCity(cityId, {
				countryCode: input.country.value?.key ?? error(),
				drivingTime: input.drivingTime.value ?? error(),
				name: input.name.value,
				postalCodeRangeEnd: input.postalCodeRangeEnd.value,
				postalCodeRangeStart: input.postalCodeRangeStart.value,
				remarks: input.remarks.value || null
			})

			this.isBusy = false
			this.statusLocation = 'city'

			if (response instanceof ApiError) {
				this.status = response

				return
			}

			this.isSaved = true
			this.updatedCurrentProcess = response.currentProcess

			if (cityId === 'new') {
				if (this.mode === 'location' && this.isEditingCityInLocation) {
					this.status = 'Ort wurde angelegt. Nun die Location erneut speichern.'
				}
				else {
					this.status = 'Ort wurde angelegt. Die \'unbekannte\' Location wurde angelegt.'
				}
			}
			else {
				this.status = 'Ort wurde aktualisiert. Die \'unbekannte\' Location wurde geändert.'
			}

			const city = response.data.city

			if (this.mode === 'location' && this.location && this.isEditingCityInLocation) {
				this.cityInput = cityInput()
				this.isEditingCityInLocation = false
				this.updatedCity = city
				this.updatedLocation = new MLocation({ ...this.location, city })

				await this.$router.push(`/location.php?get_do=zeige&get_id=${this.location.id}`)
			}
			else {
				this.apiResponse = response
				this.cityInput = cityInput(response.data)

				if (cityId === 'new') {
					await this.$router.push(`/location.php?get_do=zeige_ort&get_id=${city.id}`)
				}
			}
		}


		@Watch('locationInput.addressCountry')
		onLocationAddressCountryChanged() {
			this.updateCityForPostalCode()
		}


		onLocationAddressPostalCodeBlurred() {
			this.updateCityForPostalCode()
		}


		@Watch('locationInput.addressPostalCode')
		onLocationAddressPostalCodeChanged() {
			if (this.cityForPostalCodeTimeoutId) {
				clearTimeout(this.cityForPostalCodeTimeoutId)
			}

			this.cityForPostalCodeTimeoutId = setTimeout(
				() => this.updateCityForPostalCode(),
				1_500
			) as any
		}


		async onLocationSubmitted() {
			const input = this.locationInput

			if (!input.name.value) {
				return alert('Bitte gib den Namen der Location ein.')
			}
			if (input.name.value.toLowerCase() === 'unbekannt') {
				return alert('\'unbekannt\' ist kein gültiger Name.')
			}

			if (!input.addressCity.value) {
				return alert('Bitte gib den Ort der Location ein.')
			}

			if (isNullOrUndefined(input.drivingTime.valueOrUndefined)) {
				return alert('Bitte gib die Fahrzeit ein.')
			}

			if (isNullOrUndefined(input.drivingTimeAsPerNavigationSoftware.valueOrUndefined)) {
				return alert('Bitte gib die Fahrzeit lt. Routenplaner ein.')
			}

			if (this.hasCityChanges && !confirm(this.discardConfirmationMessage)) {
				return
			}

			const locationId = this.location ? this.location.id : 'new'

			this.isBusy = true
			this.status = null
			this.warningDismissed = true

			const response = await this.api.updateLocation(locationId, {
				addressAddition: input.addressAddition.value || null,
				addressCity: input.addressCity.value,
				addressCountryCode: input.addressCountry.value?.key ?? error(),
				addressPostalCode: input.addressPostalCode.value || null,
				addressStreet: input.addressStreet.value || null,
				approachRemarks: input.approachRemarks.value || null,
				capacity: input.capacity.value || null,
				commissionRemarks: input.commissionRemarks.value || null,
				contactName: input.contactName.value || null,
				drivingTime: input.drivingTime.value ?? error(),
				drivingTimeAsPerNavigationSoftware: input.drivingTimeAsPerNavigationSoftware.value ?? error(),
				emailAddress: input.emailAddress.value || null,
				equipmentRemarks: input.equipmentRemarks.value || null,
				faxNumber: input.faxNumber.value?.toString() ?? null,
				internalRemarks: input.internalRemarks.value || null,
				isFolderFiled: input.isFolderFiled.value,
				isRecommendingUs: input.isRecommendingUs.value,
				mobilePhoneNumber: input.mobilePhoneNumber.value?.toString() ?? null,
				name: input.name.value,
				otherRemarks: input.otherRemarks.value || null,
				phoneNumber: input.phoneNumber.value?.toString() ?? null,
				receivesCommission: input.receivesCommission.value,
				setupRemarks: input.setupRemarks.value || null,
				specificsRemarks: input.specificsRemarks.value || null,
				timingRemarks: input.timingRemarks.value || null,
				websiteUrl: input.websiteUrl.value || null
			})

			this.isBusy = false
			this.statusLocation = 'location'

			if (response instanceof ApiError) {
				this.status = response

				return
			}

			const location = response.data.location

			this.apiResponse = response
			this.cityInput = cityInput(response.data)
			this.isSaved = true
			this.locationInput = locationInput(response.data)
			this.updatedCity = undefined
			this.updatedCurrentProcess = undefined
			this.warningDismissed = false

			if (locationId === 'new') {
				if (response.currentProcess &&
					response.currentProcess.event &&
					response.currentProcess.event.location &&
					response.currentProcess.event.location.id === location.id
				) {
					this.status = 'Location neu angelegt und zum Vorgang hinzugefügt.'
				}
				else {
					this.status = 'Location neu angelegt.'
				}
			}
			else {
				this.status = 'Änderungen wurden gespeichert.'
			}

			if (locationId === 'new') {
				await this.$router.push(`/location.php?get_do=zeige&get_id=${location.id}`)
			}
		}


		async onRemoveFromProcessClicked() {
			const currentProcess = this.currentProcess ?? error()
			const event = currentProcess.event ?? error()

			this.isBusy = true
			this.status = null
			this.warningDismissed = true

			const response = await this.api.changeEventLocation(event.id, null)

			this.isBusy = false
			this.statusLocation = 'location'

			if (response instanceof ApiError) {
				this.status = response

				return
			}

			this.status = 'Location wurde aus dem Vorgang entfernt.'
			this.updatedCurrentProcess = response.currentProcess
		}


		onRouteChanged(route: RouteLocationNormalized): boolean {
			// TODO this is async and may still be running when another route update comes in. Handle that!

			const queryParameters = route.query

			let mode: 'city' | 'location' = 'location'
			let id: string | undefined = 'process'
			if (queryParameters.get_do === 'zeige' && typeof queryParameters.get_id === 'string') {
				id = queryParameters.get_id || undefined
			}
			else if (queryParameters.get_do === 'neu') {
				id = undefined
			}
			else if (queryParameters.get_do === 'neu_ort') {
				id = undefined
				mode = 'city'
			}
			else if (queryParameters.get_do === 'zeige_ort' && queryParameters.get_id) {
				id = queryParameters.get_id.toString()
				mode = 'city'
			}

			const isLoaded = this.apiResponse !== null

			this.isEditingCityInLocation = Boolean(queryParameters.ort_neu)

			const onLoadSucceeded = () => {
				if (this.isEditingCityInLocation) {
					const input = this.cityInput
					input.country = input.country.withKey(asString(queryParameters.oland))
					input.drivingTime = input.drivingTime.withValue(Number.parseFloat(asString(queryParameters.ofz) || '') || 0)
					input.name = input.name.withValue(asString(queryParameters.oname) || '')
					input.postalCodeRangeEnd = input.postalCodeRangeEnd.withValue(null)
					input.postalCodeRangeStart = input.postalCodeRangeStart.withValue(Number.parseInt(asString(queryParameters.oplz) || '', 10) || null)
					input.remarks = input.remarks.withValue('')
				}
			}

			let needsReload = !isLoaded || mode !== this.mode
			if (!needsReload) {
				if (id) {
					switch (mode) {
						case 'city':
							needsReload = id !== (this.city ? this.city.id : null)
							break

						case 'location':
							needsReload = id !== (this.location ? this.location.id : null)
							break
					}

					if (!needsReload) {
						onLoadSucceeded()
						return true
					}
				}
			}

			if (!this.confirmDiscardingChanges()) {
				return false
			}

			this.reload(mode, id)
				.then(onLoadSucceeded)

			return true
		}


		onSearchSubmitted() {
			this.$router.push({
				name: 'search',
				query: {
					query: this.searchQueryInput.value || undefined,
					type: this.mode
				}
			})
		}


		onUseCityDrivingTimeForLocationClicked() {
			if (this.city) {
				this.locationInput.drivingTime = this.locationInput.drivingTime.withValue(this.city.drivingTime)
			}
		}


		async onUseForCurrentProcessClicked(locationId: string) {
			if (!this.confirmDiscardingChanges()) {
				return
			}

			const currentProcess = this.currentProcess ?? error()
			const event = currentProcess.event
			if (!event) {
				alert('Eine Location kann erst festgelegt werden, nachdem die Veranstaltung gespeichert wurde.')
				return
			}

			this.isBusy = true
			this.status = null
			this.warningDismissed = true

			const response = await this.api.changeEventLocation(event.id, locationId)

			this.isBusy = false
			this.statusLocation = 'location'

			if (response instanceof ApiError) {
				this.status = response

				return
			}

			this.isBusy = false
			this.updatedCurrentProcess = response.currentProcess

			if (this.mode === 'city') {
				this.cityInput = cityInput()

				await this.$router.push(`/location.php?get_do=zeige&get_id=${locationId}`)
			}

			this.status = 'Location wurde in den Vorgang übernommen.'
		}


		onWindowBeforeUnload(event: Event) {
			if (this.hasChanges) {
				(event as any).returnValue = this.discardConfirmationMessage
			}
		}


		async reload(mode: 'city' | 'location', id: string | undefined) {
			this.apiResponse = null
			this.cityForPostalCodeBasis = null
			this.cityForPostalCodeIsLoading = false
			this.cityInput = cityInput()
			this.isSaved = false
			this.mode = mode
			this.locationInput = locationInput()
			this.status = null
			this.updatedCity = undefined
			this.updatedCurrentProcess = undefined
			this.updatedLocation = undefined
			this.warningDismissed = true

			let response: ApiResponse<ApiData>
			if (mode === 'location') {
				response = await this.api.fetchLocation(id)
				this.apiResponse = response

				if (response instanceof ApiError) {
					return
				}

				const location = response.data.location
				if (location) {
					this.cityForPostalCodeBasis = `${location.address.country.code}/${location.address.postalCode}`
					this.cityForPostalCodeIsLoading = false
				}
			}
			else {
				response = await this.api.fetchCity(id)
				this.apiResponse = response

				if (response instanceof ApiError) {
					return
				}
			}

			this.cityInput = cityInput(response.data)
			this.locationInput = locationInput(response.data)
			this.warningDismissed = false

			if (this.$route.query.vorgang_use === '1') {
				const query = { ...this.$route.query }
				delete query.item
				delete query.vorgang_use

				if (mode === 'location' && this.location && !this.locationIsInCurrentProcess) {
					await this.onUseForCurrentProcessClicked(this.location.id)
				}

				this.ignoresRouteUpdates = true
				this.$router.replace({ name: 'location', query }).catch(() => {})
				this.ignoresRouteUpdates = false
			}
		}


		get result(): ApiResult<ApiData> {
			return this.apiResponse?.asResult() ?? error()
		}


		get showsCityForm() {
			return this.mode === 'city' || (this.mode === 'location' && this.isEditingCityInLocation)
		}


		get showsLocationForm() {
			return this.mode === 'location' || (this.mode === 'city' && !this.city)
		}


		get tags() {
			return this.result.processTags
		}


		async updateCityForPostalCode() {
			if (this.cityForPostalCodeTimeoutId) {
				clearTimeout(this.cityForPostalCodeTimeoutId)

				this.cityForPostalCodeTimeoutId = 0
			}

			if (this.apiResponse === null) {
				return
			}

			const countryCode = this.locationInput.addressCountry?.value?.key
			const postalCode = this.locationInput.addressPostalCode.value
			if (this.mode !== 'location' || !countryCode || !postalCode) {
				if (this.mode === 'location') {
					this.updatedCity = null
				}

				this.cityForPostalCodeBasis = null

				return
			}

			if (this.cityForPostalCodeIsLoading) {
				return
			}

			const basis = `${countryCode}/${postalCode}`
			if (this.cityForPostalCodeBasis && this.cityForPostalCodeBasis === basis) {
				return
			}

			this.status = null

			this.cityForPostalCodeIsLoading = true

			const response = await this.api.fetchCityByPostalCode(postalCode, countryCode)

			this.cityForPostalCodeIsLoading = false

			const potentiallyDifferentCountryCode = this.locationInput.addressCountry?.key
			const potentiallyDifferentPostalCode = this.locationInput.addressPostalCode.value
			if (this.mode !== 'location' || potentiallyDifferentCountryCode !== countryCode || potentiallyDifferentPostalCode !== postalCode) {
				await this.updateCityForPostalCode()

				return
			}

			if (response instanceof ApiError) {
				this.status = response
				this.statusLocation = 'city'

				return
			}

			this.cityForPostalCodeBasis = basis
			this.updatedCity = response.data.city
		}
	}
