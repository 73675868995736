
	import { Options, Prop, Vue } from 'vue-property-decorator'


	@Options({
		name: 'x-loading-animation'
	})
	export default class extends Vue {

		@Prop({ default: 'jumbo' }) size!: 'jumbo' | 'mini'
	}
