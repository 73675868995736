export default class ProcessSessionManager {

	private _processId: string | null = null


	constructor() {
		this._processId = sessionStorage.getItem('processSession.processId')
	}


	private clear() {
		this._processId = null

		sessionStorage.removeItem('processSession.processId')
	}


	close() {
		if (!this._processId) {
			return
		}

		this.clear()
	}


	// TODO check existence?
	open(processId: string) {
		this.close()

		this._processId = processId

		sessionStorage.setItem('processSession.processId', processId)

		return true
	}


	get processId() {
		return this._processId
	}
}


export const processSessionManager = new ProcessSessionManager()
