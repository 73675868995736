import MError from '~/model/MError'
import { error } from '~/utility'


export default class GraphExecution<Data> {

	private readonly _abortController: AbortController | null
	private readonly _data: Data | undefined
	private readonly _error: MError | null


	private constructor(
		abortController: AbortController | null,
		data: Data | undefined,
		error: MError | null
	) {
		this._abortController = abortController
		this._data = data
		this._error = error
	}


	abort() {
		this._abortController?.abort()
	}


	get completed(): boolean {
		return this._abortController == null
	}


	get data(): Data {
		if (this._data === undefined)
			error('Cannot access \'data\' of an execution that hasn\'t succeeded.')

		return this._data as Data
	}


	get dataOrUndefined(): Data | undefined {
		return this._data
	}


	get error(): MError {
		if (this._error == null)
			error('Cannot access \'error\' of an execution that has no error.')

		return this._error
	}


	get errorOrNull(): MError | null {
		return this._error
	}


	get failed(): boolean {
		return this.completed && this.hasError
	}


	get hasData(): boolean {
		return this._data !== undefined
	}


	get hasError(): boolean {
		return this._error != null
	}


	get pending(): boolean {
		return !this.completed
	}


	get succeeded(): boolean {
		return this.completed && this.hasData
	}


	static failed<Data>(error: MError): GraphExecution<Data> {
		return new this<Data>(null, undefined, error)
	}


	static pending<Data>(abortController: AbortController, error?: MError | null): GraphExecution<Data> {
		return new this<Data>(abortController, undefined, error ?? null)
	}


	static succeeded<Data>(data: Data): GraphExecution<Data> {
		return new this(null, data, null)
	}
}
