import { reactive } from '@vue/reactivity'
import MLocalDate from '~/model/MLocalDate'
import MLocalTime from '~/model/MLocalTime'
import MPhoneNumber from '~/model/MPhoneNumber'
import { Defined, error, formatPrice, identity, isNullOrUndefined, isUndefined, parsePrice } from '~/utility/index'


// TODO Deprecated. Do not use for new code.


export interface Input<Value extends Defined> {

	initialValue: Value | undefined

	isInitial(): boolean

	isValid(): boolean

	value: Value

	valueOrUndefined: Value | undefined

	withInitialValue(): Input<Value>

	withValue(value: Value): Input<Value>

	withValueOf(other: Input<Value>): Input<Value>
}


export interface AlwaysValidInput<Value extends Defined> extends Input<Value> {

	initialValue: Value

	valueOrUndefined: Value

	withInitialValue(): AlwaysValidInput<Value>

	withValue(value: Value): AlwaysValidInput<Value>

	withValueOf(other: Input<Value>): AlwaysValidInput<Value>
}


export type CompositeInput<Value extends { [key: string]: Input<Defined> }> =
	{ [Key in keyof Value]: Value[Key] } & CompositeInputAdditions<Value>


interface CompositeInputAdditions<Value extends { [key: string]: Input<Defined> }> extends AlwaysValidInput<Value> {

	entries(): { key: keyof Value, input: Value[keyof Value & string] }[]

	filterKeys(
		filter: (input: Value[keyof Value], key: keyof Value & string) => unknown
	): (keyof Value & string)[]

	filterValues(
		filter: (input: Value[keyof Value], key: keyof Value & string) => unknown
	): Value[keyof Value][]

	forEach(
		callback: (input: Value[keyof Value], key: keyof Value & string) => void
	): void

	map<Transformed extends Input<Defined>>(
		transform: (input: Value[keyof Value], key: keyof Value & string) => Transformed
	): CompositeInput<{ [Key in keyof Value]: Transformed }>

	mapValues<Transformed>(
		transform: (input: Value[keyof Value], key: keyof Value & string) => Transformed
	): Transformed[]

	swap(
		other: CompositeInput<Value>
	): void

	withInitialValue(): CompositeInput<Value>

	withValue(value: Value): CompositeInput<Value>

	withValueOf(other: Input<Value>): CompositeInput<Value>
}


export interface EnumInput<Value extends {}> extends AlwaysValidInput<Value | null> {

	groupedOptions: readonly (EnumInputOption<Value> | EnumInputGroup<Value>)[]

	key: string | null

	options: readonly EnumInputOption<Value>[]

	withInitialValue(): EnumInput<Value>

	withKey(key: string | null): EnumInput<Value>

	withValue(value: Value | null): EnumInput<Value>

	withValueOf(other: Input<Value | null>): EnumInput<Value>
}


export class EnumInputGroup<Value extends {}> {

	readonly key: string | null
	readonly label: string
	readonly options: readonly EnumInputOption<Value>[]


	constructor({ key, label, options }: {
		readonly key: string | null
		readonly label: string
		readonly options: readonly EnumInputOption<Value>[]
	}) {
		this.key = key
		this.label = label
		this.options = options
	}
}


export interface EnumInputOption<Value extends {}> {

	readonly key: string | null

	readonly label: string

	readonly value: Value | null
}


export interface TextInput<Value extends Defined> extends Input<Value> {

	converter: TextInputConverter<Value>

	text: string

	withInitialValue(): TextInput<Value>

	withInvalidText(text: string): TextInput<Value>

	withValue(value: Value): TextInput<Value>

	withValueAndText(value: Value, text: string): TextInput<Value>

	withValueOf(other: Input<Value>): TextInput<Value>
}


class SimpleBooleanInput implements AlwaysValidInput<boolean> {

	constructor(
		readonly initialValue: boolean,
		readonly value: boolean
	) {
	}


	isInitial() {
		return this.value === this.initialValue
	}


	isValid() {
		return true
	}


	get valueOrUndefined() {
		return this.value
	}


	withInitialValue() {
		if (this.isInitial()) {
			return this
		}

		return this.withValue(this.initialValue)
	}


	withValue(value: boolean) {
		if (value === this.value) {
			return this
		}

		return new SimpleBooleanInput(this.initialValue, value)
	}


	withValueOf(other: Input<boolean>) {
		if (other === this) {
			return this
		}

		return this.withValue(other.value)
	}
}


class SimpleEnumInput<Value extends {}> implements EnumInput<Value> {

	constructor(
		readonly groupedOptions: readonly (EnumInputOption<Value> | EnumInputGroup<Value>)[],
		readonly initialKey: string | null,
		readonly initialValue: Value | null,
		readonly key: string | null,
		readonly options: readonly EnumInputOption<Value>[],
		readonly value: Value | null
	) {
	}


	isInitial() {
		return this.key === this.initialKey
	}


	isValid() {
		return true
	}


	get valueOrUndefined() {
		return this.value
	}


	withInitialValue() {
		if (this.isInitial()) {
			return this
		}

		return this.withKey(this.initialKey)
	}


	withKey(key: string | null) {
		if (key === '') {
			key = null
		}
		if (key === this.key) {
			return this
		}

		const value = this.options.find(option => option.key === key)?.value ?? null

		return new SimpleEnumInput(this.groupedOptions, this.initialKey, this.initialValue, key, this.options, value)
	}


	withValue(value: Value | null) {
		if (value === this.value) {
			return this
		}

		const key = value ? (this.options.find(option => option.value === value)?.key || null) : null
		if (!key) {
			value = null
		}

		return new SimpleEnumInput(this.groupedOptions, this.initialKey, this.initialValue, key, this.options, value)
	}


	withValueOf(other: Input<Value | null>): EnumInput<Value> {
		if (other === this) {
			return this
		}

		if (!(other instanceof SimpleEnumInput)) {
			return this.withValue(other.value)
		}

		return new SimpleEnumInput(this.groupedOptions, this.initialKey, this.initialValue, other.key, this.options, other.value)
	}
}


class SimpleTextInput<Value extends Defined> implements TextInput<Value> {

	constructor(
		readonly converter: TextInputConverter<Value>,
		readonly initialValue: Value | undefined,
		readonly text: string,
		readonly valueOrUndefined: Value | undefined
	) {
	}


	isInitial() {
		if (this.initialValue === undefined) {
			return this.valueOrUndefined === undefined
		}
		if (this.valueOrUndefined === undefined) {
			return false
		}

		return this.converter.isEqual(this.initialValue, this.valueOrUndefined)
	}


	isValid() {
		return this.valueOrUndefined !== undefined
	}


	get value() {
		if (this.valueOrUndefined === undefined) {
			return error('Accessing .value of an invalid Input is not allowed')
		}

		return this.valueOrUndefined
	}


	withInitialValue() {
		if (this.isInitial()) {
			return this
		}

		return isUndefined(this.initialValue) ? this.withInvalidText('') : this.withValue(this.initialValue)
	}


	withInvalidText(text: string): SimpleTextInput<Value> {
		if (this.valueOrUndefined === undefined && text === this.text) {
			return this
		}

		return new SimpleTextInput(this.converter, this.initialValue, text, undefined)
	}


	withValue(value: Value) {
		return this.withValueAndText(value, this.converter.textForValue(value))
	}


	withValueAndText(value: Value, text: string) {
		if (value === this.valueOrUndefined && text === this.text) {
			return this
		}

		return new SimpleTextInput(this.converter, this.initialValue, text, value)
	}


	withValueOf(other: Input<Value>): TextInput<Value> {
		if (other === this) {
			return this
		}

		const valueOrUndefined = other.isValid() ? other.value : undefined
		const text = other instanceof SimpleTextInput ? other.text : ''

		return new SimpleTextInput(this.converter, this.initialValue, text, valueOrUndefined)
	}
}


class SimpleTriStateInput implements AlwaysValidInput<boolean | null> {

	constructor(
		readonly initialValue: boolean | null,
		readonly value: boolean | null
	) {
	}


	isInitial() {
		return this.value === this.initialValue
	}


	isValid() {
		return true
	}


	get valueOrUndefined() {
		return this.value
	}


	withInitialValue() {
		if (this.isInitial()) {
			return this
		}

		return this.withValue(this.initialValue)
	}


	withValue(value: boolean | null) {
		if (value === this.value) {
			return this
		}

		return new SimpleTriStateInput(this.initialValue, value)
	}


	withValueOf(other: Input<boolean | null>) {
		if (other === this) {
			return this
		}

		return this.withValue(other.value)
	}
}


export interface TextInputConverter<Value extends Defined> {

	isEqual(oldValue: Value, newValue: Value): boolean

	textForValue(value: Value): string

	valueForText(text: string): Value | undefined
}


export function booleanInput(value: boolean): AlwaysValidInput<boolean> {
	return new SimpleBooleanInput(value, value)
}


export function compositeInput<Value extends { [key: string]: Input<Defined> }>(value: Value): CompositeInput<{ [Key in keyof Value]: Value[Key] }> {
	const keys = Object.keys(value) as (keyof Value)[]

	const self = {
		...value,

		entries() {
			return this.mapValues((input, key) => ({ key, input }))
		},

		filterKeys(filter: (input: Value[keyof Value], key: keyof Value) => unknown) {
			const filtered: (keyof Value)[] = []
			this.forEach((input, key) => {
				if (filter(input, key)) {
					filtered.push(key)
				}
			})

			return filtered
		},

		filterValues(filter: (input: Value[keyof Value], key: keyof Value) => unknown) {
			const filtered: Value[keyof Value][] = []
			this.forEach((input, key) => {
				if (filter(input, key)) {
					filtered.push(input)
				}
			})

			return filtered
		},

		forEach(callback: (input: Value[keyof Value], key: keyof Value) => void) {
			for (const key of keys) {
				callback(this[key], key)
			}
		},

		isInitial(): boolean {
			for (const key of keys) {
				if (!this[key].isInitial()) {
					return false
				}
			}

			return true
		},

		isValid(): boolean {
			for (const key of keys) {
				if (!this[key].isValid()) {
					return false
				}
			}

			return true
		},

		map<Transformed extends Input<Defined>>(transform: (input: Value[keyof Value], key: keyof Value) => Transformed) {
			const mapped: { [Key in keyof Value]?: Transformed } = {}
			this.forEach((input, key) => {
				mapped[key] = transform(input, key)
			})

			return compositeInput(mapped as { [Key in keyof Value]: Transformed })
		},

		mapValues<Transformed>(transform: (input: Value[keyof Value], key: keyof Value) => Transformed) {
			const mapped: Transformed[] = []
			this.forEach((input, key) => {
				mapped.push(transform(input, key))
			})

			return mapped
		},

		swap(other: CompositeInput<Value>): void {
			for (const key of keys) {
				const thisValue = this[key]
				this[key] = this[key].withValueOf(other[key]) as any
				other[key] = other[key].withValueOf(thisValue) as any
			}
		},

		get value(): Value {
			return this
		},

		withInitialValue() {
			if (this.isInitial()) {
				return this
			}

			return this.map(input => input.withInitialValue())
		},

		withValue(value: Value): Input<Value> {
			return compositeInput(value)
		},

		withValueOf(other: Input<Value>): Input<Value> {
			return this.withValue(other.value)
		}
	}

	return reactive(self) as unknown as CompositeInput<{ [Key in keyof Value]: Value[Key] }>
}


export function enumInput<Value extends {}>(
	key: string | null,
	options: readonly (EnumInputOption<Value> | EnumInputGroup<Value>)[]
): EnumInput<Value>


export function enumInput<Value extends {}, In = Value>(
	key: string | null,
	options: readonly In[],
	converter: (value: In, index: number) => EnumInputOption<Value> | EnumInputGroup<Value>
): EnumInput<Value>


export function enumInput<Value extends {}, In>(
	key: string | null,
	options: readonly In[],
	converter?: (value: In, index: number) => EnumInputOption<Value> | EnumInputGroup<Value>
): EnumInput<Value> {
	if (converter === undefined) {
		converter = identity as (value: In) => EnumInputOption<Value> | EnumInputGroup<Value>
	}

	if (key === '') {
		key = null
	}

	const groupedOptions = options.map(converter)
	const flatOptions = groupedOptions.reduce(
		(options, it) =>
			[...options, ...(it instanceof EnumInputGroup ? it.options : [it])],
		[] as EnumInputOption<Value>[]
	)
	const value = flatOptions.find(option => option.key === key)?.value ?? null

	return new SimpleEnumInput(groupedOptions, key, value, key, flatOptions, value)
}


export function integerInput(value: number | null | undefined, options: { optional: true, text?: string }): TextInput<number | null>
export function integerInput(value: number | undefined, options?: { optional?: false, text?: string, zeroAsEmpty?: boolean }): TextInput<number>

export function integerInput(value: number | null | undefined, options?: { optional?: boolean, text?: string, zeroAsEmpty?: boolean }) {
	let converter: TextInputConverter<number | null> = options && options.zeroAsEmpty ? integerConverterZeroAsEmpty : integerConverter
	if (options?.optional) {
		converter = optional(converter as TextInputConverter<number>)
	}

	return textInput(value, converter, options)
}


export function localDateInput(value: MLocalDate | null | undefined, options?: { text?: string }) {
	return textInput(value, localDateConverter, options)
}


export function localTimeInput(value: MLocalTime | null | undefined, options?: { text?: string }) {
	return textInput(value, localTimeConverter, options)
}


export function phoneNumberInput(value: MPhoneNumber | null | undefined, options?: { text?: string }) {
	return textInput(value, phoneNumberConverter, options)
}


export function priceInput(value: number | null | undefined, options: { optional: true, text?: string }): TextInput<number | null>
export function priceInput(value: number | undefined, options?: { optional?: false, text?: string }): TextInput<number>

export function priceInput(value: number | null | undefined, options?: { optional?: boolean, text?: string }) {
	if (!isNullOrUndefined(value)) {
		value = Math.round(value * 100) / 100
	}

	return textInput(value, options && options.optional ? optional(priceConverter) : priceConverter, options)
}


export function simpleEnumInput(
	key: string | null,
	options: readonly { readonly key: string, readonly label: string }[]
): EnumInput<{ readonly key: string, readonly label: string }> {
	if (key === '') {
		key = null
	}

	const flatOptions = options.map(it => ({ ...it, value: it }))
	const value = flatOptions.find(option => option.key === key)?.value ?? null

	return new SimpleEnumInput(flatOptions, key, value, key, flatOptions, value)
}


export function stringInput(value: string) {
	return textInput(value, stringConverter)
}


export function textInput<Value extends Defined>(
	value: Value | undefined,
	converter: TextInputConverter<Value>,
	options?: { text?: string }
): Input<Value> {
	const text = options?.text ?? (isUndefined(value) ? '' : converter.textForValue(value))

	return new SimpleTextInput(converter, value, text, value)
}


export function timeDurationInput(value: number | null | undefined, options: { optional: true, text?: string, zeroAsEmpty?: boolean }): TextInput<number | null>
export function timeDurationInput(value: number | undefined, options?: { optional?: boolean, text?: string, zeroAsEmpty?: boolean }): TextInput<number>

export function timeDurationInput(value: number | null | undefined, options?: { optional?: boolean, text?: string, zeroAsEmpty?: boolean }) {
	let converter: TextInputConverter<number | null> = options && options.zeroAsEmpty ? timeDurationConverterZeroAsEmpty : timeDurationConverter
	if (options && options.optional) {
		converter = optional(converter as TextInputConverter<number>)
	}

	return textInput(value, converter, options)
}


export function triStateInput(value: boolean | null): AlwaysValidInput<boolean | null> {
	return new SimpleTriStateInput(value, value)
}


export function websiteInput(value: string | null | undefined, options?: { text?: string }) {
	return textInput(value || null, websiteConverter, options)
}


function optional<Value extends {}>(converter: TextInputConverter<Value>): TextInputConverter<Value | null> {
	return {
		isEqual: (a, b) => {
			if (a === null) {
				return b === null
			}
			if (b === null) {
				return false
			}

			return converter.isEqual(a, b)
		},
		textForValue: value => value === null ? '' : converter.textForValue(value),
		valueForText: text => text ? converter.valueForText(text) : null
	}
}


const integerConverter: TextInputConverter<number> = {
	isEqual: (a, b) => a === b,
	textForValue: value => value.toFixed(0),
	valueForText: text => {
		if (!text) {
			return undefined
		}

		if (!text.match(/^\d+$/)) {
			return undefined
		}

		const value = Number.parseInt(text, 10)
		if (isNaN(value)) {
			return undefined
		}

		return value
	}
}


const integerConverterZeroAsEmpty: TextInputConverter<number> = {
	...integerConverter,
	textForValue: value => value ? integerConverter.textForValue(value) : '',
	valueForText: text => text ? integerConverter.valueForText(text) : 0
}


const localDateConverter: TextInputConverter<MLocalDate | null> = {
	isEqual: (a, b) => Boolean(a === b || (a && b && a.isEqualTo(b))),
	textForValue: value => value?.toString() ?? '',
	valueForText: text => text ? (MLocalDate.parseOrNull(text) ?? undefined) : null
}


const localTimeConverter: TextInputConverter<MLocalTime | null> = {
	isEqual: (a, b) => Boolean(a === b || (a && b && a.isEqualTo(b))),
	textForValue: value => value?.toString() ?? '',
	valueForText: text => text ? (MLocalTime.parseOrNull(text) ?? undefined) : null
}


const phoneNumberConverter: TextInputConverter<MPhoneNumber | null> = {
	isEqual: (a, b) => Boolean(a === b || (a && b && a.isEqualTo(b))),
	textForValue: value => value?.formatInGermany() ?? '',
	valueForText: text => text ? (MPhoneNumber.parseOrNull(text) ?? undefined) : null
}


const priceConverter: TextInputConverter<number> = {
	isEqual: (a, b) => a === b,
	textForValue: formatPrice,
	valueForText: parsePrice
}


const stringConverter: TextInputConverter<string> = {
	isEqual: (a, b) => a === b,
	textForValue: identity,
	valueForText: identity
}


const timeDurationConverter: TextInputConverter<number> = {
	isEqual: (a, b) => a === b,
	textForValue: value => {
		const totalMinutes = Math.round(value / 60)
		if (totalMinutes === 0) {
			return '0:00'
		}

		const hours = Math.floor(totalMinutes / 60)
		const minutes = totalMinutes % 60

		let text = ''
		text += hours.toFixed(0)
		text += ':'

		if (minutes < 10) {
			text += '0'
		}
		text += minutes.toFixed(0)

		return text
	},
	valueForText: text => {
		if (!text) {
			return undefined
		}

		let match = text.match(/^(\d{1,3}):(\d\d)$/)
		if (match) {
			return (Number.parseInt(match[1], 10) * 3600) + (Number.parseInt(match[2], 10) * 60)
		}

		match = text.match(/^\d{1,4}([,.]\d\d?)?$/)
		if (match) {
			return Number.parseInt(match[0].replace(/,/g, '.'), 10) * 60 * 60
		}

		return undefined
	}
}


const timeDurationConverterZeroAsEmpty: TextInputConverter<number> = {
	...timeDurationConverter,
	textForValue: value => value ? timeDurationConverter.textForValue(value) : '',
	valueForText: text => text ? timeDurationConverter.valueForText(text) : 0
}


const websiteConverter: TextInputConverter<string | null> = {
	isEqual: (a, b) => a === b,
	textForValue: value => value ?? '',
	valueForText: text => {
		if (!text) {
			return null
		}

		try {
			const url = new URL(text.replace(/^[^:/]+\.[^:/]{2,}(\/|$)/, 'http://$&'))
			if (url.protocol !== 'http:' && url.protocol !== 'https:') {
				return undefined
			}

			let value = url.href
			if (value === url.origin + '/') {
				value = url.origin
			}

			return value
		}
		catch {
			return undefined
		}
	}
}
