import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href", "rel", "tabindex", "target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: _normalizeClass(_ctx.classes),
    href: _ctx.hrefValue,
    ref: "rootNode",
    rel: _ctx.rel,
    tabindex: _ctx.tabIndexValue,
    target: _ctx.target,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createTextVNode(_toDisplayString(_ctx.text), 1)
    ])
  ], 10, _hoisted_1))
}