import MEvent from '~/model/MEvent'
import MEventConsultation from '~/model/MEventConsultation'
import MEventOccasion from '~/model/MEventOccasion'
import MProcess from '~/model/MProcess'
import { associateByTo, isNotNullOrUndefined } from '~/utility'
import { booleanInput, compositeInput, integerInput, localDateInput, localTimeInput, simpleEnumInput, stringInput } from '~/utility/Input'


export default function eventInput(properties?: {
	readonly event: MEvent | null
	readonly eventConsultations: readonly MEventConsultation[]
	readonly eventOccasions: readonly MEventOccasion[]
	readonly process: MProcess | null
	readonly seasonOptions: readonly { key: string, label: string }[]
}) {
	return compositeInput({
		consultationIds: compositeInput(associateByTo(properties?.eventConsultations ?? [], option =>
			[option.id, booleanInput(properties?.event?.givenConsultations?.some(it => it.id === option.id) ?? false)]
		)),
		contractDeadlineDate: localDateInput(properties?.process?.contractDeadline?.date ?? null),
		contractDeadlineSet: booleanInput(isNotNullOrUndefined(properties?.process?.contractDeadline)),
		contractDeadlineTimes: integerInput(properties?.process?.contractDeadline?.misses ? (properties.process.contractDeadline.misses + 1) : 1),
		date: localDateInput(properties?.event?.date ?? null),
		definiteEndTime: localTimeInput(properties?.event?.definiteEndTime ?? null),
		endTime: localTimeInput(properties?.event?.endTime ?? null),
		guestChildCount: integerInput(properties?.event?.guestChildCount ?? null, {optional: true}),
		guestCount: integerInput(properties?.event?.guestCount ?? null, {optional: true}),
		guestCountForMusic: integerInput(properties?.event?.guestCountForMusic ?? null, {optional: true}),
		guestNationalities: stringInput(properties?.event?.guestNationalities ?? ''),
		guestRemarks: stringInput(properties?.event?.guestRemarks ?? ''),
		internalRemarks: stringInput(properties?.event?.internalRemarks ?? ''),
		locationHasRecommendedUs: booleanInput(properties?.event?.locationHasRecommendedUs ?? false),
		locationRemarks: stringInput(properties?.event?.locationRemarks ?? ''),
		plannedEndTime: localTimeInput(properties?.event?.plannedEndTime ?? null),
		remarks: stringInput(properties?.event?.remarks ?? ''),
		remarksAreHighPriority: booleanInput(properties?.event?.remarksAreHighPriority ?? false),
		season: simpleEnumInput(properties?.event?.season ?? null, properties?.seasonOptions ?? []),
		setupTime: localTimeInput(properties?.event?.setupTime ?? null),
		startTime: localTimeInput(properties?.event?.startTime ?? null),
		type: simpleEnumInput(
			properties?.event?.occasion?.id ?? null,
			properties?.eventOccasions?.map(it => ({key: it.id, label: it.label})) ?? []
		),
		typeDetails: stringInput(properties?.event?.occasionDetails ?? '')
	})
}
