import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style.root, _ctx.$style[`type-${_ctx.actualType}`]])
  }, [
    _createElementVNode("p", {
      class: _normalizeClass({ [_ctx.$style.content]: true, [_ctx.$style.default]: !_ctx.$slots['default'] })
    }, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.defaultText), 1)
      ])
    ], 2),
    (_ctx.apiErrorReportUrl)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          class: _normalizeClass(_ctx.$style.errorSupportLink),
          href: _ctx.apiErrorReportUrl,
          rel: "noopener",
          target: "_blank"
        }, "Problem melden", 10, _hoisted_1))
      : _createCommentVNode("", true)
  ], 2))
}