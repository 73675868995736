export default class MProcessEmailTemplate {

	static fieldSelection(): string {
		return `{
			id
			message
			subject
		}`
	}


	readonly id: string
	readonly message: string
	readonly subject: string


	constructor(properties: {
		readonly id: string
		readonly message: string
		readonly subject: string
	}) {
		this.id = properties.id
		this.message = properties.message
		this.subject = properties.subject
	}


	static fromJson(json: any) {
		return new this({
			id: json.id,
			message: json.message,
			subject: json.subject
		})
	}
}
