import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["autocapitalize", "autocomplete", "autocorrect", "disabled", "id", "placeholder", "readonly", "rows", "required", "spellcheck", "tabindex", ".value"]
const _hoisted_3 = ["autocapitalize", "autocomplete", "autocorrect", "disabled", "id", "inputmode", "max", "maxlength", "min", "placeholder", "readonly", "required", "spellcheck", "step", "tabindex", "type", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classes)
  }, [
    (_ctx.hasLabel)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(_ctx.labelClasses),
          for: _ctx.id
        }, [
          _renderSlot(_ctx.$slots, "label", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.label) + _toDisplayString(_ctx.labelNoColon ? '' : ':'), 1)
          ])
        ], 10, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.isMultiline)
      ? (_openBlock(), _createElementBlock("textarea", {
          key: 1,
          autocapitalize: _ctx.autocapitalize,
          autocomplete: _ctx.autocomplete,
          autocorrect: _ctx.autocorrect,
          class: _normalizeClass(_ctx.inputClasses),
          disabled: _ctx.disabled,
          id: _ctx.id,
          placeholder: _ctx.placeholder,
          readonly: _ctx.readonly,
          ref: "inputElement",
          rows: _ctx.rows,
          required: _ctx.isRequired,
          spellcheck: _ctx.spellcheck,
          tabindex: _ctx.tabindex,
          ".value": _ctx.serializedValue,
          onBlur: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
          onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
          onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
        }, null, 42, _hoisted_2))
      : (_openBlock(), _createElementBlock("input", {
          key: 2,
          autocapitalize: _ctx.autocapitalize,
          autocomplete: _ctx.autocomplete,
          autocorrect: _ctx.autocorrect,
          class: _normalizeClass(_ctx.inputClasses),
          disabled: _ctx.disabled,
          id: _ctx.id,
          inputmode: _ctx.inputmode,
          max: _ctx.max,
          maxlength: _ctx.maxlength,
          min: _ctx.min,
          placeholder: _ctx.placeholder,
          ref: "inputElement",
          readonly: _ctx.readonly,
          required: _ctx.isRequired,
          spellcheck: _ctx.spellcheck,
          step: _ctx.step,
          tabindex: _ctx.tabindex,
          type: _ctx.type,
          value: _ctx.serializedValue,
          onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
          onFocus: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
          onInput: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
        }, null, 42, _hoisted_3))
  ], 2))
}