
	import XContactPersonInput from '@/main/components/XContactPersonInput.vue'
	import XCurrentProcessBar from '@/main/components/XCurrentProcessBar.vue'
	import XMainPageContentOld from '@/main/components/XMainPageContentOld.vue'
	import contactInput from '@/main/model/ContactInput'
	import { Inject, Options, Vue } from 'vue-property-decorator'
	import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
	import Api, { ApiError, ApiResponse, ApiResult, ContactReferralUpdateData, ContactUpdateData, FetchContactResult } from '~/api/Api'
	import XCheckbox from '~/components/XCheckbox.vue'
	import XFormColumn from '~/components/XFormColumn.vue'
	import XFormRow from '~/components/XFormRow.vue'
	import XIconButton from '~/components/XIconButton.vue'
	import XInput from '~/components/XInput.vue'
	import XLink from '~/components/XLink.vue'
	import XProcessId from '~/components/XProcessId.vue'
	import XSection from '~/components/XSection.vue'
	import XSelect from '~/components/XSelect.vue'
	import XStatusMessage from '~/components/XStatusMessage.vue'
	import MContact from '~/model/MContact'
	import MCountry from '~/model/MCountry'
	import MMagazine from '~/model/MMagazine'
	import MProcess from '~/model/MProcess'
	import MProcessParticipation from '~/model/MProcessParticipation'
	import MProcessParticipationTag from '~/model/MProcessParticipationTag'
	import MProcessTag from '~/model/MProcessTag'
	import MSalutation from '~/model/MSalutation'
	import MWeddingFair from '~/model/MWeddingFair'
	import icons from '~/styles/icons.module.scss'
	import { associateByTo, compareBy, compareWith, error, reverseOrder, sorted } from '~/utility'
	import { booleanInput, compositeInput, simpleEnumInput, stringInput } from '~/utility/Input'


	@Options({
		components: {
			XCheckbox,
			XContactPersonInput,
			XCurrentProcessBar,
			XFormColumn,
			XFormRow,
			XIconButton,
			XInput,
			XLink,
			XMainPageContentOld,
			XProcessId,
			XSection,
			XSelect,
			XStatusMessage
		},
		name: 'page-main-contact'
	})
	export default class extends Vue {

		readonly notAddedConfirmationMessage = 'Du hast den Kunden noch nicht zum Vorgang hinzugefügt.\nWillst du wirklich fortfahren?'
		readonly notSavedConfirmationMessage = 'Du hast Daten geändert aber noch nicht gespeichert.\nWillst du wirklich fortfahren?'
		readonly icons = icons

		apiResponse: ApiResponse<FetchContactResult> | null = null
		initialProcessParticipationTagIds: string[] = []
		input = this.makeInput()
		isBusy = false
		saveOptionData = simpleEnumInput(null, [])
		searchQueryInput = stringInput('')
		status: ApiError | string | null = null
		updatedCurrentProcess?: MProcess | null

		@Inject() readonly api!: Api


		beforeRouteLeave(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
			next(this.confirmDiscardingChanges())
		}


		beforeRouteUpdate(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
			next(this.onRouteChanged(to))
		}


		confirmDiscardingChanges() {
			if (!this.discardConfirmationMessage) {
				return true
			}

			return confirm(this.discardConfirmationMessage)
		}


		get contact() {
			return this.result.data.contact
		}


		get contactIsInProcess() {
			const contact = this.contact
			if (!contact) {
				return false
			}

			return Boolean(this.currentProcess && this.currentProcess.participations?.some(it => it.contact?.id === contact.id))
		}


		get contactIsOrWillBeInProcess() {
			if (this.contactIsInProcess && this.saveOptionData?.value?.key !== 'create') {
				return true
			}

			return this.saveOptionData.value?.key?.endsWith('AddToProcess') ?? false
		}


		created() {
			this.onRouteChanged(this.$route)
		}


		get currentProcess() {
			return this.updatedCurrentProcess === undefined ? this.result.currentProcess : this.updatedCurrentProcess
		}


		set currentProcess(value: MProcess | null) {
			this.updatedCurrentProcess = value
		}


		data() {
			return {
				updatedCurrentProcess: undefined
			}
		}


		destroyed() {
			window.removeEventListener('beforeunload', this.onWindowBeforeUnload)
		}


		get discardConfirmationMessage() {
			if (!this.apiResponse) {
				return null
			}

			if (!this.input.isInitial()) {
				return this.notSavedConfirmationMessage
			}

			if (this.contact && !this.contactIsInProcess && (this.saveOptionData.value?.key?.endsWith('AddToProcess') ?? false)) {
				return this.notAddedConfirmationMessage
			}

			return null
		}


		get isValid() {
			return this.input.isValid()
		}


		makeInput(properties?: {
			readonly contact: MContact | null
			readonly countries: readonly MCountry[]
			readonly defaultCountryId: string | null
			readonly djOptions: readonly { key: string, label: string }[]
			readonly locationOptions: readonly { key: string, label: string }[]
			readonly processParticipation: MProcessParticipation | null
			readonly processParticipationTags: readonly MProcessParticipationTag[]
			readonly magazines: readonly MMagazine[]
			readonly salutations: readonly MSalutation[]
			readonly weddingFairs: readonly MWeddingFair[]
		}) {
			return compositeInput({
				contact: contactInput(properties),
				processParticipationTagIds: compositeInput(associateByTo(properties?.processParticipationTags ?? [], tag =>
					[tag.id, booleanInput(properties?.processParticipation?.tags?.some(it => it.id === tag.id) ?? false)]
				))
			})
		}


		mounted() {
			window.addEventListener('beforeunload', this.onWindowBeforeUnload)
		}


		async onContactSubmitted() {
			if (!this.isValid) {
				return
			}

			const input = this.input
			const contactInput = input.contact
			const referralInput = contactInput.referral

			const saveOptionKey = this.saveOptionData.value?.key ?? ''
			const contactId = (this.contact && !saveOptionKey.startsWith('create')) ? this.contact.id : 'new'

			let processParticipation: 'add' | 'update' | null = null
			if (saveOptionKey.endsWith('AddToProcess')) {
				processParticipation = 'add'
			}
			else if (this.contactIsInProcess) {
				processParticipation = 'update'
			}

			const participationTagIds = input.processParticipationTagIds.filterKeys(input => input.value)
			const participationTagIdsToAdd = participationTagIds.filter(it => !this.initialProcessParticipationTagIds.includes(it))
			const participationTagIdsToRemove = this.initialProcessParticipationTagIds.filter(it => !participationTagIds.includes(it))

			if (processParticipation && !participationTagIds.length) {
				alert('Mindestens ein Tag muss für den Kontakt in diesem Vorgang ausgewählt werden.')
				return
			}

			function updateForPerson(input: typeof contactInput.person1): ContactUpdateData['person1'] {
				return {
					addressAddition: input.addressAddition.value || null,
					addressCity: input.addressCity.value || null,
					addressCountryCode: input.addressCountry.value?.key ?? error(),
					addressPostalCode: input.addressPostalCode.value || null,
					addressStreet: input.addressStreet.value || null,
					birthday: input.birthday.value?.toString() ?? null,
					canUseInformalCommunication: input.canUseInformalCommunication.value,
					changedLastName: input.changedLastName.value || null,
					changedLastNameIsAfterWedding: input.changedLastNameIsAfterWedding.value,
					emailAddress: input.emailAddress.value || null,
					firstName: input.firstName.value || null,
					homePhoneNumber: input.homePhoneNumber.value?.toString() ?? null,
					lastName: input.lastName.value || null,
					mobilePhoneNumber: input.mobilePhoneNumber.value?.toString() ?? null,
					noNewsletter: input.noNewsletter.value,
					salutationId: input.salutation.value?.key ?? null,
					title: input.title.value || null
				}
			}

			const referralUpdate: ContactReferralUpdateData = {
				eventDate: referralInput.eventDate.value?.toString() ?? null,
				eventDjId: referralInput.eventDjId.value?.key ?? null,
				eventName: referralInput.eventName.value || null,
				experienced: referralInput.experienced.value,
				friends: referralInput.friends.value,
				gastronomy: referralInput.gastronomy.value,
				google: referralInput.google.value,
				internet: referralInput.internet.value,
				locationId: referralInput.locationId.value?.key ?? null,
				magazineId: referralInput.magazineId.value?.key ?? null,
				other: referralInput.other.value || null,
				ourWebsite: referralInput.ourWebsite.value,
				weddingFairId: referralInput.weddingFairId.value?.key ?? null
			}

			const update: ContactUpdateData = {
				processParticipation,
				businessPhoneNumber: contactInput.businessPhoneNumber.value?.toString() ?? null,
				companyName: contactInput.companyName.value || null,
				faxNumber: contactInput.faxNumber?.value?.toString() ?? null,
				internalRemarks: contactInput.internalRemarks.value || null,
				person1: updateForPerson(contactInput.person1),
				person2: updateForPerson(contactInput.person2),
				processParticipationTagIdsToAdd: participationTagIdsToAdd,
				processParticipationTagIdsToRemove: participationTagIdsToRemove,
				referral: referralUpdate,
				remarks: contactInput.remarks.value || null
			}

			if (update.person1.lastName === null && update.companyName === null) {
				alert('Mindestens der Firmenname oder der Nachname der ersten Person müssen angegeben werden.')
				return
			}

			this.isBusy = true
			this.status = null

			const response = await this.api.updateContact(contactId, update)

			this.isBusy = false

			if (response instanceof ApiError) {
				this.status = response

				return
			}

			this.onLoaded(response)

			const contact = response.data.contact
			if (contactId === 'new') {
				this.status = 'Kunde neu angelegt.'

				await this.$router.push(`/kunde.php?get_do=zeige&get_id=${contact.id}`)
			}
			else {
				this.status = 'Änderungen wurden gespeichert.'
			}
		}


		onLoaded(response: ApiResponse<FetchContactResult>) {
			this.apiResponse = response
			this.updatedCurrentProcess = undefined

			if (response instanceof ApiError) {
				return
			}

			const processParticipation = response.currentProcess?.participations?.find(it => it.contact!.id === this.contact?.id) || null

			this.initialProcessParticipationTagIds = processParticipation?.tags?.map(it => it.id) ?? []
			this.input = this.makeInput({ ...response.data, processParticipation })

			this.updateSaveOptions()
		}


		async onRemoveFromProcessClicked() {
			const contact = this.contact ?? error()
			const process = this.currentProcess ?? error()

			if (!confirm(`Soll ${contact.names} wirklich aus dem Vorgang entfernt werden?`)) {
				return
			}
			if (!this.confirmDiscardingChanges()) {
				return
			}

			this.isBusy = true
			this.status = null

			const response = await this.api.removeContactFromProcess(contact.id, process.id)

			this.isBusy = false

			if (response instanceof ApiError) {
				this.status = response

				return
			}

			this.status = 'Kunde wurde aus dem Vorgang entfernt.'

			this.onLoaded(response)

			if (this.$route.query.get_do !== 'zeige') {
				await this.$router.replace({ name: 'contact', query: { get_do: 'zeige', get_id: contact.id } })
			}
		}


		onRouteChanged(route: RouteLocationNormalized): boolean {
			// TODO this is async and may still be running when another route update comes in. Handle that!

			const queryParameters = route.query

			if (queryParameters.do === 'neue_anfrage') {
				this.api.createProcess().then(() => { // TODO error handling
					this.$router.replace({ name: 'contact' })
				})

				return true
			}

			let id: string | undefined = 'process'

			if (queryParameters.get_do === 'zeige' && typeof queryParameters.get_id === 'string') {
				id = queryParameters.get_id || undefined
			}
			else if (queryParameters.get_do === 'neu') {
				id = undefined
			}

			let needsReload = (this.apiResponse === null)
			if (!needsReload && id) {
				needsReload = id !== (this.contact ? this.contact.id : null)
				if (!needsReload) {
					return true
				}
			}

			if (!this.confirmDiscardingChanges()) {
				return false
			}

			this.reload(id)

			return true
		}


		onSearchSubmitted() {
			this.$router.push({
				name: 'search',
				query: {
					query: this.searchQueryInput.value || undefined,
					type: 'contact'
				}
			})
		}


		onSwapClicked() {
			this.input.contact.person1.swap(this.input.contact.person2)
		}


		onWindowBeforeUnload(event: Event) {
			if (this.discardConfirmationMessage) {
				(event as any).returnValue = this.discardConfirmationMessage
			}
		}


		get prefersAddingToProcess() {
			return this.$route.query.vorgang_add_option === '1'
		}


		get processParticipations() {
			const participations = this.currentProcess?.participations
			if (!participations) {
				return null
			}

			const contact = this.contact

			return participations.map(({ contact: participant, tags }) => {
				const label = `${participant!.person1.name || participant!.companyName || '?'} (${tags.map(it => it.label).join(', ')})`
				const route = (participant!.id === contact?.id)
					? undefined
					: { name: 'contact', query: { get_do: 'zeige', get_id: participant!.id } }

				return { id: participant!.id, label, route }
			})
		}


		get processParticipationsOfContact(): readonly MProcessParticipation[] {
			return sorted(
				this.contact?.processParticipations ?? [],
				reverseOrder(compareBy(it => it.process!.relevantDate))
			)
		}


		async reload(id: string | undefined) {
			this.apiResponse = null
			this.input = this.makeInput()
			this.status = null

			this.onLoaded(await this.api.fetchContact(id))
		}


		get result(): ApiResult<FetchContactResult> {
			return this.apiResponse?.asResult() ?? error()
		}


		get saveButtonLabel() {
			return this.saveOptionData?.value?.key?.startsWith('update') ? 'speichern' : 'anlegen'
		}


		get sortedProcessParticipantTags(): readonly MProcessParticipationTag[] {
			const processParticipation = this.result.currentProcess?.participations?.find(it => it.contact!.id === this.contact?.id) || null

			const selectedTagIds = new Set(processParticipation?.tags?.map(it => it.id) ?? [])
			return sorted(
				this.result.data.processParticipationTags,
				compareWith(
					compareBy(it => -selectedTagIds.has(it.id)),
					compareBy(it => it.label)
				)
			)
		}


		get tags(): MProcessTag[] {
			return this.result.processTags!
		}


		updateSaveOptions() {
			const options: { key: string, label: string }[] = []

			options.push({ key: 'create', label: 'Neuen Kunden speichern' })
			if (this.currentProcess) {
				options.push({ key: 'createAndAddToProcess', label: 'Neuen Kunden speichern & zum Vorgang hinzufügen' })
			}

			if (this.contact) {
				options.push({ key: 'update', label: 'Kunde aktualisieren' })

				if (this.currentProcess && !this.contactIsInProcess) {
					options.push({ key: 'updateAndAddToProcess', label: 'Kunde aktualisieren & zum Vorgang hinzufügen' })
				}
			}

			let selectedKey: string
			if (this.contact) {
				if (this.prefersAddingToProcess && !this.contactIsInProcess) {
					selectedKey = 'updateAndAddToProcess'
				}
				else {
					selectedKey = 'update'
				}
			}
			else {
				selectedKey = this.currentProcess ? 'createAndAddToProcess' : 'create'
			}

			this.saveOptionData = simpleEnumInput(selectedKey, options)
		}
	}
