
	import { Inject, Options, Prop, Vue } from 'vue-property-decorator'
	import Api from '~/api/Api'
	import XIconButton from '~/components/XIconButton.vue'
	import XLink from '~/components/XLink.vue'
	import MRequest from '~/model/MRequest'
	import icons from '~/styles/icons.module.scss'


	@Options({
		components: { XIconButton, XLink },
		name: 'x-request-table'
	})
	export default class extends Vue {

		readonly expandedRequestIds: { [requestId: string]: boolean } = {}
		readonly requestStates: { [requestId: string]: 'archived' | 'archiving' } = {}

		@Inject()
		readonly api!: Api

		@Prop()
		requests?: MRequest[]

		readonly icons = icons


		async archiveRequest(request: MRequest) {
			const question = `Soll die Anfrage - ${request.firstName || ''} ${request.lastName || ''} - wirklich archiviert werden?`
			if (!confirm(question)) {
				return
			}

			this.requestStates[request.id] = 'archiving'

			try {
				await this.api.archiveRequest(request.id)

				this.requestStates[request.id] = 'archived'
			}
			catch (error) {
				delete this.requestStates[request.id]
				// TDOO handle
			}
		}


		requestClasses(request: MRequest) {
			return {
				[this.$style.request]: true,
				[this.$style.isExpanded]: this.expandedRequestIds[request.id],
				[this.$style.isArchived]: this.requestStates[request.id] === 'archived',
				[this.$style.isArchiving]: this.requestStates[request.id] === 'archiving'
			}
		}


		toggleRequest(requestId: string) {
			this.expandedRequestIds[requestId] = !this.expandedRequestIds[requestId]
		}
	}
