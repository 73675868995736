export default class MChange<Value> {

	static fieldSelection(valueFieldSelection?: string): string {
		return `{
			oldValue ${valueFieldSelection ?? ''}
			newValue ${valueFieldSelection ?? ''}
		}`
	}


	readonly oldValue: Value
	readonly newValue: Value


	constructor(oldValue: Value, newValue: Value) {
		this.oldValue = oldValue
		this.newValue = newValue
	}


	static fromJson<Value>(json: any | null, parseValue: (json: any) => Value): MChange<Value> | null {
		if (!json) {
			return null
		}

		return new this(
			json.oldValue === null ? null : parseValue(json.oldValue),
			json.newValue === null ? null : parseValue(json.newValue)
		) as MChange<Value>
	}
}
