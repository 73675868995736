export default class MEventPricingAdditionalOption {

	readonly isSelected: boolean
	readonly label: string
	readonly price: number


	constructor(properties: {
		readonly isSelected: boolean
		readonly label: string
		readonly price: number
	}) {
		this.isSelected = properties.isSelected
		this.label = properties.label
		this.price = properties.price
	}


	static fromJson(json: any): MEventPricingAdditionalOption {
		return new this({
			isSelected: json.isSelected,
			label: json.label,
			price: json.price / 100
		})
	}
}
