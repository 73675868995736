export default {
	apiBaseUrl: process.env.VUE_APP_API_URL,
	callService: {
		hubUrl: process.env.VUE_APP_API_URL!.replace(/^https:/, 'wss:') + '/sipgate/hub',
	},
	technicalSupportEmailAddress: 'marc@knaup.io'
} as Configuration


interface Configuration {

	apiBaseUrl: string // no trailing slash
	callService: {
		hubUrl: string
		notificationHost: string
	}
	technicalSupportEmailAddress: string
}
