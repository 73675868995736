import MEntity from '~/model/MEntity'


export default abstract class MJournalItem {

	readonly itemType: string
	readonly subjects: readonly MEntity[]


	protected constructor(itemType: string, subjects: readonly MEntity[]) {
		this.itemType = itemType
		this.subjects = subjects
	}
}
