import MDocument from '~/model/MDocument'


export default class MProcessFileDocumentSource {

	static fieldSelection(): string {
		return `{
			document ${MDocument.fieldSelection()}
		}`
	}


	readonly document: MDocument
	readonly _type = 'document'


	constructor(properties: {
		readonly document: MDocument
	}) {
		this.document = properties.document
	}


	static fromJson(json: any): MProcessFileDocumentSource {
		return new this({
			document: MDocument.fromJson(json.document)
		})
	}
}
