import MDocument from '~/model/MDocument'
import MEvent from '~/model/MEvent'
import MJournalEntry from '~/model/MJournalEntry'
import MLocalDate from '~/model/MLocalDate'
import MProcessContractDeadline from '~/model/MProcessContractDeadline'
import MProcessFile from '~/model/MProcessFile'
import MProcessParticipation from '~/model/MProcessParticipation'
import MProcessStatus from '~/model/MProcessStatus'
import MProcessTag from '~/model/MProcessTag'
import MTimestamp from '~/model/MTimestamp'
import MVariableConfiguration from '~/model/MVariableConfiguration'


export default class MProcess {

	static fieldSelection(
		{
			includeDocumentVariableConfiguration,
			includeDocuments,
			includeEvent,
			includeEventLocationEvents,
			includeJournalEntries,
			includeParticipations,
			includeVariableConfiguration
		}: {
			includeDocumentVariableConfiguration?: boolean
			includeDocuments?: boolean
			includeEvent?: boolean
			includeEventLocationEvents?: boolean
			includeJournalEntries?: boolean | string
			includeParticipations?: boolean
			includeVariableConfiguration?: boolean
		} = {}
	): string {
		return `{
			bookingTimestamp
			contractDeadline ${MProcessContractDeadline.fieldSelection()}
			creationTimestamp
			${includeDocuments ? `documents ${MDocument.fieldSelection({ includeVariableConfiguration: includeDocumentVariableConfiguration })}` : ''}
			${includeEvent ? `event ${MEvent.fieldSelection({ includeLocationEvents: includeEventLocationEvents })}` : ''}
			externalId
			files ${MProcessFile.fieldSelection()}
			id
			${includeJournalEntries ? `journalEntries${typeof includeJournalEntries === 'string' ? includeJournalEntries : ''} ${MJournalEntry.fieldSelection()}` : ''}
			${includeParticipations ? `participations ${MProcessParticipation.fieldSelection({ includeContact: true })}` : ''}
			pdfUrl
			status
			tags ${MProcessTag.fieldSelection()}
			${includeVariableConfiguration ? `variableConfiguration ${MVariableConfiguration.fieldSelection()}` : ''}
		}`
	}


	readonly bookingTimestamp: MTimestamp | null
	readonly contractDeadline: MProcessContractDeadline | null
	readonly creationTimestamp: MTimestamp
	readonly documents?: MDocument[]
	readonly event: MEvent | null
	readonly externalId: string
	readonly files: MProcessFile[]
	readonly id: string
	readonly journalEntries: MJournalEntry<any>[] | null
	readonly participations: MProcessParticipation[] | null
	readonly pdfUrl: string
	readonly relevantDate: MLocalDate
	readonly state: MProcessStatus // TODO rn to status
	readonly tags: MProcessTag[]
	readonly variableConfiguration?: MVariableConfiguration


	constructor(properties: {
		readonly bookingTimestamp: MTimestamp | null
		readonly contractDeadline: MProcessContractDeadline | null
		readonly creationTimestamp: MTimestamp
		readonly documents?: MDocument[]
		readonly event: MEvent | null
		readonly externalId: string
		readonly files: MProcessFile[]
		readonly id: string
		readonly journalEntries: MJournalEntry<any>[] | null
		readonly participations: MProcessParticipation[] | null
		readonly pdfUrl: string
		readonly state: MProcessStatus
		readonly tags: MProcessTag[]
		readonly variableConfiguration?: MVariableConfiguration
	}) {
		this.bookingTimestamp = properties.bookingTimestamp
		this.contractDeadline = properties.contractDeadline
		this.creationTimestamp = properties.creationTimestamp
		this.documents = properties.documents
		this.event = properties.event
		this.externalId = properties.externalId
		this.files = properties.files
		this.id = properties.id
		this.journalEntries = properties.journalEntries
		this.participations = properties.participations
		this.pdfUrl = properties.pdfUrl
		this.state = properties.state
		this.tags = properties.tags
		this.variableConfiguration = properties.variableConfiguration

		if (this.event && this.event.date) {
			this.relevantDate = this.event.date
		}
		else {
			// TODO temporary
			const components = this.externalId.match(/^(\d{4})(\d{2})(\d{2})-/)!
			this.relevantDate = MLocalDate.make(
				Number.parseInt(components[1]),
				Number.parseInt(components[2]),
				Number.parseInt(components[3])
			)
		}
	}


	static fromJson(json: any): MProcess {
		return new this({
			bookingTimestamp: json.bookingTimestamp ? MTimestamp.parse(json.bookingTimestamp) : null,
			contractDeadline: json.contractDeadline ? MProcessContractDeadline.fromJson(json.contractDeadline) : null,
			creationTimestamp: MTimestamp.parse(json.creationTimestamp),
			documents: json.documents?.map(it => MDocument.fromJson(it)),
			event: json.event ? MEvent.fromJson(json.event) : null,
			externalId: json.externalId,
			files: json.files ? json.files.map((json: any) => MProcessFile.fromJson(json)) : null,
			id: json.id,
			journalEntries: json.journalEntries ? json.journalEntries.map((entry: any) => MJournalEntry.fromJson(entry)) : null,
			participations: json.participations ? json.participations.map((participation: any) => MProcessParticipation.fromJson(participation)) : null,
			pdfUrl: json.pdfUrl,
			state: MProcessStatus.byId(json.status),
			tags: json.tags.map(it => MProcessTag.fromJson(it)),
			variableConfiguration: json.variableConfiguration ? MVariableConfiguration.fromJson(json.variableConfiguration) : undefined
		})
	}


	get primaryParticipation(): MProcessParticipation | null {
		return this.participations?.find(it => it.tags.some(tag => tag.isPrimary)) ?? null
	}
}
