export default class MDayOfWeek {

	static readonly monday = new MDayOfWeek('monday', '1970-01-05T00:00:00Z')
	static readonly tuesday = new MDayOfWeek('tuesday', '1970-01-06T00:00:00Z')
	static readonly wednesday = new MDayOfWeek('wednesday', '1970-01-07T00:00:00Z')
	static readonly thursday = new MDayOfWeek('thursday', '1970-01-01T00:00:00Z')
	static readonly friday = new MDayOfWeek('friday', '1970-01-02T00:00:00Z')
	static readonly saturday = new MDayOfWeek('saturday', '1970-01-03T00:00:00Z')
	static readonly sunday = new MDayOfWeek('sunday', '1970-01-04T00:00:00Z')

	static readonly all = Object.freeze([
		MDayOfWeek.monday,
		MDayOfWeek.tuesday,
		MDayOfWeek.wednesday,
		MDayOfWeek.thursday,
		MDayOfWeek.friday,
		MDayOfWeek.saturday,
		MDayOfWeek.sunday
	])


	readonly id: string
	readonly label: string


	private constructor(id: string, referenceTimestamp: string) {
		this.id = id
		this.label = new Date(referenceTimestamp).toLocaleDateString('de-DE', {
			timeZone: 'UTC',
			weekday: 'long'
		})
	}


	toString() {
		return this.id
	}
}
