
	import XCurrentProcessBar from '@/main/components/XCurrentProcessBar.vue'
	import XMainPageContentOld from '@/main/components/XMainPageContentOld.vue'
	import { Inject, Options, Vue } from 'vue-property-decorator'
	import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
	import Api, { ApiError, ApiResponse, ApiResult, SearchResult, SearchType } from '~/api/Api'
	import { makeInput } from '~/components/input/Input'
	import { stringInputDefinition } from '~/components/input/InputDefinition'
	import XNewInput from '~/components/input/XNewInput.vue'
	import XFormColumn from '~/components/XFormColumn.vue'
	import XFormRow from '~/components/XFormRow.vue'
	import XIconButton from '~/components/XIconButton.vue'
	import XInput from '~/components/XInput.vue'
	import XLink from '~/components/XLink.vue'
	import XLoadingAnimation from '~/components/XLoadingAnimation.vue'
	import XProcessId from '~/components/XProcessId.vue'
	import XSection from '~/components/XSection.vue'
	import XStatusMessage from '~/components/XStatusMessage.vue'
	import MCity from '~/model/MCity'
	import MContact from '~/model/MContact'
	import MLocation from '~/model/MLocation'
	import MProcess from '~/model/MProcess'
	import { processSessionManager } from '~/ProcessSessionManager'
	import icons from '~/styles/icons.module.scss'
	import { asString, error, formatDuration, formatNumber, randomId } from '~/utility'


	@Options({
		components: {
			XNewInput,
			XCurrentProcessBar,
			XFormColumn,
			XFormRow,
			XIconButton,
			XInput,
			XLink,
			XLoadingAnimation,
			XMainPageContentOld,
			XProcessId,
			XSection,
			XStatusMessage
		},
		name: 'page-main-search'
	})
	export default class extends Vue {

		readonly formatDuration = formatDuration
		readonly formatNumber = formatNumber
		readonly icons: { readonly [key: string]: string } = icons

		apiResponse: ApiResponse<SearchResult> | null = null
		error: ApiError | null = null
		errorLocation: 'city' | 'contact' | 'location' | 'process' | null = null
		isBusy = false
		moreCities: MCity[] = []
		moreContacts: MContact[] = []
		moreLoadingType: SearchType | null = null
		moreLocations: MLocation[] = []
		moreProcesses: MProcess[] = []
		nextCityOffset = 0
		nextContactOffset = 0
		nextLocationOffset = 0
		nextProcessOffset = 0
		query = ''
		queryInput = makeInput(stringInputDefinition({ required: true, trim: true }))
		type: SearchType | '' = ''
		typeInputName = randomId()
		typeInputValue: SearchType | '' = ''
		updatedCurrentProcess?: MProcess | null

		@Inject() readonly api!: Api


		private get queryInputElement(): HTMLInputElement | undefined {
			return this.$refs.queryInputElement as HTMLInputElement | undefined
		}


		beforeRouteUpdate(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
			this.updateDataFromRoute(to)
			this.reload()

			next()
		}


		get cities() {
			return (this.result.data.cityResult?.elements ?? []).concat(this.moreCities)
		}


		get cityCount() {
			return this.result.data.cityResult?.totalCount ?? 0
		}


		get contactCount() {
			return this.result.data.contactResult?.totalCount ?? 0
		}


		get contacts() {
			return (this.result.data.contactResult?.elements ?? []).concat(this.moreContacts)
		}


		created() {
			this.updateDataFromRoute(this.$route)
			this.reload()
		}


		get currentProcess() {
			return this.updatedCurrentProcess === undefined ? this.result.currentProcess : this.updatedCurrentProcess
		}


		set currentProcess(value: MProcess | null) {
			this.updatedCurrentProcess = value
		}


		data() {
			return {
				updatedCurrentProcess: undefined
			}
		}


		async loadMore(type: SearchType) {
			let offset = 0
			switch (type) {
				case 'city':
					offset = this.nextCityOffset
					break

				case 'contact':
					offset = this.nextContactOffset
					break

				case 'location':
					offset = this.nextLocationOffset
					break

				case 'process':
					offset = this.nextProcessOffset
					break
			}

			this.error = null
			this.errorLocation = null
			this.moreLoadingType = type

			const response = await this.api.search({ offset, query: this.query, type: type })

			this.moreLoadingType = null

			if (response instanceof ApiError) {
				this.error = response
				this.errorLocation = type

				return
			}

			const data = response.data

			switch (type) {
				case 'city':
					this.moreCities = this.moreCities.concat(data.cityResult?.elements ?? [])
					this.nextCityOffset = data.cityResult?.nextOffset ?? 0
					break

				case 'contact':
					this.moreContacts = this.moreContacts.concat(data.contactResult?.elements ?? [])
					this.nextContactOffset = data.contactResult?.nextOffset ?? 0
					break

				case 'location':
					this.nextLocationOffset = data.locationResult?.nextOffset ?? 0
					this.moreLocations = this.moreLocations.concat(data.locationResult?.elements ?? [])
					break

				case 'process':
					this.nextProcessOffset = data.processResult?.nextOffset ?? 0
					this.moreProcesses = this.moreProcesses.concat(data.processResult?.elements ?? [])
					break
			}
		}


		get locationCount() {
			return this.result.data.locationResult?.totalCount ?? 0
		}


		get locations() {
			return (this.result.data.locationResult?.elements ?? []).concat(this.moreLocations)
		}


		onSubmitted() {
			const query = this.queryInput.value
			const type = this.typeInputValue

			if (query === this.query && type === this.type) {
				this.reload()
				return
			}

			this.$router.replace({
				path: this.$route.path,
				query: {
					query: query,
					type: type || undefined
				}
			})
		}


		async openProcess(id: string) {
			processSessionManager.open(id)

			await this.$router.push({ name: 'event' })
		}


		get processCount() {
			return this.result.data.processResult?.totalCount ?? 0
		}


		get processes() {
			return (this.result.data.processResult?.elements ?? []).concat(this.moreProcesses)
		}


		get queryPlaceholder() {
			switch (this.typeInputValue) {
				case 'city':
					return 'Ortsname'
				case 'location':
					return 'Location- und/oder Ortsname'
				case 'contact':
					return 'Vor-, Nach- und/oder Firmenname'
				case 'process':
					return 'Vorgangsnummer, Veranstaltungsdatum und/oder Kundenname'
				default:
					return 'Vorgangsnummer, Kunden-/Location-/Ortsname und/oder Veranstaltungsdatum'
			}
		}


		async reload() {
			this.apiResponse = null
			this.error = null
			this.moreCities = []
			this.moreContacts = []
			this.moreLocations = []
			this.moreProcesses = []
			this.nextCityOffset = 0
			this.nextContactOffset = 0
			this.nextLocationOffset = 0
			this.nextProcessOffset = 0

			const response = await this.api.search({ query: this.query, type: this.type || undefined })

			this.apiResponse = response
			this.updatedCurrentProcess = undefined

			if (response instanceof ApiError) {
				return
			}

			this.nextCityOffset = response.data.cityResult?.nextOffset ?? 0
			this.nextContactOffset = response.data.contactResult?.nextOffset ?? 0
			this.nextLocationOffset = response.data.locationResult?.nextOffset ?? 0
			this.nextProcessOffset = response.data.processResult?.nextOffset ?? 0
		}


		get result(): ApiResult<SearchResult> {
			return this.apiResponse?.asResult() ?? error()
		}


		get sections(): SearchType[] {
			if (!this.query) {
				return []
			}

			const sections: SearchType[] = []
			if (this.processes.length || this.type === 'process') {
				sections.push('process')
			}
			if (this.contacts.length || this.type === 'contact') {
				sections.push('contact')
			}
			if (this.locations.length || this.type === 'location') {
				sections.push('location')
			}
			if (this.cities.length || this.type === 'city') {
				sections.push('city')
			}

			if (this.type === '') {
				if (!sections.includes('process')) {
					sections.push('process')
				}
				if (!sections.includes('contact')) {
					sections.push('contact')
				}
				if (!sections.includes('location')) {
					sections.push('location')
				}
				if (!sections.includes('city')) {
					sections.push('city')
				}
			}

			return sections
		}


		get tags() {
			return this.result.processTags
		}


		updateDataFromRoute(route: RouteLocationNormalized) {
			const query = asString(route.query.query) ?? ''
			this.query = query
			this.queryInput = makeInput(stringInputDefinition({ required: true, trim: true }), query || undefined, query || undefined)

			let type = asString(route.query.type) ?? undefined
			if (type !== 'city' && type !== 'contact' && type !== 'location' && type !== 'process') {
				type = undefined
			}
			this.type = type ?? ''
			this.typeInputValue = type ?? ''
		}
	}
