import { parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js/min'
import { error } from '~/utility'


export default class MPhoneNumber {

	private readonly value: PhoneNumber


	private constructor(value: PhoneNumber) {
		this.value = value
	}


	formatInGermany() {
		if (this.value.country === 'DE') {
			return this.value.formatNational()
		}
		else {
			return this.value.formatInternational()
		}
	}


	formatInternational() {
		return this.value.formatInternational()
	}


	formatNational() {
		return this.value.formatNational()
	}


	isEqualTo(other: MPhoneNumber) {
		return this.value.number === other.value.number
	}


	static parse(string: string) {
		return this.parseOrNull(string) || error(`Invalid phone number string: ${string}`)
	}


	static parseOrNull(string: string) {
		const value = parsePhoneNumberFromString(string, 'DE')
		if (!value || !value.isPossible()) {
			return null
		}

		return new this(value)
	}


	toString() {
		return this.value.number as string
	}


	toUri() {
		return this.value.format('RFC3966')
	}
}
