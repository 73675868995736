import MMagazine from '~/model/MMagazine'
import MReferral from '~/model/MReferral'
import MWeddingFair from '~/model/MWeddingFair'
import { compareBy, sorted } from '~/utility'
import { booleanInput, compositeInput, localDateInput, simpleEnumInput, stringInput } from '~/utility/Input'


export default function referralInput(properties?: {
	readonly djOptions: readonly { key: string, label: string }[]
	readonly locationOptions: readonly { key: string, label: string }[]
	readonly magazines: readonly MMagazine[]
	readonly referral: MReferral | null
	readonly weddingFairs: readonly MWeddingFair[]
}) {
	return compositeInput({
		eventDate: localDateInput(properties?.referral?.eventDate ?? null),
		eventDjId: simpleEnumInput(
			properties?.referral?.eventDj?.id ?? null,
			sorted(
				properties?.djOptions ?? [],
				compareBy(it => it.label, 'de-DE', { ignorePunctuation: true, numeric: true, sensitivity: 'base' }),
			),
		),
		eventName: stringInput(properties?.referral?.eventName ?? ''),
		experienced: booleanInput(properties?.referral?.experienced ?? false),
		friends: booleanInput(properties?.referral?.friends ?? false),
		gastronomy: booleanInput(properties?.referral?.gastronomy ?? false),
		google: booleanInput(properties?.referral?.google ?? false),
		internet: booleanInput(properties?.referral?.internet ?? false),
		locationId: simpleEnumInput(
			properties?.referral?.location?.id ?? null,
			sorted(
				properties?.locationOptions ?? [],
				compareBy(it => it.label, 'de-DE', { ignorePunctuation: true, numeric: true, sensitivity: 'base' }),
			),
		),
		magazineId: simpleEnumInput(
			properties?.referral?.magazine?.id ?? null,
			sorted(
				properties?.magazines?.map(it => ({ key: it.id, label: it.name })) ?? [],
				compareBy(it => it.label, 'de-DE', { ignorePunctuation: true, numeric: true, sensitivity: 'base' }),
			),
		),
		other: stringInput(properties?.referral?.other ?? ''),
		ourWebsite: booleanInput(properties?.referral?.ourWebsite ?? false),
		weddingFairId: simpleEnumInput(
			properties?.referral?.weddingFair?.id ?? null,
			properties?.weddingFairs?.map(it => ({ key: it.id, label: it.name })) ?? [], // TODO Sorting.
		),
	})
}
