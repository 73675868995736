import MTimestamp from '~/model/MTimestamp'


export default class MUpload {

	static fieldSelection(): string {
		return `{
			contentType
			creationTimestamp
			downloadUrl
			id
			name
			size
		}`
	}


	readonly contentType: string
	readonly creationTimestamp: MTimestamp
	readonly downloadUrl: string
	readonly id: string
	readonly name: string
	readonly size: number


	constructor(properties: {
		readonly contentType: string
		readonly creationTimestamp: MTimestamp
		readonly downloadUrl: string
		readonly id: string
		readonly name: string
		readonly size: number
	}) {
		this.contentType = properties.contentType
		this.creationTimestamp = properties.creationTimestamp
		this.downloadUrl = properties.downloadUrl
		this.id = properties.id
		this.name = properties.name
		this.size = properties.size
	}


	static fromJson(json: any) {
		return new this({
			contentType: json.contentType,
			creationTimestamp: MTimestamp.parse(json.creationTimestamp),
			downloadUrl: json.downloadUrl,
			id: json.id,
			name: json.name,
			size: json.size
		})
	}
}
