export default class MEmailRecipient {

	static fieldSelection(): string {
		return `{
			address
			name
		}`
	}


	readonly address: string
	readonly name: string | null


	constructor(properties: {
		readonly address: string
		readonly name: string | null
	}) {
		this.address = properties.address
		this.name = properties.name
	}


	get displayName() {
		if (this.name) {
			return `${this.name} (${this.address})`
		}
		else {
			return this.address
		}
	}


	static fromJson(json: any) {
		return new this({
			address: json.address,
			name: json.name
		})
	}
}
