import GraphOperation from '@/graphql/operations/GraphOperation'
import MError, { MAuthenticationRequiredError } from '~/model/MError'
import MUser from '~/model/MUser'
import { error } from '~/utility'


export default class MeQuery extends GraphOperation<MUser | MError> {

	constructor() {
		super({
			fieldSelection: `{
				__typename
				... on Error ${MError.fieldSelection()}
				... on User ${MUser.fieldSelection()}
			}`,
			name: 'me',
			type: 'query'
		})
	}


	parseData(json: any): MUser | MError {
		const typeName = json.__typename
		switch (typeName) {
			case 'User':
				return MUser.fromJson(json)

			case 'AuthenticationRequiredError':
				return MAuthenticationRequiredError.fromJson(json)

			default:
				error(`Unknown type: ${typeName}`)
		}
	}
}
