import { pauseTracking, resetTracking } from "@vue/reactivity"
import { computed, Ref, shallowReactive } from "vue"
import { InputDefinition, InputDefinitionWithOptions } from "~/components/input/InputDefinition"
import { associateTo, Defined } from "~/utility"


// TODO Cleanup all names, types, and file structure.

export interface Input<Value extends Defined, SerializedValue extends Defined> {

	readonly definition: InputDefinition<Value, SerializedValue>

	readonly initialValue: Value | undefined

	readonly value: Value | undefined

	redefine(definition: InputDefinition<Value, SerializedValue>): Input<Value, SerializedValue>

	reset(value: Value | undefined): Input<Value, SerializedValue>

	withValue(value: Value | undefined): Input<Value, SerializedValue>
}


export interface InputWithOptions<Value extends Defined> extends Input<Value, string> {

	readonly definition: InputDefinitionWithOptions<Value>
}


type DefinitionForInput<T> = T extends Input<infer Value, infer SerializedValue> ? InputDefinition<Value, SerializedValue> : never
type BaseOfInput<T> = T extends Input<infer Value, infer SerializedValue> ? Input<Value, SerializedValue> : never
type BaseOfInputs<T extends { [key: string]: Input<Defined, Defined> }> = { [Key in keyof T]: BaseOfInput<T[Key]> }
type InputForDefinition<T> = T extends InputDefinition<infer Value, infer SerializedValue> ? Input<Value, SerializedValue> : never
type InputForDefinitions<T extends { [key: string]: InputDefinition<Defined, Defined> }> = { [Key in keyof T]: InputForDefinition<T[Key]> }
type SerializedValueOfInput<T> = T extends Input<any, infer SerializedValue> ? SerializedValue : never
type ValueOfInput<T> = T extends Input<infer Value, any> ? Value : never


export type InputMap<Inputs extends { [key: string]: Input<Defined, Defined> }> =
	BaseOfInputs<Inputs> & InputMapAdditions<BaseOfInputs<Inputs>>


interface InputMapAdditions<Inputs extends { [key: string]: Input<Defined, Defined> }> {

	readonly validValues: { [Key in keyof Inputs]: ValueOfInput<Inputs[Key]> } | null

	redefine(definitions: { [Key in keyof Inputs]: DefinitionForInput<Inputs[Key]> })

	reset(values?: { [Key in keyof Inputs]?: ValueOfInput<Inputs[Key]> } | null)
}


// TODO How to do this type-safe?
export function inputMap<Inputs extends { readonly [key: string]: Input<Defined, Defined> }>(
	inputs: Inputs,
): InputMap<BaseOfInputs<Inputs>> {
	const keys = Object.keys(inputs) as (keyof Inputs)[]

	// @ts-ignore help!
	return shallowReactive({
		...(inputs as unknown as { [Key in keyof Inputs]: BaseOfInput<Inputs[Key]> }),
		redefine(definitions) {
			for (const key of keys)
				this[key] = this[key].redefine(definitions[key]) as any
		},
		reset(values) {
			for (const key of keys)
				this[key] = this[key].reset(values ? values[key] : this[key].definition.defaultValue) as any
		},
		get validValues() {
			const values: Record<any, unknown> = {}
			for (const key of keys) {
				const value = this[key].value
				if (value === undefined)
					return null

				values[key] = value
			}

			return values as { [Key in keyof Inputs]: ValueOfInput<Inputs[Key]> }
		},
	})
}


export function inputMapFromDefinitions<Definitions extends { readonly [key: string]: InputDefinition<Defined, Defined> }>(
	definitions: Definitions,
): InputMap<InputForDefinitions<Definitions>> {
	const keys = Object.keys(definitions) as (keyof Definitions)[]

	return inputMap(associateTo(keys as any, key => makeInput(definitions[key])) as any) as any
}


export function computedInputMap<Definitions extends { readonly [key: string]: InputDefinition<Defined, Defined> }>(
	getter: () => Definitions,
): Ref<InputMap<InputForDefinitions<Definitions>>> {
	const definitionsRef = computed(getter)

	let map: InputMap<InputForDefinitions<Definitions>> | undefined

	return computed({
		get: () => {
			if (map) {
				pauseTracking()
				map.redefine(definitionsRef.value as any) // TODO Why doesn't the type match?
				resetTracking()
			}
			else
				map = inputMapFromDefinitions(definitionsRef.value)

			return map
		},
		set(value) {
			map = value
		},
	})
}


export function makeInput<Value extends Defined, SerializedValue extends Defined>(
	definition: InputDefinition<Value, SerializedValue>,
	value?: Value | undefined,
	initialValue?: Value | undefined,
): Input<Value, SerializedValue> {
	return {
		definition,
		initialValue,
		value,
		redefine(definition) {
			return makeInput(definition, this.value, this.initialValue)
		},
		reset(value) {
			return makeInput(this.definition, value, value)
		},
		withValue(value) {
			return makeInput(this.definition, value, this.initialValue)
		},
	}
}
