import { error } from '~/utility'


export default class MProcessStatus {

	static readonly booked = new MProcessStatus('booked', 'gebucht')
	static readonly canceled = new MProcessStatus('canceled', 'abgesagt')
	static readonly requested = new MProcessStatus('requested', 'angefragt')

	static readonly all = [
		MProcessStatus.booked,
		MProcessStatus.canceled,
		MProcessStatus.requested
	]

	readonly id: string
	readonly label: string


	private constructor(id: string, label: string) {
		this.id = id
		this.label = label
	}


	static byId(id: string) {
		return this.byIdOrNull(id) || error(`Invalid MProcessStatus id: ${id}`)
	}


	static byIdOrNull(id: string) {
		return this.all.find(state => state.id === id) || null
	}
}
