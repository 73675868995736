
	import XCurrentProcessBar from '@/main/components/XCurrentProcessBar.vue'
	import XMainPageContentOld from '@/main/components/XMainPageContentOld.vue'
	import XNoProcess from '@/main/components/XNoProcess.vue'
	import { Inject, Options, Vue, Watch } from 'vue-property-decorator'
	import Api, { ApiError, ApiResponse, ApiResult, FetchEmailDataResult } from '~/api/Api'
	import XCheckbox from '~/components/XCheckbox.vue'
	import XFormColumn from '~/components/XFormColumn.vue'
	import XFormRow from '~/components/XFormRow.vue'
	import XIconButton from '~/components/XIconButton.vue'
	import XInput from '~/components/XInput.vue'
	import XLink from '~/components/XLink.vue'
	import XLoadingAnimation from '~/components/XLoadingAnimation.vue'
	import XSection from '~/components/XSection.vue'
	import XSelect from '~/components/XSelect.vue'
	import XStatusMessage from '~/components/XStatusMessage.vue'
	import MEmailRecipient from '~/model/MEmailRecipient'
	import MEmailRecipientOption from '~/model/MEmailRecipientOption'
	import MProcess from '~/model/MProcess'
	import MProcessEmailTemplate from '~/model/MProcessEmailTemplate'
	import MProcessFile from '~/model/MProcessFile'
	import MUpload from '~/model/MUpload'
	import icons from '~/styles/icons.module.scss'
	import { associateByTo, asString, error, randomId } from '~/utility'
	import { booleanInput, compositeInput, enumInput, Input, simpleEnumInput, stringInput } from '~/utility/Input'


	@Options({
		components: {
			XCheckbox,
			XCurrentProcessBar,
			XFormColumn,
			XFormRow,
			XIconButton,
			XInput,
			XLink,
			XLoadingAnimation,
			XMainPageContentOld,
			XNoProcess,
			XSection,
			XSelect,
			XStatusMessage
		},
		name: 'page-main-email'
	})
	export default class extends Vue {

		readonly icons = icons

		additionalRecipientIds: readonly string[] = []
		apiResponse: ApiResponse<FetchEmailDataResult> | null = null
		fileUploadQueue: ReadonlyArray<{ file: File, name: string }> = []
		files: { name: string, source: MProcessFile | MUpload }[] = []
		input = this.makeInput()
		isBusy = false
		isSent = false
		status: ApiError | string | null = null
		statusType: 'error' | 'success' | 'warning' = 'success'
		type: 'customer' | 'dj' | 'file' = 'customer'
		updatedCurrentProcess?: MProcess | null

		@Inject() readonly api!: Api


		private get attachmentElement(): HTMLInputElement | undefined {
			return this.$refs.attachmentElement as HTMLInputElement | undefined
		}


		private get formElement(): HTMLFormElement | undefined {
			return this.$refs.formElement as HTMLFormElement | undefined
		}


		get copyRecipientOptions() {
			return this.result.data.copyRecipientOptions
		}


		created() {
			const type = asString(this.$route.query.type)
			if (type === 'customer' || type === 'dj' || type === 'file') {
				this.type = type
			}

			this.reload()
		}


		get currentProcess() {
			return this.updatedCurrentProcess === undefined ? this.result.currentProcess : this.updatedCurrentProcess
		}


		set currentProcess(value: MProcess | null) {
			this.updatedCurrentProcess = value
		}


		data() {
			return {
				updatedCurrentProcess: undefined
			}
		}


		deleteFile(file: { name: string, source: MProcessFile | MUpload }): void {
			const newFiles = this.files.filter(it => it !== file)
			if (newFiles.length === this.files.length) {
				return
			}

			this.files = newFiles

			if (file.source instanceof MUpload) {
				this.api.deleteUpload(file.source.id).catch(e => console.error(e))
			}
		}


		get isReadyForSubmission() {
			return Boolean(this.input.isValid() &&
				this.input.sender.value &&
				this.input.subject.value &&
				this.input.message.value &&
				(
					this.recipientOptions.find(it => this.input.recipients[it.recipient.address].value) ||
					this.additionalRecipientIds.find(it =>
						this.input.additionalRecipientsChecked[it].value && this.input.additionalRecipientsAddresses[it].valueOrUndefined
					)
				) &&
				!this.fileUploadQueue.length
			)
		}


		makeInput(properties?: {
			readonly copyRecipientOptions: readonly MEmailRecipientOption[]
			readonly defaultMessage: string
			readonly defaultSubject: string
			readonly recipientOptions: readonly MEmailRecipientOption[]
			readonly senderOptions: readonly MEmailRecipientOption[]
			readonly templates: readonly MProcessEmailTemplate[]
		}) {
			return compositeInput({
				additionalRecipientsAddresses: compositeInput({} as { [id: string]: Input<string> }),
				additionalRecipientsChecked: compositeInput({} as { [id: string]: Input<boolean> }),
				additionalRecipientsNames: compositeInput({} as { [id: string]: Input<string> }),
				copyRecipients: compositeInput(associateByTo(properties?.copyRecipientOptions ?? [], it => [
					it.recipient.address,
					booleanInput(it.isSelected)
				])),
				message: stringInput(properties?.defaultMessage ?? ''),
				recipients: compositeInput(associateByTo(properties?.recipientOptions ?? [], it => [
					it.recipient.address,
					booleanInput(it.isSelected)
				])),
				sender: simpleEnumInput(
					properties?.senderOptions?.find(it => it.isSelected)?.recipient?.address ?? null,
					properties?.senderOptions?.map(it => ({ key: it.recipient.address, label: it.recipient.displayName })) ?? []
				),
				subject: stringInput(properties?.defaultSubject ?? ''),
				template: enumInput(null, properties?.templates ?? [], it => ({ key: it.id, label: it.subject, value: it }))
			})
		}


		onAddAdditionalRecipientClicked() {
			const id = randomId()

			this.additionalRecipientIds = this.additionalRecipientIds.concat([id])
			this.input.additionalRecipientsAddresses[id] = stringInput('')
			this.input.additionalRecipientsChecked[id] = booleanInput(true)
			this.input.additionalRecipientsNames[id] = stringInput('')

			this.$nextTick(() => {
				(this.$refs[id] as any[])[0].focus()
			})
		}


		async onFilesSelected() {
			const fileElement = this.attachmentElement ?? error()

			const filesToAdd = fileElement.files
			if (!filesToAdd || !filesToAdd.length) {
				return
			}

			const isUploading = this.fileUploadQueue.length > 0

			const fileUploadsToAdd: { file: File, name: string }[] = []
			for (let i = 0; i < filesToAdd.length; ++i) {
				const file = filesToAdd.item(i) ?? error()
				const name = file.name.replace(/.*[/\\]/, '')

				fileUploadsToAdd.push({
					file,
					name
				})
			}

			fileElement.value = ''

			this.fileUploadQueue = this.fileUploadQueue.concat(fileUploadsToAdd)
			this.status = null

			if (!isUploading) {
				while (this.fileUploadQueue.length > 0) {
					const uploadedFile = await this.uploadFile(this.fileUploadQueue[0])
					if (uploadedFile) {
						this.files = this.files.concat([uploadedFile])
						this.fileUploadQueue = this.fileUploadQueue.slice(1)
					}
					else {
						this.fileUploadQueue = []
					}
				}
			}
		}


		async onSubmitted() {
			if (!this.isReadyForSubmission) {
				return
			}

			if (this.formElement && this.formElement.reportValidity && !this.formElement.reportValidity()) {
				return
			}

			const input = this.input
			const message = input.message.value || error()
			const sender = this.result.data.senderOptions.find(it => it.recipient.address === input.sender.key)?.recipient ?? error()
			const subject = input.subject.value || error()

			const copyRecipients = this.copyRecipientOptions
				.filter(it => input.copyRecipients[it.recipient.address].value)
				.map(it => it.recipient)

			const additionalRecipients = this.additionalRecipientIds
				.map(id => ({
					address: input.additionalRecipientsAddresses[id].valueOrUndefined ?? '',
					id,
					name: input.additionalRecipientsNames[id].value ?? ''
				}))
				.filter(it =>
					it.address && input.additionalRecipientsChecked[it.id].value
				)
				.map(({ address, name }) => ({ address, name }))

			const recipients = this.recipientOptions
				.filter(it => input.recipients[it.recipient.address].value)
				.map(it => it.recipient)
				.concat(additionalRecipients.map(it => new MEmailRecipient(it)))

			const files = this.files

			this.isBusy = true

			const response = await this.api.sendEmail({
				copyRecipients,
				files,
				message,
				processId: this.currentProcess?.id ?? error(),
				recipients,
				sender,
				subject
			})

			this.isBusy = false

			if (response instanceof ApiError) {
				this.status = response
				this.statusType = 'error'

				return
			}

			this.status = 'Die Email wurde versandt.'
			this.statusType = 'success'
		}


		@Watch('input.template')
		onTemplateChanged() {
			const template = this.input.template.value
			if (!template) {
				return
			}

			this.input.message = stringInput(template.message)
			this.input.subject = stringInput(template.subject)
		}


		get recipientOptions() {
			return this.result.data.recipientOptions
		}


		async reload() {
			const defaultFileId = asString(this.$route.query.fileId) || undefined

			this.apiResponse = null
			this.files = []
			this.input = this.makeInput()

			const response = await this.api.fetchEmailData(this.type, defaultFileId)

			this.apiResponse = response
			this.updatedCurrentProcess = undefined

			if (response instanceof ApiError) {
				return
			}

			this.files = response.data.defaultFile ? [response.data.defaultFile] : []
			this.input = this.makeInput(response.data)

			const actualDefaultFileId = response.data.defaultFile?.source?.id ?? null
			if (actualDefaultFileId !== defaultFileId) {
				await this.$router.replace({
					path: this.$route.path,
					query: {
						type: this.type === 'customer' ? undefined : this.type,
						fileId: actualDefaultFileId || undefined
					}
				})
			}
		}


		get result(): ApiResult<FetchEmailDataResult> {
			return this.apiResponse?.asResult() ?? error()
		}


		get tags() {
			return this.result.processTags
		}


		get title() {
			switch (this.type) {
				case 'dj':
					return 'Informationen an DJ senden'

				case 'file':
					return 'Datei senden'

				default:
					return 'Informationen an Kunden senden'
			}
		}


		async uploadFile({ file, name }: { file: File, name: string }) {
			const response = await this.api.uploadFile(file)
			if (response instanceof ApiError) {
				this.status = response
				this.statusType = 'error'

				return null
			}

			return {
				name: name,
				source: response.data
			}
		}
	}
