import MDayOfWeek from '~/model/MDayOfWeek'
import { Comparable, error } from '~/utility'


export default class MLocalDate implements Comparable<MLocalDate> {

	readonly day: number
	readonly month: number
	readonly year: number


	private constructor(year: number, month: number, day: number) {
		this.day = day
		this.month = month
		this.year = year
	}


	compare(other: MLocalDate) {
		let result = this.year - other.year
		if (result === 0) {
			result = this.month - other.month
			if (result === 0) {
				result = this.day - other.day
			}
		}

		return result
	}


	get dayOfWeek() {
		switch (this.toMidnightDate().getDay()) {
			case 1:
				return MDayOfWeek.monday

			case 2:
				return MDayOfWeek.tuesday

			case 3:
				return MDayOfWeek.wednesday

			case 4:
				return MDayOfWeek.thursday

			case 5:
				return MDayOfWeek.friday

			case 6:
				return MDayOfWeek.saturday

			default:
				return MDayOfWeek.sunday
		}
	}


	private static daysInMonthAndYear(month: number, year: number) {
		switch (month) {
			case 1:
				return 31
			case 2:
				return this.isLeapYear(year) ? 29 : 28
			case 3:
				return 31
			case 4:
				return 30
			case 5:
				return 31
			case 6:
				return 30
			case 7:
				return 31
			case 8:
				return 31
			case 9:
				return 30
			case 10:
				return 31
			case 11:
				return 30
			case 12:
				return 31
			default:
				return error(`Invalid month: ${month}`)
		}
	}


	format() {
		return this.toMidnightDate()
			.toLocaleDateString('de-DE', { day: 'numeric', month: 'long', year: 'numeric' })
	}


	formatShort() {
		return this.toMidnightDate()
			.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })
	}


	isAfter(other: MLocalDate) {
		return this.compare(other) > 0
	}


	isBefore(other: MLocalDate) {
		return this.compare(other) < 0
	}


	isEqualTo(other: MLocalDate) {
		return !this.compare(other)
	}


	private static isLeapYear(year: number) {
		return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)
	}


	static isValid(year: number, month: number, day: number) {
		return year >= 0 && year <= 99_999 && month >= 1 && month <= 12 && day >= 1 && day <= this.daysInMonthAndYear(month, year)
	}


	static make(year: number, month: number, day: number) {
		return this.makeOrNull(year, month, day) || error(`Incorrect date (year=${year}, month=${month}, day=${day})`)
	}


	static makeOrNull(year: number, month: number, day: number) {
		if (!this.isValid(year, month, day)) {
			return null
		}

		return new this(year, month, day)
	}


	static now() {
		const date = new Date()

		return new this(date.getFullYear(), date.getMonth() + 1, date.getDate())
	}


	static parse(string: string) {
		return this.parseOrNull(string) || error(`Invalid MLocalDate string: ${string}`)
	}


	static parseOrNull(string: string) {
		const match = string.match(/^(\d{1,5})-(\d{1,2})-(\d{1,2})$/)
		if (!match) {
			return null
		}

		return this.makeOrNull(
			Number.parseInt(match[1]),
			Number.parseInt(match[2]),
			Number.parseInt(match[3])
		)
	}


	private toMidnightDate() {
		return new Date(this.year, this.month - 1, this.day)
	}


	toString() {
		return `${this.year}-${this.month < 10 ? '0' : ''}${this.month}-${this.day < 10 ? '0' : ''}${this.day}`
	}


	yearsSince(other: MLocalDate) {
		let years = this.year - other.year
		if (this.month < other.month) {
			years -= 1
		}
		else if (this.month === other.month && this.day < other.day) {
			years -= 1
		}

		return years
	}
}
