import MContactPerson from '~/model/MContactPerson'
import MCountry from '~/model/MCountry'
import MPhoneNumber from '~/model/MPhoneNumber'
import MSalutation from '~/model/MSalutation'
import { compareBy, sorted } from '~/utility'
import { booleanInput, compositeInput, localDateInput, phoneNumberInput, simpleEnumInput, stringInput } from '~/utility/Input'


export default function contactPersonInput(properties?: {
	readonly person: MContactPerson | null
	readonly countries: readonly MCountry[]
	readonly defaultCountryId: string | null
	readonly initialInput?: {
		readonly addressCity: string
		readonly addressPostalCode: string
		readonly addressStreet: string
		readonly emailAddress: string
		readonly firstName: string
		readonly homePhoneNumber: MPhoneNumber | null
		readonly lastName: string
		readonly mobilePhoneNumber: MPhoneNumber | null
	}
	readonly salutations: readonly MSalutation[]
}) {
	return compositeInput({
		addressAddition: stringInput(properties?.person?.address?.line2 ?? ''),
		addressCity: stringInput(properties?.person?.address?.city ?? properties?.initialInput?.addressCity ?? ''),
		addressCountry: simpleEnumInput(
			properties?.person?.address?.country?.code ?? properties?.defaultCountryId ?? null,
			sorted(
				properties?.countries?.map(it => ({ key: it.code, label: it.name })) ?? [],
				compareBy(it => it.label, 'de-DE', { ignorePunctuation: true, numeric: true, sensitivity: 'base' })
			)
		),
		addressPostalCode: stringInput(properties?.person?.address?.postalCode ?? properties?.initialInput?.addressPostalCode ?? ''),
		addressStreet: stringInput(properties?.person?.address?.line1 ?? properties?.initialInput?.addressStreet ?? ''),
		birthday: localDateInput(properties?.person?.birthday ?? null),
		canUseInformalCommunication: booleanInput(properties?.person?.canUseInformalCommunication ?? false),
		changedLastName: stringInput(properties?.person?.changedLastName ?? ''),
		changedLastNameIsAfterWedding: booleanInput(properties?.person?.changedLastNameIsAfterWedding ?? true),
		emailAddress: stringInput(properties?.person?.emailAddress ?? properties?.initialInput?.emailAddress ?? ''),
		firstName: stringInput(properties?.person?.firstName ?? properties?.initialInput?.firstName ?? ''),
		homePhoneNumber: phoneNumberInput(properties?.person?.homePhoneNumber ?? properties?.initialInput?.homePhoneNumber ?? null),
		lastName: stringInput(properties?.person?.lastName ?? properties?.initialInput?.lastName ?? ''),
		mobilePhoneNumber: phoneNumberInput(properties?.person?.mobilePhoneNumber ?? properties?.initialInput?.mobilePhoneNumber ?? null),
		noNewsletter: booleanInput(properties?.person?.noNewsletter ?? false),
		salutation: simpleEnumInput(
			properties?.person?.salutation?.id ?? '',
			properties?.salutations?.map(it => ({ key: it.id, label: it.label })) ?? [] // TODO Sorting.
		),
		title: stringInput(properties?.person?.title ?? '')
	})
}
