import GraphOperation from '@/graphql/operations/GraphOperation'
import MError from '~/model/MError'
import MUser from '~/model/MUser'
import { error } from '~/utility'


export class AuthenticateOutputAuthenticated {

	readonly user: MUser


	constructor(properties: {
		readonly user: MUser
	}) {
		this.user = properties.user
	}
}


export default class AuthenticateMutation extends GraphOperation<AuthenticateOutputAuthenticated | MError> {

	constructor(parameters: {
		readonly emailAddress: string
		readonly password: string
	}) {
		super({
			arguments: 'input: { emailAddress: $emailAddress, password: $password }',
			fieldSelection: `{
				__typename
				... on AuthenticateOutputAuthenticated {
					user ${MUser.fieldSelection()}
				}
			}`,
			name: 'authenticate',
			type: 'mutation',
			variables: {
				emailAddress: ['EmailAddress!', parameters.emailAddress],
				password: ['Password!', parameters.password]
			}
		})
	}


	parseData(json: any): AuthenticateOutputAuthenticated | MError {
		const typeName = json.__typename
		switch (typeName) {
			case 'AuthenticateOutputAuthenticated':
				return new AuthenticateOutputAuthenticated({
					user: MUser.fromJson(json.user)
				})

			case 'AuthenticateOutputAlreadyAuthenticated':
				return new MError({ userMessage: 'Du bist bereits angemeldet. Bitte die Seite neu laden.' })

			case 'AuthenticateOutputIncorrectPassword':
				return new MError({ userMessage: 'Das Passwort ist falsch.' })

			case 'AuthenticateOutputUnknownEmailAddress':
				return new MError({ userMessage: 'Es gibt keinen Benutzer mit dieser Email-Adresse.' })

			default:
				error(`Unknown type: ${typeName}`)
		}
	}
}
