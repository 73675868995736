import MEvent from '~/model/MEvent'
import MProcess from '~/model/MProcess'
import MProcessFile from '~/model/MProcessFile'


export default abstract class MEntity {

	static fieldSelection(): string {
		return `{
			__typename
			... on Event ${MEvent.fieldSelection()}
			... on Process ${MProcess.fieldSelection()}
			... on ProcessFile ${MProcessFile.fieldSelection()}
		}`
	}


	readonly id: string


	protected constructor(id: string) {
		this.id = id
	}
}
