import MJournalItem from '~/model/MJournalItem'
import MJournalItemParser from '~/model/MJournalItemParser'
import MTimestamp from '~/model/MTimestamp'
import MUser from '~/model/MUser'


export default class MJournalEntry<Item extends MJournalItem> {

	static fieldSelection(): string {
		return `{
			archivalTimestamp
			creationTimestamp
			id
			item ${MJournalItemParser.fieldSelection()}
			user ${MUser.fieldSelection()}
		}`
	}


	readonly archivalTimestamp: MTimestamp | null
	readonly creationTimestamp: MTimestamp
	readonly id: string
	readonly item: Item
	readonly user: MUser | null


	constructor(properties: {
		readonly archivalTimestamp: MTimestamp | null
		readonly creationTimestamp: MTimestamp
		readonly id: string
		readonly item: Item
		readonly user: MUser | null
	}) {
		this.archivalTimestamp = properties.archivalTimestamp
		this.creationTimestamp = properties.creationTimestamp
		this.id = properties.id
		this.item = properties.item
		this.user = properties.user
	}


	static fromJson(json: any) {
		return new this({
			archivalTimestamp: json.archivalTimestamp ? MTimestamp.parse(json.archivalTimestamp) : null,
			creationTimestamp: MTimestamp.parse(json.creationTimestamp),
			id: json.id,
			item: MJournalItemParser.parse(json.item),
			user: json.user ? MUser.fromJson(json.user) : null
		})
	}
}
