import moment from 'moment'
import MAddress from '~/model/MAddress'
import MCity from '~/model/MCity'
import MEvent from '~/model/MEvent'
import MPhoneNumber from '~/model/MPhoneNumber'


export default class MLocation {

	static fieldSelection({ includeCity, includeEvents }: { includeCity?: boolean, includeEvents?: boolean } = {}): string {
		return `{
			address ${MAddress.fieldSelection()}
			approachRemarks
			capacity
			${includeCity ? `city ${MCity.fieldSelection()}` : ''}
			commissionRemarks
			contactName
			drivingTime
			drivingTimeAsPerNavigationSoftware
			emailAddress
			equipmentRemarks
			${includeEvents ? `events ${MEvent.fieldSelection({ includeProcess: true })}` : ''}
			faxNumber
			id
			internalRemarks
			isFolderFiled
			isRecommendingUs
			mobilePhoneNumber
			name
			otherRemarks
			phoneNumber
			receivesCommission
			setupRemarks
			specificsRemarks
			timingRemarks
			websiteUrl
		}`
	}


	readonly address: MAddress
	readonly approachRemarks: string | null
	readonly capacity: number | null
	readonly city: MCity | null
	readonly commissionRemarks: string | null
	readonly contactName: string | null
	readonly drivingTime: number | null
	readonly drivingTimeAsPerNavigationSoftware: number | null
	readonly emailAddress: string | null
	readonly equipmentRemarks: string | null
	readonly events?: MEvent[]
	readonly faxNumber: MPhoneNumber | null
	readonly id: string
	readonly internalRemarks: string | null
	readonly isFolderFiled: boolean
	readonly isRecommendingUs: boolean
	readonly mobilePhoneNumber: MPhoneNumber | null
	readonly name: string
	readonly otherRemarks: string | null
	readonly phoneNumber: MPhoneNumber | null
	readonly receivesCommission: boolean | null
	readonly setupRemarks: string | null
	readonly specificsRemarks: string | null
	readonly timingRemarks: string | null
	readonly websiteUrl: string | null


	constructor(properties: {
		readonly address: MAddress
		readonly approachRemarks: string | null
		readonly capacity: number | null
		readonly city: MCity | null
		readonly commissionRemarks: string | null
		readonly contactName: string | null
		readonly drivingTime: number | null
		readonly drivingTimeAsPerNavigationSoftware: number | null
		readonly emailAddress: string | null
		readonly equipmentRemarks: string | null
		readonly events?: MEvent[]
		readonly faxNumber: MPhoneNumber | null
		readonly id: string
		readonly internalRemarks: string | null
		readonly isFolderFiled: boolean
		readonly isRecommendingUs: boolean
		readonly mobilePhoneNumber: MPhoneNumber | null
		readonly name: string
		readonly otherRemarks: string | null
		readonly phoneNumber: MPhoneNumber | null
		readonly receivesCommission: boolean | null
		readonly setupRemarks: string | null
		readonly specificsRemarks: string | null
		readonly timingRemarks: string | null
		readonly websiteUrl: string | null
	}) {
		this.address = properties.address
		this.approachRemarks = properties.approachRemarks
		this.capacity = properties.capacity
		this.city = properties.city
		this.commissionRemarks = properties.commissionRemarks
		this.contactName = properties.contactName
		this.drivingTime = properties.drivingTime
		this.drivingTimeAsPerNavigationSoftware = properties.drivingTimeAsPerNavigationSoftware
		this.emailAddress = properties.emailAddress
		this.equipmentRemarks = properties.equipmentRemarks
		this.events = properties.events
		this.faxNumber = properties.faxNumber
		this.id = properties.id
		this.internalRemarks = properties.internalRemarks
		this.isFolderFiled = properties.isFolderFiled
		this.isRecommendingUs = properties.isRecommendingUs
		this.mobilePhoneNumber = properties.mobilePhoneNumber
		this.name = properties.name
		this.otherRemarks = properties.otherRemarks
		this.phoneNumber = properties.phoneNumber
		this.receivesCommission = properties.receivesCommission
		this.setupRemarks = properties.setupRemarks
		this.specificsRemarks = properties.specificsRemarks
		this.timingRemarks = properties.timingRemarks
		this.websiteUrl = properties.websiteUrl
	}


	static fromJson(json: any): MLocation {
		return new this({
			address: MAddress.fromJson(json.address),
			approachRemarks: json.approachRemarks,
			capacity: json.capacity,
			city: json.city ? MCity.fromJson(json.city) : null,
			commissionRemarks: json.commissionRemarks,
			contactName: json.contactName,
			drivingTime: json.drivingTime ? moment.duration(json.drivingTime).asSeconds() : null,
			drivingTimeAsPerNavigationSoftware: json.drivingTimeAsPerNavigationSoftware ? moment.duration(json.drivingTimeAsPerNavigationSoftware).asSeconds() : null,
			emailAddress: json.emailAddress,
			equipmentRemarks: json.equipmentRemarks,
			events: json.events ? json.events.map(it => MEvent.fromJson(it)) : undefined,
			faxNumber: json.faxNumber ? MPhoneNumber.parse(json.faxNumber) : null,
			id: json.id,
			internalRemarks: json.internalRemarks,
			isFolderFiled: json.isFolderFiled,
			isRecommendingUs: json.isRecommendingUs,
			mobilePhoneNumber: json.mobilePhoneNumber ? MPhoneNumber.parse(json.mobilePhoneNumber) : null,
			name: json.name,
			otherRemarks: json.otherRemarks,
			phoneNumber: json.phoneNumber ? MPhoneNumber.parse(json.phoneNumber) : null,
			receivesCommission: json.receivesCommission,
			setupRemarks: json.setupRemarks,
			specificsRemarks: json.specificsRemarks,
			timingRemarks: json.timingRemarks,
			websiteUrl: json.websiteUrl
		})
	}
}
