import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_x_loading_animation = _resolveComponent("x-loading-animation")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style.root, _ctx.$style[`state-${_ctx.state}`]])
  }, [
    (_ctx.state === 'error')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style.error)
        }, [
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.$style.errorMessage)
          }, _toDisplayString(_ctx.error.userMessage), 3),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.errorActions)
          }, [
            _createElementVNode("button", {
              class: _normalizeClass(_ctx.$style.errorRetryButton),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.retryButtonClicked && _ctx.retryButtonClicked(...args))),
              type: "button"
            }, " Erneut versuchen ", 2),
            _createElementVNode("a", {
              class: _normalizeClass(_ctx.$style.errorSupportLink),
              href: _ctx.error.reportUrl,
              rel: "noopener",
              target: "_blank"
            }, "Problem melden", 10, _hoisted_1)
          ], 2)
        ], 2))
      : (_ctx.state === 'result')
        ? _renderSlot(_ctx.$slots, "default", { key: 1 })
        : (_openBlock(), _createBlock(_component_x_loading_animation, { key: 2 }))
  ], 2))
}