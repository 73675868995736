export default class MEquipment {

	static fieldSelection(): string {
		return `{
			id
			label
			priceExcludingVat
			type
		}`
	}


	readonly id: string
	readonly label: string
	readonly priceExcludingVat: number
	readonly type: 'light' | 'sound'


	constructor(properties: {
		readonly id: string
		readonly label: string
		readonly priceExcludingVat: number
		readonly type: 'light' | 'sound'
	}) {
		this.id = properties.id
		this.label = properties.label
		this.priceExcludingVat = properties.priceExcludingVat
		this.type = properties.type
	}


	static fromJson(json: any): MEquipment {
		return new this({
			id: json.id,
			label: json.label,
			priceExcludingVat: json.priceExcludingVat / 100,
			type: json.type
		})
	}
}
