export default class MProcessFileDocumentSource {

	static fieldSelection(): string {
		return `{
			type
		}`
	}


	readonly type: 'contract' | 'contractCoverLetter' | 'processOverview' | 'proposal' | 'questionnaire'
	readonly _type = 'missing document'


	constructor(properties: {
		readonly type: 'contract' | 'contractCoverLetter' | 'processOverview' | 'proposal' | 'questionnaire'
	}) {
		this.type = properties.type
	}


	static fromJson(json: any): MProcessFileDocumentSource {
		return new this({
			type: json.type
		})
	}
}
