export default abstract class GraphOperation<Data> {

	readonly arguments: string
	readonly fieldSelection: string
	readonly type: 'mutation' | 'query'
	readonly name: string
	readonly variables: Record<string, [string, any]>


	protected constructor(properties: {
		readonly arguments?: string
		readonly fieldSelection?: string
		readonly type: 'mutation' | 'query'
		readonly name: string
		readonly variables?: Record<string, [string, any]>
	}) {
		this.arguments = properties.arguments ?? ''
		this.fieldSelection = properties.fieldSelection ?? ''
		this.type = properties.type
		this.name = properties.name
		this.variables = properties.variables ?? {}
	}


	abstract parseData(json: any): Data
}
