
	import { Options, Prop, Vue } from 'vue-property-decorator'
	import { RouteLocationRaw } from 'vue-router'


	@Options({
		emits: { click: true },
		name: 'x-icon-button'
	})
	export default class extends Vue {

		@Prop() disabled?: boolean
		@Prop() href?: string
		@Prop() keepFocus?: boolean
		@Prop() rel?: string
		@Prop() tabindex?: number | string
		@Prop() target?: string
		@Prop({ type: [Object, String] }) to?: RouteLocationRaw

		private rootNode!: HTMLElement


		get classes() {
			const classes = [this.$style.root]
			if (this.disabled) {
				classes.push(this.$style.disabled)
			}

			return classes
		}


		get disabledAttribute() {
			return this.isButton && this.disabled
		}


		get hrefAttribute() {
			return this.href || (this.to ? this.$router.resolve(this.to, this.$route).href : undefined)
		}


		get isButton() {
			return !this.hrefAttribute
		}


		mounted() {
			this.rootNode = this.$refs.rootNode as HTMLElement
		}


		onClicked(event: MouseEvent) {
			if (this.hrefAttribute && (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey || event.button)) {
				// use browser default behavior
				return
			}

			if (!this.keepFocus) {
				this.rootNode.blur()
			}

			if (this.to !== undefined) {
				event.preventDefault()

				this.$router.push(this.to).catch(() => {})
			}

			this.$emit('click', event)
		}


		get tabIndexAttribute() {
			if (this.tabindex !== undefined) {
				return this.tabindex
			}

			if (!this.isButton && this.disabled) {
				return -1
			}

			return undefined
		}


		get tag() {
			return this.isButton ? 'button' : 'a'
		}
	}
