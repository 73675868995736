import MEmailRecipient from '~/model/MEmailRecipient'


export default class MConfiguration {

	static fieldSelection(): string {
		return `{
			djEmailCopyRecipients ${MEmailRecipient.fieldSelection()}
			emailCopyRecipients ${MEmailRecipient.fieldSelection()}
		}`
	}


	readonly djEmailCopyRecipients: MEmailRecipient[]
	readonly emailCopyRecipients: MEmailRecipient[]


	constructor(properties: {
		readonly djEmailCopyRecipients: MEmailRecipient[]
		readonly emailCopyRecipients: MEmailRecipient[]
	}) {
		this.djEmailCopyRecipients = properties.djEmailCopyRecipients
		this.emailCopyRecipients = properties.emailCopyRecipients
	}


	static fromJson(json: any): MConfiguration {
		return new this({
			djEmailCopyRecipients: json.djEmailCopyRecipients.map(it => MEmailRecipient.fromJson(it)),
			emailCopyRecipients: json.emailCopyRecipients.map(it => MEmailRecipient.fromJson(it))
		})
	}
}
