
	import XCurrentProcessBar from '@/main/components/XCurrentProcessBar.vue'
	import XMainPageContentOld from '@/main/components/XMainPageContentOld.vue'
	import XTagFilter from '@/main/components/XTagFilter.vue'
	import { Inject, Options, Vue } from 'vue-property-decorator'
	import Api, { ApiError, ApiResponse, ApiResult, FetchDashboardResult } from '~/api/Api'
	import XFormColumn from '~/components/XFormColumn.vue'
	import XFormRow from '~/components/XFormRow.vue'
	import XIconButton from '~/components/XIconButton.vue'
	import XInput from '~/components/XInput.vue'
	import XLink from '~/components/XLink.vue'
	import XProcessId from '~/components/XProcessId.vue'
	import XSection from '~/components/XSection.vue'
	import XStatusMessage from '~/components/XStatusMessage.vue'
	import MProcess from '~/model/MProcess'
	import { processSessionManager } from '~/ProcessSessionManager'
	import { storage } from '~/storage/Storage'
	import icons from '~/styles/icons.module.scss'
	import { compareBy, compareWith, deleteCookie, error, getCookie, nullsFirst, setCookie, sorted } from '~/utility'
	import { stringInput } from '~/utility/Input'


	@Options({
		components: {
			XCurrentProcessBar,
			XFormColumn,
			XFormRow,
			XIconButton,
			XInput,
			XLink,
			XMainPageContentOld,
			XProcessId,
			XSection,
			XStatusMessage,
			XTagFilter
		},
		name: 'page-main-dashboard'
	})
	export default class extends Vue {

		readonly icons: { readonly [key: string]: string } = icons

		apiResponse: ApiResponse<FetchDashboardResult> | null = null
		error: ApiError | null = null
		isBusy = false
		openProcessesExpanded = !getCookie('HideWV')
		searchQueryInput = stringInput('')
		updatedCurrentProcess?: MProcess | null
		updatedTaggedProcesses?: readonly MProcess[]

		@Inject() readonly api!: Api


		private get searchQueryInputElement(): HTMLInputElement | undefined {
			return this.$refs.searchQueryInputElement as HTMLInputElement | undefined
		}


		async created() {
			this.apiResponse = await this.api.fetchDashboard(storage.tagIds)
		}


		get currentProcess() {
			return this.updatedCurrentProcess === undefined ? this.result.currentProcess : this.updatedCurrentProcess
		}


		set currentProcess(value: MProcess | null) {
			this.updatedCurrentProcess = value
		}


		data() {
			return {
				updatedCurrentProcess: undefined,
				updatedTaggedProcesses: undefined
			}
		}


		onOpenProcessesToggleClicked() {
			this.openProcessesExpanded = !this.openProcessesExpanded
			if (this.openProcessesExpanded) {
				deleteCookie('HideWV')
			}
			else {
				setCookie('HideWV', '1')
			}
		}


		onSearchSubmitted() {
			this.$router.push({ name: 'search', query: { query: this.searchQueryInput.value || undefined } })
		}


		onTagIdsChanged(tagIds: string[]) {
			storage.tagIds = tagIds
		}


		onTaggedProcessesChanged(taggedProcesses: MProcess[]) {
			this.updatedTaggedProcesses = taggedProcesses
		}


		get openImportRequestCount() {
			return this.result.data.openImportRequestCount
		}


		async openProcess(id: string) {
			processSessionManager.open(id)

			await this.$router.push({ name: 'event' })
		}


		get openProcesses() {
			return sorted(
				this.result.data.openProcesses,
				compareWith(compareBy(it => it.contractDeadline?.date), compareBy(it => it.externalId))
			)
		}


		get openRequestStatistics() {
			return [
				{
					count: this.openWebsiteRequestCount,
					zero: 'keine',
					singular: 'unbearbeitete Anfrage',
					plural: 'unbearbeitete Anfragen',
					none: 'unbearbeiteten Anfragen'
				},
				{
					count: this.openImportRequestCount,
					zero: 'keine',
					singular: 'unbearbeiteter Import',
					plural: 'unbearbeitete Importe',
					none: 'unbearbeiteten Importe'
				}
			]
		}


		get openWebsiteRequestCount() {
			return this.result.data.openWebsiteRequestCount
		}


		get result(): ApiResult<FetchDashboardResult> {
			return this.apiResponse?.asResult() ?? error()
		}


		get tagIds(): string[] {
			return storage.tagIds
		}


		tagLabelsForProcess(process: MProcess) {
			return process.tags.map(tag => tag.label).join(', ')
		}


		get taggedProcesses() {
			return sorted(
				this.updatedTaggedProcesses ?? this.result.data.taggedProcesses,
				nullsFirst(compareBy(it => it.event && it.event.date))
			)
		}


		get tags() {
			return this.result.processTags
		}
	}
