import { ComponentOptions } from '@vue/runtime-core'
import { Observable, Subscription } from 'rxjs'


interface Data {

	allSubscriptions: Subscription | null
}


const mixin: ComponentOptions<{}, any, Data> = {

	beforeUnmount() {
		this.$data.allSubscriptions?.unsubscribe()
	},

	data() {
		return { allSubscriptions: null }
	},

	methods: {
		$subscribeTo<T>(
			observable: Observable<T>,
			next: (t: T) => void,
			error?: (e: any) => void,
			complete?: () => void
		): Subscription {
			if (!this.$data.allSubscriptions) {
				this.$data.allSubscriptions = new Subscription()
			}

			const subscription = observable.subscribe(next, error, complete)
			this.$data.allSubscriptions.add(subscription)

			return subscription
		}
	}
}
export default mixin
