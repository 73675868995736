import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick", "textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_x_checkbox = _resolveComponent("x-checkbox")!
  const _component_x_form_row = _resolveComponent("x-form-row")!
  const _component_x_input = _resolveComponent("x-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.tabBar)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.configurations, (configuration, index) => {
        return (_openBlock(), _createElementBlock("button", {
          class: _normalizeClass({ [_ctx.$style.tab]: true, [_ctx.$style.active]: index === _ctx.tabIndex }),
          onClick: _withModifiers(($event: any) => (_ctx.onTabClicked($event, index)), ["prevent"]),
          key: index.toString(),
          type: "button",
          textContent: _toDisplayString(configuration.label)
        }, null, 10, _hoisted_1))
      }), 128))
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.content)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentOptions, (option) => {
        return (_openBlock(), _createBlock(_component_x_form_row, {
          key: option.key
        }, {
          default: _withCtx(() => [
            _createVNode(_component_x_checkbox, {
              autocomplete: "really-off",
              label: option.label,
              modelValue: _ctx.currentConfiguration.inputs.options[option.id],
              "onUpdate:modelValue": ($event: any) => ((_ctx.currentConfiguration.inputs.options[option.id]) = $event)
            }, null, 8, ["label", "modelValue", "onUpdate:modelValue"])
          ]),
          _: 2
        }, 1024))
      }), 128)),
      _createVNode(_component_x_form_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_x_input, {
            autocomplete: "really-off",
            class: _normalizeClass(_ctx.$style.remarks),
            multiline: "",
            label: "Bemerkungen",
            layout: "vertical",
            modelValue: _ctx.currentConfiguration.inputs.remarks,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentConfiguration.inputs.remarks) = $event))
          }, null, 8, ["class", "modelValue"])
        ]),
        _: 1
      })
    ], 2)
  ]))
}