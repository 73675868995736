
	import XMainHeader from '@/main/components/XMainHeader.vue'
	import { Inject, Options, Vue } from 'vue-property-decorator'
	import { AuthenticationStatus } from '~/authentication/AuthenticationStatus'
	import Authenticator from '~/authentication/Authenticator'
	import XCallService from '~/components/XCallService.vue'
	import XLoadingAnimation from '~/components/XLoadingAnimation.vue'


	@Options({
		components: { XLoadingAnimation, XCallService, XMainHeader },
		name: 'page-main'
	})
	export default class extends Vue {

		isAuthenticating = false


		@Inject()
		readonly authenticator!: Authenticator


		beforeMount() {
			this.$subscribeTo<AuthenticationStatus>(this.authenticator.status$, status => {
				switch (status.type) {
					case 'authenticated':
						this.isAuthenticating = false
						break

					case 'authenticating':
						this.isAuthenticating = true
						break

					case 'not authenticated':
						this.$router.replace({ name: 'sign-in', query: { next: this.$route.fullPath } })
						break
				}
			})
		}
	}
