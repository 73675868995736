import MCountry from '~/model/MCountry'
import MLocation from '~/model/MLocation'
import { compareBy, sorted, undefinedAsNull } from '~/utility'
import {
	booleanInput,
	compositeInput,
	integerInput,
	phoneNumberInput,
	simpleEnumInput,
	stringInput,
	timeDurationInput,
	triStateInput,
	websiteInput
} from '~/utility/Input'


export default function locationInput(properties?: {
	readonly countries: readonly MCountry[]
	readonly defaultCountryId: string | null
	readonly location?: MLocation | null
}) {
	return compositeInput({
		addressAddition: stringInput(properties?.location?.address?.line2 ?? ''),
		addressCity: stringInput(properties?.location?.address?.city ?? ''),
		addressCountry: simpleEnumInput(
			properties?.location?.address?.country?.code ?? properties?.defaultCountryId ?? null,
			sorted(
				properties?.countries?.map(it => ({ key: it.code, label: it.name })) ?? [],
				compareBy(it => it.label, 'de-DE', { numeric: true, sensitivity: 'base' })
			)
		),
		addressPostalCode: stringInput(properties?.location?.address?.postalCode ?? ''),
		addressStreet: stringInput(properties?.location?.address?.line1 ?? ''),
		approachRemarks: stringInput(properties?.location?.approachRemarks ?? ''),
		capacity: integerInput(properties?.location?.capacity ?? null, { optional: true }),
		commissionRemarks: stringInput(properties?.location?.commissionRemarks ?? ''),
		contactName: stringInput(properties?.location?.contactName ?? ''),
		drivingTime: timeDurationInput(properties?.location?.drivingTime ?? null, { optional: true }),
		drivingTimeAsPerNavigationSoftware: timeDurationInput(properties?.location?.drivingTimeAsPerNavigationSoftware ?? null, { optional: true }),
		emailAddress: stringInput(properties?.location?.emailAddress ?? ''),
		equipmentRemarks: stringInput(properties?.location?.equipmentRemarks ?? ''),
		faxNumber: phoneNumberInput(properties?.location?.faxNumber ?? null),
		internalRemarks: stringInput(properties?.location?.internalRemarks ?? ''),
		isFolderFiled: booleanInput(properties?.location?.isFolderFiled ?? false),
		isRecommendingUs: booleanInput(properties?.location?.isRecommendingUs ?? false),
		mobilePhoneNumber: phoneNumberInput(properties?.location?.mobilePhoneNumber ?? null),
		name: stringInput(properties?.location?.name ?? ''),
		otherRemarks: stringInput(properties?.location?.otherRemarks ?? ''),
		phoneNumber: phoneNumberInput(properties?.location?.phoneNumber ?? null),
		receivesCommission: triStateInput(undefinedAsNull(properties?.location?.receivesCommission)),
		setupRemarks: stringInput(properties?.location?.setupRemarks ?? ''),
		specificsRemarks: stringInput(properties?.location?.specificsRemarks ?? ''),
		timingRemarks: stringInput(properties?.location?.timingRemarks ?? ''),
		websiteUrl: websiteInput(properties?.location?.websiteUrl ?? '')
	})
}
