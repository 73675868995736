
	import { Inject, Options, Vue } from 'vue-property-decorator'
	import { AuthenticationStatus } from '~/authentication/AuthenticationStatus'
	import Authenticator from '~/authentication/Authenticator'
	import XLink from '~/components/XLink.vue'
	import MUser from '~/model/MUser'


	@Options({
		components: { XLink },
		name: 'x-main-header'
	})
	export default class extends Vue {

		private user: MUser | null = null

		@Inject()
		readonly authenticator!: Authenticator


		beforeMount() {
			this.$subscribeTo<AuthenticationStatus>(this.authenticator.status$, status => {
				this.user = status.type === 'authenticated' ? status.user : null
			})
		}


		signOut() {
			// FIXME async
			this.authenticator.signOut()
		}
	}
