import MCommentJournalItem from '~/model/MCommentJournalItem'
import MDocumentCreatedJournalItem from '~/model/MDocumentCreatedJournalItem'
import MProcessCreatedJournalItem from '~/model/MProcessCreatedJournalItem'
import MProcessFileCreatedJournalItem from '~/model/MProcessFileCreatedJournalItem'
import MProcessParticipantAddedJournalItem from '~/model/MProcessParticipantAddedJournalItem'
import MProcessUpdatedJournalItem from '~/model/MProcessUpdatedJournalItem'
import { error } from '~/utility'


export default {

	fieldSelection(): string { // TODO Use named fragments
		return `{
				__typename
				... on CommentJournalItem ${MCommentJournalItem.fieldSelection()}
				... on DocumentCreatedJournalItem ${MDocumentCreatedJournalItem.fieldSelection()}
				... on ProcessCreatedJournalItem ${MProcessCreatedJournalItem.fieldSelection()}
				... on ProcessFileCreatedJournalItem ${MProcessFileCreatedJournalItem.fieldSelection()}
				... on ProcessParticipantAddedJournalItem ${MProcessParticipantAddedJournalItem.fieldSelection()}
				... on ProcessUpdatedJournalItem ${MProcessUpdatedJournalItem.fieldSelection()}
			}`
	},

	parse(json: any) {
		switch (json.__typename) {
			case 'CommentJournalItem':
				return MCommentJournalItem.fromJson(json)

			case 'DocumentCreatedJournalItem':
				return MDocumentCreatedJournalItem.fromJson(json)

			case 'ProcessCreatedJournalItem':
				return MProcessCreatedJournalItem.fromJson(json)

			case 'ProcessFileCreatedJournalItem':
				return MProcessFileCreatedJournalItem.fromJson(json)

			case 'ProcessParticipantAddedJournalItem':
				return MProcessParticipantAddedJournalItem.fromJson(json)

			case 'ProcessUpdatedJournalItem':
				return MProcessUpdatedJournalItem.fromJson(json)

			case undefined:
				return error('Journal item is missing __typename property.')

			default:
				return error(`Unsupported journal item type ${json.__typename}.`)
		}
	}
}
