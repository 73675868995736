import GraphExecution from '@/graphql/GraphExecution'
import GraphOperation from '@/graphql/operations/GraphOperation'
import { lastValueFrom, Observable, Subscriber } from 'rxjs'
import { InjectionKey } from 'vue'
import MError, { MAuthenticationRequiredError } from '~/model/MError'
import { asString, delay } from '~/utility'


const defaultErrorUserMessage = 'Die Kundendatenbank hat einen Fehler oder ist vorübergehend außer Betrieb.'


// TODO Maybe use a full typed Apollo Client with Vue.js integration one day…
export default class GraphClient {

	private readonly baseUrl: string


	constructor(properties: {
		readonly baseUrl: string
	}) {
		this.baseUrl = properties.baseUrl
	}


	private createRequest(operation: GraphOperation<any>): Request {
		const field = `${operation.name}${operation.arguments ? `(${operation.arguments})` : ''} ${operation.fieldSelection}`
		const variables = operation.variables
		const variableNames = Object.keys(variables)
		const variableValues: Record<string, any> = {}

		let variableDefinitions = ''
		if (variableNames.length) {
			variableDefinitions = '(' + variableNames
				.map(variableName => `$${variableName}: ${variables[variableName][0]}`)
				.join(', ') + ')'

			variableNames.forEach(it => variableValues[it] = variables[it][1])
		}

		const body = JSON.stringify({
			query: `${operation.type}${variableDefinitions} {
				${field}
			}`,
			variables: variableValues
		})
		const headers: { [key: string]: string } = { 'Content-Type': 'application/json; charset=utf-8' }

		return new Request(`${this.baseUrl}/graphql`, {
			body,
			headers,
			method: 'post'
		})
	}


	async execute<Data>(operation: GraphOperation<Data>): Promise<Data | MError> {
		const execution = await lastValueFrom(this.execute$(operation))
		if (execution.failed)
			return execution.error

		return execution.data
	}


	execute$<Data>(operation: GraphOperation<Data>): Observable<GraphExecution<Data>> {
		const request = this.createRequest(operation)

		return new Observable<GraphExecution<Data>>(subscriber => {
			const abortController = new AbortController()

			GraphClient.executeSubscription(subscriber, abortController, operation, request).then(null, error => {
				if (error instanceof Error && error.name === 'AbortError') {
					// Subscriber has unsubscribed. Nothing more to do.
					return
				}

				subscriber.error(error)
			})

			return () => abortController.abort()
		})
	}


	private static async executeRequest<Data>(
		request: Request,
		abortSignal: AbortSignal,
		operation: GraphOperation<Data>
	): Promise<{ data?: Data, error?: MError }> {
		// Shouldn't throw any relevant errors except AbortError which has to be caught upstream.
		let response: Response
		try {
			response = await fetch(request, {
				credentials: 'include',
				signal: abortSignal
			})
		}
		catch (e) {
			return {
				error: new MAuthenticationRequiredError({
					cause: e,
					graphOperationName: operation.name,
					isTemporary: true,
					userMessage: defaultErrorUserMessage
				})
			}
		}

		if (!response.ok) {
			if (response.status === 401)
				return {
					error: new MAuthenticationRequiredError({
						developerMessage: 'The server responded with status code 401.',
						graphOperationName: operation.name,
						isTemporary: false,
						userMessage: 'Du bist nicht angemeldet.'
					})
				}

			const isServerSideError = response.status >= 500 && response.status <= 599

			return {
				error: new MError({
					developerMessage: `The server responded with status code ${response.status} (${response.statusText}).`,
					graphOperationName: operation.name,
					isTemporary: isServerSideError,
					userMessage: defaultErrorUserMessage
				})
			}
		}

		let json: any
		try {
			json = await response.json()
		}
		catch (e) {
			console.error(e)

			// TODO Add text response to improve debugging.
			return {
				error: new MError({
					cause: e,
					graphOperationName: operation.name,
					isTemporary: true,
					userMessage: defaultErrorUserMessage
				})
			}
		}

		return GraphClient.parseResponse(json, operation)
	}


	private static async executeSubscription<Data>(
		subscriber: Subscriber<GraphExecution<Data>>,
		abortController: AbortController,
		operation: GraphOperation<Data>,
		request: Request
	) {
		let data: Data | undefined
		let error: MError | undefined
		let keepTrying = operation.type === 'query'

		do {
			subscriber.next(GraphExecution.pending(abortController, error))

			if (error)
				await delay(500 + (Math.random() * 4_500));

			({ data, error } = await GraphClient.executeRequest(request.clone(), abortController.signal, operation))
			if (error && !error.isTemporary)
				keepTrying = false
		}
		while (error && keepTrying)

		if (error)
			subscriber.next(GraphExecution.failed(error))
		else
			subscriber.next(GraphExecution.succeeded(data as Data)) // Known to be `Data` if there is no error.

		subscriber.complete()
	}


	private static parseResponse<Data>(json: any, operation: GraphOperation<Data>): { data?: Data, error?: MError } {
		if (json.errors || !json.data) {
			// TODO Support developer & user message in back-end.
			// TODO Support handling multiple errors.

			const errors = Array.isArray(json.errors) ? json.errors : []
			const error = errors[0] || {}
			const errorMessage = asString(error.message)

			// TODO Add JSON response to improve debugging.
			return {
				// TODO GraphQL error messages should never be presented to users.
				//       All user-relevant messages must either be part of the operation's return type or the 401 error.
				error: new MError({
					developerMessage: errorMessage || 'Invalid GraphQL error response.',
					graphOperationName: operation.name,
					isTemporary: false, // TODO Support in back-end.
					userMessage: errorMessage || defaultErrorUserMessage
				})
			}
		}

		try {
			return { data: operation.parseData(json.data[operation.name]) }
		}
		catch (e) {
			console.error(e)

			// TODO Add JSON response to improve debugging.
			return {
				error: new MError({
					cause: e,
					graphOperationName: operation.name,
					isTemporary: false,
					userMessage: 'Die Kundendatenbank arbeitet nicht korrekt.\n' +
						'Eventuell ist eine neue Version verfügbar und ein erneutes Laden der Seite löst das Problem.\n' +
						'Bleibt das Problem bestehen dann melde es bitte dem zuständigen Techniker.'
				})
			}
		}
	}
}


export const GraphClientInjectionKey: InjectionKey<GraphClient> = Symbol('GraphClient')
