export default class MProcessFileOverviewSource {

	static fieldSelection(): string {
		return `{
			audience
		}`
	}


	readonly audience: 'dj' | 'internal'
	readonly _type = 'overview'


	constructor(properties: {
		readonly audience: 'dj' | 'internal'
	}) {
		this.audience = properties.audience
	}


	static fromJson(json: any): MProcessFileOverviewSource {
		return new this({
			audience: json.audience
		})
	}
}
