import MDjCategory from '~/model/MDjCategory'
import MPhoneNumber from '~/model/MPhoneNumber'


export default class MDj {

	static fieldSelection(): string {
		return `{
			alias
			businessPhoneNumber
			category ${MDjCategory.fieldSelection()}
			emailAddress
			firstName
			homePhoneNumber
			id
			lastName
			mobilePhoneNumber
			remarks
		}`
	}


	readonly alias: string | null
	readonly businessPhoneNumber: MPhoneNumber | null
	readonly category: MDjCategory
	readonly emailAddress: string | null
	readonly firstName: string
	readonly homePhoneNumber: MPhoneNumber | null
	readonly id: string
	readonly lastName: string
	readonly mobilePhoneNumber: MPhoneNumber | null
	readonly remarks: string | null


	constructor(properties: {
		readonly alias: string | null
		readonly businessPhoneNumber: MPhoneNumber | null
		readonly category: MDjCategory
		readonly emailAddress: string | null
		readonly firstName: string
		readonly homePhoneNumber: MPhoneNumber | null
		readonly id: string
		readonly lastName: string
		readonly mobilePhoneNumber: MPhoneNumber | null
		readonly remarks: string | null
	}) {
		this.alias = properties.alias
		this.businessPhoneNumber = properties.businessPhoneNumber
		this.category = properties.category
		this.emailAddress = properties.emailAddress
		this.firstName = properties.firstName
		this.homePhoneNumber = properties.homePhoneNumber
		this.id = properties.id
		this.lastName = properties.lastName
		this.mobilePhoneNumber = properties.mobilePhoneNumber
		this.remarks = properties.remarks
	}


	get name() {
		return `${this.firstName} ${this.lastName}`
	}


	get nameReversed() {
		return `${this.lastName}, ${this.firstName}`
	}


	static fromJson(json: any) {
		return new this({
			alias: json.alias,
			businessPhoneNumber: json.businessPhoneNumber ? MPhoneNumber.parse(json.businessPhoneNumber) : null,
			category: MDjCategory.fromJson(json.category),
			emailAddress: json.emailAddress,
			firstName: json.firstName,
			homePhoneNumber: json.homePhoneNumber ? MPhoneNumber.parse(json.homePhoneNumber) : null,
			id: json.id,
			lastName: json.lastName,
			mobilePhoneNumber: json.mobilePhoneNumber ? MPhoneNumber.parse(json.mobilePhoneNumber) : null,
			remarks: json.remarks
		})
	}
}
