import { toHandlers as _toHandlers, mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["autocomplete", "checked", "disabled", "id", ".indeterminate", "readonly", "required"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classes)
  }, [
    _createElementVNode("label", {
      class: _normalizeClass(_ctx.labelClasses),
      for: _ctx.id
    }, [
      _createElementVNode("input", _mergeProps({
        autocomplete: _ctx.actualAutocomplete,
        checked: _ctx.modelValue.value,
        class: _ctx.inputClasses,
        disabled: _ctx.disabled,
        id: _ctx.id,
        ".indeterminate": _ctx.modelValue.value === null,
        ref: "inputElement",
        readonly: _ctx.readonly,
        required: _ctx.required,
        type: "checkbox"
      }, _toHandlers(_ctx.inputListeners)), null, 16, _hoisted_2),
      _createTextVNode(_toDisplayString(_ctx.label), 1)
    ], 10, _hoisted_1)
  ], 2))
}