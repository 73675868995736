
	import { computed, defineComponent, ref, useCssModule } from 'vue'
	import { useRoute, useRouter } from 'vue-router'
	import { isSameRoute } from '~/utility'


	export default defineComponent({
		props: {
			activeClass: { type: String, default: undefined },
			activeDisabled: { type: Boolean, default: false },
			disabled: { type: Boolean, default: false },
			href: { type: String, default: undefined },
			keepFocus: { type: Boolean, default: false },
			rel: { type: String, default: undefined },
			tabIndex: { type: [Number, String], default: undefined },
			target: { type: String, default: undefined },
			text: { type: String, default: undefined },
			to: { type: [Object, String], default: undefined }
		},
		emits: {
			click: (event: MouseEvent) => true
		},
		setup(props, { emit }) {
			const $route = useRoute()
			const $router = useRouter()
			const $style = useCssModule()

			const rootNodeRef = ref<HTMLAnchorElement>()

			const classesRef = computed(() => {
				const classes = [$style.root]
				if (isDisabledRef.value)
					classes.push($style.disabled)
				if (props.activeClass && isActiveRef.value)
					classes.push(props.activeClass)

				return classes
			})
			const currentHrefRef = computed(() => $router.resolve($route, $route).href)
			const hrefRef = computed(() => props.href || (props.to === undefined ? undefined : $router.resolve(props.to, $route).href))
			const isActiveRef = computed(() => {
				if (props.to)
					return isSameRoute($route, props.to, $router)

				return currentHrefRef.value === props.href
			})
			const isDisabledRef = computed(() => props.disabled || (props.activeDisabled && isActiveRef.value))
			const tabIndexRef = computed(() => {
				if (props.tabIndex !== undefined)
					return props.tabIndex
				if (isDisabledRef.value)
					return -1
				if (!hrefRef.value)
					return 0

				return undefined
			})


			function onClick(event: MouseEvent) {
				// use browser default behavior
				if (hrefRef.value && (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey || event.button))
					return

				// use browser default behavior
				if (props.target && props.target !== 'self')
					return

				if (!props.keepFocus)
					rootNodeRef.value?.blur()

				if (props.to !== undefined) {
					event.preventDefault()

					$router.push(props.to).catch(() => {})
				}

				emit('click', event)
			}


			return {
				classes: classesRef,
				hrefValue: hrefRef,
				onClick,
				tabIndexValue: tabIndexRef,
				rootNodeRef
			}
		}
	})
