
	/* eslint-disable vue/no-dupe-keys */
	import { computed, defineComponent, onMounted, PropType, ref, useCssModule, watch } from 'vue'
	import { Input } from '~/components/input/Input'
	import { Defined, randomId } from '~/utility'


	// TODO Make generic once solved: https://github.com/vuejs/vue-next/issues/3102
	// TODO For 'autocapitalize' and 'spellcheck' type warnings in IntelliJ IDEA see https://youtrack.jetbrains.com/issue/WEB-32256.
	// TODO For missing 'model-value' attribute warning in IntelliJ IDEA see https://youtrack.jetbrains.com/issue/WEB-50085.
	export default defineComponent({
		props: {
			autocapitalize: { type: String as PropType<'characters' | 'none' | 'off' | 'on' | 'sentences' | 'words' | undefined>, default: undefined },
			autocomplete: { type: String, default: 'off' }, // Opt-in.
			autocorrect: { type: String, default: undefined },
			autofocus: Boolean,
			disabled: Boolean,
			flexible: { type: String as PropType<'input' | 'label' | 'no'>, default: 'label' },
			inputmode: { type: String, default: undefined },
			label: { type: String, default: undefined },
			labelNoColon: Boolean,
			labelSide: { type: String as PropType<'left' | 'right'>, default: 'left' },
			layout: { type: String as PropType<'horizontal' | 'inline' | 'vertical'>, default: 'horizontal' },
			max: { type: String, default: undefined }, // TODO Move to definition.
			min: { type: String, default: undefined }, // TODO Move to definition.
			modelValue: {
				required: true,
				type: null as unknown as PropType<Input<Defined, string>>,
				validator: (value: any) => Boolean(value && value.definition)
			},
			noChangeHighlight: Boolean,
			placeholder: { type: String, default: undefined },
			readonly: Boolean,
			rows: { type: Number, default: undefined },
			spellcheck: { type: String as PropType<'' | 'false' | 'true' | undefined>, default: undefined },
			step: { type: String, default: undefined }, // TODO Move to definition.
			tabindex: { type: Number, default: undefined }
		},
		emits: {
			blur: null,
			focus: null,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			'update:modelValue': (_: Defined | undefined) => true
		},
		setup(props, { emit, slots }) {
			const style = useCssModule()
			const id = randomId()

			const autocompleteRef = computed(() => props.autocomplete === 'off' ? id : props.autocomplete) // Chrome hack. Often ignores 'off'.
			const definitionRef = computed(() => props.modelValue.definition)
			const hasChangesRef = computed(() => // TODO Might be odd for forms that are initially part undefined (required) and null (not required).
				initialValueRef.value !== undefined && !definitionRef.value.isEqualValueOrUndefined(initialValueRef.value, valueRef.value)
			)
			const hasLabelRef = computed(() => Boolean(props.label || slots.label))
			const initialValueRef = computed(() => props.modelValue.initialValue)
			const inputElementRef = ref<HTMLInputElement>()
			const isEmptyRef = computed(() => serializedValueRef.value === '')
			const isFocusedRef = ref(false)
			const isMultilineRef = computed(() => definitionRef.value.hints.multiline ?? false)
			const isRequiredRef = computed(() => definitionRef.value.hints.required)
			const isVisuallyInvalidRef = computed(() => !isFocusedRef.value && valueRef.value === undefined && serializedValueRef.value !== '')
			const layoutRef = computed(() => (hasLabelRef.value || props.layout === 'inline') ? props.layout : 'standalone')
			const maxlengthRef = computed(() => definitionRef.value.hints.maximumLength)
			const serializedValueRef = ref('')
			const typeRef = computed(() => definitionRef.value.hints.htmlType ?? 'text')
			const valueRef = computed(() => props.modelValue.value)

			const classesRef = computed(() => [
				style.root,
				style[`root-${layoutRef.value}`],
				style[`root-label-${props.labelSide}`]
			])

			const inputClassesRef = computed(() => {
				const classes = [
					style.input,
					style[`input-${layoutRef.value}`],
					isMultilineRef.value ? style['input-multiline'] : style['input-singleline']
				]

				if (props.flexible === 'input')
					classes.push(style['input-flexible'])
				if (!props.noChangeHighlight && hasChangesRef.value)
					classes.push(style.changed)
				if (props.disabled)
					classes.push(style.disabled)

				if (isVisuallyInvalidRef.value)
					classes.push(style.invalid)
				else if (isEmptyRef.value)
					classes.push(style.empty)

				return classes
			})

			const labelClassesRef = computed(() => {
				const classes = [
					style.label,
					style[`label-${layoutRef.value}`],
					style[`label-${props.labelSide}`]
				]
				if (props.flexible === 'label')
					classes.push(style['label-flexible'])
				if (!props.noChangeHighlight && hasChangesRef.value)
					classes.push(style.changed)
				if (props.disabled)
					classes.push(style.disabled)
				if (isVisuallyInvalidRef.value)
					classes.push(style.invalid)

				return classes
			})

			watch([definitionRef, valueRef], ([definition, value]) => {
				const oldValue = definition.parseValueOrUndefined(serializedValueRef.value)
				if (!definition.isEqualValueOrUndefined(oldValue, value))
					serializedValueRef.value = value === undefined ? '' : definition.serializeValue(value)
			}, { immediate: true })

			onMounted(() => {
				if (props.autofocus && inputElementRef.value)
					inputElementRef.value.focus()
			})


			function onBlur() {
				isFocusedRef.value = false

				const value = definitionRef.value.parseValueOrUndefined(serializedValueRef.value)
				if (value !== undefined)
					serializedValueRef.value = definitionRef.value.serializeValue(value)
				else if (serializedValueRef.value !== '' && serializedValueRef.value.trim() === '')
					serializedValueRef.value = ''

				updateValue(value)

				emit('blur')
			}

			function onFocus() {
				isFocusedRef.value = true

				emit('focus')
			}

			function onInput() {
				const element = inputElementRef.value
				if (!element)
					return

				const validity = element.validity
				const isConsideredInvalidByBrowser = Boolean(element.value && validity && (validity.badInput || validity.typeMismatch))

				updateSerializedValue(element.value, isConsideredInvalidByBrowser)
			}

			function updateSerializedValue(serializedValue: string, isInvalid: boolean) {
				const definition = definitionRef.value

				let newValue: Defined | undefined
				if (!isInvalid) {
					const result = definition.parseValue(serializedValue)
					if ('value' in result)
						newValue = result.value
				}

				serializedValueRef.value = serializedValue

				updateValue(newValue)
			}

			function updateValue(value: Defined | undefined) {
				if (definitionRef.value.isEqualValueOrUndefined(valueRef.value, value))
					return

				emit('update:modelValue', props.modelValue.withValue(value))
			}

			return {
				acceptAutofill() {
					// re-assigning unsets autofill state
					const inputElement = inputElementRef.value
					if (inputElement)
						inputElement.value = inputElement.value as string
				},
				autocapitalize: props.autocapitalize,
				autocomplete: autocompleteRef,
				autocorrect: props.autocorrect,
				autofocus: props.autofocus,
				classes: classesRef,
				disabled: props.disabled,
				// eslint-disable-next-line no-undef
				focus(options?: FocusOptions) {
					inputElementRef.value?.focus(options)
				},
				hasLabel: hasLabelRef,
				id,
				isMultiline: isMultilineRef,
				isRequired: isRequiredRef,
				inputClasses: inputClassesRef,
				inputElement: inputElementRef,
				labelClasses: labelClassesRef,
				maxlength: maxlengthRef,
				onBlur,
				onFocus,
				onInput,
				select() {
					inputElementRef.value?.select()
				},
				serializedValue: serializedValueRef,
				setSelectionRange(start: number, end: number, direction?: 'forward' | 'backward' | 'none') {
					inputElementRef.value?.setSelectionRange(start, end, direction)
				},
				type: typeRef
			}
		}
	})
