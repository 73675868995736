import moment from 'moment'


export default class MEventCompensation {

	static fieldSelection(): string {
		return `{
			baseFee
			baseHours
			customerPrice
			customerPriceRemarks
			defaultBaseFee
			defaultBaseHours
			drivingTime
			drivingTimeCompensation
			lightDetails
			lightPrice
			miscLabel1
			miscLabel2
			miscLabel3
			miscPrice1
			miscPrice2
			miscPrice3
			overtimeUnits
			overtimeFee
			remarks
			soundDetails
			soundPrice
		}`
	}


	readonly baseFee: number
	readonly baseHours: number
	readonly customerPrice: number
	readonly customerPriceRemarks: string | null
	readonly defaultBaseFee: number | null
	readonly defaultBaseHours: number | null
	readonly drivingTime: number | null
	readonly drivingTimeCompensation: number
	readonly lightDetails: string
	readonly lightPrice: number
	readonly miscLabel1: string | null
	readonly miscLabel2: string | null
	readonly miscLabel3: string | null
	readonly miscPrice1: number | null
	readonly miscPrice2: number | null
	readonly miscPrice3: number | null
	readonly overtimeUnits: number
	readonly overtimeFee: number
	readonly remarks: string | null
	readonly soundDetails: string | null
	readonly soundPrice: number


	constructor(properties: {
		readonly baseFee: number
		readonly baseHours: number
		readonly customerPrice: number
		readonly customerPriceRemarks: string | null
		readonly defaultBaseFee: number | null
		readonly defaultBaseHours: number | null
		readonly drivingTime: number | null
		readonly drivingTimeCompensation: number
		readonly lightDetails: string
		readonly lightPrice: number
		readonly miscLabel1: string | null
		readonly miscLabel2: string | null
		readonly miscLabel3: string | null
		readonly miscPrice1: number | null
		readonly miscPrice2: number | null
		readonly miscPrice3: number | null
		readonly overtimeUnits: number
		readonly overtimeFee: number
		readonly remarks: string | null
		readonly soundDetails: string | null
		readonly soundPrice: number
	}) {
		this.baseFee = properties.baseFee
		this.baseHours = properties.baseHours
		this.customerPrice = properties.customerPrice
		this.customerPriceRemarks = properties.customerPriceRemarks
		this.defaultBaseFee = properties.defaultBaseFee
		this.defaultBaseHours = properties.defaultBaseHours
		this.drivingTime = properties.drivingTime
		this.drivingTimeCompensation = properties.drivingTimeCompensation
		this.lightDetails = properties.lightDetails
		this.lightPrice = properties.lightPrice
		this.miscLabel1 = properties.miscLabel1
		this.miscLabel2 = properties.miscLabel2
		this.miscLabel3 = properties.miscLabel3
		this.miscPrice1 = properties.miscPrice1
		this.miscPrice2 = properties.miscPrice2
		this.miscPrice3 = properties.miscPrice3
		this.overtimeUnits = properties.overtimeUnits
		this.overtimeFee = properties.overtimeFee
		this.remarks = properties.remarks
		this.soundDetails = properties.soundDetails
		this.soundPrice = properties.soundPrice
	}


	static fromJson(json: any): MEventCompensation {
		return new this({
			baseFee: json.baseFee / 100,
			baseHours: moment.duration(json.baseHours).asSeconds(),
			customerPrice: json.customerPrice / 100,
			customerPriceRemarks: json.customerPriceRemarks,
			defaultBaseFee: json.defaultBaseFee ? (json.defaultBaseFee / 100) : null,
			defaultBaseHours: json.defaultBaseHours ? moment.duration(json.defaultBaseHours).asSeconds() : null,
			drivingTime: json.drivingTime ? moment.duration(json.drivingTime).asSeconds() : null,
			drivingTimeCompensation: json.drivingTimeCompensation / 100,
			lightDetails: json.lightDetails,
			lightPrice: json.lightPrice / 100,
			miscLabel1: json.miscLabel1,
			miscLabel2: json.miscLabel2,
			miscLabel3: json.miscLabel3,
			miscPrice1: json.miscPrice1 ? json.miscPrice1 / 100 : json.miscPrice1,
			miscPrice2: json.miscPrice2 ? json.miscPrice2 / 100 : json.miscPrice2,
			miscPrice3: json.miscPrice3 ? json.miscPrice3 / 100 : json.miscPrice3,
			overtimeUnits: json.overtimeUnits,
			overtimeFee: json.overtimeFee / 100,
			remarks: json.remarks,
			soundDetails: json.soundDetails,
			soundPrice: json.soundPrice / 100
		})
	}
}
