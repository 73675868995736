
	import { Options, Prop, Vue } from 'vue-property-decorator'
	import { ApiError, ApiResponse } from '~/api/Api'
	import XLoadingAnimation from '~/components/XLoadingAnimation.vue'
	import { error } from '~/utility'


	@Options({
		components: { XLoadingAnimation },
		name: 'x-main-page-content-old'
	})
	export default class XMainPageContentOld extends Vue {

		@Prop({ type: [Array, Error, Object] }) readonly apiResponse?: ApiResponse<any> | (ApiResponse<any> | null)[] | null
		@Prop() readonly isBusy!: boolean


		get error(): ApiError {
			const input = this.apiResponse ?? error()

			if (Array.isArray(input)) {
				for (const response of input) {
					if (response instanceof ApiError) {
						return response
					}
				}

				error()
			}
			else {
				return input.asError() ?? error()
			}
		}


		retryButtonClicked() {
			window.location.reload()
		}


		get state() {
			const input = this.apiResponse
			if (!input) {
				return 'loading'
			}
			if (input instanceof ApiError) {
				return 'error'
			}

			if (Array.isArray(input)) {
				let isLoading = false

				for (const response of input) {
					if (!response) {
						isLoading = true
					}
					else if (response instanceof ApiError) {
						return 'error'
					}
				}

				if (isLoading) {
					return 'loading'
				}
			}

			return 'result'
		}
	}
