
	import { Options, Vue } from 'vue-property-decorator'
	import XLink from '~/components/XLink.vue'
	import configuration from '~/configuration'
	import { error } from '~/utility'


	@Options({
		components: { XLink },
		name: 'x-call-service'
	})
	export default class extends Vue {

		readonly hubUrl = configuration.callService.hubUrl
		readonly showsNotifications = (document.location.hostname === configuration.callService.notificationHost)

		activeCall: CallState | null = null
		commandCallbacks: { [commandId: string]: (errorMessage: string | null) => void } = {}
		isRegistered = false
		isStarted = false
		isStartingCall = false
		lastNotificationCallId: string | null = null
		notification: Notification | null = null
		socket: WebSocket | null = null
		statusMessage: string | null = null


		get activeCallForwarder() {
			return this.activeCall ? (this.activeCall.forwardingName || this.activeCall.forwardingNumber) : null
		}


		connect() {
			if (this.socket !== null) {
				return
			}

			const socket = new WebSocket(this.hubUrl)
			socket.onclose = this.socketDidClose.bind(this)
			socket.onopen = this.socketDidOpen.bind(this)
			socket.onmessage = this.socketDidReceiveMessage.bind(this)

			this.socket = socket
		}


		completeCommand(commandId: string, errorMessage: string | null) {
			const callback = this.commandCallbacks[commandId]
			delete this.commandCallbacks[commandId]

			if (callback) {
				callback(errorMessage)
			}
		}


		destroyed() {
			this.stop()
			this.unregisterPhoneNumberLinkClickHandler()
		}


		didChangeCallState(callState: CallState | null) {
			this.activeCall = callState

			if (callState) {
				if (this.showsNotifications && callState.isIncoming) {
					this.showNotification(callState.callId, callState.phoneNumber || null, callState.contacts)
				}
			}
			else {
				this.hideNotification()
			}
		}


		disconnect() {
			if (!this.socket) {
				return
			}

			this.socket.close()
		}


		hideNotification() {
			const notification = this.notification
			if (notification) {
				notification.close()
				this.notification = null
			}
		}


		mounted() {
			this.registerPhoneNumberLinkClickHandler()
			this.start()

			if (this.showsNotifications) {
				Notification.requestPermission()
			}
		}


		phoneNumberLinkClickHandler(event: MouseEvent) {
			if (event.defaultPrevented) {
				return
			}

			const target = event.target as HTMLBodyElement
			const link = target.closest('a')
			if (!link) {
				return
			}

			const url = link.getAttribute('href') || ''
			if (!url.startsWith('tel:')) {
				return
			}

			const phoneNumber = decodeURIComponent(url.replace(/^tel:\/*/i, '')).trim()
			if (!phoneNumber) {
				return
			}

			event.stopPropagation()
			event.preventDefault()

			this.startCall(phoneNumber)
		}


		registerLocalAddresses() {
			if (this.socket === null || this.socket.readyState !== WebSocket.OPEN) {
				return
			}

			this.sendCommand('register', { ipAddresses: ['automatic'] }, errorMessage => {
				if (!errorMessage) {
					this.isRegistered = true
				}
			})
		}


		registerPhoneNumberLinkClickHandler() {
			document.body.addEventListener('click', this.phoneNumberLinkClickHandler)
		}


		sendCommand(command: string, parameters: { [name: string]: any }, callback: (errorMessage: string | null) => void) {
			if (!this.socket) {
				return error('Cannot send without connection')
			}

			const id = +new Date() + '.' + Math.random()
			if (callback) {
				this.commandCallbacks[id] = callback
			}

			this.socket.send(JSON.stringify({
				command: command,
				id: id,
				parameters: parameters
			}))
		}


		showNotification(callId: string, phoneNumber: string | null, contacts: Contact[]) {
			if (callId === this.lastNotificationCallId) {
				return
			}

			this.lastNotificationCallId = callId

			if (!this.storeNotificationStatusForCallId(callId)) {
				return
			}

			let action: () => void
			let body: string
			let callerName: string

			if (contacts.length === 0) {
				action = window.focus.bind(window)
				body = 'Kein Kunde zu dieser Nummer gefunden.'
				callerName = phoneNumber || 'Unterdrückte Nummer'
			}
			else if (contacts.length === 1) {
				const contact = contacts[0]
				const contactURL = 'kunde.php?get_do=zeige&get_id=' + contact.id

				action = window.open.bind(window, contactURL, '_blank')
				body = 'Hier klicken für Details.'
				callerName = contact.name
			}
			else {
				action = window.focus.bind(window)
				body = 'Mehrere Kunden haben diese Nummer.'
				callerName = phoneNumber || 'Unterdrückte Nummer'
			}

			const notification = new Notification(`${callerName} ruft an!`, {
				body: body,
				renotify: true,
				requireInteraction: true,
				tag: 'callService'
			})

			notification.onclick = (event) => {
				const notification = event.target as Notification
				notification.close()

				this.notification = null

				action()
			}
			notification.onerror = () => {
				this.notification = null
			}

			this.notification = notification
		}


		socketDidClose() {
			const commandCallbacks = this.commandCallbacks

			this.commandCallbacks = {}
			this.socket = null

			if (this.isStarted) {
				setTimeout(this.connect.bind(this), 10_000)
			}

			for (const commandId of Object.keys(commandCallbacks)) {
				commandCallbacks[commandId](null)
			}
		}


		socketDidOpen() {
			this.registerLocalAddresses()
		}


		socketDidReceiveMessage(event: MessageEvent) {
			let command: (() => void) | undefined

			try {
				const data = JSON.parse(event.data)
				switch (data && data.command) {
					case 'callStateChanged':
						command = this.didChangeCallState.bind(this, data.parameters.callState || null)
						break

					case 'commandCompleted':
						this.completeCommand(data.parameters.commandId, null)
						break

					case 'errorOccurred':
						this.completeCommand(data.parameters.commandId, data.parameters.message)
						break
				}
			}
			catch (error) {
				console.error('Call service socket sent wrong data: ', event.data)
				this.disconnect()
			}

			if (command) {
				command()
			}
		}


		start() {
			if (this.isStarted) {
				return
			}

			this.isStarted = true

			this.connect()
		}


		startCall(phoneNumber: string) {
			if (this.isStartingCall) {
				return
			}
			if (!this.isRegistered) {
				alert('Das System zum Starten von Anrufen ist zur Zeit leider nicht erreichbar.')
				return
			}

			this.isStartingCall = true
			this.statusMessage = 'Starte Anruf\u2026'

			this.sendCommand('call', { phoneNumber: phoneNumber }, errorMessage => {
				this.isStartingCall = false
				this.statusMessage = null

				if (errorMessage) {
					alert(errorMessage)
				}
			})
		}


		stop() {
			if (!this.isStarted) {
				return
			}

			this.isStarted = false

			this.disconnect()
		}


		storeNotificationStatusForCallId(callId: string) {
			const latestNotificationCallIds = JSON.parse(localStorage.getItem('callService.latestNotificationCallIds') || '[]') || []
			if (latestNotificationCallIds.indexOf(callId) >= 0) {
				return false
			}

			latestNotificationCallIds.push(callId)
			if (latestNotificationCallIds.length > 5) {
				latestNotificationCallIds.shift()
			}

			localStorage.setItem('callService.latestNotificationCallIds', JSON.stringify(latestNotificationCallIds))

			return true
		}


		unregisterPhoneNumberLinkClickHandler() {
			document.body.removeEventListener('click', this.phoneNumberLinkClickHandler)
		}
	}


	interface CallState {

		callId: string
		contacts: Contact[]
		forwardingName?: string | null
		forwardingNumber?: string | null
		isAnswered: boolean
		isIncoming: boolean
		phoneNumber?: string | null
	}


	interface Contact {
		id: number
		latestProcessId?: string | null
		name: string
	}
