import MEntity from '~/model/MEntity'
import MEvent from '~/model/MEvent'
import MProcess from '~/model/MProcess'
import MProcessFile from '~/model/MProcessFile'
import { error } from '~/utility'


export default function MEntityParser(json: any): MEntity {
	switch (json.__typename) {
		case 'Event':
			return MEvent.fromJson(json)

		case 'Process':
			return MProcess.fromJson(json)

		case 'ProcessFile':
			return MProcessFile.fromJson(json)

		case undefined:
			return error('Entity is missing __typename property.')

		default:
			return error(`Unsupported entity type ${json.__typename}.`)
	}
}
