// import 'reflect-metadata'
import { Vue } from 'vue-property-decorator'


// Make sure that this file is imported before all other imports.
// Esp. `Component.registerHooks()` demands that it's called before components are defined.

// Vue.config.productionTip = false
//
// Vue.use(VueRouter)
// Vue.use(VueRx)
// Vue.use(VueCompositionApi)

Vue.registerHooks([
	'beforeRouteEnter',
	'beforeRouteLeave',
	'beforeRouteUpdate'
])
