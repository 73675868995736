export default class MWeddingFair {

	static fieldSelection(): string {
		return `{
			id
			name
		}`
	}


	readonly id: string
	readonly name: string


	constructor(properties: {
		readonly id: string
		readonly name: string
	}) {
		this.id = properties.id
		this.name = properties.name
	}


	static fromJson(json: any): MWeddingFair {
		return new this({
			id: json.id,
			name: json.name
		})
	}
}
