import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
    class: _normalizeClass(_ctx.classes),
    onClick: _ctx.onClicked,
    disabled: _ctx.disabledAttribute,
    href: _ctx.hrefAttribute,
    ref: "rootNode",
    rel: _ctx.isButton ? null : _ctx.rel,
    target: _ctx.isButton ? null : _ctx.target,
    tabindex: _ctx.tabIndexAttribute,
    type: _ctx.isButton ? 'button' : undefined
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["class", "onClick", "disabled", "href", "rel", "target", "tabindex", "type"]))
}