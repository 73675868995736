export default class MIntRange {

	static fieldSelection(): string {
		return `{
			endInclusive
			start
		}`
	}


	readonly endInclusive: number
	readonly start: number


	constructor(properties: {
		readonly endInclusive: number
		readonly start: number
	}) {
		this.endInclusive = properties.endInclusive
		this.start = properties.start
	}


	static fromJson(json: any): MIntRange {
		return new this({
			endInclusive: json.endInclusive,
			start: json.start
		})
	}
}
