
	import { Options, Prop, Vue } from 'vue-property-decorator'
	import { Defined, randomId } from '~/utility'
	import { stringInput, TextInput } from '~/utility/Input'


	// TODO Deprecated. Do not use for new code.
	@Options({ name: 'x-input' })
	export default class XInput<Value extends Defined> extends Vue {

		readonly id = randomId()

		isFocused = false

		@Prop() autocapitalize?: 'characters' | 'none' | 'off' | 'on' | 'sentences' | 'words'
		@Prop() autocomplete?: string
		@Prop() autocorrect?: string
		@Prop({ type: Boolean }) autofocus?: boolean
		@Prop({ type: Boolean }) disabled?: boolean
		@Prop({ default: 'label' }) flexible!: 'input' | 'label' | 'no'
		@Prop() inputmode?: string
		@Prop() label?: string
		@Prop({ default: false, type: Boolean }) labelNoColon?: boolean
		@Prop({ default: 'left' }) labelSide?: 'left' | 'right'
		@Prop({ default: 'horizontal' }) layout?: 'horizontal' | 'inline' | 'vertical'
		@Prop() max?: string
		@Prop() maxlength?: number
		@Prop() min?: string
		@Prop({ type: Boolean }) multiline?: boolean
		@Prop({ type: Boolean }) noChangeHighlight?: boolean
		@Prop({ type: Boolean }) noTrim?: boolean
		@Prop() placeholder?: string
		@Prop({ type: Boolean }) readonly?: boolean
		@Prop({ type: Boolean }) required?: boolean
		@Prop() rows?: number
		@Prop() spellcheck?: '' | 'false' | 'true'
		@Prop() step?: string
		@Prop() tabindex?: string
		@Prop({ default: 'text' }) type!: string

		@Prop({
			default: () => stringInput(''),
			type: null as any
		})
		modelValue!: TextInput<Value>


		private get inputElement(): HTMLInputElement | undefined {
			return this.$refs.inputElement as HTMLInputElement | undefined
		}


		acceptAutofill() {
			if (this.inputElement) {
				// re-assigning unsets autofill state
				this.inputElement.value = this.inputElement.value as string
			}
		}


		get actualAutocomplete() {
			return this.autocomplete === 'really-off' ? this.id : this.autocomplete
		}


		get actualLayout() {
			return (this.hasLabel || this.layout === 'inline') ? this.layout : 'standalone'
		}


		get classes() {
			return [
				this.$style.root,
				this.$style[`root-${this.actualLayout}`],
				this.$style[`root-label-${this.labelSide}`]
			]
		}


		private emitInput(input: TextInput<Value>) {
			this.$emit('update:modelValue', input)
		}


		focus(options?: FocusOptions) {
			if (this.inputElement) {
				this.inputElement.focus(options)
			}
		}


		get hasChanges() {
			return !this.modelValue.isInitial()
		}


		get hasLabel() {
			return Boolean(this.label || this.$slots.label)
		}


		get inputClasses() {
			const classes = [
				this.$style.input,
				this.$style[`input-${this.actualLayout}`]
			]
			if (this.flexible === 'input') {
				classes.push(this.$style['input-flexible'])
			}
			if (this.multiline) {
				classes.push(this.$style['input-multiline'])
			}
			else {
				classes.push(this.$style['input-singleline'])
			}
			if (!this.noChangeHighlight && this.hasChanges) {
				classes.push(this.$style.changed)
			}
			if (this.disabled) {
				classes.push(this.$style.disabled)
			}

			if (this.isInvalid) {
				classes.push(this.$style.invalid)
			}
			else if (this.isEmpty) {
				classes.push(this.$style.empty)
			}

			return classes
		}


		get inputListeners() {
			return {
				...this.$listeners,
				blur: this.onBlurred,
				focus: this.onFocused,
				input: this.onInput
			}
		}


		get isEmpty() {
			return !this.modelValue.text
		}


		get isInvalid() {
			return !this.modelValue.isValid()
		}


		get labelClasses() {
			const classes = [
				this.$style.label,
				this.$style[`label-${this.layout}`],
				this.$style[`label-${this.labelSide}`]
			]
			if (this.flexible === 'label') {
				classes.push(this.$style['label-flexible'])
			}
			if (!this.noChangeHighlight && this.hasChanges) {
				classes.push(this.$style.changed)
			}
			if (this.disabled) {
				classes.push(this.$style.disabled)
			}
			if (this.isInvalid) {
				classes.push(this.$style.invalid)
			}

			return classes
		}


		mounted() {
			if (this.autofocus && this.inputElement) {
				this.inputElement.focus()
			}
		}


		private onBlurred(event: FocusEvent) {
			this.isFocused = false

			if (this.modelValue.isValid()) {
				this.onTextChanged(this.modelValue.converter.textForValue(this.modelValue.value), false)
			}

			this.$emit('blur', event)
		}


		private onFocused(event: FocusEvent) {
			this.isFocused = true

			this.$emit('focus', event)
		}


		onInput() {
			const element = this.inputElement
			if (element) {
				const validity = element.validity
				const isConsideredInvalidByBrowser = Boolean(element.value && validity && (validity.badInput || validity.typeMismatch))

				this.onTextChanged(element.value, isConsideredInvalidByBrowser)
			}
		}


		onTextChanged(text: string, isInvalid: boolean) {
			let value: Value | undefined
			if (isInvalid) {
				value = undefined
			}
			else if (text === this.modelValue.text && this.modelValue.isValid()) {
				return
			}
			else {
				value = this.modelValue.converter.valueForText(this.noTrim ? text : text.trim())
			}

			const data = value === undefined ? this.modelValue.withInvalidText(text) : this.modelValue.withValueAndText(value, text)
			if (data === this.modelValue) {
				return
			}

			this.emitInput(data)
		}


		select() {
			if (this.inputElement) {
				this.inputElement.select()
			}
		}


		setSelectionRange(start: number, end: number, direction?: 'forward' | 'backward' | 'none') {
			if (this.inputElement) {
				this.inputElement.setSelectionRange(start, end, direction)
			}
		}
	}
