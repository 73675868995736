
	import XCurrentProcessBar from '@/main/components/XCurrentProcessBar.vue'
	import XMainPageContentOld from '@/main/components/XMainPageContentOld.vue'
	import { Inject, Options, Vue, Watch } from 'vue-property-decorator'
	import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
	import Api, { ApiError, ApiResponse, ApiResult, CreateProcessResult, FetchRequestResult } from '~/api/Api'
	import XFormColumn from '~/components/XFormColumn.vue'
	import XFormRow from '~/components/XFormRow.vue'
	import XInput from '~/components/XInput.vue'
	import XLink from '~/components/XLink.vue'
	import XLoadingAnimation from '~/components/XLoadingAnimation.vue'
	import XProcessId from '~/components/XProcessId.vue'
	import XSection from '~/components/XSection.vue'
	import XSelect from '~/components/XSelect.vue'
	import XStatusMessage from '~/components/XStatusMessage.vue'
	import MContact from '~/model/MContact'
	import MEventOccasion from '~/model/MEventOccasion'
	import MLocalDate from '~/model/MLocalDate'
	import MProcess from '~/model/MProcess'
	import MProcessParticipationTag from '~/model/MProcessParticipationTag'
	import MRequest from '~/model/MRequest'
	import MSalutation from '~/model/MSalutation'
	import { asString, compareBy, error, randomId, reverseOrder, sorted } from '~/utility'
	import { compositeInput, localDateInput, simpleEnumInput, stringInput } from '~/utility/Input'


	@Options({
		components: {
			XLoadingAnimation,
			XCurrentProcessBar,
			XFormColumn,
			XFormRow,
			XInput,
			XLink,
			XMainPageContentOld,
			XProcessId,
			XSection,
			XSelect,
			XStatusMessage
		},
		name: 'page-main-request-import'
	})
	export default class extends Vue {

		readonly contactInputName = randomId()
		readonly discardConfirmationMessage = 'Du hast Daten geändert aber noch nicht gespeichert.\nWillst du wirklich fortfahren?'
		readonly locationInputName = randomId()

		apiResponse: ApiResponse<FetchRequestResult> | null = null
		contactInputValue = ''
		createdContact: MContact | null = null
		creationResponse: ApiResponse<CreateProcessResult> | null = null
		input = this.makeInput()
		isBusy = false
		locationInputValue = ''
		processesOnDate: readonly MProcess[] | null = null
		processesOnDateBasis: MLocalDate | null = null
		processesOnDateIsLoading = false
		updatedProcess?: MProcess | null

		@Inject() readonly api!: Api


		beforeRouteLeave(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
			next(this.confirmDiscardingChanges())
		}


		beforeRouteUpdate(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
			if (!this.confirmDiscardingChanges()) {
				next(false)
				return
			}

			this.reload(to)
			next()
		}


		confirmDiscardingChanges() {
			if (!this.hasChanges) {
				return true
			}

			return confirm(this.discardConfirmationMessage)
		}


		created() {
			this.reload(this.$route)
		}


		get creationResult() {
			return this.creationResponse instanceof ApiResult ? this.creationResponse : null
		}


		data() {
			return {
				updatedProcess: undefined
			}
		}


		destroyed() {
			window.removeEventListener('beforeunload', this.onWindowBeforeUnload)
		}


		get formattedAddress() {
			return this.request?.address?.format(true) ?? ''
		}


		get hasChanges() {
			return !this.input.isInitial()
		}


		makeInput(properties?: {
			eventTypeOptions: readonly MEventOccasion[]
			participantRoleOptions: readonly MProcessParticipationTag[]
			request: MRequest
			salutationOptions: readonly MSalutation[]
		}) {
			let eventDateDetails = ''
			if (properties) {
				eventDateDetails = `${properties.request.eventWeekday ?? ''} ${properties.request.eventSeason ?? ''}`.trim()
			}

			return compositeInput({
				eventDate: localDateInput(properties?.request?.eventDate ?? null),
				eventDateDetails: stringInput(eventDateDetails),
				eventType: simpleEnumInput(
					properties?.request?.eventOccasion?.id ?? null,
					sorted(
						properties?.eventTypeOptions?.map(it => ({ key: it.id, label: it.label })) ?? [],
						compareBy(it => it.label, 'de-DE', { sensitivity: 'base' })
					)
				),
				eventTypeDetails: stringInput(properties?.request?.eventOccasionDetails ?? ''),
				participantRole: simpleEnumInput( // FIXME multi-selection
					null,
					sorted(
						properties?.participantRoleOptions
							?.filter(it => !it.archivalTimestamp)
							?.map(it => ({ key: it.id, label: it.label })) ?? [],
						compareBy(it => it.label, 'de-DE', { sensitivity: 'base' })
					)
				),
				remarks: stringInput(properties?.request?.remarks ?? ''),
				salutation: simpleEnumInput( // TODO Sorting.
					null,
					properties?.salutationOptions?.map(it => ({ key: it.id, label: it.label })) ?? []
				)
			})
		}


		mounted() {
			window.addEventListener('beforeunload', this.onWindowBeforeUnload)
		}


		@Watch('input.eventDate')
		onEventDateChanged() {
			this.updateProcessesForDate()
		}


		async onSubmitted() {
			const request = this.request ?? error()
			const input = this.input

			if (!input.participantRole.key) {
				return alert('Bitte gib die Kategorie des Kunden an.')
			}
			if (!this.contactInputValue) {
				return alert('Bitte wähle eine der Kunden-Optionen aus.')
			}
			if (!this.locationInputValue) {
				return alert('Bitte wähle eine der Location-Optionen aus.')
			}
			if (!input.eventType) {
				return alert('Bitte gibt den Veranstaltungstyp an.')
			}

			this.creationResponse = null
			this.isBusy = true

			// TODO what if this fails partially

			let contactId = this.contactInputValue
			if (contactId === 'new') {
				if (!this.createdContact) {
					// TODO link to request?
					const contactResponse = await this.api.createContact({
						businessPhoneNumber: request.businessPhoneNumber,
						companyName: request.companyName,
						faxNumber: request.faxNumber,
						person1: {
							addressCity: request.address?.city || null,
							addressCountryCode: request.address?.country?.code || null,
							addressPostalCode: request.address?.postalCode || null,
							addressStreet: request.address?.line1 || null,
							emailAddress: request.emailAddress,
							firstName: request.firstName,
							homePhoneNumber: request.homePhoneNumber,
							lastName: request.lastName,
							mobilePhoneNumber: request.mobilePhoneNumber,
							salutationId: input.salutation.key
						}
					})

					if (contactResponse instanceof ApiError) {
						this.creationResponse = contactResponse
						this.isBusy = false

						return
					}

					this.createdContact = contactResponse.data
				}

				contactId = this.createdContact.id
			}

			const response = await this.api.createProcessFromRequest({
				event: {
					date: input.eventDate.value,
					dateRemarks: input.eventDateDetails.value || null,
					occasionId: input.eventType?.key ?? error(),
					occasionDetails: input.eventTypeDetails.value || null
				},
				locationId: this.locationInputValue === 'none' ? null : this.locationInputValue,
				participation: {
					contactId: contactId,
					tagIds: [input.participantRole.key ?? error()] // FIXME support multiple
				},
				remarks: input.remarks.value || null,
				requestId: request.id
			})

			this.creationResponse = response
			this.isBusy = false

			if (response instanceof ApiError) {
				return
			}

			this.input = this.makeInput() // Don't ask for saving changes when leaving page.
		}


		onWindowBeforeUnload(event: Event) {
			if (this.hasChanges) {
				(event as any).returnValue = this.discardConfirmationMessage
			}
		}


		get potentialContacts() {
			const potentialPersons = this.request?.potentialPersons
			if (!potentialPersons) {
				return []
			}

			return sorted(
				potentialPersons,
				reverseOrder(compareBy(it => it.address?.city ?? ''))
			)
				.map(person => {
					const contact = person.contact!

					return {
						address: person.address?.format(true),
						businessPhoneNumber: contact.businessPhoneNumber?.formatInGermany(),
						companyName: contact.companyName,
						emailAddress: person.emailAddress,
						faxNumber: contact.faxNumber?.formatInGermany(),
						homePhoneNumber: person.homePhoneNumber?.formatInGermany(),
						id: contact.id,
						isSecondPerson: person.id.endsWith('/2'),
						mobilePhoneNumber: person.mobilePhoneNumber?.formatInGermany(),
						name: person.name,
						processParticipation: contact.processParticipations![0]
					}
				})
		}


		get potentialLocations() {
			return this.request?.potentialLocations ?? []
		}


		get process() {
			return this.updatedProcess === undefined ? (this.creationResult?.data?.process ?? null) : this.updatedProcess
		}


		set process(value: MProcess | null) {
			this.updatedProcess = value
		}


		async reload(route: RouteLocationNormalized) {
			this.apiResponse = null
			this.contactInputValue = ''
			this.creationResponse = null
			this.input = this.makeInput()
			this.locationInputValue = ''

			const id = asString(route.query.id)
			if (!id) {
				await this.$router.replace({ name: 'requests' })

				return
			}

			const response = await this.api.fetchRequest(id)

			this.apiResponse = response
			this.updatedProcess = undefined

			if (response instanceof ApiError) {
				return
			}

			const data = response.data
			this.input = this.makeInput(data)
			this.processesOnDate = response.data.request.potentialProcesses ?? []
			this.processesOnDateBasis = response.data.request.eventDate

			if (!response.data.request.potentialLocations!.length) {
				this.locationInputValue = 'none'
			}
		}


		get request() {
			return this.result.data.request
		}


		get result(): ApiResult<FetchRequestResult> {
			return this.apiResponse?.asResult() ?? error()
		}


		async updateProcessesForDate() {
			const date = this.input.eventDate.valueOrUndefined
			if (!date) {
				this.processesOnDate = null
				this.processesOnDateBasis = null

				return
			}

			if (this.processesOnDateIsLoading) {
				return
			}
			if (this.processesOnDateBasis && this.processesOnDateBasis.isEqualTo(date)) {
				return
			}

			this.processesOnDateIsLoading = true

			const response = await this.api.findProcessesByEventDate(date)

			this.processesOnDateIsLoading = false

			if (response instanceof ApiError) {
				console.error(response)

				return
			}

			const potentiallyDifferentDate = this.input.eventDate.valueOrUndefined
			if (!potentiallyDifferentDate || !potentiallyDifferentDate.isEqualTo(date)) {
				await this.updateProcessesForDate()

				return
			}

			this.processesOnDate = response.data.processes
			this.processesOnDateBasis = date
		}
	}
