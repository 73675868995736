import MEntity from '~/model/MEntity'
import MEntityParser from '~/model/MEntityParser'
import MJournalItem from '~/model/MJournalItem'
import MProcessFile from '~/model/MProcessFile'


export default class MProcessFileCreatedJournalItem extends MJournalItem {

	static fieldSelection(): string {
		return `{
			file ${MProcessFile.fieldSelection()}
			subjects ${MEntity.fieldSelection()}
		}`
	}


	readonly file: MProcessFile


	constructor(properties: {
		readonly file: MProcessFile
		readonly subjects: readonly MEntity[]
	}) {
		super('process file created', properties.subjects)

		this.file = properties.file
	}


	static fromJson(json: any) {
		return new this({
			file: MProcessFile.fromJson(json.file),
			subjects: json.subjects.map(it => MEntityParser(it))
		})
	}
}
