import MInlineText, { MInlineTextNodeText } from '~/model/MInlineText'
import { error } from '~/utility'


export type MTextNode =
	MTextNodeHeading |
	MTextNodeList |
	MTextNodePageBreak |
	MTextNodeParagraph |
	MTextNodePricingTable


export default class MText {

	constructor(
		readonly nodes: readonly MTextNode[]
	) {
	}


	static fromJson(json: any): MText {
		if (typeof json === 'string') {
			return this.ofString(json)
		}

		return new MText(json.map(json => {
			if (typeof json === 'string') {
				return new MTextNodeParagraph(new MInlineText([new MInlineTextNodeText(json)]))
			}

			const [type, data] = json
			switch (type) {
				case 'heading':
					return new MTextNodeHeading(
						MInlineText.fromJson(data.content),
						data.level
					)

				case 'list':
					return new MTextNodeList(
						data.items.map(it => MInlineText.fromJson(it)),
						data.style
					)

				case 'page break':
					return new MTextNodePageBreak()

				case 'paragraph':
					return new MTextNodeParagraph(MInlineText.fromJson(data))

				case 'pricing table':
					return new MTextNodePricingTable()

				default:
					return error(`Unknown type: ${type}`)
			}
		}))
	}


	get isEmpty(): boolean {
		return !this.nodes.length
	}


	static readonly empty = new MText([]) // TODO not valid


	static ofString(text: string): MText {
		return this.ofText(new MInlineText([new MInlineTextNodeText(text)]))
	}


	static ofText(text: MInlineText): MText {
		return new MText([
			new MTextNodeParagraph(text)
		])
	}


	static parse(value: string): MText {
		return this.fromJson(JSON.parse(value))
	}


	serialize(): string {
		return JSON.stringify(this.toJson())
	}


	toJson(): any {
		if (this.nodes.length == 1
			&& this.nodes[0] instanceof MTextNodeParagraph
			&& this.nodes[0].content.nodes.length == 1
			&& this.nodes[0].content.nodes[0] instanceof MInlineTextNodeText
			&& this.nodes[0].content.nodes[0].marks.length === 0
		) {
			return this.nodes[0].content.nodes[0].value
		}

		return this.nodes.map(it => it.toJson())
	}
}


export class MTextNodeHeading {

	readonly type = 'heading'


	constructor(
		readonly content: MInlineText,
		readonly level: number
	) {
	}


	toJson(): any {
		return ['heading', { content: this.content.toJson(), level: this.level }]
	}
}


export class MTextNodeList {

	readonly type = 'list'


	constructor(
		readonly items: readonly MInlineText[],
		readonly style: 'bulleted' | 'numeric' = 'bulleted'
	) {
	}


	toJson(): any {
		return ['list', { items: this.items.map(it => it.toJson()), style: this.style }]
	}
}


export class MTextNodePageBreak {

	readonly type = 'page break'


	toJson(): any {
		return ['page break']
	}
}


export class MTextNodeParagraph {

	readonly type = 'paragraph'


	constructor(readonly content: MInlineText) {
	}


	toJson(): any {
		return ['paragraph', this.content.toJson()]
	}
}


export class MTextNodePricingTable {

	readonly type = 'pricing table'


	toJson(): any {
		return ['pricing table']
	}
}
