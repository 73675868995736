
	import XCurrentProcessBar from '@/main/components/XCurrentProcessBar.vue'
	import XEquipmentAndMusicInput from '@/main/components/XEquipmentAndMusicInput.vue'
	import XMainPageContentOld from '@/main/components/XMainPageContentOld.vue'
	import XNoProcess from '@/main/components/XNoProcess.vue'
	import equipmentAndMusicInput from '@/main/model/EquipmentAndMusicInput'
	import eventInput from '@/main/model/EventInput'
	import { Inject, Options, Vue, Watch } from 'vue-property-decorator'
	import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
	import Api, { ApiError, ApiResponse, ApiResult, EventUpdateData, FetchEventResult } from '~/api/Api'
	import XCheckbox from '~/components/XCheckbox.vue'
	import XFormColumn from '~/components/XFormColumn.vue'
	import XFormRow from '~/components/XFormRow.vue'
	import XIconButton from '~/components/XIconButton.vue'
	import XInput from '~/components/XInput.vue'
	import XLink from '~/components/XLink.vue'
	import XLoadingAnimation from '~/components/XLoadingAnimation.vue'
	import XSection from '~/components/XSection.vue'
	import XSelect from '~/components/XSelect.vue'
	import XStatusMessage from '~/components/XStatusMessage.vue'
	import MEvent from '~/model/MEvent'
	import MJournalEntry from '~/model/MJournalEntry'
	import MLocalDate from '~/model/MLocalDate'
	import MLocation from '~/model/MLocation'
	import MProcess from '~/model/MProcess'
	import MProcessFile from '~/model/MProcessFile'
	import MProcessStatus from '~/model/MProcessStatus'
	import icons from '~/styles/icons.module.scss'
	import { compareBy, error, randomId, reverseOrder, sorted } from '~/utility'
	import { EnumInput, simpleEnumInput, stringInput } from '~/utility/Input'


	@Options({
		components: {
			XCheckbox,
			XCurrentProcessBar,
			XEquipmentAndMusicInput,
			XFormColumn,
			XFormRow,
			XIconButton,
			XInput,
			XLink,
			XLoadingAnimation,
			XMainPageContentOld,
			XNoProcess,
			XSection,
			XSelect,
			XStatusMessage
		},
		name: 'page-main-event'
	})
	export default class extends Vue {

		readonly attachmentStates: { [id: string]: 'archived' | 'archiving' } = {}
		readonly discardConfirmationMessage = 'Du hast Daten geändert aber noch nicht gespeichert.\nWillst du wirklich fortfahren?'
		readonly discardConfirmationMessagePendingUploads = 'Es wurden noch nicht alle Dateien hochgeladen.\nWillst du wirklich fortfahren?'
		readonly icons = icons
		readonly stateOptions: { key: string, label: string }[] = MProcessStatus.all.map(it => ({ key: it.id, label: it.label }))

		readonly seasonOptions = [
			{ key: 'high', label: 'Hauptsaison' },
			{ key: 'low', label: 'Nebensaison' }
		]

		addedAttachments: readonly MProcessFile[] = []
		apiResponse: ApiResponse<FetchEventResult> | null = null
		attachmentUploadQueue: { file: File, id: string, name: string }[] = []
		automaticSeasonInput = simpleEnumInput(null, this.seasonOptions)
		commentMessageInput = stringInput('')
		djCategoryInput: EnumInput<{ key: string, label: string }> | null = null
		equipmentAndMusicInput = equipmentAndMusicInput()
		input = eventInput()
		isArchivingJournalEntryById = {}
		isBusy = false
		isCreatingComment = false
		journalEntries: ReadonlyArray<MJournalEntry<any>> = []
		journalEntriesStatus: 'loaded' | 'loading' = 'loading' // TODO error
		seasonForDate: 'high' | 'low' | null = null
		seasonForDateBasis: MLocalDate | null = null
		seasonForDateIsLoading = false
		showsArchivedJournalEntries = false
		stateInput: EnumInput<{ key: string, label: string }> | null = null
		status: ApiError | string | null = null
		statusLocation: 'attachments' | 'journal' | 'top' = 'top'
		updatedProcess?: MProcess

		@Inject() readonly api!: Api


		private get attachmentInputElement(): HTMLInputElement | undefined {
			return this.$refs.attachmentInputElement as HTMLInputElement | undefined
		}


		get actualSeasonInput(): EnumInput<{ key: string, label: string }> {
			if (this.event) {
				return this.input.season
			}
			else {
				return this.automaticSeasonInput
			}
		}


		// noinspection JSUnusedGlobalSymbols // used by v-model
		set actualSeasonInput(input: EnumInput<{ key: string, label: string }>) {
			if (this.event) {
				this.input.season = input
			}
			else {
				this.automaticSeasonInput = input
			}
		}


		attachmentClasses(file: MProcessFile) {
			return {
				[this.$style.attachment]: true,
				[this.$style.isArchived]: this.attachmentStates[file.id] === 'archived',
				[this.$style.isArchiving]: this.attachmentStates[file.id] === 'archiving'
			}
		}


		get attachmentUploadQueueReversed() {
			return this.attachmentUploadQueue.concat().reverse()
		}


		beforeRouteLeave(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
			next(this.confirmDiscardingChanges())
		}


		confirmDiscardingChanges() {
			if (this.hasChanges) {
				return confirm(this.discardConfirmationMessage)
			}
			else if (this.attachmentUploadQueue.length) {
				return confirm(this.discardConfirmationMessagePendingUploads)
			}
			else {
				return true
			}
		}


		async created() {
			this.onLoaded(await this.api.fetchEvent('process'))
		}


		data() {
			return {
				updatedProcess: undefined
			}
		}


		get dateIsInPast() {
			const date = this.input?.date?.valueOrUndefined
			if (!date) {
				return false
			}

			return date.isBefore(MLocalDate.now())
		}


		destroyed() {
			window.removeEventListener('beforeunload', this.onWindowBeforeUnload)
		}


		get event(): MEvent | null {
			return this.result.data.event
		}


		get files(): readonly MProcessFile[] {
			return sorted(
				this.event!.files.concat(this.addedAttachments),
				reverseOrder(compareBy(it => it.creationTimestamp))
			)
		}


		get filteredJournalEntries(): readonly MJournalEntry<any>[] {
			return this.showsArchivedJournalEntries
				? this.journalEntries
				: this.journalEntries.filter(it => !it.archivalTimestamp)
		}


		get hasArchivedJournalEntries(): boolean {
			return Boolean(this.journalEntries.find(it => it.archivalTimestamp))
		}


		get hasChanges(): boolean {
			return !this.input.isInitial() || !(this.stateInput?.isInitial() ?? true) || !(this.djCategoryInput?.isInitial() ?? true)
		}


		get isSeasonMismatch(): boolean {
			const date = this.input.date.valueOrUndefined
			const season = this.input.season.key
			if (!season || !date || !this.seasonForDate || !this.seasonForDateBasis || !date.isEqualTo(this.seasonForDateBasis)) {
				return false
			}

			return season !== this.seasonForDate
		}


		get isValid(): boolean {
			return this.input.isValid()
		}


		async loadJournalEntries() {
			const process = this.process
			if (!process) {
				return
			}

			this.journalEntriesStatus = 'loading'

			const response = await this.api.fetchProcessJournalEntries(process.id)
			if (response instanceof ApiError) {
				return
			}

			this.journalEntries = response.data
			this.journalEntriesStatus = 'loaded'
		}


		get location(): MLocation | null {
			return this.event?.location ?? null
		}


		mounted() {
			window.addEventListener('beforeunload', this.onWindowBeforeUnload)
		}


		onAddAttachmentClicked() {
			const fileElement = this.attachmentInputElement ?? error()

			fileElement.click()
		}


		async onAttachmentFilesSelected() {
			const fileElement = this.attachmentInputElement ?? error()

			const filesToAdd = fileElement.files
			if (!filesToAdd || !filesToAdd.length) {
				return
			}

			const isUploading = this.attachmentUploadQueue.length > 0

			const attachmentUploadsToAdd: { file: File, id: string, name: string }[] = []
			for (let i = 0; i < filesToAdd.length; ++i) {
				const file = filesToAdd.item(i) ?? error()
				const name = file.name.replace(/.*[/\\]/, '')

				attachmentUploadsToAdd.push({
					file,
					id: randomId(),
					name
				})
			}

			fileElement.value = ''

			this.attachmentUploadQueue = this.attachmentUploadQueue.concat(attachmentUploadsToAdd)
			this.status = null

			if (!isUploading) {
				while (this.attachmentUploadQueue.length > 0) {
					const uploadedAttachment = await this.uploadAttachment(this.attachmentUploadQueue[0])
					if (uploadedAttachment) {
						this.addedAttachments = this.addedAttachments.concat(uploadedAttachment)
						this.attachmentUploadQueue = this.attachmentUploadQueue.slice(1)
					}
					else {

						this.attachmentUploadQueue = []
					}
				}
			}
		}


		async onArchiveAttachmentClicked(file: MProcessFile): Promise<void> {
			if (!confirm(`Soll die Datei '${file.name}' wirklick archiviert werden?`)) {
				return
			}

			this.status = null

			this.attachmentStates[file.id] = 'archiving'

			const response = await this.api.archiveFile(file.id)

			if (response instanceof ApiError) {
				this.status = response
				this.statusLocation = 'attachments'

				delete this.attachmentStates[file.id]
			}
			else {
				this.attachmentStates[file.id] = 'archived'
			}
		}


		async onArchiveJournalEntryClicked(entry: MJournalEntry<any>): Promise<void> {
			const index = this.journalEntries.indexOf(entry)
			index >= 0 || error()

			this.status = null

			this.isArchivingJournalEntryById[entry.id] = true

			const response = await this.api.archiveJournalEntry(entry.id)

			delete this.isArchivingJournalEntryById[entry.id]

			if (response instanceof ApiError) {
				this.status = response
				this.statusLocation = 'journal'
			}
			else {
				const entries = this.journalEntries.concat()
				entries[index] = entry
				this.journalEntries = entries
			}
		}


		async onCommentCreationSubmitted() {
			const event = this.event ?? error()
			const process = this.process ?? error()

			this.isCreatingComment = true
			this.status = null

			const response = await this.api.createComment({
				message: this.commentMessageInput.value,
				subjectIds: [event.id, process.id]
			})

			this.isCreatingComment = false

			if (response instanceof ApiError) {
				this.status = response
				this.statusLocation = 'journal'

				return
			}

			this.commentMessageInput = stringInput('')
			this.journalEntries = [response.data].concat(this.journalEntries)
		}


		onLoaded(response: ApiResponse<FetchEventResult>): void {
			this.apiResponse = response
			this.addedAttachments = []
			this.seasonForDate = null
			this.seasonForDateBasis = null
			this.updatedProcess = undefined

			if (response instanceof ApiError) {
				return
			}

			const event = response.data.event
			const process = response.currentProcess

			this.automaticSeasonInput = simpleEnumInput(null, this.seasonOptions)
			this.djCategoryInput = process && process.state === MProcessStatus.booked
				? simpleEnumInput(event?.djCategory?.id ?? null, response.data.djCategories.map(djCategory => ({
					key: djCategory.id,
					label: djCategory.label
				}))) : null
			this.equipmentAndMusicInput = equipmentAndMusicInput(response.data)
			this.input = eventInput({ ...response.data, process, seasonOptions: this.seasonOptions })
			this.stateInput = process && event
				? simpleEnumInput(process?.state?.id ?? null, this.stateOptions)
				: null

			this.loadJournalEntries()
			this.updateSeasonForDate()
		}


		onStartTimeBlurred(): void {
			if (this.input.endTime.valueOrUndefined) {
				return
			}

			const startTime = this.input.startTime.valueOrUndefined
			if (!startTime) {
				return
			}

			this.input.endTime = this.input.endTime.withValue(startTime.plus(5))
		}


		@Watch('input.date')
		onDateChanged(): void {
			this.updateSeasonForDate()
		}


		async onSubmitted(): Promise<void> {
			if (!this.isValid) {
				return
			}

			const process = this.process ?? error()

			const input = this.input
			if (!input.type.value) {
				return alert('Die Art der Veranstaltung muss angegeben werden.')
			}

			if (this.commentMessageInput.value) {
				const message = 'Du hast einen Kommentar geschrieben, ohne ihn hinzuzufügen.\n' +
					'Dieser Kommentar geht beim Speichern verloren.\n\nFortfahren?'
				if (!confirm(message)) {
					return
				}

				this.commentMessageInput = stringInput('')
			}

			const equipmentAndMusicInput = this.equipmentAndMusicInput

			this.isBusy = true
			this.status = null

			let event = this.event
			if (!event) {
				const response = await this.api.createEvent(this.process!.id, input.type.value?.key)
				if (response instanceof ApiError) {
					this.isBusy = false
					this.status = response
					this.statusLocation = 'top'

					return
				}

				event = response.data.event
			}

			const update: EventUpdateData = {
				consultationIds: input.consultationIds
					.filterKeys(input => input.value),
				contractDeadline: input.contractDeadlineSet.value
					? {
						date: input.contractDeadlineDate.value ?? error(),
						misses: input.contractDeadlineTimes.value > 0 ? (input.contractDeadlineTimes.value - 1) : 0
					} : null,
				date: input.date.value?.toString() ?? null,
				definiteEndTime: input.definiteEndTime.value?.toString() ?? null,
				endTime: input.endTime.value?.toString() ?? null,
				guestChildCount: input.guestChildCount.value,
				guestCount: input.guestCount.value,
				guestCountForMusic: input.guestCountForMusic.value,
				guestNationalities: input.guestNationalities.value || null,
				guestRemarks: input.guestRemarks.value || null,
				internalRemarks: input.internalRemarks.value || null,
				lightIds: equipmentAndMusicInput.lightInputs.options
					.filterKeys(input => input.value),
				lightRemarks: equipmentAndMusicInput.lightInputs.remarks.value || null,
				locationHasRecommendedUs: input.locationHasRecommendedUs.value,
				locationRemarks: input.locationRemarks.value || null,
				musicIds: equipmentAndMusicInput.musicInputs.options
					.filterKeys(input => input.value),
				musicRemarks: equipmentAndMusicInput.musicInputs.remarks.value || null,
				plannedEndTime: input.plannedEndTime.value?.toString() ?? null,
				processId: process.id,
				remarks: input.remarks.value || null,
				remarksAreHighPriority: input.remarksAreHighPriority.value,
				season: (input.season.value?.key as 'high' | 'low' | undefined) ?? undefined,
				setupTime: input.setupTime.value?.toString() ?? null,
				soundIds: equipmentAndMusicInput.soundInputs.options
					.filterKeys(input => input.value),
				soundRemarks: equipmentAndMusicInput.soundInputs.remarks.value || null,
				startTime: input.startTime.value?.toString() ?? null,
				typeDetails: input.typeDetails.value || null,
				typeId: input.type.value?.key
			}

			const djCategoryId = this.djCategoryInput?.value?.key
			const processState = this.stateInput?.value?.key

			const updatedResponse = await this.api.updateEvent(event.id, update)
			if (updatedResponse instanceof ApiError) {
				this.isBusy = false
				this.status = updatedResponse
				this.statusLocation = 'top'

				return
			}

			if (processState && processState !== process.state.id) {
				const processResponse = await this.api.changeProcessState(process.id, processState)
				if (processResponse instanceof ApiError) {
					this.isBusy = false
					this.status = processResponse
					this.statusLocation = 'top'

					return
				}
			}

			if ((djCategoryId ?? null) !== (event.djCategory?.id ?? null)) {
				const eventResponse = await this.api.changeEventDjCategory(event.id, djCategoryId ?? null)
				if (eventResponse instanceof ApiError) {
					this.isBusy = false
					this.status = eventResponse
					this.statusLocation = 'top'

					return
				}
			}

			const response = await this.api.fetchEvent(event.id)

			this.isBusy = false

			if (response instanceof ApiError) {
				this.status = null
			}
			else {
				this.status = this.event?.id ? 'Änderungen wurden gespeichert.' : 'Veranstaltung wurde gespeichert.'
			}

			this.onLoaded(response)
		}


		onWindowBeforeUnload(event: Event): void {
			if (this.hasChanges) {
				(event as any).returnValue = this.discardConfirmationMessage
			}
			else if (this.attachmentUploadQueue.length) {
				(event as any).returnValue = this.discardConfirmationMessagePendingUploads
			}
		}


		get process(): MProcess | null {
			return this.updatedProcess === undefined ? this.result.currentProcess : this.updatedProcess
		}


		set process(value: MProcess | null) {
			if (value) {
				this.updatedProcess = value
			}
		}


		get result(): ApiResult<FetchEventResult> {
			return this.apiResponse?.asResult() ?? error()
		}


		setCommentMessage(message: string) {
			this.commentMessageInput = this.commentMessageInput.withValue(message)
		}


		get tags(): readonly MProcessStatus[] {
			return this.result.processTags!
		}


		async updateSeasonForDate(): Promise<void> {
			const date = this.input.date.valueOrUndefined
			if (!date) {
				this.automaticSeasonInput = this.automaticSeasonInput.withKey(null)
				this.seasonForDate = null
				this.seasonForDateBasis = null

				return
			}

			if (this.seasonForDateIsLoading) {
				return
			}
			if (this.seasonForDateBasis && this.seasonForDateBasis.isEqualTo(date)) {
				return
			}

			this.seasonForDateIsLoading = true

			const response = await this.api.fetchSeason(date)

			this.seasonForDateIsLoading = false

			if (response instanceof ApiError) {
				console.error(response)

				return
			}

			const season = response.data

			const potentiallyDifferentDate = this.input.date.valueOrUndefined
			if (!potentiallyDifferentDate || !potentiallyDifferentDate.isEqualTo(date)) {
				await this.updateSeasonForDate()

				return
			}

			this.automaticSeasonInput = this.automaticSeasonInput.withKey(season)
			this.seasonForDate = season
			this.seasonForDateBasis = date
		}


		async uploadAttachment({ file, name }: { file: File, name: string }): Promise<null | MProcessFile> {
			const event = this.event ?? error()

			const response1 = await this.api.uploadFile(file)
			if (response1 instanceof ApiError) {
				this.status = response1
				this.statusLocation = 'attachments'

				return null
			}

			const response2 = await this.api.createEventAttachment(event.id, response1.data.id, name)
			if (response2 instanceof ApiError) {
				this.status = response2
				this.statusLocation = 'attachments'

				return null
			}

			return response2.data.attachment
		}
	}
