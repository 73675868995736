import MCity from '~/model/MCity'
import MCountry from '~/model/MCountry'
import { compositeInput, integerInput, simpleEnumInput, stringInput, timeDurationInput } from '~/utility/Input'


export default function cityInput(properties?: {
	readonly city: MCity | null
	readonly countries: readonly MCountry[]
	readonly defaultCountryId: string | null
}) {
	return compositeInput({
		country: simpleEnumInput(
			properties?.city?.country?.code ?? properties?.defaultCountryId ?? null,
			properties?.countries?.map(it => ({ key: it.code, label: it.name })) ?? []
		),
		drivingTime: timeDurationInput(properties?.city?.drivingTime ?? null, { optional: true }),
		name: stringInput(properties?.city?.name ?? ''),
		postalCodeRangeEnd: integerInput(properties?.city?.postalCodeRange?.endInclusive ?? null, { optional: true }),
		postalCodeRangeStart: integerInput(properties?.city?.postalCodeRange?.start ?? null, { optional: true }),
		remarks: stringInput(properties?.city?.remarks ?? '')
	})
}
