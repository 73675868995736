export default class MEventConsultation {

	static fieldSelection(): string {
		return `{
			id
			label
		}`
	}


	readonly id: string
	readonly label: string


	constructor(properties: {
		readonly id: string
		readonly label: string
	}) {
		this.id = properties.id
		this.label = properties.label
	}


	static fromJson(json: any): MEventConsultation {
		return new this({
			id: json.id,
			label: json.label
		})
	}
}
