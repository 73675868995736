export default class MUser {

	static fieldSelection(): string {
		return `{
			emailAddress
			firstName
			id
			lastName
		}`
	}


	readonly emailAddress: string
	readonly firstName: string
	readonly id: string
	readonly lastName: string


	constructor(properties: {
		readonly emailAddress: string
		readonly firstName: string
		readonly id: string
		readonly lastName: string
	}) {
		this.emailAddress = properties.emailAddress
		this.firstName = properties.firstName
		this.id = properties.id
		this.lastName = properties.lastName
	}


	get name() {
		return `${this.firstName} ${this.lastName}`
	}


	static fromJson(json: any): MUser {
		return new this({
			emailAddress: json.emailAddress,
			firstName: json.firstName,
			id: json.id,
			lastName: json.lastName
		})
	}
}
