
	import XCurrentProcessBar from '@/main/components/XCurrentProcessBar.vue'
	import XMainPageContentOld from '@/main/components/XMainPageContentOld.vue'
	import XNoProcess from '@/main/components/XNoProcess.vue'
	import { Inject, Options, Vue } from 'vue-property-decorator'
	import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
	import Api, { ApiError, ApiResponse, ApiResult, FetchEventPricingResult } from '~/api/Api'
	import XCheckbox from '~/components/XCheckbox.vue'
	import XFormColumn from '~/components/XFormColumn.vue'
	import XFormRow from '~/components/XFormRow.vue'
	import XIconButton from '~/components/XIconButton.vue'
	import XInput from '~/components/XInput.vue'
	import XLink from '~/components/XLink.vue'
	import XModal from '~/components/XModal.vue'
	import XSection from '~/components/XSection.vue'
	import XSelect from '~/components/XSelect.vue'
	import XStatusMessage from '~/components/XStatusMessage.vue'
	import MDjCategory from '~/model/MDjCategory'
	import MDocument from '~/model/MDocument'
	import MEquipment from '~/model/MEquipment'
	import MEvent from '~/model/MEvent'
	import MEventPricing from '~/model/MEventPricing'
	import MLocation from '~/model/MLocation'
	import MProcess from '~/model/MProcess'
	import MProcessFile from '~/model/MProcessFile'
	import MProcessTag from '~/model/MProcessTag'
	import icons from '~/styles/icons.module.scss'
	import {
		associateBy,
		associateByTo,
		compareBy,
		error,
		formatDuration,
		formatNumber,
		isNotNull,
		isNullOrUndefined,
		isUndefined,
		randomId,
		reverseOrder,
		sorted
	} from '~/utility'
	import { booleanInput, CompositeInput, compositeInput, Input, integerInput, priceInput, stringInput, TextInput } from '~/utility/Input'


	@Options({ // TODO warn about equipment selection out of sync with event
		components: {
			XFormColumn,
			XCheckbox,
			XCurrentProcessBar,
			XFormRow,
			XIconButton,
			XInput,
			XLink,
			XMainPageContentOld,
			XModal,
			XNoProcess,
			XSection,
			XSelect,
			XStatusMessage
		},
		name: 'page-main-pricing'
	})
	export default class extends Vue {

		readonly formatDuration = formatDuration // TODO there must be a better way
		readonly formatNumber = formatNumber

		readonly discardConfirmationMessage = 'Du hast Daten geändert aber noch nicht gespeichert.\nWillst du wirklich fortfahren?'
		readonly documentStates: { [id: string]: 'archived' | 'archiving' } = {}
		readonly fileStates: { [id: string]: 'archived' | 'archiving' } = {}
		readonly icons: { readonly [key: string]: string } = icons

		apiResponse: ApiResponse<FetchEventPricingResult> | null = null
		basePriceInput = priceInput(null, { optional: true })
		creationVatIncludedInput = booleanInput(true)
		defaultVatRate = 0
		input = this.makeInput()
		isBusy = false
		status: ApiError | string | null = null
		updatedProcess?: MProcess
		vatRateChangeCalculation = 'gross'
		vatRateChangeCalculationInputId = randomId()
		vatRateInModal = integerInput(undefined)
		vatRateModalIsVisible = false

		@Inject() readonly api!: Api


		private get vatRateInModalElement(): HTMLInputElement | undefined {
			return this.$refs.vatRateInModalElement as HTMLInputElement | undefined
		}


		get additionalItemCount(): number {
			return (this.result.data.pricing?.additionalOptions?.length ?? 0) + 3
		}


		adminUrlEditDocument = (decumentId): string => {
			return (`https://admin.mmptdb.de/process-document/${JSON.stringify({
				documentID: decumentId, eventId: this?.process?.event?.id
			})}`)
		}

		adminUrlCreateDocument = (type, process): string => {
			return (`https://admin.mmptdb.de/process-new-document/${JSON.stringify({
				type: type, processId: process?.id
			})}`)
		}


		beforeRouteLeave(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void {
			next(this.confirmDiscardingChanges())
		}


		confirmDiscardingChanges(): boolean {
			if (!this.hasChanges) {
				return true
			}

			return confirm(this.discardConfirmationMessage)
		}


		copyGrossToNet(grossInput: TextInput<number>, netInput: TextInput<number>, replaceNetInput: (input: TextInput<number>) => void): void {
			const grossPrice = grossInput.valueOrUndefined
			if (grossPrice === undefined) {
				replaceNetInput(netInput.withInvalidText(''))
				return
			}

			const vatRate = this.input.vatRate.value
			const netPrice = Math.round(grossPrice / (100 + vatRate) * 100 * 100) / 100

			replaceNetInput(netInput.withValue(netPrice))
		}


		copyNetToGross(netInput: TextInput<number>, grossInput: TextInput<number>, replaceGrossInput: (input: TextInput<number>) => void): void {
			const netPrice = netInput.valueOrUndefined
			if (netPrice === undefined) {
				replaceGrossInput(grossInput.withInvalidText(''))
				return
			}

			const vatRate = this.input.vatRate.value
			const grossPrice = Math.round(netPrice * (100 + vatRate)) / 100

			replaceGrossInput(grossInput.withValue(grossPrice))
		}


		created(): void {
			this.reload()
		}


		data(): { updatedProcess: undefined } {
			return {
				updatedProcess: undefined
			}
		}


		destroyed(): void {
			window.removeEventListener('beforeunload', this.onWindowBeforeUnload)
		}


		get djCategories(): readonly MDjCategory[] {
			return this.result.data.djCategories
		}


		documentClasses(document: MDocument): { [p: string]: boolean } {
			return {
				[this.$style.document]: true,
				[this.$style.isArchived]: this.documentStates[document.id] === 'archived',
				[this.$style.isArchiving]: this.documentStates[document.id] === 'archiving'
			}
		}


		get documents(): readonly MDocument[] {
			const documents = this.result.data.documents
			if (!documents) {
				return []
			}

			return sorted(
				documents,
				reverseOrder(compareBy(it => it.modificationTimestamp))
			)
		}


		get equipments(): readonly MEquipment[] {
			return this.result.data.equipments
		}


		get event(): null | MEvent {
			return this.process && this.process.event
		}


		get eventDuration(): number | null {
			return this.event && this.event.duration && (Math.ceil(this.event.duration / 30 / 60) * 30 * 60)
		}


		fileClasses(file: MProcessFile): { [p: string]: boolean } {
			return {
				[this.$style.file]: true,
				[this.$style.isArchived]: this.fileStates[file.id] === 'archived',
				[this.$style.isArchiving]: this.fileStates[file.id] === 'archiving'
			}
		}


		get filesWithMissingDocument(): readonly MProcessFile[] {
			const files = this.process
				?.files
				?.filter(it => it.source._type === 'missing document')
			if (!files) {
				return []
			}

			return sorted(
				files,
				reverseOrder(compareBy(it => it.creationTimestamp))
			)
		}


		get hasChanges(): boolean {
			return (this.apiResponse && this.pricing) ? !this.relevantInput.isInitial() : !this.basePriceInput.isInitial()
		}


		get isValidInput(): boolean {
			return this.relevantInput.isValid()
		}


		get lightEquipments(): readonly MEquipment[] {
			return this.equipments.filter(it => it.type === 'light')
		}


		get location(): MLocation | null {
			return this.process?.event?.location ?? null
		}


		makeInput(properties?: {
			readonly djCategories: readonly MDjCategory[]
			readonly equipments: readonly MEquipment[]
			readonly pricing: MEventPricing | null
		}) {
			const pricing = properties?.pricing
			const vatIncluded = pricing?.vatInclusion === 'inclusive'
			const vatRate = pricing?.vatRate ?? 0

			function calculateGrossPriceFromNetPrice(price: number): number {
				return Math.round(price * (100 + vatRate)) / 100
			}

			function calculateGrossPrice(price: number | undefined): number | undefined {
				if (price === undefined) {
					return undefined
				}
				if (vatIncluded) {
					return price
				}

				return calculateGrossPriceFromNetPrice(price)
			}

			function calculateNetPrice(price: number | undefined): number | undefined {
				if (price === undefined) {
					return undefined
				}
				if (!vatIncluded) {
					return price
				}

				return Math.round(price / (100 + vatRate) * 100 * 100) / 100
			}

			const additionalOptionsByIndex = properties?.pricing?.additionalOptions ?? []
			const additionalItemCount = additionalOptionsByIndex.length + 3
			const djCategoryOptionsById = associateBy(properties?.pricing?.djCategoryOptions ?? [], it => it.category.id)
			const equipmentOptionsById = associateBy(properties?.pricing?.equipmentOptions ?? [], it => it.equipment.id)

			return compositeInput({
				additionalItemLabels: compositeInput(associateByTo(new Array(additionalItemCount).fill(null), (_, index) => {
					const option = additionalOptionsByIndex[index]

					return [index.toString(), stringInput(option?.label ?? '')]
				})),
				additionalItemPrices: compositeInput(associateByTo(new Array(additionalItemCount).fill(null), (_, index) => {
					const option = additionalOptionsByIndex[index]

					return [index.toString(), priceInput(calculateNetPrice(option?.price ?? undefined))]
				})),
				additionalItemSelections: compositeInput(associateByTo(new Array(additionalItemCount).fill(null), (_, index) => {
					const option = additionalOptionsByIndex[index]

					return [index.toString(), booleanInput(option?.isSelected ?? false)]
				})),
				djCategoryLabels: compositeInput(associateByTo(properties?.djCategories ?? [], category =>
					[category.id, stringInput(category.label)]
				)),
				djCategoryPrices: compositeInput(associateByTo(properties?.djCategories ?? [], category => {
					const option = djCategoryOptionsById[category.id]

					return [category.id, priceInput(calculateNetPrice(option?.price ?? undefined))]
				})),
				djCategorySelections: compositeInput(associateByTo(properties?.djCategories ?? [], category => {
					const option = djCategoryOptionsById[category.id]

					return [category.id, booleanInput(option?.isSelected ?? false)]
				})),
				djCategoryPrepaymentSelections: compositeInput(associateByTo(properties?.djCategories ?? [], category => {
					const option = djCategoryOptionsById[category.id]

					return [category.id, booleanInput(((option?.prepaymentAmount ?? 0) !== 0) ?? true)]
				})),
				equipmentPrices: compositeInput(associateByTo(properties?.equipments ?? [], equipment => {
					const option = equipmentOptionsById[equipment.id]

					return [equipment.id, priceInput(calculateNetPrice(option?.price ?? undefined) ?? equipment.priceExcludingVat)]
				})),
				equipmentSelections: compositeInput(associateByTo(properties?.equipments ?? [], equipment => {
					const option = equipmentOptionsById[equipment.id]

					return [equipment.id, booleanInput(option?.isSelected ?? false)]
				})),
				grossAdditionalItemPrices: compositeInput(associateByTo(new Array(additionalItemCount).fill(null), (_, index) => {
					const option = additionalOptionsByIndex[index]

					return [index.toString(), priceInput(calculateGrossPrice(option?.price ?? undefined))]
				})),
				grossDjCategoryPrices: compositeInput(associateByTo(properties?.djCategories ?? [], category => {
					const option = djCategoryOptionsById[category.id]

					return [category.id, priceInput(calculateGrossPrice(option?.price ?? undefined))]
				})),
				grossEquipmentPrices: compositeInput(associateByTo(properties?.equipments ?? [], equipment => {
					const option = equipmentOptionsById[equipment.id]

					return [equipment.id, priceInput(calculateGrossPrice(option?.price ?? undefined) ?? calculateGrossPriceFromNetPrice(equipment.priceExcludingVat))]
				})),
				grossPriceForEarlyStart: priceInput(calculateGrossPrice(pricing?.priceForEarlyStart ?? undefined)),
				grossPriceForService: priceInput(calculateGrossPrice(pricing?.priceForService ?? undefined)),
				grossPriceForSetupInAdvance: priceInput(calculateGrossPrice(pricing?.priceForSetupInAdvance ?? undefined)),
				grossPricePerOvertimeUnit: priceInput(calculateGrossPrice(pricing?.pricePerOvertimeUnit ?? undefined)),
				includesEarlyStart: booleanInput(pricing?.isEarlyStartSelected ?? false),
				includesService: booleanInput(pricing?.isServiceSelected ?? false),
				includesSetupInAdvance: booleanInput(pricing?.isSetupInAdvanceSelected ?? false),
				priceForEarlyStart: priceInput(calculateNetPrice(pricing?.priceForEarlyStart ?? undefined)),
				priceForService: priceInput(calculateNetPrice(pricing?.priceForService ?? undefined)),
				priceForSetupInAdvance: priceInput(calculateNetPrice(pricing?.priceForSetupInAdvance ?? undefined)),
				pricePerOvertimeUnit: priceInput(calculateNetPrice(pricing?.pricePerOvertimeUnit ?? undefined)),
				remarks: stringInput(pricing?.remarks ?? ''),
				vatIncluded: booleanInput(vatIncluded),
				vatRate: integerInput(vatRate)
			})
		}


		mounted(): void {
			window.addEventListener('beforeunload', this.onWindowBeforeUnload)
		}


		onChangeVatRateClicked(): void {
			this.vatRateInModal = integerInput(this.input.vatRate.value)
			this.vatRateModalIsVisible = true

			this.vatRateInModalElement!.select()
		}


		async onArchiveDocumentClicked(document: MDocument): Promise<void> {
			if (!confirm(`Soll das Dokument '${document.name}' wirklich archiviert werden?`)) {
				return
			}

			this.documentStates[document.id] = 'archiving'

			try {
				await this.api.archiveDocument(document.id)

				this.documentStates[document.id] = 'archived'
			}
			catch (error) {
				delete this.documentStates[document.id]
				// TODO handle error
			}
		}


		async onArchiveFileClicked(file: MProcessFile): Promise<void> {
			if (!confirm(`Soll die Datei '${file.name}' wirklich archiviert werden?`)) {
				return
			}

			this.fileStates[file.id] = 'archiving'

			try {
				await this.api.archiveFile(file.id)

				this.fileStates[file.id] = 'archived'
			}
			catch (error) {
				delete this.fileStates[file.id]
				// TODO handle error
			}
		}


		async onPricingSubmitted() {
			const event = this.event || error()
			let response: ApiResponse<FetchEventPricingResult & { pricing: MEventPricing }>

			const pricing = this.pricing
			if (!pricing) {
				const basePrice = this.basePriceInput.valueOrUndefined
				if (isNullOrUndefined(basePrice)) {
					return
				}

				this.isBusy = true
				this.status = null

				response = await this.api.createPricing(event.id, basePrice, this.creationVatIncludedInput.value)
			}
			else {
				const input = this.grossNetInput

				this.isBusy = true
				this.status = null

				response = await this.api.updatePricing(event.id, {
					additionalItems: input.additionalItemPrices.mapValues((_, index) => index)
						.filter(index => !isNullOrUndefined(input.additionalItemPrices[index].valueOrUndefined))
						.map(index => ({
							isSelected: input.additionalItemSelections[index].value,
							label: input.additionalItemLabels[index].value,
							price: input.additionalItemPrices[index].value
						})),
					djCategories: this.djCategories
						.filter(category => !isNullOrUndefined(input.djCategoryPrices[category.id].valueOrUndefined))
						.map(category => ({
							id: category.id,
							isSelected: input.djCategorySelections[category.id].value,
							label: input.djCategoryLabels[category.id].value,
							price: input.djCategoryPrices[category.id].value,
							prepaymentAmount: input.djCategoryPrepaymentSelections[category.id].value ? 20 : 0 // TODO make dynamic
						})),
					equipmentList: this.equipments
						.filter(equipment => !isNullOrUndefined(input.equipmentPrices[equipment.id].valueOrUndefined))
						.map(equipment => ({
							id: equipment.id,
							isSelected: input.equipmentSelections[equipment.id].value,
							price: input.equipmentPrices[equipment.id].value
						})),
					includesEarlyStart: input.includesEarlyStart.value,
					includesService: input.includesService.value,
					includesSetupInAdvance: input.includesSetupInAdvance.value,
					priceForEarlyStart: input.priceForEarlyStart.valueOrUndefined,
					priceForService: input.priceForService.valueOrUndefined,
					priceForSetupInAdvance: input.priceForSetupInAdvance.valueOrUndefined,
					pricePerOvertimeUnit: input.pricePerOvertimeUnit.value,
					remarks: input.remarks.value || null,
					vatInclusion: input.vatIncluded.value ? 'inclusive' : 'exclusive',
					vatRate: input.vatRate.value
				})
			}

			this.isBusy = false

			if (response instanceof ApiError) {
				this.status = response

				return
			}

			const process = response.currentProcess
			if ((this.process && this.process.id) !== (process && process.id)) {
				await this.reload()
				return
			}

			this.apiResponse = response
			this.basePriceInput = priceInput(null, { optional: true })
			this.defaultVatRate = response.data.pricing.defaultVatRate
			this.input = this.makeInput(response.data)
			this.status = 'Die Preise wurden erfolgreich gespeichert.'
			this.updatedProcess = undefined
		}


		onSelectAllDjCategoriesClicked() {
			this.input.djCategorySelections.forEach((input, id) => {
				this.input.djCategorySelections[id] = input.withValue(true)
			})
		}


		onVatRateSubmitted() {
			const oldVatRate = this.input.vatRate.value
			const newVatRate = this.vatRateInModal.value

			if (newVatRate < 1 || newVatRate > 100) {
				alert('Ungültiger Mehrwertsteuersatz.')
				return
			}

			this.vatRateModalIsVisible = false

			if (newVatRate === oldVatRate) {
				return
			}

			this.input.vatRate = this.input.vatRate.withValue(newVatRate)

			switch (this.vatRateChangeCalculation) {
				case 'gross':
					this.input.additionalItemPrices.forEach((input, key) => {
						this.copyNetToGross(input, this.input.grossAdditionalItemPrices[key], value => this.input.grossAdditionalItemPrices[key] = value)
					})
					this.input.djCategoryPrices.forEach((input, key) => {
						this.copyNetToGross(input, this.input.grossDjCategoryPrices[key], value => this.input.grossDjCategoryPrices[key] = value)
					})
					this.input.equipmentPrices.forEach((input, key) => {
						this.copyNetToGross(input, this.input.grossEquipmentPrices[key], value => this.input.grossEquipmentPrices[key] = value)
					})
					this.copyNetToGross(this.input.priceForEarlyStart, this.input.grossPriceForEarlyStart, value => this.input.grossPriceForEarlyStart = value)
					this.copyNetToGross(this.input.priceForService, this.input.grossPriceForService, value => this.input.grossPriceForService = value)
					this.copyNetToGross(this.input.priceForSetupInAdvance, this.input.grossPriceForSetupInAdvance, value => this.input.grossPriceForSetupInAdvance = value)
					this.copyNetToGross(this.input.pricePerOvertimeUnit, this.input.grossPricePerOvertimeUnit, value => this.input.grossPricePerOvertimeUnit = value)

					break

				case 'net':
					this.input.grossAdditionalItemPrices.forEach((input, key) => {
						this.copyGrossToNet(input, this.input.additionalItemPrices[key], value => this.input.additionalItemPrices[key] = value)
					})
					this.input.grossDjCategoryPrices.forEach((input, key) => {
						this.copyGrossToNet(input, this.input.djCategoryPrices[key], value => this.input.djCategoryPrices[key] = value)
					})
					this.input.grossEquipmentPrices.forEach((input, key) => {
						this.copyGrossToNet(input, this.input.equipmentPrices[key], value => this.input.equipmentPrices[key] = value)
					})
					this.copyGrossToNet(this.input.grossPriceForEarlyStart, this.input.priceForEarlyStart, value => this.input.priceForEarlyStart = value)
					this.copyGrossToNet(this.input.grossPriceForService, this.input.priceForService, value => this.input.priceForService = value)
					this.copyGrossToNet(this.input.grossPriceForSetupInAdvance, this.input.priceForSetupInAdvance, value => this.input.priceForSetupInAdvance = value)
					this.copyGrossToNet(this.input.grossPricePerOvertimeUnit, this.input.pricePerOvertimeUnit, value => this.input.pricePerOvertimeUnit = value)

					break
			}
		}


		onWindowBeforeUnload(event: Event) {
			if (this.hasChanges) {
				(event as any).returnValue = this.discardConfirmationMessage
			}
		}


		get grossPrepaymentByDjCategoryId(): CompositeInput<{ [id: string]: Input<number> }> {
			return this.grossTotalByDjCategoryId.map( // TODO make amount configurable
				input => priceOrErrorInput(input.isValid() ? Math.round(input.value * 0.2 * 100) / 100 : undefined)
			)
		}


		get prepaymentByDjCategoryId(): CompositeInput<{ [id: string]: Input<number> }> {
			return this.totalByDjCategoryId.map( // TODO make amount configurable
				input => priceOrErrorInput(input.isValid() ? Math.round(input.value * 0.2 * 100) / 100 : undefined)
			)
		}


		get pricing(): MEventPricing | null {
			return this.process?.event?.pricing ?? null
		}


		get process() {
			return this.updatedProcess === undefined ? this.result.currentProcess : this.updatedProcess
		}


		set process(value: MProcess | null) {
			if (value) {
				this.updatedProcess = value
			}
		}


		get referralText(): string | null {
			return this.process?.primaryParticipation?.contact?.referral?.components?.join(', ') ?? null
		}


		get grossNetInput() {
			const input = this.input
			const vatIncluded = input.vatIncluded.value

			return compositeInput({
				additionalItemLabels: input.additionalItemLabels,
				additionalItemPrices: (vatIncluded ? input.grossAdditionalItemPrices : input.additionalItemPrices),
				additionalItemSelections: input.additionalItemSelections,
				djCategoryLabels: input.djCategoryLabels,
				djCategoryPrices: (vatIncluded ? input.grossDjCategoryPrices : input.djCategoryPrices),
				djCategorySelections: input.djCategorySelections,
				djCategoryPrepaymentSelections: input.djCategoryPrepaymentSelections,
				equipmentPrices: (vatIncluded ? input.grossEquipmentPrices : input.equipmentPrices),
				equipmentSelections: input.equipmentSelections,
				includesEarlyStart: input.includesEarlyStart,
				includesService: input.includesService,
				includesSetupInAdvance: input.includesSetupInAdvance,
				priceForEarlyStart: vatIncluded ? input.grossPriceForEarlyStart : input.priceForEarlyStart,
				priceForService: vatIncluded ? input.grossPriceForService : input.priceForService,
				priceForSetupInAdvance: vatIncluded ? input.grossPriceForSetupInAdvance : input.priceForSetupInAdvance,
				pricePerOvertimeUnit: vatIncluded ? input.grossPricePerOvertimeUnit : input.pricePerOvertimeUnit,
				remarks: input.remarks,
				vatIncluded: input.vatIncluded,
				vatRate: input.vatRate
			})
		}


		get relevantInput() {
			const input = this.grossNetInput

			function noPrice() {
				return priceInput(null, { optional: true })
			}

			return compositeInput({
				additionalItemLabels: input.additionalItemLabels,
				additionalItemPrices: input.additionalItemPrices.map(
					(price, id) => input.additionalItemSelections[id].value ? price : noPrice()
				),
				additionalItemSelections: input.additionalItemSelections,
				djCategoryLabels: input.djCategoryLabels,
				djCategoryPrices: input.djCategoryPrices.map(
					(price, id) => input.djCategorySelections[id].value ? price : noPrice()
				),
				djCategorySelections: input.djCategorySelections,
				djCategoryPrepaymentSelections: input.djCategoryPrepaymentSelections,
				equipmentPrices: input.equipmentPrices.map(
					(price, id) => input.equipmentSelections[id].value ? price : noPrice()
				),
				equipmentSelections: input.equipmentSelections,
				includesEarlyStart: input.includesEarlyStart,
				includesService: input.includesService,
				includesSetupInAdvance: input.includesSetupInAdvance,
				priceForEarlyStart: input.includesEarlyStart.value ? input.priceForEarlyStart : noPrice(),
				priceForService: input.includesService.value ? input.priceForService : noPrice(),
				priceForSetupInAdvance: input.includesSetupInAdvance.value ? input.priceForSetupInAdvance : noPrice(),
				pricePerOvertimeUnit: input.pricePerOvertimeUnit,
				remarks: input.remarks,
				vatIncluded: input.vatIncluded,
				vatRate: input.vatRate
			})
		}


		async reload(): Promise<void> {
			this.apiResponse = null
			this.basePriceInput = priceInput(null, { optional: true })
			this.input = this.makeInput()
			this.status = null
			this.updatedProcess = undefined

			const response = await this.api.fetchPricing()

			this.apiResponse = response

			if (response instanceof ApiError) {
				return
			}

			this.defaultVatRate = this.pricing?.defaultVatRate ?? 0
			this.input = this.makeInput(response.data)
		}


		get result(): ApiResult<FetchEventPricingResult> {
			return this.apiResponse?.asResult() ?? error()
		}


		get soundEquipments(): readonly MEquipment[] {
			return this.equipments.filter(it => it.type === 'sound')
		}


		get stateWarningMessage(): string | null {
			switch (this.process && this.process.state.id) {
				case 'booked':
					return 'Diese Veranstaltung wurde gebucht und sollte nicht mehr verändert werden.'

				case 'canceled':
					return 'Diese Veranstaltung wurde abgesagt und sollte nicht mehr verändert werden.'
			}

			return null
		}


		get tags(): MProcessTag[] | null {
			return this.result.processTags
		}


		get totalByDjCategoryId(): CompositeInput<{ [id: string]: Input<number> }> {
			const subtotal = this.totalExcludingDjCategories.valueOrUndefined
			if (isUndefined(subtotal)) {
				return this.input.djCategoryPrices.map(() => priceOrErrorInput(undefined))
			}

			return this.input.djCategoryPrices.map(
				input => priceOrErrorInput(isUndefined(input.valueOrUndefined) ? undefined : (subtotal + input.valueOrUndefined))
			)
		}


		get totalExcludingDjCategories(): Input<number> {
			const input = this.input

			const total = [
				...input.additionalItemPrices.filterValues((_, id) => input.additionalItemSelections[id].value),
				...input.equipmentPrices.filterValues((_, id) => input.equipmentSelections[id].value),
				input.includesEarlyStart.value ? input.priceForEarlyStart : null,
				input.includesService.value ? input.priceForService : null,
				input.includesSetupInAdvance.value ? input.priceForSetupInAdvance : null
			]
				.filter(isNotNull)
				.map(input => input.valueOrUndefined)
				.reduce((total, value) => (total === undefined || value === undefined) ? undefined : (total + value), 0)

			return priceOrErrorInput(total)
		}


		get grossTotalByDjCategoryId(): CompositeInput<{ [id: string]: Input<number> }> {
			const subtotal = this.grossTotalExcludingDjCategories.valueOrUndefined
			if (isUndefined(subtotal)) {
				return this.input.grossDjCategoryPrices.map(() => priceOrErrorInput(undefined))
			}

			return this.input.grossDjCategoryPrices.map(
				input => priceOrErrorInput(isUndefined(input.valueOrUndefined) ? undefined : (subtotal + input.valueOrUndefined))
			)
		}


		get grossTotalExcludingDjCategories(): Input<number> {
			const input = this.input

			const total = [
				...input.grossAdditionalItemPrices.filterValues((_, id) => input.additionalItemSelections[id].value),
				...input.grossEquipmentPrices.filterValues((_, id) => input.equipmentSelections[id].value),
				input.includesEarlyStart.value ? input.grossPriceForEarlyStart : null,
				input.includesService.value ? input.grossPriceForService : null,
				input.includesSetupInAdvance.value ? input.grossPriceForSetupInAdvance : null
			]
				.filter(isNotNull)
				.map(input => input.valueOrUndefined)
				.reduce((total, value) => (total === undefined || value === undefined) ? undefined : (total + value), 0)

			return priceOrErrorInput(total)
		}
	}


	function priceOrErrorInput(price: number | undefined) {
		return priceInput(price, isUndefined(price) ? { text: 'Fehler!' } : undefined)
	}
