import contactPersonInput from '@/main/model/ContactPersonInput'
import referralInput from '@/main/model/ReferralInput'
import MContact from '~/model/MContact'
import MCountry from '~/model/MCountry'
import MMagazine from '~/model/MMagazine'
import MPhoneNumber from '~/model/MPhoneNumber'
import MSalutation from '~/model/MSalutation'
import MWeddingFair from '~/model/MWeddingFair'
import { compositeInput, phoneNumberInput, stringInput } from '~/utility/Input'


export default function contactInput(properties?: {
	readonly contact: MContact | null
	readonly countries: readonly MCountry[]
	readonly defaultCountryId: string | null
	readonly djOptions: readonly { key: string, label: string }[]
	readonly initialInput?: null | {
		readonly businessPhoneNumber: MPhoneNumber | null
		readonly companyName: string
		readonly internalRemarks: string
		readonly person1: {
			readonly addressCity: string
			readonly addressPostalCode: string
			readonly addressStreet: string
			readonly emailAddress: string
			readonly firstName: string
			readonly homePhoneNumber: MPhoneNumber | null
			readonly lastName: string
			readonly mobilePhoneNumber: MPhoneNumber | null
		}
		readonly remarks: string
	}
	readonly locationOptions: readonly { key: string, label: string }[]
	readonly magazines: readonly MMagazine[]
	readonly salutations: readonly MSalutation[]
	readonly weddingFairs: readonly MWeddingFair[]
}) {
	return compositeInput({
		businessPhoneNumber: phoneNumberInput(properties?.contact?.businessPhoneNumber ?? properties?.initialInput?.businessPhoneNumber ?? null),
		companyName: stringInput(properties?.contact?.companyName ?? properties?.initialInput?.companyName ?? ''),
		faxNumber: phoneNumberInput(properties?.contact?.faxNumber ?? null),
		internalRemarks: stringInput(properties?.contact?.internalRemarks ?? properties?.initialInput?.internalRemarks ?? ''),
		person1: contactPersonInput(
			properties
				? { ...properties, initialInput: properties.initialInput?.person1, person: properties.contact?.person1 ?? null }
				: undefined
		),
		person2: contactPersonInput(properties ? { ...properties, initialInput: undefined, person: properties.contact?.person2 ?? null } : undefined),
		referral: referralInput(properties ? { ...properties, referral: properties?.contact?.referral ?? null } : undefined),
		remarks: stringInput(properties?.contact?.remarks ?? properties?.initialInput?.remarks ?? '')
	})
}
