import { ComponentOptions } from '@vue/runtime-core'


const listenerRegex = /^on([A-Za-z])/

const mixin: ComponentOptions = {

	computed: {
		$listeners() {
			const attrs = this.$attrs

			return Object.keys(attrs).reduce((listeners, key) => {
				const match = key.match(listenerRegex)
				if (match) {
					const newKey = key.replace(listenerRegex, (_, firstLetter: string) => firstLetter.toLowerCase())
					listeners[newKey] = attrs[key]
				}

				return listeners
			}, {})
		}
	}
}
export default mixin
