import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toHandlers as _toHandlers, mergeProps as _mergeProps, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["autocomplete", "disabled", "id", "readonly", "required", "value"]
const _hoisted_3 = ["label"]
const _hoisted_4 = ["value", "textContent"]
const _hoisted_5 = ["value", "textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classes)
  }, [
    (_ctx.hasLabel)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(_ctx.labelClasses),
          for: _ctx.id
        }, [
          _renderSlot(_ctx.$slots, "label", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.label) + ":", 1)
          ])
        ], 10, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("select", _mergeProps({
      autocomplete: _ctx.actualAutocomplete,
      class: _ctx.inputClasses,
      disabled: _ctx.disabled,
      id: _ctx.id,
      ref: "inputElement",
      readonly: _ctx.readonly,
      required: _ctx.required,
      value: _ctx.modelValue.key
    }, _toHandlers(_ctx.inputListeners)), [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue.groupedOptions, (groupOrOption, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (_ctx.isGroup(groupOrOption))
            ? (_openBlock(), _createElementBlock("optgroup", {
                key: groupOrOption.key || index.toString(),
                label: groupOrOption.label
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(groupOrOption.options, (option, optionIndex) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: option.key || optionIndex.toString(),
                    value: option.key || '',
                    textContent: _toDisplayString(option.label)
                  }, null, 8, _hoisted_4))
                }), 128))
              ], 8, _hoisted_3))
            : (_openBlock(), _createElementBlock("option", {
                key: groupOrOption.key || index.toString(),
                value: groupOrOption.key || '',
                textContent: _toDisplayString(groupOrOption.label)
              }, null, 8, _hoisted_5))
        ], 64))
      }), 256))
    ], 16, _hoisted_2)
  ], 2))
}