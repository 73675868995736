import MDjCategory from '~/model/MDjCategory'


export default class MEventPricingDjCategoryOption {

	readonly category: MDjCategory
	readonly isSelected: boolean
	readonly label: string
	readonly prepaymentAmount: number
	readonly price: number


	constructor(properties: {
		readonly category: MDjCategory
		readonly isSelected: boolean
		readonly label: string
		readonly prepaymentAmount: number
		readonly price: number
	}) {
		this.category = properties.category
		this.isSelected = properties.isSelected
		this.label = properties.label
		this.prepaymentAmount = properties.prepaymentAmount
		this.price = properties.price
	}


	static fromJson(json: any): MEventPricingDjCategoryOption {
		return new this({
			category: MDjCategory.fromJson(json.category),
			isSelected: json.isSelected,
			label: json.label,
			prepaymentAmount: json.prepaymentAmount,
			price: json.price / 100
		})
	}
}
