
	import { Options, Prop, Vue } from 'vue-property-decorator'
	import { randomId } from '~/utility'
	import { EnumInput, enumInput, EnumInputGroup, EnumInputOption } from '~/utility/Input'


	@Options({ name: 'x-select' })
	export default class XSelect<Value extends {}> extends Vue {

		readonly id = randomId()

		@Prop() autocomplete?: string
		@Prop({ type: Boolean }) disabled?: boolean
		@Prop({ default: 'label' }) flexible!: 'input' | 'label' | 'no'
		@Prop() label?: string
		@Prop({ default: 'horizontal' }) layout?: 'horizontal' | 'inline' | 'vertical'
		@Prop({ type: Boolean }) noChangeHighlight?: boolean
		@Prop({ type: Boolean }) readonly?: boolean
		@Prop({ type: Boolean }) required?: boolean
		@Prop({ default: 'normal' }) size?: 'normal' | 'large'

		@Prop({
			default: () => enumInput(null, []),
			type: null as any
		})
		modelValue!: EnumInput<Value>

		private inputElement?: HTMLSelectElement


		get actualAutocomplete() {
			if (this.autocomplete === 'really-off') {
				return this.id
			}

			return this.autocomplete
		}


		get actualLayout() {
			return (this.hasLabel || this.layout === 'inline') ? this.layout : 'standalone'
		}


		get classes() {
			return [
				this.$style.root,
				this.$style[`root-${this.actualLayout}`],
				this.$style[`root-${this.size}`]
			]
		}


		focus(options?: FocusOptions) {
			if (this.inputElement) {
				this.inputElement.focus(options)
			}
		}


		get hasChanges() {
			return !this.modelValue.isInitial()
		}


		get hasLabel() {
			return Boolean(this.label || this.$slots.label)
		}


		get inputClasses() {
			const classes = [
				this.$style.input,
				this.$style[`input-${this.actualLayout}`]
			]
			if (this.flexible === 'input') {
				classes.push(this.$style['input-flexible'])
			}
			if (!this.noChangeHighlight && this.hasChanges) {
				classes.push(this.$style.changed)
			}
			if (this.disabled) {
				classes.push(this.$style.disabled)
			}

			return classes
		}


		get inputListeners() {
			return {
				...this.$listeners,
				input: this.onInput
			}
		}


		isGroup<Value extends {}>(groupOrOption: EnumInputGroup<Value> | EnumInputOption<Value>): groupOrOption is EnumInputGroup<Value> {
			return groupOrOption instanceof EnumInputGroup
		}


		get labelClasses() {
			const classes = [
				this.$style.label,
				this.$style[`label-${this.actualLayout}`]
			]
			if (this.flexible === 'label') {
				classes.push(this.$style['label-flexible'])
			}
			if (!this.noChangeHighlight && this.hasChanges) {
				classes.push(this.$style.changed)
			}
			if (this.disabled) {
				classes.push(this.$style.disabled)
			}

			return classes
		}


		mounted() {
			this.inputElement = this.$refs.inputElement as HTMLSelectElement
		}


		onInput() {
			if (!this.inputElement) {
				return
			}

			const key = this.inputElement.value || null
			const data = this.modelValue.withKey(key)

			this.$emit('update:modelValue', data)
		}
	}
