
	import XProcessTags from '@/main/components/XProcessTags.vue'
	import { Inject, Options, Prop, Vue } from 'vue-property-decorator'
	import Api from '~/api/Api'
	import XIconButton from '~/components/XIconButton.vue'
	import XLink from '~/components/XLink.vue'
	import XLoadingAnimation from '~/components/XLoadingAnimation.vue'
	import XProcessId from '~/components/XProcessId.vue'
	import MProcess from '~/model/MProcess'
	import MProcessTag from '~/model/MProcessTag'
	import { processSessionManager } from '~/ProcessSessionManager'
	import icons from '~/styles/icons.module.scss'


	@Options({
		components: {
			XIconButton,
			XLink,
			XLoadingAnimation,
			XProcessId,
			XProcessTags
		},
		name: 'x-current-process-bar'
	})
	export default class extends Vue {

		readonly icons: { readonly [key: string]: string } = icons

		isClosing = false

		@Inject() api!: Api

		@Prop() tags!: MProcessTag[]
		@Prop() modelValue!: MProcess


		async onCloseButtonClicked() {
			const question = `Willst du den aktuellen Vorgang ${this.modelValue.externalId} schließen?\nDu kannst ihn jederzeit wieder öffnen.`
			if (!confirm(question)) {
				return
			}

			this.isClosing = true

			try {
				await processSessionManager.close()

				if (this.$route.name === 'dashboard') {
					window.location.reload()
				}
				else {
					await this.$router.push({ name: 'dashboard' })
				}
			}
			catch (e) {
				this.isClosing = false
			}
		}
	}
