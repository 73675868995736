import MJournalItem from '~/model/MJournalItem'
import MProcessParticipation from '~/model/MProcessParticipation'


export default class MProcessParticipantAddedJournalItem extends MJournalItem {

	static fieldSelection(): string {
		return `{
			participation ${MProcessParticipation.fieldSelection()}
		}`
	}


	readonly participation: MProcessParticipation


	constructor(properties: {
		readonly participation: MProcessParticipation
	}) {
		super('process participant added', [])

		this.participation = properties.participation
	}


	static fromJson(json: any) {
		return new this({
			participation: MProcessParticipation.fromJson(json.participation),
		})
	}
}
