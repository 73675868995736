import MEntity from '~/model/MEntity'
import MEntityParser from '~/model/MEntityParser'
import MJournalItem from '~/model/MJournalItem'


export default class MCommentJournalItem extends MJournalItem {

	static fieldSelection(): string {
		return `{
			message
			subjects ${MEntity.fieldSelection()}
		}`
	}


	readonly message: string


	constructor(properties: {
		readonly message: string
		readonly subjects: readonly MEntity[]
	}) {
		super('comment', properties.subjects)

		this.message = properties.message
	}


	static fromJson(json: any) {
		return new this({
			message: json.message,
			subjects: json.subjects.map(it => MEntityParser(it))
		})
	}
}
